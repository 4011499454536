import useTracking from '@src/composables/tracking/tracking'
import { ITrackingProps } from '@src/types/events.types'
import { ITooltipContextRefs, OnboardingTooltipFlow } from '@src/types/onboardingTooltips.types'
import { computed, watch } from '@vue/composition-api'

export function useOnboardingTooltipsTracking(contextRefs: ITooltipContextRefs) {
  const { trackMixpanelEvent } = useTracking()
  const isLastTooltip = computed(() => contextRefs.currentTooltipIndex.value + 1 >= contextRefs.totalTooltipsOfCurrentFlow.value)

  const commonClickEventProps = {
    component: 'onboarding_tooltip',
    element: 'button',
    type: 'click'
  } as ITrackingProps

  function trackOnboardingTooltipView() {
    trackMixpanelEvent('viewOnboardingTooltips', {
      automatic: false,
      component: 'onboarding_tooltip',
      type: 'view'
    })
  }

  function trackClickDismiss() {
    trackMixpanelEvent('clickDismissOnboarding', {
      onboarding_step: contextRefs.currentTooltipIndex.value + 1,
      ...commonClickEventProps
    })
  }

  function trackClickNext() {
    if (isLastTooltip.value) {
      trackMixpanelEvent('clickCompleteOnboarding', commonClickEventProps)
    } else {
      trackMixpanelEvent('clickNextOnboarding', {
        onboarding_step: contextRefs.currentTooltipIndex.value + 1,
        ...commonClickEventProps
      })
    }
  }
  watch(contextRefs.isHighlighting, (value) => {
    if (value && [ OnboardingTooltipFlow.Desktop, OnboardingTooltipFlow.Mobile ].includes(contextRefs.currentTooltipFlow.value)) {
      trackOnboardingTooltipView()
    }
  })

  return {
    trackClickDismiss,
    trackClickNext
  }
}
