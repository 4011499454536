import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'

type LocaleMessageObject = Record<string, string>;

export function translationApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/translations'
  const methods = {
    get(language: string) {
      return api.get<LocaleMessageObject>(`${baseUrl}/${language}`)
    },
    getAdmin() {
      return api.get<LocaleMessageObject>(`${baseUrl}/admin/en`)
    }
  }
  return createOptimizedAPI(methods)
}
