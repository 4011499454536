import streamApi from '@src/api/stream'
import { IAutocompleteItemModel } from '@src/types/autocomplete.types'
import { Ref, ref, watch } from '@vue/composition-api'
import { refThrottled } from '@vueuse/core'

export function useAlternativeQueriesSearch(hasSearchResults: Ref<boolean>, searchTerm: Ref<string | null>) {
  const alternativeQueries = ref<IAutocompleteItemModel[]>([])
  const throttledSearchTerm = refThrottled<string | null>(searchTerm, 300)

  watch([ hasSearchResults, throttledSearchTerm ], async () => {
    if (!hasSearchResults.value && searchTerm.value && searchTerm.value.length > 1) {
      const { data: { alternative_queries } } = await streamApi.throttled.fetchAlternativeQueries(searchTerm.value)
      alternativeQueries.value = alternative_queries.map((result) => ({ text: result, value: { image: null, text: result } }))
    }
  }, { immediate: true })

  return { alternativeQueries }
}
