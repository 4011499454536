import { ITrackingProps } from '@src/types/events.types'

export const CLICK_IN_BADGE_NOTIFICATION = 'clickInBadgeNotification'

export default {
  NotificationsDialogItem: {
    [CLICK_IN_BADGE_NOTIFICATION]: (
      customProps:
      {
        badge_type: boolean;
        badge_count: number;
        element: 'link';

      }
    ): ITrackingProps => ({
      type: 'click',
      component: 'notification',
      ...customProps
    })
  }
}
