import _some from 'lodash/some'
import _find from 'lodash/find'
import useStore from '@src/store'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import { computed } from '@vue/composition-api'
import { IDeeplinkFilterParams } from '@src/types/filters.types'
import { computedAsync } from '@vueuse/core'
import knowledgeSpacesApi from '@src/api/knowledgeSpaces'
import { useRoute } from './router'

export default function useKnowledgeSpaces() {
  const store = useStore()
  const route = useRoute()

  const ownKnowledgeSpaces = computed<IKnowledgeSpace[]>(() => store.state.knowledgeSpaces.ownKnowledgeSpaces)

  const currentKnowledgeSpaceId = computed(() => Number((route.query as IDeeplinkFilterParams).space) || undefined)

  const currentKnowledgeSpace = computedAsync(async () => {
    const knowledgeSpaceFromMyList = _find(ownKnowledgeSpaces.value, { id: currentKnowledgeSpaceId.value })
    if (knowledgeSpaceFromMyList) {
      return knowledgeSpaceFromMyList
    } if (currentKnowledgeSpaceId.value) {
      const { data } = await knowledgeSpacesApi.cached.get(currentKnowledgeSpaceId.value)
      return data
    }
    return undefined
  }, undefined)

  const isMemberOfCurrentKnowledgeSpace = computed<boolean>(() => {
    return _some(ownKnowledgeSpaces.value, (space) => space.id === currentKnowledgeSpaceId.value)
  })
  const needsToRequestAccessForCurrentKnowledgeSpace = computed<boolean>(() => !!currentKnowledgeSpace.value?.is_closed && !isMemberOfCurrentKnowledgeSpace.value)

  async function loadKnowledgeSpace() {
    await store.dispatch('knowledgeSpaces/load', null, { root: true })
  }

  return {
    loadKnowledgeSpace,
    currentKnowledgeSpaceId,
    ownKnowledgeSpaces,
    currentKnowledgeSpace,
    isMemberOfCurrentKnowledgeSpace,
    needsToRequestAccessForCurrentKnowledgeSpace
  }
}
