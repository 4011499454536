



































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import { useFormRules } from '@src/composables/useFormValidation'

export default defineComponent({
  name: 'SMEditAnnouncementComment',
  props: {
    editMode: {
      type: Boolean,
      required: true
    },
    initialValue: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const commentInput = ref('')
    const { requiredRule } = useFormRules()

    onMounted(() => {
      commentInput.value = props.initialValue
    })
    function postComment(value: string) {
      if (value.length > 0) {
        emit('submit', value)
        commentInput.value = ''
      }
    }

    return {
      commentInput,
      postComment,
      requiredRule
    }
  }
})
