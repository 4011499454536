import { gql, ApolloQueryResult } from '@apollo/client'
import { ContextSetter } from '@apollo/client/link/context'
import {
  ITermsGetVersionsResponse,
  ITermsTranslations,
  ITermsVersion,
  ITermsTranslationUpdateInput,
  ITermsTranslationDeleteInput,
  ITermsOfUseUpdateInput,
  ITermsGetLatestPublishedResponse,
  ITermsGetLatestPublishedIdResponse
} from '../../../types/terms.types'
import { apolloClientFactory } from './apolloClientFactory'

export function termsGraphQlApiFactory(authContextSetter: ContextSetter) {
  const graphqlClient = apolloClientFactory(authContextSetter)

  return {
    async getVersions(): Promise<ITermsGetVersionsResponse> {
      const query = gql`
        query getVersions {
          latestPublishedTerms{
            id
            title
            created
            updated
            published
            translations {
              id
              language
              title
              text
              created
              updated
            }
          }
          draftTerms{
            id
            title
            created
            updated
            published
            translations {
              id
              language
              title
              text
              created
              updated
            }
          }
        }
      `

      const response = await graphqlClient.query({
        query,
        fetchPolicy: 'network-only'
      })
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      return response.data
    },
    async createDraft(): Promise<ITermsVersion> {
      const mutation = gql`
        mutation {
          createTerms{
            id
            title
            created
            updated
            published
            translations {
              id
              language
              title
              text
              created
              updated
            }
          }
        }
      `

      const response = await graphqlClient.mutate({
        mutation
      }) as ApolloQueryResult<{ createTerms: ITermsVersion }>
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      return response.data.createTerms
    },
    async updateVersion(termsOfUseUpdateInput: ITermsOfUseUpdateInput): Promise<ITermsVersion> {
      const mutation = gql`
        mutation updateTermsOfUse($termsOfUseUpdateInput: TermsOfUseUpdateInput!) {
          updateTermsOfUse(input: $termsOfUseUpdateInput) {
            id
            title
            created
            updated
            published
            translations {
              id
              language
              title
              text
              created
              updated
            }
          }
        }
      `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          termsOfUseUpdateInput
        }
      })

      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      return response.data.updateTermsOfUse
    },
    async updateTranslation(termsTranslationUpdateInput: ITermsTranslationUpdateInput): Promise<ITermsTranslations> {
      const mutation = gql`
        mutation updateTermsTranslation($termsTranslationUpdateInput: TermsTranslationUpdateInput!) {
          updateTermsTranslation(input: $termsTranslationUpdateInput) {
            id
            language
            title
            text
            created
            updated
          }
        }
      `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          termsTranslationUpdateInput
        }
      })

      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      return response.data.termsTranslationUpdateInput
    },
    async deleteTranslation(termsTranslationDeleteInput: ITermsTranslationDeleteInput): Promise<number> {
      const mutation = gql`
        mutation deleteTermsTranslation($termsTranslationDeleteInput: TermsTranslationDeleteInput!) {
          deleteTermsTranslation(input: $termsTranslationDeleteInput)
        }
     `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          termsTranslationDeleteInput
        }
      })

      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      return response.data.termsTranslationDeleteInput
    },
    async publishVersion(id: number): Promise<ITermsVersion> {
      const mutation = gql`
        mutation publishTermsDraft($id: Int!) {
          publishTerms(id: $id) {
            id
            title
            created
            updated
            published
            translations {
              id
              language
              title
              text
              created
              updated
            }
          }
        }
      `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          id
        }
      })
      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      return response.data.publishTerms
    },
    async deleteDraft(id: number): Promise<number> {
      const mutation = gql`
        mutation deleteTermsDraft($id: Int!) {
          deleteTermsDraft(id: $id)
        }
     `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          id
        }
      })

      if (response.errors) {
        throw new Error(response.errors[0].message)
      }
      return response.data.deleteTermsDraft
    },
    async getLatestPublishedTerms(): Promise<ITermsGetLatestPublishedResponse> {
      const query = gql`
        query getVersions {
          latestPublishedTerms{
            id
            title
            created
            updated
            published
            translations {
              id
              language
              title
              text
              created
              updated
            }
          }
        }
      `

      const response = await graphqlClient.query({
        query
      })
      return response.data
    },
    async getLatestPublishedTermsId(): Promise<{ data: ITermsGetLatestPublishedIdResponse}> {
      const query = gql`
        query getVersions {
          latestPublishedTerms{
            id
          }
        }
      `

      return graphqlClient.query({
        query
      })
    }
  }
}
