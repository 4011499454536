import { ITrackingProps } from '@src/types/events.types'

const commonProps = {
  component: 'search_result_box_people',
  element: 'user_card',
  type: 'click'
} as ITrackingProps

export const CLICK_PROFILE = 'clickProfile'

export default {
  SearchResultPerson: {
    [CLICK_PROFILE]: (customProps: {is_own_profile: boolean}): ITrackingProps => ({
      ...commonProps,
      ...customProps
    })
  },
  PeopleSearchResultItem: {
    [CLICK_PROFILE]: (customProps: {is_own_profile: boolean}): ITrackingProps => ({
      ...commonProps,
      ...customProps
    })
  }
}
