


























import {
  PropType, defineComponent, computed
} from '@vue/composition-api'
import useUser from '@src/composables/user'
import { useRoute } from '@src/composables/router'
import { IMenuItem } from '../composables/useAdminMenu'

export default defineComponent({
  name: 'SMAdminMenuItem',
  props: {
    item: {
      type: Object as PropType<IMenuItem>,
      required: true
    },
    isSubItem: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { roles } = useUser()
    const route = useRoute()
    const userHasPrivilege = computed(() => props.item.roles.some((role) => roles.value.includes(role)))

    function isActive(item: IMenuItem) {
      return item.routerLinkTo && route.path.includes(item.routerLinkTo)
    }

    return {
      userHasPrivilege,
      isActive
    }
  }
})
