








































import { getAvatarUrl } from '@src/utilities/userUtils'
import {
  PropType, computed, defineComponent, ref, toRefs
} from '@vue/composition-api'

export default defineComponent({
  name: 'SMUserAvatar',
  props: {
    anonymous: {
      type: Boolean,
      default: false
    },
    elevation: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 30
    },
    resolutionFactor: {
      type: Number,
      default: 2
    },
    imageUrl: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    showLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { elevation } = toRefs(props)

    const imgLoadingError = ref(false)

    const elevationClass = computed(() => `elevation-${elevation.value}`)

    return {
      imgLoadingError,
      elevationClass,
      getAvatarUrl
    }
  }
})
