import _set from 'lodash/fp/set'
import { ISkills } from '@src/types/user.types'
import {
  GetterTree, Module, MutationTree, ActionTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'

import userApi from '@src/api/user'

export interface IOtherUsersSkills {
  skills: Partial<ISkills>;
  loading: boolean;
  error: boolean;
}
export interface IOtherUsersSkillsState {
  skills: { [key: number]: IOtherUsersSkills };
}

const state: IOtherUsersSkillsState = {
  skills: {}
}
const defaultUserSkills = { skills: {}, loading: true, error: false }

const getters: GetterTree<IOtherUsersSkillsState, IRootState> = {
  getSkillsByUserId: (state) => (id: number): IOtherUsersSkills => {
    return id && id in state.skills ? state.skills[id] : defaultUserSkills
  }
}

const actions: ActionTree<IOtherUsersSkillsState, IRootState> = {
  async loadSkillsByUserId({ commit, state }, userId: number): Promise<void> {
    // if the user skills has already been fetched or if it has error
    if (!(userId in state.skills) || state.skills[userId].error) {
      commit('setOtherUsersSkillsLoading', { userId, loading: true })
      commit('setOtherUsersSkillsError', { userId, error: false })
      try {
        const { data: skills } = await userApi.throttled.getSkills(userId)
        commit('setOtherUsersSkills', { userId, skills })
      } catch {
        commit('setOtherUsersSkillsError', { userId, error: true })
      } finally {
        commit('setOtherUsersSkillsLoading', { userId, loading: false })
      }
    }
  }
}

const mutations: MutationTree<IOtherUsersSkillsState> = {
  setOtherUsersSkills: (state, otherUser: {userId: number; skills: ISkills}): void => {
    state.skills = _set(`${otherUser.userId}.skills`, otherUser.skills, state.skills)
  },
  setOtherUsersSkillsLoading: (state, otherUser: {userId: number; loading: boolean}) => {
    state.skills = _set(`${otherUser.userId}.loading`, otherUser.loading, state.skills)
  },
  setOtherUsersSkillsError: (state, otherUser: {userId: number; error: boolean}) => {
    state.skills = _set(`${otherUser.userId}.error`, otherUser.error, state.skills)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<IOtherUsersSkillsState, IRootState>
