
















import { computed, defineComponent, ref } from '@vue/composition-api'
import {
  useAuthIframeCommunication,
  useRouterIframeCommunication,
  useTranslationIframeCommunication
} from '@src/composables/angularWrapper'
import MainLayout from '@src/layouts/MainLayout.vue'
import { useRoute } from './composables/router'

export default defineComponent({
  name: 'AngularWrapper',
  components: {
    MainLayout
  },
  setup() {
    const iframeElement = ref<HTMLIFrameElement|null>(null)

    const { translationsReady } = useTranslationIframeCommunication(iframeElement)

    const { isPegasusRoute, isRouterReady, iframeRoute } = useRouterIframeCommunication(iframeElement)

    useAuthIframeCommunication()

    /*
    Ugly hack to force a fixed positioning for the insights page depending on the route.
    The desktop version of the insights page is too wide for the iframe, so it would show the tablet version inside the iframe.
    */
    const route = useRoute()
    const fixedPosition = computed(() => route.path.startsWith('/insights'))
    /* Ugly hack end */

    const angularIframeVisible = computed(() => !isPegasusRoute.value && isRouterReady.value && translationsReady.value)

    return {
      iframeRoute,
      iframeElement,
      fixedPosition,
      angularIframeVisible
    }
  }
})
