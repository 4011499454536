


































import { IAnnouncementComplete } from '@src/types/announcements.types'
import {
  computed, defineComponent, PropType, ref, toRefs
} from '@vue/composition-api'
import SMAnnouncementComments from '@src/ui/SMAnnouncements/SMAnnouncementComments.vue'
import SMDialog from '@src/ui/SMDialog/index.vue'
import SMAnnouncementContent from './SMAnnouncementContent.vue'
import { useAnnouncementViewTracking } from './composables/useAnnouncementViewTracking'

export default defineComponent({
  name: 'SMAnnouncementDialog',
  components: {
    SMAnnouncementComments,
    SMAnnouncementContent,
    SMDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    content: {
      type: Object as PropType<IAnnouncementComplete>,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const { content } = toRefs(props)
    const dialog = ref(false)
    const isOpen = computed({
      get() {
        return dialog.value || props.value
      },
      set(value: boolean) {
        dialog.value = value
        emit('input', value)
      }
    })

    useAnnouncementViewTracking(content, isOpen)

    return { isOpen }
  }
})
