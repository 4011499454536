import { AxiosPromise } from 'axios'
import {
  IKnowledgeSpace,
  IKnowledgeSpaceInput,
  IKnowledgeSpaceMembersResponse,
  IKnowledgeSpaceMembershipRequestResponse,
  IKnowledgeSpaceMemberChangeSet,
  IncludeOptions,
  IMemberQueryParams
} from '@src/types/knowledgeSpaces.types'
import createAxiosInstance, { createOptimizedAPI } from '.'

interface IKnowledgeSpacesApi {
  getAll(): AxiosPromise<{ items: IKnowledgeSpace[]; total: number }>;
  get(id: number): AxiosPromise<IKnowledgeSpace>;
  create(knowledgeSpace: IKnowledgeSpaceInput): AxiosPromise<IKnowledgeSpace>;
  update(id: number, knowledgeSpace: IKnowledgeSpaceInput): AxiosPromise<IKnowledgeSpace>;
  delete(id: number): AxiosPromise<void>;
  getMembers(id: number, params: IMemberQueryParams): AxiosPromise<IKnowledgeSpaceMembersResponse>;
  getModerators(id: number, include?: IncludeOptions[]): AxiosPromise<IKnowledgeSpaceMembersResponse>;
  updateMembers(id: number, changeSet: IKnowledgeSpaceMemberChangeSet): AxiosPromise<void>;
  updateModerators(id: number, changeSet: IKnowledgeSpaceMemberChangeSet): AxiosPromise<void>;
  addMembers(id: number, members: number[]): AxiosPromise<void>;
  getMembershipRequests(id: number): AxiosPromise<IKnowledgeSpaceMembershipRequestResponse>;
  grantMembership(id: number, userIds: number[]): AxiosPromise<void>;
}

const api = createAxiosInstance('knowledge-spaces')

const knowledgeSpacesApi: IKnowledgeSpacesApi = {
  getAll() {
    return api.get('')
  },
  get(id) {
    return api.get(`${id}`)
  },
  create(knowledgeSpace) {
    return api.post('', knowledgeSpace)
  },
  update(id, knowledgeSpace) {
    return api.put(`${id}`, knowledgeSpace)
  },
  delete(id) {
    return api.delete(`${id}`)
  },
  getMembers(id, {
    include = [ 'technical' ], limit = 30, query = '', offset = 0, filter = 'active'
  }: IMemberQueryParams) {
    const includeParam = include.join(',')
    return api.get(`${id}/members`, includeParam ? {
      params: {
        include: includeParam, limit, query, offset, filter
      }
    } : undefined)
  },
  getModerators(id, include = [ 'technical' ]) {
    const includeParam = include.join(',')
    return api.get(`${id}/moderators`, includeParam ? { params: { include: includeParam } } : undefined)
  },
  updateMembers(id, changeSet) {
    return api.post(`${id}/members`, changeSet)
  },
  updateModerators(id, changeSet) {
    return api.post(`${id}/moderators`, changeSet)
  },
  addMembers(id, members) {
    return api.post(`${id}/members`, {
      add: members
    })
  },
  getMembershipRequests(id) {
    return api.get(`${id}/membership-requests`)
  },
  grantMembership(knowledgeSpaceId, userIds) {
    return api.put(`${knowledgeSpaceId}/membership-requests`, {
      action_type: 'grant',
      users: userIds
    })
  }
}

export default {
  ...knowledgeSpacesApi,
  ...createOptimizedAPI(knowledgeSpacesApi)
}
