























































import SMDialog from '@src/ui/SMDialog/index.vue'
import {
  PropType, computed, defineComponent, toRefs
} from '@vue/composition-api'
import { ActivationFlowStep } from '@src/types/activationFlow.types'

const educationDialogTypeMap: Record<ActivationFlowStep.EDUCATION_DIALOG_EXPERTS | ActivationFlowStep.EDUCATION_DIALOG_USER_INTERACTION, { title: string, body: string, gifSrc: string, btn: string }> = {
  [ActivationFlowStep.EDUCATION_DIALOG_EXPERTS]: {
    title: 'activation-flow.education-dialog.experts.title',
    body: 'activation-flow.education-dialog.experts.body',
    gifSrc: 'education-dialog-experts.gif',
    btn: 'global.continue'
  },
  [ActivationFlowStep.EDUCATION_DIALOG_USER_INTERACTION]: {
    title: 'activation-flow.education-dialog.user-interaction.title',
    body: 'activation-flow.education-dialog.user-interaction.body',
    gifSrc: 'education-dialog-user-interaction.gif',
    btn: 'global.finish'
  }
}

export default defineComponent({
  name: 'SMEducationDialog',
  components: {
    SMDialog
  },
  props: {
    isDialogVisible: {
      type: Boolean,
      required: true
    },
    type: {
      type: String as PropType<ActivationFlowStep.EDUCATION_DIALOG_EXPERTS | ActivationFlowStep.EDUCATION_DIALOG_USER_INTERACTION>,
      required: true
    }
  },
  setup(props) {
    const { type } = toRefs(props)

    const educationDialogType = computed(() => educationDialogTypeMap[type.value])

    return {
      educationDialogType
    }
  }
})
