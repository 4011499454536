function autoQuestionMarkInputHandler(event: any) {
  const { target } = event as InputEvent & { target: HTMLTextAreaElement }
  const textWithoutQuestionMarkEnding = target.value.replace(/\?$/, '')
  target.value = `${textWithoutQuestionMarkEnding}?`

  const positionBeforeQuestionMark = textWithoutQuestionMarkEnding.length
  if (target.selectionEnd >= positionBeforeQuestionMark) {
    target.setSelectionRange(positionBeforeQuestionMark, positionBeforeQuestionMark)
  }
}

const directive = {
  bind(el: HTMLElement) {
    el.addEventListener('input', autoQuestionMarkInputHandler)
  },
  unbind(el: HTMLElement) {
    el.removeEventListener('input', autoQuestionMarkInputHandler)
  }
}

export default directive
