import { v4 as uuidv4 } from 'uuid'
import {
  computed, Ref, ref, watch
} from '@vue/composition-api'
import i18n from '@src/plugins/i18n'
import { AxiosError } from 'axios'
import bytes from '@src/utilities/filters/bytes'
import { IAttachment, IAttachmentFile, IComputedAttachment } from '@src/types/attachments.types'
import attachmentsApi from '@src/api/attachments'
import { IApiResponseError } from '@src/types/errors.types'
import useAttachmentValidation, { ATTACHMENT_FILE_MAX_SIZE } from '@src/composables/attachmentValidation'

export default function useAttachments(remoteAttachments: Ref<IAttachment[]>, entityId: Ref<number | undefined>, entity: 'questions' | 'solutions') {
  const { isAllowedFiletype, isWithinAllowedSize, getFileExtension } = useAttachmentValidation()
  const remoteFiles = ref(remoteAttachments.value)
  const localFiles = ref<IAttachmentFile[]>([])
  const attachments = computed<IComputedAttachment[]>(() => (
    [
      ...remoteFiles.value.map(({ name, size, id }) => ({
        name, size: bytes(size), isUploading: false, id, isPendingUpload: false
      })),
      ...localFiles.value.map(({
        file: { name, size }, isUploading, id, isPendingUpload
      }) => ({
        name, size: bytes(size), isUploading, id, isPendingUpload
      })) ]
  ))
  const attachmentError = ref<string | undefined>(undefined)

  function addFile(file: File) {
    const attachment = {
      file,
      isPendingUpload: true,
      isUploading: false,
      id: uuidv4()
    }

    if (!isAllowedFiletype(file.name)) {
      attachmentError.value = i18n.t('attachment.error.type', { type: getFileExtension(file.name) }).toString()
    } else if (!isWithinAllowedSize(file.size)) {
      attachmentError.value = i18n.t('attachment.error.size', {
        size: bytes(file.size),
        max: bytes(ATTACHMENT_FILE_MAX_SIZE)
      }).toString()
    } else {
      localFiles.value = [ ...localFiles.value, attachment ]

      if (entityId.value) {
        upload(attachment)
      }
    }
  }

  function removeAttachment(attachment: IComputedAttachment) {
    if (attachment.isPendingUpload) {
      localFiles.value = localFiles.value.filter(({ id }) => id !== attachment.id)
    } else {
      attachmentsApi.deleteAttachment(attachment.id as number)
      remoteFiles.value = remoteFiles.value.filter(({ id }) => id !== attachment.id)
    }
  }

  function uploadPendingAttachments() {
    localFiles.value.forEach(upload)
  }

  async function upload(attachment: IAttachmentFile) {
    attachment.isUploading = true // eslint-disable-line no-param-reassign
    try {
      // upload
      const { data } = await attachmentsApi.uploadEntityAttachment(entity, entityId.value!, attachment.file)
      // add to remote files
      remoteFiles.value = [ ...remoteFiles.value, data ]
    } catch (error) {
      const errorResponse = (error as AxiosError<IApiResponseError>).response?.data?.error
      if (errorResponse?.code === 'ContentTypeNotSupported') {
        attachmentError.value = errorResponse.error_user_msg || errorResponse.message
      }
    } finally {
      // remove from local files
      localFiles.value = localFiles.value.filter((local) => local.id !== attachment.id)
      attachment.isUploading = false // eslint-disable-line no-param-reassign
    }
  }

  watch(remoteAttachments, (newRemoteAttachments) => {
    remoteFiles.value = newRemoteAttachments
  })

  watch(entityId, (id) => {
    if (id) {
      uploadPendingAttachments()
    }
  }, { immediate: true })

  return {
    attachments, addFile, removeAttachment, attachmentError
  }
}
