






































































































import {
  computed, defineComponent
} from '@vue/composition-api'
import useTracking from '@src/composables/tracking/tracking'
import SMAskButton from '@src/ui/SMAsk/components/askQuestion/SMAskButton.vue'
import KnowledgeSpacesSidenavMenuItem from '@src/views/KnowledgeSpaces/components/navigation/KnowledgeSpacesSidenavMenuItem.vue'
import { mapActions } from 'vuex'
import useRealmSettings from '@src/composables/realmSettings'
import useUser from '@src/composables/user'
import { useFeatureToggles } from '@src/composables/featureToggles'
import { PrivilegedUserRoles } from '@src/types/user.types'
import { logout } from '@src/utilities/auth'
import { useRoute } from '@src/composables/router'
import useKnowledgeSpaces from '@src/composables/knowledgeSpaces'
import { CLICK_FEED } from '@src/plugins/analytics/events/views/knowledgeSpaces.events'
import { createOnboardingTooltipDirective } from '@src/ui/SMOnboardingTooltip/directives/onboardingTooltip'
import { useInviteButton } from '@src/ui/SMInviteDialog/useInviteButton'
import { useOAuthClientId } from '@src/composables/useOAuthClientId'
import useAdminMenu from '@src/ui/SMAdminMenu/composables/useAdminMenu'
import SMDiscussButton from '@src/ui/SMDiscussButton/index.vue'
import SMSidenavMenuItem from './components/SMSidenavMenuItem.vue'
import SMSidenavMenuGroupTitle from './components/SMSidenavMenuGroupTitle.vue'
import { useAngularRouterState } from '../composables/useAngularRouterState'
import { useSidenavState } from '../composables/useSidenavState'

interface IMenuItem {
  active: boolean;
  isEnabled?: boolean;
  icon: string;
  title: string;
  routerLinkTo?: string;
}

export default defineComponent({
  name: 'SMSidenavCore',
  components: {
    SMAskButton,
    SMDiscussButton,
    KnowledgeSpacesSidenavMenuItem,
    SMSidenavMenuItem,
    SMSidenavMenuGroupTitle
  },
  directives: {
    onboardingTooltip: createOnboardingTooltipDirective()
  },
  setup() {
    const { trackMixpanelEvent } = useTracking()
    const { menuItems } = useAdminMenu()
    const { isSidenavOpen, toggleSidenav } = useSidenavState()
    const { isAdminStateActive, isInsightsStateActive } = useAngularRouterState()
    const { realmSettings } = useRealmSettings()
    const { roles, isAdmin } = useUser()
    const { isInvitationEnabled, openInviteDialog } = useInviteButton({ component: 'main_navigation' })
    const route = useRoute()

    const { currentKnowledgeSpaceId } = useKnowledgeSpaces()
    const { llmConversationsEnabled, insightsKillSwitchEnabled, fakeDoorDiscussionEnabled } = useFeatureToggles()
    const { isMsTeamsPersonalApp } = useOAuthClientId()

    const isKnowledgeSpacesEnabled = computed(() => realmSettings.value?.knowledge_spaces.knowledge_spaces_enabled)
    const isInsightsEnabled = computed(() => (realmSettings.value?.expert_search.show_expert_search || roles.value.includes(PrivilegedUserRoles.UserStatisticsAdmin)) && !insightsKillSwitchEnabled.value)
    const isHomepageEnabled = computed(() => realmSettings.value?.homepage.enabled)
    const expertSearchEnabled = computed(() => realmSettings.value?.expert_search.show_expert_search)
    const networkName = computed(() => realmSettings.value?.metadata.realm_name ?? '')
    const adminRoute = computed(() => {
      const validMenuItem = menuItems.value.find((item) => item.roles.some((role) => roles.value.includes(role)) && item.isEnabled)
      if (validMenuItem?.subsections) {
        const validSubSection = validMenuItem.subsections.find((item) => item.roles.some((role) => roles.value.includes(role)) && item.isEnabled)
        return validSubSection?.routerLinkTo || ''
      }
      return validMenuItem?.routerLinkTo || ''
    })

    function trackMenuItemClick(eventName: string) {
      trackMixpanelEvent(eventName, {
        component: 'main_navigation',
        element: 'list_item',
        type: 'click'
      })
    }
    function trackExpertConnectClick() {
      trackMixpanelEvent('clickExpertConnect', { component: 'main_navigation' })
    }
    function trackFeedClick() {
      trackMixpanelEvent(CLICK_FEED, { component: 'main_navigation' })
    }
    function trackStarGPTlick() {
      trackMixpanelEvent('clickStarGPTEntryPoint', { component: 'main_navigation_sidenav' })
    }

    const homepageMenuItem = computed<IMenuItem>(() => ({
      routerLinkTo: '/',
      active: route.name === 'homepage',
      icon: 'ico-sm-home-line',
      title: 'home.button',
      isEnabled: isHomepageEnabled.value
    }))

    const feedMenuItem = computed<IMenuItem>(() => ({
      routerLinkTo: '/stream',
      active: (route.name === 'stream' || route.name === 'question') && !currentKnowledgeSpaceId.value,
      icon: 'ico-sm-feed-line',
      title: 'ks.sidenav.title'
    }))

    const starGptMenuItem = computed<IMenuItem>(() => ({
      routerLinkTo: '/conversation',
      active: route.name === 'conversation',
      icon: 'ico-sm-StarGPT-icon',
      title: 'ks.sidenav.starGpt'
    }))

    const insightsMenuItem = computed<IMenuItem>(() => ({
      active: isInsightsStateActive.value,
      icon: 'ico-sm-profile-line',
      title: 'header.button.user',
      isEnabled: isInsightsEnabled.value,
      routerLinkTo: '/insights'
    }))

    const expertConnectMenuiItem = computed<IMenuItem>(() => ({
      title: 'expert-search.title',
      isEnabled: expertSearchEnabled.value,
      routerLinkTo: '/expert-connect',
      active: route.name === 'expert-connect',
      icon: 'ico-sm-person_search_line'
    }))

    const settingsMenuItem = computed<IMenuItem>(() => ({
      active: route.name === 'settings',
      icon: 'ico-sm-settings-line',
      title: 'settings',
      routerLinkTo: '/settings'
    }))

    const userProfileMenuItem = computed<IMenuItem>(() => ({
      active: route.name === 'user',
      icon: 'ico-sm-user',
      title: 'global.view-profile',
      routerLinkTo: '/user'
    }))

    const adminMenuItem = computed<IMenuItem>(() => ({
      active: isAdminStateActive.value,
      icon: 'ico-sm-admin-main-line',
      title: 'menu.admin.app',
      routerLinkTo: adminRoute.value,
      isEnabled: isAdmin.value
    }))

    const inviteMenuItem = computed<IMenuItem>(() => ({
      active: false,
      icon: 'ico-sm-recommend',
      title: 'invite.button.user-to-network',
      isEnabled: isInvitationEnabled.value
    }))

    const userGuideMenuItem = computed<IMenuItem>(() => ({
      active: false,
      title: 'user-guide.header.tooltip.title',
      icon: 'ico-sm-user-guide-line'
    }))

    const logoutMenuItem = computed<IMenuItem>(() => ({
      active: false,
      icon: 'ico-sm-sign-out',
      title: 'dashboard.header.menu.logout'
    }))

    return {
      isSidenavOpen,
      toggleSidenav,
      trackMenuItemClick,
      trackExpertConnectClick,
      trackStarGPTlick,
      logout,
      openInviteDialog,
      trackFeedClick,
      fakeDoorDiscussionEnabled,
      networkName,
      isMsTeamsPersonalApp,
      isKnowledgeSpacesEnabled,
      isAdmin,
      homepageMenuItem,
      feedMenuItem,
      starGptMenuItem,
      insightsMenuItem,
      settingsMenuItem,
      userGuideMenuItem,
      expertConnectMenuiItem,
      userProfileMenuItem,
      logoutMenuItem,
      inviteMenuItem,
      adminMenuItem,
      llmConversationsEnabled
    }
  },
  methods: {
    ...mapActions('userGuideSidenav', [ 'showDrawer' ]) as {
      showDrawer: () => Promise<void>;
    }
  }
})
