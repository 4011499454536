// attributes can only hold strings; here's a convenience function
export function coerceAttributeType(value: string | null) {
  if (typeof value === 'string') {
    // maybe it's a boolean
    if (value === 'true') {
      return true
    } if (value === 'false') {
      return false
    }
    // maybe it's a number
    if (!Number.isNaN(Number(value))) {
      return Number(value)
    }
    return value
  }
  return value
}

export function getPrefixedAttributes(el: HTMLElement, prefix: RegExp) {
  return el.getAttributeNames()
    .filter((attr) => prefix.test(attr))
    .reduce((propsList, attr) => (
      {
        ...propsList,
        [attr.replace(prefix, '')]: coerceAttributeType(el.getAttribute(attr))
      }
    ), {})
}
