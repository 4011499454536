import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'
import { IInitialExpertise } from '../../../types/initialExpertise.types'
import {
  IOverviewConceptResponse
} from '../../../types/concept.types'

interface IExpertiseTrendResponse {
  concept_id: string;
  current_weight: number;
  past_weight: number;
  until_period: string;
}
interface IConceptExpertCount {
  count: number
}

export function ulfShieldExpertsApiFactory(api: AxiosInstance) {
  const baseUrl = '/api/v2/learning/experts'
  const methods = {
    getExpertCountForConcept(concept_id: string) {
      return api.get<IConceptExpertCount>(`${baseUrl}/count`, {
        params: {
          concept_id
        }
      })
    },
    setInitialUserExpertise(id: string, concepts: IInitialExpertise[]) {
      return api.post<void>(`${baseUrl}/${id}/expertise/initial`, { concepts })
    },
    getExpertiseTrendForConcept(globalUserId: string, conceptId: string, beforeDays = 180) {
      return api.get<IExpertiseTrendResponse>(`${baseUrl}/${globalUserId}/expertise/trend`, {
        params: {
          concept_id: conceptId,
          before_days: beforeDays
        }
      })
    },
    getExpertisePoints(concept_id: string, userId: string) {
      return api.get<number>(`${baseUrl}/${userId}/expertise/${concept_id}/points`)
    },
    getVisibleExperience(userId: string, language: string) {
      return api.get<IOverviewConceptResponse[]>(`${baseUrl}/${userId}/expertise/experience`, {
        params: {
          language
        }
      })
    },
    getHiddenExperience(userId: string, language: string) {
      return api.get<IOverviewConceptResponse[]>(`${baseUrl}/${userId}/expertise/experience/hidden`, {
        params: {
          language
        }
      })
    },
    setExperienceFlag(userId: string, conceptId: string, type: 'hidden' | 'highlighted') {
      return api.put(`${baseUrl}/${userId}/expertise/experience`, {
        agent_has_concept_type: type,
        concept_id: conceptId
      })
    },
    deleteExperienceFlag(userId: string, conceptId: string) {
      return api.delete(
        `${baseUrl}/${userId}/expertise/experience`,
        {
          params: {
            concept_id: conceptId
          }
        }
      )
    },
    addManualExpertise(userId: string, concepts: IInitialExpertise[]) {
      return api.post<void>(`${baseUrl}/${userId}/expertise/manual`, { concepts })
    }
  }
  return createOptimizedAPI(methods)
}
