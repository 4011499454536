import {
  Ref, watch
} from '@vue/composition-api'
import debounce from 'lodash/fp/debounce'
import isEqual from 'lodash/isEqual'
import questionsApi from '@src/api/questions'
import { AUTOSAVE_DEBOUNCE_TIME } from '../constants'

type OrderedWatchValues = [string, string, number, number]

export default function useAutosave({
  title,
  description,
  category,
  knowledgeSpace,
  questionId
} : {
  title: Ref<string>,
  description: Ref<string>,
  category: Ref<undefined | null | number>,
  knowledgeSpace: Ref<null | number>,
  questionId: Ref<number | undefined>
}) {
  const save = debounce(AUTOSAVE_DEBOUNCE_TIME)(async ([ titleValue, descriptionValue, categoryValue, ksValue ]: OrderedWatchValues) => {
    const questionPayload = {
      title: titleValue,
      description: descriptionValue,
      category: categoryValue,
      knowledge_space: ksValue
    }

    // This function can potentially be called multiple times after each other,
    // that's why the following differentiation is made:
    if (questionId.value) {
      // If POST /questions already gave a response with a question id
      await questionsApi.updateQuestion(questionId.value, questionPayload)
    }
  })

  watch([ title, description, category, knowledgeSpace ], (newValues, oldValues) => {
    if (!isEqual(newValues, oldValues)) {
      save(newValues as OrderedWatchValues)
    }
  })
}
