import {
  ActionTree, GetterTree, Module, MutationTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import userApi from '@src/api/user'
import { INotificationChannelSettings } from '@src/types/notifications.types'

export interface ICommunicationSettingsState {
  loading: boolean;
  updating: boolean;
  error: boolean;
  emailSettings?: INotificationChannelSettings;
  webPushSettings?: INotificationChannelSettings
}

export const state: ICommunicationSettingsState = {
  loading: true,
  updating: false,
  error: false,
  emailSettings: undefined,
  webPushSettings: undefined
}

const getters: GetterTree<ICommunicationSettingsState, IRootState> = {
  getCommunicationSettingsLoading(state): boolean { return state.loading },
  getCommunicationSettingsUpdating(state): boolean { return state.updating },
  getCommunicationSettingsError(state): boolean { return state.error },
  getEmailSettings(state): INotificationChannelSettings | undefined { return state.emailSettings },
  getWebPushSettings(state): INotificationChannelSettings | undefined { return state.webPushSettings }
}

const actions: ActionTree<ICommunicationSettingsState, IRootState> = {
  async loadNotificationSettings({ commit, state }, force = false): Promise<void> {
    if (!state.emailSettings || !state.webPushSettings || force) {
      commit('setLoading', true)
      commit('setError', false)

      try {
        const { data: emailSettings } = await userApi.getEmailSettings()
        const { data: webPushSettings } = await userApi.getWebPushSettings()
        commit('setEmailSettings', emailSettings)
        commit('setWebPushSettings', webPushSettings)
      } catch {
        commit('setError', true)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  async updateEmailSettings({ commit }, settings: INotificationChannelSettings): Promise<void> {
    commit('setUpdating', true)

    try {
      const { data: emailSettings } = await userApi.setEmailSettings(settings)
      commit('setEmailSettings', emailSettings)
    } finally {
      commit('setUpdating', false)
    }
  },
  async updateWebPushSettings({ commit }, settings: INotificationChannelSettings): Promise<void> {
    commit('setUpdating', true)

    try {
      const { data: webPushSettings } = await userApi.setWebPushSettings(settings)
      commit('setWebPushSettings', webPushSettings)
    } finally {
      commit('setUpdating', false)
    }
  }
}

const mutations: MutationTree<ICommunicationSettingsState> = {
  setEmailSettings(state, emailSettings: INotificationChannelSettings): void {
    state.emailSettings = emailSettings
  },
  setWebPushSettings(state, webPushSettings: INotificationChannelSettings): void {
    state.webPushSettings = webPushSettings
  },
  setLoading(state, loading: boolean): void { state.loading = loading },
  setUpdating(state, updating: boolean): void { state.updating = updating },
  setError(state, error: boolean): void { state.error = error }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  getters,
  actions,
  mutations
} as Module<ICommunicationSettingsState, IRootState>
