





































import { computed, defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  name: 'SMTopicsChip',
  props: {
    tag: {
      type: String,
      required: true
    },
    primaryLabel: {
      type: String as PropType< string | null | undefined>,
      default: undefined
    },
    removable: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    isNotSubscribed: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: true
    },
    color: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isHighlighted: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const chipColor = computed(() => {
      if (!props.transparent || !props.isNotSubscribed) {
        return props.color ?? 'blue-magenta'
      }
      return ''
    })

    return {
      chipColor
    }
  }
})
