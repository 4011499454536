/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/prefer-default-export */

import './public-path'
import './environment'
import './assets/styles/main.scss'
import './installCompositionApi'

import Vue from 'vue'

import appWrapperHook from '@src/utilities/appWrapper'
import PlainLayout from '@src/layouts/PlainLayout.vue'
import { initSentryOnce } from '@src/plugins/sentry'
import _get from 'lodash/get'
import { createPinia, PiniaVuePlugin } from 'pinia'
import VueSnip from './directives/snip'
import App from './App.vue'
import i18n, { initLanguage } from './plugins/i18n'
import { createRouterForVueNativeMode } from './router'
import vuetify from './plugins/vuetify/vuetify'
import AnalyticsPlugin, { IAnalyticsPluginOptions } from './plugins/analytics'
import generateStore from './store'
import { initAuth } from './utilities/auth'
import periodicVersionCheck from './periodicVersionCheck'
import { realmSettingsApi } from './api/apiModuleInstances'

async function init() {
  const { data: realmSettings } = await realmSettingsApi.cached.get()
  const router = createRouterForVueNativeMode(realmSettings)
  initSentryOnce(router)
  const primaryColor = _get(realmSettings, 'white_labelling.primary', '#432CA0')
  // eslint-disable-next-line no-param-reassign
  vuetify.framework.theme.currentTheme.primary = primaryColor
  await initAuth(realmSettings.auth.cookie_same_site_none)
  await initLanguage()
  const analyticsOptions: IAnalyticsPluginOptions = {
    getTrackingState: () => router.currentRoute.name || '',
    debugMode: /debug-tracking/.test(window.location.search)
  }
  Vue.use(AnalyticsPlugin, analyticsOptions)
  Vue.use(VueSnip)
  Vue.use(PiniaVuePlugin)
  const pinia = createPinia()

  Vue.component('PlainLayout', PlainLayout)

  if (process.env.NODE_ENV === 'development') {
    Vue.config.devtools = true
  }
  const store = generateStore()

  // export the store for the angular iframe
  window.store = store

  periodicVersionCheck()

  new Vue({
    pinia,
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App)
  }).$mount('#app')

  appWrapperHook('appIsLoaded')
}
init()
