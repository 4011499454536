import { Ref, computed } from '@vue/composition-api'
import highlightWords, { HighlightWords } from 'highlight-words'

export function getChunks(text: string, query: string) {
  return highlightWords({ text, query, matchExactly: true })
}

export function chunksContainMatch(chunks: HighlightWords.Chunk[]) {
  return chunks.some((chunk) => chunk.match)
}

export default function useHighlightWords(text: Ref<string>, query: Ref<string>) {
  const chunks = computed(() => getChunks(text.value, query.value))
  const hasMatch = computed(() => chunksContainMatch(chunks.value))

  return {
    chunks,
    hasMatch
  }
}
