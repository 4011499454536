























































import {
  defineComponent
} from '@vue/composition-api'

export default defineComponent({
  name: 'SMQuestionSubmitButton',
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    existingQuestionId: {
      type: Number,
      default: undefined
    },
    expertCount: {
      type: Number,
      default: 0
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
})
