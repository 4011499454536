import {
  computed
} from '@vue/composition-api'
import { useOnboardingTooltips } from './useOnboardingTooltips'

export function useTooltipPosition() {
  const {
    overlayCutoutDimensionsOfCurrentTooltip
  } = useOnboardingTooltips()

  const TOOLTIP_WIDTH = 360
  const TOOLTIP_HEIGHT = 240
  const MARGIN = 16

  function getCenteredCoordinates() {
    return {
      top: `${window.innerHeight / 2 - TOOLTIP_HEIGHT / 2 - MARGIN}px`,
      left: `${window.innerWidth / 2 - TOOLTIP_WIDTH / 2 - MARGIN}px`
    }
  }

  function bufferBottom(top: number) {
    const bottom = top + TOOLTIP_HEIGHT + 2 * MARGIN
    const pixelsBelowBottom = bottom - window.innerHeight
    return pixelsBelowBottom > 0 ? top - pixelsBelowBottom : top
  }

  function bufferTop(bottom: number) {
    const top = window.innerHeight - (bottom + TOOLTIP_HEIGHT + 2 * MARGIN)
    return top < 0 ? (window.innerHeight - TOOLTIP_HEIGHT - 2 * MARGIN) : bottom
  }

  function bufferTopAndBottom(top: number) {
    const bottom = top + TOOLTIP_HEIGHT + 2 * MARGIN
    const pixelsBelowBottom = bottom - window.innerHeight
    return pixelsBelowBottom > 0
      ? top - pixelsBelowBottom
      : (top < 0 ? 0 : top)
  }

  function bufferLeftAndRight(left: number) {
    const right = left + TOOLTIP_WIDTH + 2 * MARGIN
    const pixelsExceedingRightViewportBoundary = right - window.innerWidth
    return pixelsExceedingRightViewportBoundary > 0
      ? left - pixelsExceedingRightViewportBoundary
      : (left < 0 ? 0 : left)
  }

  const hasInvalidCutoutDimensions = computed(() => !overlayCutoutDimensionsOfCurrentTooltip.value.top && !overlayCutoutDimensionsOfCurrentTooltip.value.left)

  const tooltipPositionBottom = computed(() => (hasInvalidCutoutDimensions.value ? getCenteredCoordinates() : {
    top: `${bufferBottom(overlayCutoutDimensionsOfCurrentTooltip.value.top + overlayCutoutDimensionsOfCurrentTooltip.value.height)}px`,
    left: `${bufferLeftAndRight(overlayCutoutDimensionsOfCurrentTooltip.value.left + overlayCutoutDimensionsOfCurrentTooltip.value.width / 2 - TOOLTIP_WIDTH / 2 - MARGIN)}px`
  }))

  const tooltipPositionTop = computed(() => (hasInvalidCutoutDimensions.value ? getCenteredCoordinates() : {
    bottom: `${bufferTop(window.innerHeight - overlayCutoutDimensionsOfCurrentTooltip.value.bottom + overlayCutoutDimensionsOfCurrentTooltip.value.height)}px`,
    left: `${bufferLeftAndRight(overlayCutoutDimensionsOfCurrentTooltip.value.left + overlayCutoutDimensionsOfCurrentTooltip.value.width / 2 - TOOLTIP_WIDTH / 2 - MARGIN)}px`
  }))

  const tooltipPositionRight = computed(() => (hasInvalidCutoutDimensions.value ? getCenteredCoordinates() : {
    top: `${bufferTopAndBottom(overlayCutoutDimensionsOfCurrentTooltip.value.top + overlayCutoutDimensionsOfCurrentTooltip.value.height / 2 - TOOLTIP_HEIGHT / 2 - MARGIN)}px`,
    left: `${bufferLeftAndRight(overlayCutoutDimensionsOfCurrentTooltip.value.left + overlayCutoutDimensionsOfCurrentTooltip.value.width)}px`
  }))

  const tooltipPositionLeft = computed(() => (hasInvalidCutoutDimensions.value ? getCenteredCoordinates() : {
    top: `${bufferTopAndBottom(overlayCutoutDimensionsOfCurrentTooltip.value.top + overlayCutoutDimensionsOfCurrentTooltip.value.height / 2 - TOOLTIP_HEIGHT / 2 - MARGIN)}px`,
    right: `${window.innerWidth - overlayCutoutDimensionsOfCurrentTooltip.value.right + overlayCutoutDimensionsOfCurrentTooltip.value.width}px`
  }))

  const tooltipPositionTopRight = computed(() => (hasInvalidCutoutDimensions.value ? getCenteredCoordinates() : {
    bottom: `${bufferTop(window.innerHeight - overlayCutoutDimensionsOfCurrentTooltip.value.bottom + overlayCutoutDimensionsOfCurrentTooltip.value.height)}px`,
    left: `${bufferLeftAndRight(overlayCutoutDimensionsOfCurrentTooltip.value.left + overlayCutoutDimensionsOfCurrentTooltip.value.width)}px`
  }))

  return {
    tooltipPositionBottom,
    tooltipPositionTop,
    tooltipPositionRight,
    tooltipPositionLeft,
    tooltipPositionTopRight
  }
}
