import { v4 as uuidv4 } from 'uuid'
import {
  CookieName,
  getCookie,
  removeCookie,
  resolveCookieSameSite,
  SameSiteAttribute,
  setCookie
} from '@src/helpers/cookie'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import { IApiResponseError } from '@src/types/errors.types'
import _some from 'lodash/some'
import _get from 'lodash/get'
import usersAPI from '@src/api/user'
import { IAuthRedirectMessage, PostMessageType } from '@src/types/postMessage.types'
import { OAuthClientId } from '@src/types/auth.types'
import { isWebview } from '@dvlden/is-webview'
import { realmSettingsApi } from '@src/api/apiModuleInstances'
import appWrapperHook from './appWrapper'
import {
  getCurrentWindowEnvironment, getWindowLocationObject, IframeEnvironment, isInCrossDomainIframe
} from './iframeDetection'

let redirectInProgress = false

export function cleanUpAuthCookies(sameSite: SameSiteAttribute): void {
  removeCookie(CookieName.API_TOKEN, sameSite)
  removeCookie(CookieName.AUTH_TRACKING, sameSite)
  removeCookie(CookieName.ID_TOKEN, sameSite)
  removeCookie(CookieName.SM_LOGOUT_REDIRECT, sameSite)
}

/**
 * The callback we expected after the client returns from a successful auth flow with keycloak.
 * @return true if callback to /oauth/idp-callback otherwise false
 */
function isIdpCallback() {
  return window.location.href.includes('/oauth/idp-callback')
}

/**
 * Checks if the current window is inside an iframe or a web view.
 *
 * @returns {boolean} Returns true if the current window is inside an iframe or a web view, otherwise false.
 */
function isIframeOrWebview(): boolean {
  return (isInCrossDomainIframe() || isWebview(window.navigator.userAgent))
}

function getInfiniteLoopProtectionCounter() {
  return Number(getCookie(CookieName.INFINITE_LOOP_PROTECTION)) ?? 0
}

function incrementInfiniteLoopProtectionCounter(sameSite: SameSiteAttribute) {
  const counter = getInfiniteLoopProtectionCounter()
  const cookieValue = (counter + 1).toString()
  setCookie({
    name: CookieName.INFINITE_LOOP_PROTECTION,
    value: cookieValue,
    expiration: new Date(Date.now() + 7 * 1000),
    sameSite
  })
}

export function iframeSafeRedirection(url: string) {
  /*
  Checks if the parent frame is also the Pegasus application and if it is the case,
  redirect on the parent window location.

  If there is no parent, or Starmind is embedded in a customer's website as iframe
  (if the origins don't match), then redirect on the current location.
  */
  const messagePayload: IAuthRedirectMessage = {
    url,
    type: PostMessageType.AUTH_REDIRECT
  }
  if (getCurrentWindowEnvironment() === IframeEnvironment.ANGULAR_IFRAME) {
    window.parent.postMessage(
      messagePayload,
      '/' // param required to allow typescript to build the tests
    )
  } else {
    const location = getWindowLocationObject()
    location.assign(url)
  }
}

function getErrorCodeUrlParams(code?: string) {
  const params: { code?: string; iframe?: string } = {}
  if (code) {
    params.code = code
  }
  if (isInCrossDomainIframe()) {
    params.iframe = 'true'
  }
  return new URLSearchParams(params).toString()
}

export function isBehindAuthentication(url: string): boolean {
  return !_some(
    [ '/logout', '/invite', '/external-app', '/error', '/oauth/idp-callback' ],
    (publicPath) => url.includes(publicPath)
  )
}

export async function logout(): Promise<void> {
  appWrapperHook('logout')

  const { data } = await realmSettingsApi.cached.get()
  const sameSite = resolveCookieSameSite(data.auth.cookie_same_site_none)
  const logoutUrl = getLogoutUrl(data)
  cleanUpAuthCookies(sameSite)
  iframeSafeRedirection(logoutUrl)
}

export function isAuthenticated(): boolean {
  return !!getCookie(CookieName.API_TOKEN)
}

export async function login(errorResponseData?: IApiResponseError) {
  const targetUrl = (getWindowLocationObject().href).replace(window.location.origin, '')
  if (isBehindAuthentication(targetUrl)) {
    const { data } = await realmSettingsApi.cached.get()
    const sameSite = resolveCookieSameSite(data.auth.cookie_same_site_none)
    cleanUpAuthCookies(sameSite)
    const errorCode = errorResponseData?.error?.code

    if (errorCode === 'UserNotYetAvailable') {
      iframeSafeRedirection(`/error/401/?${getErrorCodeUrlParams(errorCode)}`)
    } else if (getInfiniteLoopProtectionCounter() >= 3) {
      /*
      * If the user has already been redirected to the auth provider within the last 7 seconds
      * show an error page instead, to prevent an infinite loop.
      * This can happen if the user successfully logs in at the auth provider but an API endpoint requiring authentication rejects the token.
      */
      iframeSafeRedirection(`/error/401/?${getErrorCodeUrlParams(errorCode)}`)
    } else {
      incrementInfiniteLoopProtectionCounter(sameSite)
      if (targetUrl.length > 1 && !targetUrl.includes('angular/')) { // never put /angular/ into the redirect cookie
        setCookie({
          name: CookieName.SM_REDIRECT,
          value: encodeURIComponent(targetUrl),
          sameSite
        })
      }
      const loginUrl = getLoginUrl(data)
      iframeSafeRedirection(loginUrl)
    }
  }
}

export async function handle401(errorResponseData: IApiResponseError): Promise<void> {
  appWrapperHook('unauthenticatedRequest')
  if (!redirectInProgress) {
    redirectInProgress = true
    await login(errorResponseData)
  }
}

export async function initAuth(cookieSameSiteNone: boolean) {
  const sameSite = resolveCookieSameSite(cookieSameSiteNone)
  try {
    saveTokensFromIdpCallback(sameSite)
    redirectIdpCallbackToTarget()
  } catch (error) {
    iframeSafeRedirection(`/error/401?${getErrorCodeUrlParams('UnableToStoreApiToken')}`)
  }

  if (!isBehindAuthentication(window.location.pathname)) {
    removeCookie(CookieName.SM_REDIRECT, sameSite)
    return
  }
  /**
   * If authenticated (apiToken cookie is present) then fetch the user's roles in order to have them
   * ready before the application bootstrap. The roles are then available synchronously at any time.
   */
  if (isAuthenticated()) {
    try {
      await usersAPI.getRoles()
      removeCookie(CookieName.SM_REDIRECT, sameSite)
    } catch (error) {
      const responseStatus = _get(error, 'response.status')
      if (responseStatus === 503) {
        iframeSafeRedirection('/error/503')
      } else {
        // by default go to login
        await handle401(error as IApiResponseError)
      }
    }
  } else {
    await login({
      error: {
        code: 'NoApiTokenCookiePresent',
        message: 'API token is missing in cookie.'
      }
    })
  }
}

/**
 * Tries to resolve the initial target url after the idp callback and then trigger the client redirect.
 */
export function redirectIdpCallbackToTarget() {
  if (isIdpCallback()) {
    // read the possible redirect from the cookie
    const redirectFromCookie = getCookie(CookieName.SM_REDIRECT)?.replace(/angular\//, '')

    // read query params
    const redirectQueryParam = new URL(
      window.location.toString()
    ).searchParams.get('redirect')

    if (redirectFromCookie) {
      window.history.pushState({}, '', redirectFromCookie)
    } else if (redirectQueryParam) {
      window.history.pushState({}, '', redirectQueryParam)
    } else {
      window.history.pushState({}, '', '/')
    }
  }
}

/**
 * Tries to parse the JWTs found in url fragment from /oauth/idp-callback and persist them as cookies
 * @param sameSite the required SameSite cookie attribute for the auth cookies
 */
export function saveTokensFromIdpCallback(sameSite = 'Strict' as SameSiteAttribute) {
  if (isIdpCallback()) {
    const hashFragmentParams = window.location.hash
      .split('&')
      .reduce((paramsObject: Record<string, string>, keyValueString) => {
        const modifiedKeyValueString = keyValueString.replace('#', '')
        const keyAndValue = modifiedKeyValueString.split('=')
        // eslint-disable-next-line no-param-reassign
        paramsObject[decodeURIComponent(keyAndValue[0])] = decodeURIComponent(keyAndValue[1])
        return paramsObject
      }, {})

    if (hashFragmentParams.access_token) {
      setCookie({
        name: CookieName.API_TOKEN,
        value: hashFragmentParams.access_token,
        sameSite
      })
      if (hashFragmentParams.id_token) {
        setCookie({
          name: CookieName.ID_TOKEN,
          value: hashFragmentParams.id_token,
          sameSite
        })
      }
      if (isIframeOrWebview()) {
        if (hashFragmentParams.logout_redirect_uri) {
          setCookie({
            name: CookieName.SM_LOGOUT_REDIRECT,
            value: hashFragmentParams.logout_redirect_uri,
            sameSite
          })
        }
        if (hashFragmentParams.client_login_uri) {
          setCookie({
            name: CookieName.SM_CLIENT_LOGIN,
            value: hashFragmentParams.client_login_uri,
            sameSite
          })
        }
      }
    } else {
      iframeSafeRedirection(`/error/401/?${getErrorCodeUrlParams('NoAccessTokenFragmentPresent')}`)
    }
  }
}

export function getLoginUrl(realmSettings: IUnauthenticatedRealmSettings) {
  const redirectUri = encodeURIComponent(`${window.location.origin}/oauth/idp-callback/`)
  const scopes = encodeURIComponent('accounts-api openid')
  const responseTypes = encodeURIComponent('token id_token')
  const nonce = uuidv4()
  const frontendIframeClientId = OAuthClientId.QA_FRONTEND_IFRAME
  // default clientId in case pegasus runs outside an iFrame
  let clientId: OAuthClientId | string = OAuthClientId.QA_FRONTEND
  if (isIframeOrWebview()) {
    // try to resolve client specific login page in case it was provided in the authenticated iframe or webview context
    const clientLoginUrlFromParam = getCookie(CookieName.SM_CLIENT_LOGIN)
    if (clientLoginUrlFromParam) {
      return clientLoginUrlFromParam
    }

    const urlParams = new URL(
      window.location.toString()
    )
    const clientIdParam = urlParams.searchParams.get('client_id')
    // in case the iframe integration misses to request a dedicated keycloak client, we force qa-frontend-iframe client to be available at least
    clientId = clientIdParam || frontendIframeClientId
  }
  return `${realmSettings?.auth.keycloak_url}/realms/${realmSettings?.metadata.realm_id}/protocol/openid-connect/auth?response_mode=fragment&response_type=${responseTypes}&scope=${scopes}&client_id=${clientId}&redirect_uri=${redirectUri}&nonce=${nonce}`
}

export function getLogoutUrl(realmSettings: IUnauthenticatedRealmSettings) {
  const idToken = getCookie(CookieName.ID_TOKEN)
  // default pegasus logout page
  let logoutRedirectUri = encodeURIComponent(`${window.location.origin}/logout`)
  if (isIframeOrWebview()) {
    // try to resolve client specific logout page in case it was provided in the authenticated iframe or webview context
    const logoutRedirectFromParam = getCookie(CookieName.SM_LOGOUT_REDIRECT)
    if (logoutRedirectFromParam) {
      logoutRedirectUri = encodeURIComponent(logoutRedirectFromParam)
    }
  }
  return `${realmSettings?.auth.keycloak_url}/realms/${realmSettings?.metadata.realm_id}/protocol/openid-connect/logout?post_logout_redirect_uri=${logoutRedirectUri}&id_token_hint=${idToken}`
}
