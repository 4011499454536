



























































import Vue from 'vue'
import BadgeImg from '@src/views/User/components/contribution/components/badge/index.vue'
import { INotificationItem, NotificationTargetType } from '@src/types/notifications.types'
import { mapActions } from 'vuex'
import kebabCase from 'lodash/fp/kebabCase'
import { CLICK_IN_BADGE_NOTIFICATION } from '@src/plugins/analytics/events/ui/NotificationsDialogItem.events'

export default Vue.extend({
  name: 'NotificationsDialogItem',
  components: {
    BadgeImg
  },
  props: {
    notification: {
      type: Object as () => INotificationItem,
      required: true
    },
    notificationsCount: {
      type: Number as () => number,
      required: true
    }
  },
  computed: {
    badgeName(): string {
      return this.$t(`badges.${kebabCase(this.notification.target_type || '')}.name`).toString()
    },
    secondParagraphTranslationKey(): string {
      return `badges.modal.${this.notification.target_type}.subtitle.second.paragraph.subtext`
    },
    secondParagraphSubtextAchievement(): string {
      return this.$t(this.secondParagraphTranslationKey).toString()
    },
    isSecondParagraphWithUrl(): boolean {
      const BADGES_TYPE_WITH_URL = [ NotificationTargetType.SingleSolutionUniqueViews ]
      return BADGES_TYPE_WITH_URL.includes(this.notification.target_type)
    },
    urlData(): {url: string; title: string} {
      return {
        url: `/question/${this.notification.question_id}`,
        title: this.$t(`badges.modal.${this.notification.target_type}.subtitle.second.paragraph.urlTitle`).toString()
      }
    }
  },
  methods: {
    ...mapActions('notifications', [ 'markBadgesNotificationsAsRead' ]) as {
      markBadgesNotificationsAsRead: () => void;
    },
    trackClickInBadgeNotification(): void {
      this.$trackEvent(CLICK_IN_BADGE_NOTIFICATION, this.$options.name, {
        badge_type: this.notification.target_type,
        badge_count: this.notificationsCount,
        element: 'link'
      })
    }
  }

})
