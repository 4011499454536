import axios, { AxiosPromise } from 'axios'
import { IUser } from '@src/types/user.types'
import { IUserImage } from '@src/types/accounts.types'
import { CookieName, getCookie } from '@src/helpers/cookie'

export default {
  getBaseUrl(user: Partial<IUser>): string {
    return `/accounts/assets/users/${user.global_user_id}/avatar`
  },
  setAccountImage(user: Partial<IUser>, image: File): AxiosPromise<IUserImage> {
    return axios({
      method: 'POST',
      url: this.getBaseUrl(user),
      data: image,
      headers: {
        Authorization: `Bearer ${getCookie(CookieName.API_TOKEN)}`
      },
      transformRequest: (file: File) => {
        const form = new FormData()

        form.append('file', file, file.name)
        return form
      }
    })
  },
  deleteAccountImage(user: Partial<IUser>): AxiosPromise<IUserImage> {
    return axios({
      method: 'DELETE',
      url: this.getBaseUrl(user),
      headers: {
        Authorization: `Bearer ${getCookie(CookieName.API_TOKEN)}`
      }
    })
  }
}
