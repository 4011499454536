













































/* eslint-disable no-unused-expressions */
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { CLICK_IN_HELP_MENU } from '@src/plugins/analytics/events/ui/SMUserGuideSidenavItem.events'
import SMWhatsNewBadge from '@src/ui/SMWhatsNew/components/SMWhatsNewBadge/index.vue'
import { IUserGuideListItem, UserGuideListType } from '@src/store/modules/userGuideSidenav'

const ABOUT_STARMIND_URL = 'https://help.starmind.com/docs/intro-to-starmind'

export default Vue.extend({
  name: 'SMUserGuideSidenavItem',
  components: {
    SMWhatsNewBadge
  },
  props: {
    sidenavItem: {
      required: true,
      type: Object as () => IUserGuideListItem
    },
    networkName: {
      required: true,
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isEnabled: false,
      userGuideListType: UserGuideListType
    }
  },
  computed: {
    navigationDrawerWidth(): string {
      return this.$vuetify.breakpoint.smAndUp ? '400' : '100%'
    },
    title(): string {
      return this.$t(this.sidenavItem.title)?.toString()
    },
    subtitle(): string {
      if (this.sidenavItem.id === UserGuideListType.COMPANY_GUIDELINES) {
        return this.$t(this.sidenavItem.text, { network: this.networkName })?.toString()
      }
      return this.$t(this.sidenavItem.text)?.toString()
    },
    image(): any {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      return require(`@src/assets/img/${this.sidenavItem.id}.svg`)
    }
  },
  methods: {
    ...mapMutations('userGuideSidenav', [ 'hideDrawer' ]) as {
      hideDrawer: () => void;
    },
    ...mapMutations('companyGuidelines', [ 'openCompanyGuidelines' ]) as {
      openCompanyGuidelines: () => void;
    },
    ...mapMutations('feedbackDialog', [ 'toggleFeedbackDialog' ]) as {
      toggleFeedbackDialog: (isOpen: boolean) => void;
    },
    ...mapActions('whatsNew', [ 'openWhatsNewDialog' ]) as {
      openWhatsNewDialog: () => Promise<void>;
    },
    ...mapActions('activationFlow/tooltips', [ 'startOnboardingTooltipsManually' ]) as {
      startOnboardingTooltipsManually: () => void;
    },
    action(): void {
      this.$trackEvent(CLICK_IN_HELP_MENU, this.$options.name, {
        menu_item_id: this.sidenavItem.tracking.menuItemId,
        label: this.sidenavItem.tracking.label
      })
      if (this.sidenavItem.id === UserGuideListType.WHATS_NEW) {
        this.openWhatsNewDialog()
      } else if (this.sidenavItem.id === UserGuideListType.COMPANY_GUIDELINES) {
        this.openCompanyGuidelines()
      } else if (this.sidenavItem.id === UserGuideListType.GETTING_STARTED) {
        this.startOnboardingTooltipsManually()
      } else if (this.sidenavItem.id === UserGuideListType.LEAVE_FEEDBACK) {
        this.toggleFeedbackDialog(true)
      } else if (this.sidenavItem.id === UserGuideListType.ABOUT_STARMIND) {
        window.open(ABOUT_STARMIND_URL, '_blank')
      }
      this.hideDrawer()
    }
  }
})
