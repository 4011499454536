





import { defineComponent } from '@vue/composition-api'
import SMDialog from '@src/ui/SMDialog/index.vue'
import { useFeedback } from '../composables/useFeedback'

export default defineComponent({
  name: 'SmFeedbackDialogAnchor',
  components: {
    SMDialog
  },
  setup() {
    return {
      ...useFeedback()
    }
  }
})
