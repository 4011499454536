
























import Vue from 'vue'
import { NodeViewWrapper, NodeViewContent, nodeViewProps } from '@tiptap/vue-2'

export default Vue.extend({
  components: {
    NodeViewWrapper,
    NodeViewContent
  },
  props: nodeViewProps,
  data() {
    return {
      languages: this.extension.options.lowlight.listLanguages()
    }
  },
  computed: {
    selectedLanguage: {
      get(): string {
        if (this.node.attrs.language) {
          const selectedLangClasses = this.node.attrs.language.split(' ')
          const selectedLang = selectedLangClasses.find((selectedLangClass: string): string|boolean => selectedLangClass && selectedLangClass.includes('language-'))
          if (selectedLang) {
            const language = selectedLang.replace('language-', '')
            this.updateAttributes({ language })
            return language
          }
        }
        return this.node.attrs.language
      },
      set(language: string): void {
        this.updateAttributes({ language })
      }
    }
  }
})
