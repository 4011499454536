export interface IMetricsResponse {
  metrics: {
    name: string;
    value: number;
  }[];
}

export enum VSMMetricId {
  ACTIVATED_USERS = 'activated_users',
  USERS_WITH_JOB_TITLE = 'users_with_job_title',
  USERS_WITH_TOPICS = 'users_with_topics',
  ANSWERED_QUESTIONS = 'answered_questions',
  AVERAGE_ANSWER_TIME = 'average_answer_time',
  AVERAGE_SOLUTION_RATING = 'average_solution_rating',
  AVERAGE_DAILY_ACTIVE_USERS = 'average_daily_active_users',
  AVERAGE_QUESTION_VIEWS = 'average_question_views'
}

export interface IVSMMetric {
  id: VSMMetricId;
  name: string;
  description: string;
  targetValue: number;
  currentValue: number;
  percentageValue: number;
  unit: VSMMetricUnit;
  color: string;
  loading: boolean;
  status: VSMMetricStatus;
  actions: IVSMMetricAction[];
  tooltip?: string;
}

export type IVSMMetricSimpleValue = Pick<IVSMMetric, 'id' | 'name' | 'currentValue' | 'unit' | 'loading' >

export type VSMMetricUnit = 'percentage' | 'hours' | 'stars' | 'absolute'
export type VSMMetricStatus = 'default' | 'warn' | 'done'

export interface IVSMMetricAction {
  id: string;
  label: string;
  icon?: string;
  primary?: boolean;
  link?: {
    url: string;
    target?: string;
  };
}
