import { IRootState } from '@src/types/store.types'
import { ActionTree, Module } from 'vuex'
import { ActivationFlowStepStatus, ActivationFlowStep } from '@src/types/activationFlow.types'

const actions: ActionTree<Record<string, never>, IRootState> = {
  async possiblyShowOnboardingChecklist({ dispatch }) {
    dispatch('onboardingChecklistCompleted', ActivationFlowStepStatus.STEPPED_THROUGH_COMPLETE)
  },
  onboardingChecklistCompleted({ dispatch }, status: ActivationFlowStepStatus) {
    dispatch('activationFlow/next', { status, step: ActivationFlowStep.ONBOARDING_CHECKLIST }, { root: true })
  }
}

export default {
  namespaced: true,
  actions
} as Module<Record<string, never>, IRootState>
