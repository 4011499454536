














































import {
  computed,
  defineComponent
} from '@vue/composition-api'

export default defineComponent({
  name: 'SMOnboardingTooltipCard',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    tooltipIndex: {
      type: Number,
      default: 0
    },
    totalTooltips: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const isLast = computed(() => props.tooltipIndex + 1 >= props.totalTooltips)
    const hasMultiple = computed(() => props.totalTooltips > 1)

    return {
      isLast,
      hasMultiple
    }
  }
})
