import { ITrackingProps } from '@src/types/events.types'

export const COMPLETE_LAST_STEP_ONBOARDING_WIZARD = 'completeFirstStepOnboardingWizard'
export const COMPLETE_ONBOARDING_WIZARD = 'completeOnboardingWizard'
export const TOGGLE_TERMS_CHECKBOX = 'checkApproveTerms'
export const CLICK_APPROVE_TERMS = 'clickApproveTerms'
export const CLICK_LOGOUT_TERMS = 'clickLogoutTerms'
export const VIEW_TERMS = 'viewUserTerms'

const commonPropsLanguageSelection = {
  state: 'language-select'
}

const commonPropsTerms = {
  state: 'user_terms',
  component: 'activation_flow_terms_dialog'
}

export default {
  SMActivationFlowLanguageDialog: {
    [COMPLETE_ONBOARDING_WIZARD]: (customProps: { approved_emails: boolean }): ITrackingProps => ({
      ...commonPropsLanguageSelection,
      ...customProps,
      component: 'language_screen',
      element: 'button',
      type: 'click'
    })
  },
  SMActivationFlowTermsDialog: {
    [TOGGLE_TERMS_CHECKBOX]: (): ITrackingProps => ({
      ...commonPropsTerms,
      type: 'click',
      element: 'check_box'
    }),
    [CLICK_APPROVE_TERMS]: (): ITrackingProps => ({
      ...commonPropsTerms,
      type: 'click',
      element: 'button'
    }),
    [CLICK_LOGOUT_TERMS]: (): ITrackingProps => ({
      ...commonPropsTerms,
      type: 'click',
      element: 'button'
    }),
    [VIEW_TERMS]: (): ITrackingProps => ({
      ...commonPropsTerms,
      type: 'view'
    })
  },
  SMActivationFlow: {
    [COMPLETE_LAST_STEP_ONBOARDING_WIZARD]: (): ITrackingProps => ({
      component: 'onboarding_dialog',
      element: 'button',
      type: 'click'
    })
  }
}
