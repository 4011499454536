import { accountUserPropNames, IAccountUserProps, IUser } from '@src/types/user.types'
import {
  GetterTree, Module, MutationTree, ActionTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import _set from 'lodash/fp/set'

import userApi from '@src/api/user'
import { getFullName } from '@src/utilities/userUtils'
import _pick from 'lodash/pick'
import { userGraphQlApi } from '@src/api/apiModuleInstances'
import otherUsersSkills from './otherUsersSkills'

export interface IOtherUser {
  user: Partial<IUser>;
  accountUserProps: IAccountUserProps;
  loading: boolean;
  error: boolean;
}
export interface IOtherUsersState {
  users: { [key: number]: IOtherUser };
}

const state: IOtherUsersState = {
  users: {}
}

const defaultOtherUser: IOtherUser = {
  user: {},
  loading: true,
  error: false,
  accountUserProps: { socialLinks: [] }
}

const getters: GetterTree<IOtherUsersState, IRootState> = {
  getOtherUserById: (state) => (id: number): IOtherUser => {
    return id && id in state.users ? state.users[id] : defaultOtherUser
  },
  getFullName: () => (user: IUser): string => {
    return getFullName(user.firstname, user.lastname)
  },
  getOtherAccountUserProps: (state) => (id: number): IAccountUserProps => {
    const otherUser = id && id in state.users ? state.users[id] : defaultOtherUser
    return otherUser.accountUserProps
  }
}

const actions: ActionTree<IOtherUsersState, IRootState> = {
  async loadUserById({ commit, state }, userId: number): Promise<void> {
    // if the user was already fetched or if it has error
    if (!(userId in state.users) || state.users[userId].error) {
      commit('setOtherUsersLoading', { userId, loading: true })
      commit('setOtherUsersError', { userId, error: false })
      try {
        const { data: user } = await userApi.getUser(userId)
        commit('setOtherUsers', { userId, user })
        try {
          const accountUserProps = _pick((await userGraphQlApi.getAccountUserProps(user.global_user_id)).user, accountUserPropNames)
          commit('setOtherAccountUserProps', { userId, accountUserProps })
        } catch (e) {
          // ignore
        }
      } catch {
        commit('setOtherUsersError', { userId, error: true })
      } finally {
        commit('setOtherUsersLoading', { userId, loading: false })
      }
    }
  }
}

const mutations: MutationTree<IOtherUsersState> = {
  setOtherUsers: (state, otherUser: {userId: number; user: IUser}): void => {
    state.users = _set(`${otherUser.userId}.user`, otherUser.user, state.users)
  },
  setOtherAccountUserProps: (state, otherAccountUser: {userId: number; accountUserProps: IAccountUserProps}): void => {
    state.users = _set(`${otherAccountUser.userId}.accountUserProps`, otherAccountUser.accountUserProps, state.users)
  },
  setOtherUsersLoading: (state, otherUser: {userId: number; loading: boolean}) => {
    state.users = _set(`${otherUser.userId}.loading`, otherUser.loading, state.users)
  },
  setOtherUsersError: (state, otherUser: {userId: number; error: boolean}) => {
    state.users = _set(`${otherUser.userId}.error`, otherUser.error, state.users)
  }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  getters,
  actions,
  mutations,
  modules: {
    otherUsersSkills
  }
} as Module<IOtherUsersState, IRootState>
