import { ITrackingProps } from '@src/types/events.types'

export const CLICK_TRANSLATION_ORIGINAL = 'clickTranslationOriginal'
export const CLICK_TRANSLATION_BUTTON = 'clickTranslationButton'

const commonProps = {
}

export interface ITranslationButton {
  component: ITranslationButtonComponent;
  element?: string;
  question_id?: number;
  answer_id?: number;
  comment_id?: number;
}

export type ITranslationButtonComponent = 'question_card' | 'answer_card' | 'comment'

export default {
  SMTranslationToggle: {
    [CLICK_TRANSLATION_ORIGINAL]: (customProps: ITranslationButton):
    ITrackingProps & ITranslationButton => ({
      ...commonProps,
      type: 'click',
      element: 'button',
      ...customProps
    }),
    [CLICK_TRANSLATION_BUTTON]: (customProps: ITranslationButton):
    ITrackingProps & ITranslationButton => ({
      ...commonProps,
      type: 'click',
      element: 'button',
      ...customProps
    })
  }
}
