import { AxiosInstance } from 'axios'

export function dismissedExpertiseFeedbackApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/dismissed-expertise-feedbacks'
  const methods = {
    getDismissed() {
      return api.get<number[]>(baseUrl)
    },
    dismiss(questionId: number) {
      return api.post<{questionId: number}>(baseUrl, { questionId })
    }
  }
  return methods
}
