















































import {
  defineComponent, PropType
} from '@vue/composition-api'
import {
  IOnboardingChecklistItemComplete
} from '@src/utilities/onboardingChecklist'
import { useRouter, useRoute } from '@src/composables/router'

export default defineComponent({
  name: 'SMOnboardingChecklistItem',
  props: {
    checklistItem: {
      type: Object as PropType<IOnboardingChecklistItemComplete>,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const router = useRouter()
    const route = useRoute()
    return {
      router,
      route,
      emit
    }
  }
})
