import _union from 'lodash/union'
import _pick from 'lodash/pick'
import _mapKeys from 'lodash/mapKeys'
import _map from 'lodash/map'
import { gql, ApolloQueryResult } from '@apollo/client'
import { ContextSetter } from '@apollo/client/link/context'
import { apolloClientFactory } from './apolloClientFactory'
import { IInviteTokenLinkResponse } from '../../../types/accounts.types'
import { IEditUser, IGraphqlUserSchema, IUser } from '../../../types/user.types'

// These user properties can be set by any user
const userProperties = [
  'firstname',
  'lastname',
  'languageId',
  'countryId',
  'gender',
  'company',
  'location',
  'position',
  'department',
  'about',
  'avatarUrl',
  'utcOffsetMinutes',
  `socialLinks {
    type
    handle
    name
  }`
] as const

// These user properties can only be edited by a privileged user
const privilegedUserProperties = [
  'email',
  'isTechnicalUser'
] as const

const readOnlyUserProperties = [
  'globalUserId',
  'dateDeleted'
] as const

export interface IGetUserLatestTermsApprovedResponse {
  user: Pick<IGraphqlUserSchema, 'latestTermsApproved' | 'termsOfUse'>;
}

export interface IGetUserIdpLinksResponse {
  user: Pick<IGraphqlUserSchema, 'federatedIdentityLinks'>;
}

export interface IAcceptTermsResponse {
  acceptTerms: Pick<IGraphqlUserSchema, 'latestTermsApproved'>;
}

export interface IGetAccountUserProps {
  user: {
    globalUserId: string;
    socialLinks: Pick<IGraphqlUserSchema, 'socialLinks'>;
  }
}

type UserField = keyof IGraphqlUserSchema

const queryUserProperties = (): string[] => _union(userProperties, privilegedUserProperties, readOnlyUserProperties)

function mapRestUserToGraphQLSchema(user: Partial<IUser>): Partial<IGraphqlUserSchema> {
  const map: {
    [key: string]: keyof IGraphqlUserSchema;
  } = {
    about: 'about',
    company: 'company',
    country: 'countryId',
    department: 'department',
    email: 'email',
    firstname: 'firstname',
    gender: 'gender',
    global_user_id: 'globalUserId',
    is_technical_user: 'isTechnicalUser',
    language: 'languageId',
    lastname: 'lastname',
    location: 'location',
    position: 'position',
    socialLinks: 'socialLinks',
    utc_offset_minutes: 'utcOffsetMinutes'
  }

  return _mapKeys(user, (_value, key) => map[key])
}

export function userGraphqlApiFactory(authContextSetter: ContextSetter) {
  const graphqlClient = apolloClientFactory(authContextSetter)

  return {
    async saveUser(user: Partial<IEditUser>): Promise<boolean> {
      const allowedProperties = _union(userProperties, [ 'socialLinks', 'globalUserId', 'utcOffsetMinutes' ])
      const updateUserInput = _pick(mapRestUserToGraphQLSchema(user), allowedProperties)

      const SAVE_USER_MUTATION = gql`
      mutation updateUser($updateUserInput: UpdateUserInput!) {
        updateUser(input: $updateUserInput) {
          ${queryUserProperties().join()}
        }
      }`

      try {
        await graphqlClient.mutate({
          mutation: SAVE_USER_MUTATION,
          variables: {
            updateUserInput
          }
        })
        return true
      } catch {
        return false
      }
    },
    async generateInviteTokenLink(globalUserId: string): Promise<IInviteTokenLinkResponse> {
      const GENERATE_INVITE_TOKEN_LINK = gql`mutation generateInviteTokenLink($globalUserId: UUID!) {
        generateInviteTokenLink(globalUserId: $globalUserId)
      }`

      return (await graphqlClient.mutate({
        mutation: GENERATE_INVITE_TOKEN_LINK,
        variables: { globalUserId }
      })) as IInviteTokenLinkResponse
    },
    async getUserLatestTermsApproved(globalUserId: string): Promise<IGetUserLatestTermsApprovedResponse> {
      const query = gql`
        query user($globalUserId: UUID!) {
          user(globalUserId: $globalUserId) {
            globalUserId,
            latestTermsApproved,
            termsOfUse {
              id
              title
            }
          }
        }
      `
      const response = await graphqlClient.query({
        query,
        variables: { globalUserId }
      }) as ApolloQueryResult<IGetUserLatestTermsApprovedResponse>

      if (response.errors) {
        throw new Error('could not fetch user')
      } else {
        return response.data
      }
    },
    async getUserIdpLinks(globalUserId: string): Promise<IGetUserIdpLinksResponse> {
      const query = gql`
        query user($globalUserId: UUID!) {
          user(globalUserId: $globalUserId) {
            globalUserId
            federatedIdentityLinks {
              identityProviderAlias
              providerUserId
              providerUsername
            }
          }
        }
      `
      const response = await graphqlClient.query({
        query,
        variables: { globalUserId }
      }) as ApolloQueryResult<IGetUserIdpLinksResponse>

      if (response.errors) {
        throw new Error('could not fetch user')
      } else {
        return response.data
      }
    },
    async getAccountUserProps(globalUserId: string): Promise<IGetAccountUserProps> {
      const query = gql`
          query user($globalUserId: UUID!) {
              user(globalUserId: $globalUserId) {
                  globalUserId
                  socialLinks {
                      name
                      handle
                      type
                  }
              }
          }
      `
      const response = await graphqlClient.query({
        query,
        variables: { globalUserId }
      }) as ApolloQueryResult<IGetAccountUserProps>

      if (response.errors) {
        throw new Error('could not fetch user')
      } else {
        return response.data
      }
    },
    async getUserDetails(globalUserId: string, fields: UserField[]): Promise<{ user: Partial<IGraphqlUserSchema>}> {
      const query = gql`
          query user($globalUserId: UUID!) {
              user(globalUserId: $globalUserId) {
                ${fields.join()},
                globalUserId
              }
          }
      `
      const response = await graphqlClient.query({
        query,
        variables: { globalUserId }
      }) as ApolloQueryResult<{user:Partial<IGraphqlUserSchema>}>

      if (response.errors) {
        throw new Error('could not fetch user')
      } else {
        return response.data
      }
    },
    async acceptTerms(globalUserId: string, termsId: number): Promise<IAcceptTermsResponse> {
      const mutation = gql`
        mutation acceptTerms($globalUserId: UUID!, $termsId: Int!) {
          acceptTerms(globalUserId: $globalUserId, termsId: $termsId) {
            latestTermsApproved
          }
        }
      `
      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          globalUserId,
          termsId
        }
      })
      if (response.errors) {
        throw new Error('could not accept terms')
      } else {
        return response.data
      }
    },
    async updateUserEmail(user: Partial<IUser>): Promise<boolean> {
      const updateUserEmailInput = _pick(mapRestUserToGraphQLSchema(user), [ 'email', 'globalUserId' ])

      const mutation = gql`
        mutation updateUserEmail($updateUserEmailInput: UpdateUserEmailInput!) {
          updateUserEmail(input: $updateUserEmailInput) {
            email,
            globalUserId
          }
        }
      `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          updateUserEmailInput
        }
      })
      if (response.errors) {
        throw new Error('could not update users email address')
      } else {
        return true
      }
    },
    async getUserIsFederated(globalUserId: string): Promise<boolean> {
      const query = gql`
        query user($globalUserId: UUID!) {
          user(globalUserId: $globalUserId) {
            globalUserId,
            isFederated
          }
        }
      `
      const response = await graphqlClient.query({
        query,
        variables: { globalUserId }
      }) as ApolloQueryResult<{ user: Partial<IGraphqlUserSchema> }>

      if (response.errors) {
        throw new Error('could not fetch user')
      } else {
        return !!response.data.user.isFederated
      }
    },
    async getUserUtcOffset(globalUserId: string): Promise<number | undefined> {
      const query = gql`
        query user($globalUserId: UUID!) {
          user(globalUserId: $globalUserId) {
            globalUserId,
            utcOffsetMinutes
          }
        }
      `
      const response = await graphqlClient.query({
        query,
        variables: { globalUserId }
      }) as ApolloQueryResult<{ user: Partial<IGraphqlUserSchema> }>

      if (response.errors) {
        throw new Error('could not fetch user')
      } else {
        return response.data.user.utcOffsetMinutes!
      }
    },
    async requestUserEmailUpdate(globalUserId: string, email: string): Promise<boolean> {
      const mutation = gql`
        mutation requestUserEmailUpdate($updateUserEmailInput: UpdateUserEmailInput!) {
          requestUserEmailUpdate(input: $updateUserEmailInput) {
            email,
            globalUserId
          }
        }
      `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          updateUserEmailInput: {
            globalUserId,
            email
          }
        }
      })
      if (response.errors) {
        throw new Error('could not update users email address')
      } else {
        return true
      }
    },
    async confirmUserEmailUpdate(oneTimeToken: string): Promise<boolean> {
      const mutation = gql`
        mutation confirmUserEmailUpdate($oneTimeToken: UUID!) {
          confirmUserEmailUpdate(oneTimeToken: $oneTimeToken) {
            email,
            globalUserId
          }
        }
      `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          oneTimeToken
        }
      })
      if (response.errors) {
        throw new Error('could not update users email address')
      } else {
        return true
      }
    },
    async getMultipleUsersById(globalUserIds: string[]) {
      if (!globalUserIds.length) {
        return []
      }
      const generatedQueries = globalUserIds.map((userId, index) => {
        return `user${index}: user(globalUserId: "${userId}") {
          globalUserId, firstname, lastname, avatarUrl, position, email
        }`
      })
      const query = gql`
          {
            ${generatedQueries}
          }
      `
      const response = await graphqlClient.query({
        query
      }) as ApolloQueryResult<Record<string, Pick<IGraphqlUserSchema, 'firstname' | 'lastname' | 'globalUserId' | 'avatarUrl' | 'position' | 'email'>>>

      if (response.errors) {
        throw new Error('could not fetch users')
      } else {
        return _map(response.data, (user) => ({
          ...user,
          image: user.avatarUrl
        }))
      }
    },
    async removeFederatedIdentityLink(globalUserId: string, identityProviderAlias: string): Promise<boolean> {
      const mutation = gql`
        mutation removeFederatedIdentityLink($removeFederatedLinkInput: RemoveFederatedLinkInput!) {
          removeFederatedIdentityLink(input: $removeFederatedLinkInput) {
            globalUserId
            federatedIdentityLinks {
              identityProviderAlias
              providerUserId
              providerUsername
            }
          }
        }
      `

      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          removeFederatedLinkInput: {
            globalUserId,
            identityProviderAlias
          }
        }
      })
      if (response.errors) {
        throw new Error('could not delete federated identity link')
      } else {
        return true
      }
    },
    async deactivateUser(globalUserId: string): Promise<{ dateDeleted: string | null }> {
      const mutation = gql`
        mutation deactivateUser($globalUserId: UUID!) {
          deactivateUser(globalUserId: $globalUserId) { globalUserId, dateDeleted }
        }
      `
      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          globalUserId
        }
      })
      if (response.errors) {
        throw new Error('could not deactivateUser')
      } else {
        return response.data.deactivateUser
      }
    },
    async reactivateUser(globalUserId: string): Promise<{ dateDeleted: string | null }> {
      const mutation = gql`
        mutation reactivateUser($globalUserId: UUID!) {
          reactivateUser(globalUserId: $globalUserId) { globalUserId, dateDeleted }
        }
      `
      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          globalUserId
        }
      })
      if (response.errors) {
        throw new Error('could not reactivateUser')
      } else {
        return response.data.reactivateUser
      }
    },
    async deleteUser(globalUserId: string): Promise<{
      dateDeleted: string | null,
      dateAnonymize: string | null,
      dateAnonymized: string | null,
    }> {
      const mutation = gql`
        mutation deleteUser($globalUserId: UUID!) {
          deleteUser(globalUserId: $globalUserId) { globalUserId, dateDeleted, dateAnonymize, dateAnonymized }
        }
      `
      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          globalUserId
        }
      })
      if (response.errors) {
        throw new Error('could not deleteUser')
      } else {
        return response.data.deleteUser
      }
    },
    async cancelDelete(globalUserId: string): Promise<{
      dateDeleted: string | null,
      dateAnonymize: string | null,
      dateAnonymized: string | null,
    }> {
      const mutation = gql`
        mutation cancelDelete($globalUserId: UUID!) {
          cancelDelete(globalUserId: $globalUserId) { globalUserId, dateDeleted, dateAnonymize, dateAnonymized }
        }
      `
      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          globalUserId
        }
      })
      if (response.errors) {
        throw new Error('could not cancelDelete')
      } else {
        return response.data.cancelDelete
      }
    },
    async updatePassword(globalUserId: string, password: string) {
      const mutation = gql`mutation updateUserCredentials($updateUserCredentialsInput: UpdateUserCredentialsInput!) {
        updateUserCredentials(input: $updateUserCredentialsInput) { globalUserId }
      }`
      const response = await graphqlClient.mutate({
        mutation,
        variables: {
          updateUserCredentialsInput: {
            globalUserId,
            password
          }
        }
      })
      if (response.errors) {
        throw new Error('could not updatePassword')
      } else {
        return true
      }
    }
  }
}
