import {
  MutationTree, ActionTree, Module, GetterTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import solutionAuthoritiesApi from '@src/api/solutionAuthorities'
import { IAdminSolutionApprovalGroup } from '@src/types/solutionApproval.types'
import { IUser } from '@src/types/user.types'
import _orderBy from 'lodash/orderBy'
import { AxiosResponse } from 'axios'
import { ISolutionAuthorityRealmSettings } from '@src/types/realmSettings.types'

export interface ISolutionApprovalState {
  showingDetailView: boolean;
  groups: Partial<IAdminSolutionApprovalGroup>[];
  currentlyEditingGroup: IAdminSolutionApprovalGroup;
  isLoading: boolean;
  isDeleteLoading: boolean;
  isDialogShowing: boolean;
}

const createNewApprovalGroup = (): IAdminSolutionApprovalGroup => ({
  name: '',
  users: []
})

const state: ISolutionApprovalState = {
  showingDetailView: false,
  groups: [],
  currentlyEditingGroup: createNewApprovalGroup(),
  isLoading: false,
  isDeleteLoading: false,
  isDialogShowing: false
}

const actions: ActionTree<ISolutionApprovalState, IRootState> = {
  async updateFeature({ dispatch }, enabled: boolean): Promise<void> {
    await dispatch('realmSettings/updateSolutionAuthorityRealmSettings', {
      solution_authority_enabled: enabled
    } as ISolutionAuthorityRealmSettings, { root: true })
    const snackbarText = enabled ? 'admin.extensions.solution-approval.toast.visible' : 'admin.extensions.solution-approval.toast.not-visible'
    dispatch('snackbar/show', snackbarText, { root: true })
  },
  async saveGroup({ commit, dispatch, state }, { name, users }: { name: string; users: IUser[] }): Promise<IAdminSolutionApprovalGroup> {
    try {
      commit('setLoading', true)
      const userIds = users.map((user) => user.id)
      let response: AxiosResponse<IAdminSolutionApprovalGroup>
      if (state.currentlyEditingGroup.id) {
        // we have an ID, update the group
        response = await solutionAuthoritiesApi.updateSolutionAuthority(
          state.currentlyEditingGroup.id,
          name,
          userIds
        )
      } else {
        // create new group
        response = await solutionAuthoritiesApi.createSolutionAuthority(name, userIds)
        dispatch('snackbar/show', 'admin.extensions.solution-approval.snackbar.created', { root: true })
      }
      commit('returnToOverview')
      return response.data
    } finally {
      commit('setLoading', false)
    }
  },
  async loadGroups({ commit }): Promise<void> {
    try {
      commit('setLoading', true)
      const { data: groups } = await solutionAuthoritiesApi.getSolutionAuthorities()
      commit('setGroups', groups)
    } finally {
      commit('setLoading', false)
    }
  },
  async editGroup({ commit }, id: number): Promise<void> {
    try {
      commit('setLoading', true)
      const { data: group } = await solutionAuthoritiesApi.getSolutionAuthority(id)
      commit('editGroup', group)
    } finally {
      commit('setLoading', false)
    }
  },
  async deleteGroup({ commit, dispatch }, id: number): Promise<void> {
    try {
      commit('setDeleteLoading', true)
      await solutionAuthoritiesApi.deleteSolutionAuthority(id)
      commit('deleteGroup', id)
      dispatch('snackbar/show', 'admin.extensions.solution-approval.snackbar.deleted', { root: true })
    } finally {
      commit('toggleDialog')
      commit('setDeleteLoading', false)
    }
  }
}

const mutations: MutationTree<ISolutionApprovalState> = {
  editGroup(state, group: IAdminSolutionApprovalGroup) {
    state.currentlyEditingGroup = {
      ...group,
      users: _orderBy(group.users, 'firstname', 'asc')
    }
    state.showingDetailView = true
  },
  deleteGroup(state, id: number) {
    state.groups = state.groups.filter((group) => group.id !== id)
    state.showingDetailView = false
  },
  addGroup(state) {
    state.currentlyEditingGroup = createNewApprovalGroup()
    state.showingDetailView = true
  },
  returnToOverview(state) {
    state.showingDetailView = false
  },
  setGroups(state, groups: Partial<IAdminSolutionApprovalGroup>[]) {
    state.groups = _orderBy(groups, 'name', 'asc')
  },
  setLoading(state, loading: boolean) {
    state.isLoading = loading
  },
  setDeleteLoading(state, loading: boolean) {
    state.isDeleteLoading = loading
  },
  toggleDialog(state) {
    state.isDialogShowing = !state.isDialogShowing
  }
}

const getters: GetterTree<ISolutionApprovalState, IRootState> = {
  isEditing(state) {
    return !!state.currentlyEditingGroup.id
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
} as Module<ISolutionApprovalState, IRootState>
