









import Vue from 'vue'
import sanitizeHtml from 'sanitize-html'
import { tiptapHtmlToHtml } from '@src/utilities/filters/mention'
import Autolinker from 'autolinker'
import SMLightboxWrapper from '@src/ui/SMLightbox/SMLightboxWrapper.vue'
import sanitizeHtmlDefaultRules from '../constants/sanitizeRules'

export default Vue.extend({
  name: 'SMSimpleEditorOutputView',
  components: {
    SMLightboxWrapper
  },
  props: {
    text: {
      required: true,
      default: '',
      type: String
    }
  },
  computed: {
    processedText(): string {
      return Autolinker.link(
        tiptapHtmlToHtml(
          sanitizeHtml(this.text, sanitizeHtmlDefaultRules)
        ) as string,
        { newWindow: true }
      )
    }
  },
  mounted() {
    this.$emit('mounted')
  }
})
