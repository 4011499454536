







import Vue from 'vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import useCategories from '@src/composables/categories'
import AngularWrapper from '@src/AngularWrapper.vue'
import { useDismissedExpertiseFeedback } from '@src/pinia/dismissedExpertiseFeedback'
import { onMounted } from '@vue/composition-api'
import { IUser, IUserSettings } from './types/user.types'
import { useRouter } from './composables/router'
import useAnalytics from './composables/tracking/tracking'
import { userGraphQlApi } from './api/apiModuleInstances'

const { mapActions: mapRealmSettingsActions } = createNamespacedHelpers('realmSettings')
const { mapActions: mapUserActions } = createNamespacedHelpers('user')
const { mapActions: mapUserSettingsActions } = createNamespacedHelpers('user/settings')
const { mapActions: mapFeatureTogglesActions } = createNamespacedHelpers('featureToggles')
const { mapActions: mapImpressionsActions } = createNamespacedHelpers('featureToggles/impressions')

export default Vue.extend({
  name: 'App',
  components: {
    AngularWrapper
  },
  setup() {
    const dismissedExpertiseFeedbackStore = useDismissedExpertiseFeedback()
    const { loadDismissedExpertiseFeedback } = dismissedExpertiseFeedbackStore
    onMounted(loadDismissedExpertiseFeedback)

    useCategories()
    const router = useRouter()
    const { trackMixpanelEvent } = useAnalytics()
    router.afterEach((to) => {
      const queryValues = Object.create({})
      Object.entries(to.query).forEach(([ key, value ]) => {
        queryValues[`route_query_param_${key}`] = value
      })

      trackMixpanelEvent('Page Viewed', {
        page: to.path,
        ...queryValues
      } as any)
    })
  },
  computed: {
    ...mapGetters('user', { currentUser: 'getUser' }) as {
      currentUser: () => IUser;
    }
  },
  async created() {
    this.loadRealmSettings()
    this.loadUserSettings()
    await this.loadCurrentUser()
    this.setUtc()
    this.loadFeatureToggles()
    this.loadImpressions()
  },
  methods: {
    ...mapRealmSettingsActions([ 'loadRealmSettings' ]) as {
      loadRealmSettings(): Promise<void>;
    },
    ...mapUserActions([ 'loadCurrentUser' ]) as {
      loadCurrentUser(): Promise<IUser>;
    },
    ...(mapUserSettingsActions({ loadUserSettings: 'load' }) as {
      loadUserSettings(lazy?: boolean): Promise<IUserSettings>;
    }),
    ...mapFeatureTogglesActions([ 'loadFeatureToggles' ]) as {
      loadFeatureToggles(): Promise<void>;
    },
    ...mapImpressionsActions([ 'loadImpressions' ]) as {
      loadImpressions(): Promise<void>;
    },
    async setUtc() {
      const userUtcOffset = -(new Date().getTimezoneOffset())
      if (this.currentUser.utc_offset_minutes !== userUtcOffset) {
        await userGraphQlApi.saveUser({ global_user_id: this.currentUser.global_user_id, utc_offset_minutes: userUtcOffset })
      }
    }
  }
})
