import {
  ActionTree, GetterTree, MutationTree, Module
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import i18n, { initLanguage } from '@src/plugins/i18n'
import { DEFAULT_LANGUAGE } from '@src/plugins/i18n/constants'

export interface ILanguageState {
  currentLanguage: string;
  isLoaded: boolean;
}

const state: ILanguageState = {
  currentLanguage: DEFAULT_LANGUAGE,
  isLoaded: false
}

const getters: GetterTree<ILanguageState, IRootState> = {
  getCurrentLanguage(state) {
    return state.currentLanguage
  }
}

const mutations: MutationTree<ILanguageState> = {
  setLoaded: (state: ILanguageState, isLoaded: boolean): void => {
    state.isLoaded = isLoaded
  },
  setCurrentLanguage: (state: ILanguageState, currentLanguage: string): void => {
    state.currentLanguage = currentLanguage
  }
}

const actions: ActionTree<ILanguageState, IRootState> = {
  async setLanguage({ commit }, language: string): Promise<string> {
    if (!language) {
      return ''
    }

    if (language === i18n.locale) {
      commit('setCurrentLanguage', language)
      commit('setLoaded', true)
    } else {
      commit('setCurrentLanguage', language)
      commit('setLoaded', false)
      await initLanguage(language)
      commit('setLoaded', true)
    }

    return language
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<ILanguageState, IRootState>
