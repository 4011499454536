import { snip } from 'js-snip'
import { VueSnipDirectiveHook } from '../types'

export const mounted: VueSnipDirectiveHook = (el: any, { value } : any) => {
  const {
    lines, mode, midWord, ellipsis, onSnipped
  } = value
  snip(el, {
    lines, mode, midWord, ellipsis
  }, onSnipped)
}
