

















































































































































































import {
  defineComponent
} from '@vue/composition-api'
import { useOnboardingTooltipsTracking } from '@src/composables/tracking/tooltips/useOnboardingTooltipsTracking'
import { useEducationalTooltipsTracking } from '@src/composables/tracking/tooltips/useEducationalTooltipsTracking'
import { useOnboardingTooltips } from '../composables/useOnboardingTooltips'
import { useTooltipPosition } from '../composables/useTooltipPosition'
import SMOnboardingToolTipCard from './SMOnboardingTooltipCard.vue'

export default defineComponent({
  name: 'SMOnboardingTooltipOverlay',
  components: {
    SMOnboardingToolTipCard
  },
  setup() {
    const {
      dynamicCutoutCss,
      currentTooltipId,
      currentTooltipIndex,
      isHighlighting,
      totalTooltipsOfCurrentFlow,
      currentTooltipFlow,
      dismissOnboardingTooltips,
      nextOnboardingTooltip,
      dismissEducationalTooltips,
      nextEducationalTooltip
    } = useOnboardingTooltips()

    const {
      tooltipPositionBottom, tooltipPositionRight, tooltipPositionTop, tooltipPositionLeft, tooltipPositionTopRight
    } = useTooltipPosition()

    const onboardingTooltipsTracking = useOnboardingTooltipsTracking({
      isHighlighting,
      currentTooltipIndex,
      currentTooltipFlow,
      totalTooltipsOfCurrentFlow
    })
    const educationalTooltipsTracking = useEducationalTooltipsTracking({
      isHighlighting,
      currentTooltipIndex,
      currentTooltipFlow,
      totalTooltipsOfCurrentFlow
    })

    return {
      isHighlighting,
      currentTooltipId,
      currentTooltipIndex,
      dynamicCutoutCss,
      tooltipPositionBottom,
      tooltipPositionRight,
      tooltipPositionTop,
      tooltipPositionLeft,
      tooltipPositionTopRight,
      totalTooltipsOfCurrentFlow,
      currentTooltipFlow,
      dismissOnboardingTooltips,
      nextOnboardingTooltip,
      dismissEducationalTooltips,
      nextEducationalTooltip,
      onboardingTooltipsTracking,
      educationalTooltipsTracking
    }
  }
})
