import { ref, computed, watch } from '@vue/composition-api'
import { refThrottled } from '@vueuse/core'
import { useRouter, useRoute } from '@src/composables/router'
import { IAutocompleteItemModel } from '@src/types/autocomplete.types'
import { IDeeplinkFilterParams } from '@src/types/filters.types'
import useStore from '@src/store'
import { LogQueryTypeParam } from '@src/store/modules/searchV2/modules/logQueryType'
import useAutoCompleteTracking from './useAutoCompleteTracking'
import { useQuestionAutocompleteSearch } from './useQuestionAutocompleteSearch'
import { useUserAutocompleteSearch } from './useUserAutocompleteSearch'
import { useAutocompleteSearchResultsAggregation } from './useAutocompleteSearchResultsAggregation'

export default function useAutoComplete() {
  const router = useRouter()
  const route = useRoute()
  const queryParams = computed(() => route.query as IDeeplinkFilterParams)
  const store = useStore()

  const searchTerm = ref<string | null>(queryParams.value.query || queryParams.value.search || '')
  const selectedAutocompleteResult = ref<string>(queryParams.value.query || queryParams.value.search || '')
  const throttledSearchTerm = refThrottled<string | null>(searchTerm, 300)

  const { evaluating: questionSearchEvaluating, searchResults: questionSearchResults } = useQuestionAutocompleteSearch(throttledSearchTerm)
  const { evaluating: userSearchEvaluating, searchResults: userSearchResults } = useUserAutocompleteSearch(throttledSearchTerm)
  const { evaluating, searchResults } = useAutocompleteSearchResultsAggregation({
    questionSearchEvaluating,
    questionSearchResults,
    userSearchEvaluating,
    userSearchResults
  })

  const { trackSubmit, trackFocus } = useAutoCompleteTracking(questionSearchResults, userSearchResults, throttledSearchTerm)
  /**
   * @param selectedValue is a string when search has been manually submitted without selecting a suggestion, otherwise is a suggestion object
   */
  function onSelect(selectedValue: IAutocompleteItemModel | string | null) {
    const isCustomStringWithoutAutocompleteSelection = typeof selectedValue === 'string'
    const selectedText = isCustomStringWithoutAutocompleteSelection ? selectedValue : selectedValue?.text
    if (selectedText && selectedText !== searchTerm.value) {
      searchTerm.value = selectedText
    }
    if (selectedText && selectedText.length > 1) {
      // Mixpanel
      trackSubmit(selectedText, isCustomStringWithoutAutocompleteSelection ? 'manual' : (selectedValue?.value?.isRecentSearch ? 'recent' : 'autocomplete'))

      // Remember the log_query_type param for the stream endpoint in the store, it will be loaded on the next route
      store.commit('searchV2/questions/logQueryType/setLastQueryType', isCustomStringWithoutAutocompleteSelection ? LogQueryTypeParam.MANUAL : LogQueryTypeParam.AUTOCOMPLETE)

      // Go to the next route where the questions will be fetched
      router.push({ path: `/search/?query=${selectedText}` })
    }
  }

  function clearInput() {
    searchTerm.value = ''
  }

  // Keeps the search input in sync with what's in the url.
  watch(queryParams, (value) => {
    if (value.query || value.search) {
      searchTerm.value = value.query! || value.search!
      selectedAutocompleteResult.value = value.query! || value.search!
    }
  })

  return {
    evaluating,
    searchResults,
    searchTerm,
    selectedAutocompleteResult,
    onSelect,
    clearInput,
    trackFocus
  }
}
