import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import { CookieName, getCookie } from '@src/helpers/cookie'
import _includes from 'lodash/includes'
import { IApiResponseError } from '@src/types/errors.types'
import { createOptimizedAPI as createOptimizedAPIFromModules } from './modules/utils'

const MISSING_API_TOKEN_MESSAGE = 'Missing apiToken'
const ALLOWED_URLS_WITHOUT_TOKEN = [ 'api/v1/settings', 'api/v2/settings' ]

interface IApiParams {
  web?: boolean;
  v2?: boolean;
}

function urlIsAllowedWithoutToken({ baseURL, url }: Pick<AxiosRequestConfig, 'baseURL' | 'url'>): boolean {
  const fullUrl = [ baseURL, url ].map((u) => u?.replace(/(^\/)|(\/$)/g, '')).join('/').replace(/(^\/)|(\/$)/g, '')
  return _includes(ALLOWED_URLS_WITHOUT_TOKEN, fullUrl)
}

export function addAxiosInterceptors(instance: AxiosInstance): AxiosInstance {
  // apiToken must be added to the request header at the time just before it is sent.
  // Otherwise, (if added eagerly while creating the Axios instance) the cookie does not contain the apiToken yet.
  instance.interceptors.request.use((config) => {
    const apiToken = getCookie(CookieName.API_TOKEN)
    // If for some reason there is no apiToken, don't add a 'Bearer undefined' header as this will break the application.
    // Without a header, the server will still consider the apiToken from the cookie (although not recommended any may change in the future).
    if (apiToken) {
      Object.assign(config, {
        headers: {
          Authorization: `Bearer ${apiToken}`
        }
      })
      return config
    }

    // some url's do not require token
    if (urlIsAllowedWithoutToken(config)) {
      return config
    }

    // do not do the req if the apiToken is missing
    throw new axios.Cancel(MISSING_API_TOKEN_MESSAGE)
  }, (error) => Promise.reject(error))

  instance.interceptors.response.use((response) => response, (error) => {
    // do not throw 403 in case of canceled request
    if (axios.isCancel(error) && error.message === MISSING_API_TOKEN_MESSAGE) {
      return { status: 401, data: {} }
    }
    throw error
  })
  instance.interceptors.response.use((response) => response, async (error: AxiosError<IApiResponseError>) => {
    if (error.response?.status === 401) {
      const { handle401 } = await import('@src/utilities/auth')
      handle401(error.response.data)
    }
    throw error
  })
  return instance
}

export default function createAxiosInstance(apiPath = '', { web = false, v2 = false } = {} as IApiParams): AxiosInstance {
  const instance = axios.create({
    baseURL: `${web ? '/web' : ''}/api/${v2 ? 'v2' : 'v1'}/${apiPath}`
  })
  return addAxiosInterceptors(instance)
}

export const createOptimizedAPI = createOptimizedAPIFromModules
