import { IVSMMetric, VSMMetricId } from '@src/types/metric.types'

const metric: IVSMMetric = {
  id: VSMMetricId.USERS_WITH_JOB_TITLE,
  name: 'admin.vsm.metrics.users-with-job-title.name',
  description: 'admin.vsm.metrics.users-with-job-title.description',
  targetValue: 100,
  currentValue: 0,
  percentageValue: 0,
  unit: 'percentage',
  color: 'purple',
  loading: true,
  status: 'default',
  actions: [
    {
      id: 'user_sync',
      label: 'admin.vsm.metrics.actions.user-sync',
      primary: true,
      link: {
        url: 'https://help.starmind.com/docs/user-management',
        target: 'blank'
      }
    },
    {
      id: 'learn_more',
      label: 'admin.vsm.metrics.actions.learn-more',
      icon: 'ico-sm-help-outline',
      primary: true,
      link: {
        url: 'https://help.starmind.com/docs/skill-maps',
        target: 'blank'
      }
    }
  ]
}

export default metric
