import { AxiosInstance, AxiosResponse } from 'axios'
import {
  IUnauthenticatedRealmSettings,
  ITermsOfUseRealmSettings,
  IMetadataRealmSettings,
  IContextMessageRealmSettings,
  IGeneralRealmSettings,
  IAdminTranslationRealmSettings,
  IWhitelabellingRealmSettings,
  IUserAdminRealmSettings,
  IAdminEmailMailRealmSettings,
  IGamificationSettings,
  IUserProfileRealmSettings,
  IUserFollowRealmSettings,
  IContentSettings,
  IQuestionSettings,
  IAdminExpertSearchSettings,
  ISolutionSettings,
  ICategoriesRealmSettings,
  ISolutionAuthorityRealmSettings,
  IKnowledgeSpacesRealmSettings,
  ISolutionValidationRealmSettings,
  IAdminNotificationRealmSettings,
  IRssFeedRealmSettings,
  IWebhookRealmSettings,
  ISolutionValueRealmSettings,
  IOpenSearchRealmSettings,
  ICompanyGuidelinesRealmSettings,
  IWorkspacesRealmSettings,
  IIntegrationsRealmSettings,
  IAdminQuestionEscalationRealmSettings,
  IQuestionEscalationRealmSettingsForm,
  IFlaggingRealmSettingsForm,
  IAdminFlaggingRealmSettings,
  IKpiRealmSettings,
  IVsmMetricsTargetsResponse
} from '../../../types/realmSettings.types'
import { IGraphqlUserSchema, IUser } from '../../../types/user.types'
import fallbackRealmSettings from './settingsFallback'
import { createOptimizedAPI } from '../utils'
import { userGraphqlApiFactory } from '../accounts/user'

export interface ISettingsAdminApiFactoryOptions {
  userGraphQlApi?: ReturnType<typeof userGraphqlApiFactory>;
  staticSettings?: IUnauthenticatedRealmSettings;
  postProcessSettings?: (settings: IUnauthenticatedRealmSettings) => IUnauthenticatedRealmSettings;
}

const baseUrl = '/api/v2/settings'

export function settingsApiFactory(api: AxiosInstance, options?: ISettingsAdminApiFactoryOptions) {
  const methods = {
    async get(): Promise<Pick<AxiosResponse<IUnauthenticatedRealmSettings>, 'data'> & Partial<AxiosResponse<IUnauthenticatedRealmSettings>>> {
      const realmSettings = options?.staticSettings
      try {
        const returnValue = realmSettings ? {
          data: realmSettings
        } : await api.get<IUnauthenticatedRealmSettings>(baseUrl)

        if (options?.postProcessSettings) {
          try {
            return { data: options.postProcessSettings(returnValue.data) }
          } catch (error) {
            return returnValue
          }
        }
        return returnValue
      } catch {
        return {
          data: fallbackRealmSettings
        }
      }
    }
  }
  return createOptimizedAPI(methods)
}

export function settingsAdminApiFactory(api: AxiosInstance, options?: ISettingsAdminApiFactoryOptions) {
  const methods = {
    getTermsOfUseSettings() {
      return api.get<ITermsOfUseRealmSettings>(`${baseUrl}/terms-of-use`)
    },
    setTermsOfUseSettings(settings: Partial<ITermsOfUseRealmSettings>) {
      return api.put<ITermsOfUseRealmSettings>(`${baseUrl}/terms-of-use`, settings)
    },
    getMetadata() {
      return api.get<IMetadataRealmSettings>(`${baseUrl}/metadata`)
    },
    setMetadata(settings: Partial<IMetadataRealmSettings>) {
      return api.put<IMetadataRealmSettings>(`${baseUrl}/metadata`, settings)
    },
    getContextMessage() {
      return api.get<IContextMessageRealmSettings>(`${baseUrl}/context-message`)
    },
    setContextMessage(settings: Partial<IContextMessageRealmSettings>) {
      return api.put<IContextMessageRealmSettings>(`${baseUrl}/context-message`, settings)
    },
    getGeneralSettings() {
      return api.get<IGeneralRealmSettings>(`${baseUrl}/general`)
    },
    setGeneralSettings(settings: Partial<IGeneralRealmSettings>) {
      return api.put<IGeneralRealmSettings>(`${baseUrl}/general`, settings)
    },
    getTranslationSettings() {
      return api.get<IAdminTranslationRealmSettings>(`${baseUrl}/translation`)
    },
    setTranslationSettings(settings: Partial<IAdminTranslationRealmSettings>) {
      return api.put<IAdminTranslationRealmSettings>(`${baseUrl}/translation`, settings)
    },
    getWhiteLabellingSettings() {
      return api.get<IWhitelabellingRealmSettings>(`${baseUrl}/white-labelling`)
    },
    getUserAdminRealmSettings() {
      return api.get<IUserAdminRealmSettings>(`${baseUrl}/user-admin`)
    },
    setUserAdminRealmSettings(settings: Partial<IUserAdminRealmSettings>) {
      return api.put<IUserAdminRealmSettings>(`${baseUrl}/user-admin`, settings)
    },
    getUserFollowRealmSettings() {
      return api.get<IUserFollowRealmSettings>(`${baseUrl}/user-follow`)
    },
    setUserFollowRealmSettings(settings: Partial<IUserFollowRealmSettings>) {
      return api.put<IUserFollowRealmSettings>(`${baseUrl}/user-follow`, settings)
    },
    getUserProfileRealmSettings() {
      return api.get<IUserProfileRealmSettings>(`${baseUrl}/user-profile`)
    },
    setUserProfileRealmSettings(settings: Partial<IUserProfileRealmSettings>) {
      return api.put<IUserProfileRealmSettings>(`${baseUrl}/user-profile`, settings)
    },
    getEmailRealmSettings() {
      return api.get<IAdminEmailMailRealmSettings>(`${baseUrl}/email`)
    },
    setEmailRealmSettings(settings: Partial<IAdminEmailMailRealmSettings>) {
      return api.put<IAdminEmailMailRealmSettings>(`${baseUrl}/email`, settings)
    },
    getGamificationSettings() {
      return api.get<IGamificationSettings>(`${baseUrl}/gamification`)
    },
    setGamificationSettings(settings: Partial<IGamificationSettings>) {
      return api.put<IGamificationSettings>(`${baseUrl}/gamification`, settings)
    },
    getContentSettings() {
      return api.get<IContentSettings>(`${baseUrl}/content`)
    },
    setContentSettings(settings: Partial<IContentSettings>) {
      return api.put<IContentSettings>(`${baseUrl}/content`, settings)
    },
    getQuestionSettings() {
      return api.get<IQuestionSettings>(`${baseUrl}/question`)
    },
    setQuestionSettings(settings: Partial<IQuestionSettings>) {
      return api.put<IQuestionSettings>(`${baseUrl}/question`, settings)
    },
    getExpertSearchSettings() {
      return api.get<IAdminExpertSearchSettings>(`${baseUrl}/expert-search`)
    },
    setExpertSearchSettings(settings: Partial<IAdminExpertSearchSettings>) {
      return api.put<IAdminExpertSearchSettings>(`${baseUrl}/expert-search`, settings)
    },
    getSolutionSettings() {
      return api.get<ISolutionSettings>(`${baseUrl}/solution`)
    },
    setSolutionSettings(settings: Partial<ISolutionSettings>) {
      return api.put<ISolutionSettings>(`${baseUrl}/solution`, settings)
    },
    getCategoriesSettings() {
      return api.get<ICategoriesRealmSettings>(`${baseUrl}/categories`)
    },
    setCategoriesSettings(settings: Partial<ICategoriesRealmSettings>) {
      return api.put<ICategoriesRealmSettings>(`${baseUrl}/categories`, settings)
    },
    getSolutionAuthoritySettings() {
      return api.get<ISolutionAuthorityRealmSettings>(`${baseUrl}/solution-authority`)
    },
    setSolutionAuthoritySettings(settings: Partial<ISolutionAuthorityRealmSettings>) {
      return api.put<ISolutionAuthorityRealmSettings>(`${baseUrl}/solution-authority`, settings)
    },
    getKnowledgeSpacesRealmSettings() {
      return api.get<IKnowledgeSpacesRealmSettings>(`${baseUrl}/knowledge-spaces`)
    },
    setKnowledgeSpacesRealmSettings(settings: Partial<IKnowledgeSpacesRealmSettings>) {
      return api.put<IKnowledgeSpacesRealmSettings>(`${baseUrl}/knowledge-spaces`, settings)
    },
    getSolutionValidationRealmSettings() {
      return api.get<ISolutionValidationRealmSettings>(`${baseUrl}/solution-validation`)
    },
    setSolutionValidationRealmSettings(settings: Partial<ISolutionValidationRealmSettings>) {
      return api.put<ISolutionValidationRealmSettings>(`${baseUrl}/solution-validation`, settings)
    },
    getNotificationRealmSettings() {
      return api.get<IAdminNotificationRealmSettings>(`${baseUrl}/notification`)
    },
    setNotificationRealmSettings(settings: Partial<IAdminNotificationRealmSettings>) {
      return api.put<IAdminNotificationRealmSettings>(`${baseUrl}/notification`, settings)
    },
    getRssFeedRealmSettings() {
      return api.get<IRssFeedRealmSettings>(`${baseUrl}/rss-feed`)
    },
    setRssFeedRealmSettings(settings: Partial<IRssFeedRealmSettings>) {
      return api.put<IRssFeedRealmSettings>(`${baseUrl}/rss-feed`, settings)
    },
    getWebhookRealmSettings() {
      return api.get<IWebhookRealmSettings>(`${baseUrl}/web-hook`)
    },
    setWebhookRealmSettings(settings: Partial<IWebhookRealmSettings>) {
      return api.put<IWebhookRealmSettings>(`${baseUrl}/web-hook`, settings)
    },
    getSolutionValueRealmSettings() {
      return api.get<ISolutionValueRealmSettings>(`${baseUrl}/solution-value`)
    },
    setSolutionValueRealmSettings(settings: Partial<ISolutionValueRealmSettings>) {
      return api.put<ISolutionValueRealmSettings>(`${baseUrl}/solution-value`, settings)
    },
    getOpenSearchRealmSettings() {
      return api.get<IOpenSearchRealmSettings>(`${baseUrl}/open-search`)
    },
    setOpenSearchRealmSettings(settings: Partial<IOpenSearchRealmSettings>) {
      return api.put<IOpenSearchRealmSettings>(`${baseUrl}/open-search`, settings)
    },
    getCompanyGuidelinesRealmSettings() {
      return api.get<ICompanyGuidelinesRealmSettings>(`${baseUrl}/company-guidelines`)
    },
    setCompanyGuidelinesRealmSettings(settings: Partial<ICompanyGuidelinesRealmSettings>) {
      return api.put<ICompanyGuidelinesRealmSettings>(`${baseUrl}/company-guidelines`, settings)
    },
    getWorkspacesRealmSettings() {
      return api.get<IWorkspacesRealmSettings>(`${baseUrl}/workspaces`)
    },
    setWorkspacesRealmSettings(settings: Partial<IWorkspacesRealmSettings>) {
      return api.put<IWorkspacesRealmSettings>(`${baseUrl}/workspaces`, settings)
    },
    getIntegrationRealmSettings() {
      return api.get<IIntegrationsRealmSettings>(`${baseUrl}/integrations`)
    },
    setIntegrationRealmSettings(settings: Partial<IIntegrationsRealmSettings>) {
      return api.put<IIntegrationsRealmSettings>(`${baseUrl}/integrations`, settings)
    },
    async getQuestionEscalationRealmSettings(): Promise<{ data: IQuestionEscalationRealmSettingsForm }> {
      if (!options?.userGraphQlApi) { throw new Error(`${baseUrl}/[settingsAdminApiFactory] An instance of userGraphQlApi must be passed via the options.`) }

      const { data: questionEscalationSettings } = await api.get<IAdminQuestionEscalationRealmSettings>(`${baseUrl}/question-escalation`)
      const users = await options.userGraphQlApi.getMultipleUsersById(questionEscalationSettings.user_ids)
      return {
        data: {
          ...questionEscalationSettings,
          users
        }
      }
    },
    async setQuestionEscalationRealmSettings({ users, ...settings }: IQuestionEscalationRealmSettingsForm): Promise<{ data: IQuestionEscalationRealmSettingsForm }> {
      const { data: questionEscalationSettings } = await api.put<IAdminQuestionEscalationRealmSettings>(`${baseUrl}/question-escalation`, {
        ...settings,
        user_ids: users.map((user) => (user as Partial<IGraphqlUserSchema>).globalUserId || (user as IUser).global_user_id)
      })
      return {
        data: {
          ...questionEscalationSettings,
          users
        }
      }
    },
    async getFlaggingRealmSettings(): Promise<{ data: IFlaggingRealmSettingsForm }> {
      if (!options?.userGraphQlApi) { throw new Error(`${baseUrl}/[settingsAdminApiFactory] An instance of userGraphQlApi must be passed via the options.`) }

      const { data } = await api.get<IAdminFlaggingRealmSettings>(`${baseUrl}/question-flagging`)
      const users = await options.userGraphQlApi.getMultipleUsersById(data.user_ids)
      return {
        data: {
          ...data,
          users
        }
      }
    },
    async setFlaggingRealmSettings({ users, enabled }: IFlaggingRealmSettingsForm): Promise<{ data: IFlaggingRealmSettingsForm }> {
      const { data } = await api.put<IAdminQuestionEscalationRealmSettings>(`${baseUrl}/question-flagging`, {
        enabled,
        user_ids: users.map((user) => (user as Partial<IGraphqlUserSchema>).globalUserId || (user as IUser).global_user_id)
      })
      return {
        data: {
          ...data,
          users
        }
      }
    },
    getKpiRealmSettings() {
      return api.get<IKpiRealmSettings>(`${baseUrl}/kpi`)
    },
    setKpiRealmSettings(settings: Partial<IKpiRealmSettings>) {
      return api.put<IKpiRealmSettings>(`${baseUrl}/kpi`, settings)
    },
    getVsmMetricsTargets() {
      return api.get<IVsmMetricsTargetsResponse>('metrics/targets')
    },
    setVsmMetricsInviteTarget(invitedUsers: number) {
      return api.put('metrics/targets', {
        invited_users: invitedUsers
      })
    }
  }
  return methods
}
