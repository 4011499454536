import { Store, MutationPayload } from 'vuex'
import { IRootState } from '@src/types/store.types'

const setFeatureToggles = 'featureToggles/setFeatureToggles'
const setImpressions = 'featureToggles/impressions/setImpressions'

const featureTogglesHook = (store: Store<IRootState>): void => {
  store.subscribe(({ type }: MutationPayload) => {
    if ([ setFeatureToggles, setImpressions ].includes(type)) {
      store.commit('analytics/setSuperproperties', store.getters['featureToggles/getExperimentsSuperProps'])
    }
  })
}

export default featureTogglesHook
