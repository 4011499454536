import { InteractionElement, InteractionType } from '@src/types/events.types'
import { DirectiveOptions } from 'vue'
import { getPrefixedAttributes } from '@src/utilities/trackingUtils'

/*
 *           /^\/^\
 *         _|__|  O|
 *\/     /~     \_/ \
 * \____|__________/  \
 *        \_______      \
 *                `\     \                 \
 *                  |     |                  \
 *                 /      /                    \
 *                /     /                       \\
 *              /      /                         \ \
 *             /     /  TRACKING DIRECTIVE USAGE: \  \
 *           /     /             _----_            \   \
 *          /     /           _-~      ~-_         |   |
 *         (      (        _-~    _--_    ~-_     _/   |
 *          \      ~-____-~    _-~    ~-_    ~-_-~    /
 *            ~-_           _-~          ~-_       _-~
 *               ~--______-~                ~-___-~
 *
 * Basic:
 * <element v-track="'clickQuestion'" />
 *
 * With category modifier:
 * <button v-track="'clickQuestion'" />
 * <button v-track="'clickQuestion'" />
 * <button v-track="'clickQuestion'" />
 *
 * With custom event (click is default):
 * <element v-track:mouseover="'hoverQuestion'" />
 * <input v-track:blur="'blurInputField'" />
 * <input v-track:focus="'focusInputField'" />
 * <select v-track:change="'selectFromDropdown'" />
 *
 * With custom static props:
 * <button
 *   v-track="'clickQuestion'"
 *   data-tracking-prop-element="icon_button"
 * />
 *
 * With custom dynamic props:
 * <button
 *   v-track="'clickQuestion'"
 *   :data-tracking-prop-question_id="questionId"
 * />
 *
 * All combined:
 * <button
 *   v-track:click="'clickQuestion'"
 *   :data-tracking-prop-question_id="questionId"
 * />
 *
 * Debug modifier prints out all tracking props in the console (don't use in production):
 * <button v-track:click.debug="'clickQuestion'" />
 */

const attributePrefixRegex = /^data-tracking-prop-/

function getTrackingElement(tagName: string): InteractionElement | undefined {
  // this list is not really accurate or complete but should cover most simple click events
  const elementMap: Record<string, InteractionElement> = {
    A: 'link',
    BUTTON: 'button',
    INPUT: 'input_field',
    SELECT: 'dropdown_menu',
    I: 'icon',
    LI: 'list_item'
  }
  return elementMap[tagName]
}

const trackingDirective: DirectiveOptions = {
  bind(el, binding, vnode) {
    const eventType = binding.arg ?? 'click' // falls back to click if argument is not provided
    const eventName: string = binding.value
    const additionalProps = getPrefixedAttributes(el, attributePrefixRegex)

    el.addEventListener(eventType, () => {
      const eventProps = {
        type: eventType as InteractionType,
        element: getTrackingElement(el.tagName),
        component: vnode.context?.$options?.name,
        ...additionalProps
      }

      if (binding.modifiers.debug) {
        console.table({ eventName, ...eventProps }) // eslint-disable-line no-console
      }
      vnode.context?.$trackMixpanelEvent(eventName, eventProps)
    })
  }
}
export default trackingDirective
