import useTracking from '@src/composables/tracking/tracking'
import _once from 'lodash/once'

export default function useQuestionTitleQualityIndicator() {
  const { trackMixpanelEvent } = useTracking()

  function trackViewQuestionTitleQualityIndicator() {
    trackMixpanelEvent('viewQuestionTitleQualityIndicator', {
      component: 'question_title_quality_indicatore',
      type: 'view'
    })
  }

  function trackClickQuestionTitleQualityIndicator() {
    trackMixpanelEvent('clickQuestionTitleQualityIndicator', {
      component: 'question_title_quality_indicatore',
      type: 'click'
    })
  }

  function trackChangeQuestionTitle() {
    trackMixpanelEvent('changeQuestionTitle', {
      component: 'question_title_quality_indicatore',
      type: 'enter'
    })
  }

  const trackViewQuestionTitleQualityIndicatorOnce = _once(trackViewQuestionTitleQualityIndicator)
  const trackClickQuestionTitleQualityIndicatorOnce = _once(trackClickQuestionTitleQualityIndicator)
  const trackChangeQuestionTitleOnce = _once(trackChangeQuestionTitle)

  return {
    trackViewQuestionTitleQualityIndicatorOnce,
    trackClickQuestionTitleQualityIndicatorOnce,
    trackChangeQuestionTitleOnce
  }
}
