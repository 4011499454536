import { CombinedVueInstance } from 'vue/types/vue' // eslint-disable-line
import { Editor } from '@tiptap/vue-2'
import { SuggestionOptions, SuggestionProps } from '@tiptap/suggestion'
import userApi from '@src/api/user'
import questionsApi from '@src/api/questions'
import Mention from '@tiptap/extension-mention'
import { IUser } from '@src/types/user.types'
import { getFullName } from '@src/utilities/userUtils'
import Vue from 'vue'
import { IQuestionComplete } from '@src/types/question.types'
import { Ref } from '@vue/composition-api'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import { getSuggestedUsers } from '@src/utilities/suggestedUsersUtils'
// Typing seems to be wrong, as docs state this function can be async: https://www.tiptap.dev/api/nodes/mention
type AsyncSuggestionItem = (query: string) => Promise<IUser[]>

export interface IMentionData {
  isMentioning: boolean;
  suggestionProps: Partial<SuggestionProps> & Pick<SuggestionProps, 'command' | 'clientRect' | 'items'>;
  selectedSuggestionIndex: number;
}

type EditorWithMentionVM = CombinedVueInstance<Vue, {
  editor: Editor | null;
  question?: Ref<IQuestionComplete | undefined>
} & IMentionData, unknown, unknown, Readonly<{
  value: string;
  placeholder: string;
  errorMessages: string[];
  error: boolean;
  realmSettings: IUnauthenticatedRealmSettings;
  knowledgeSpace?: IKnowledgeSpace | null
  isReadOnly: boolean;
  trackUserMention: () => void;
}>>

// A desperate attempt to extract the mention logic from the main component
export default (componentVm: EditorWithMentionVM): typeof Mention => {
  const vm = componentVm
  return Mention.configure({
    HTMLAttributes: {
      class: 'mention'
    },
    renderLabel({ node }) {
      // render the name without the "@"-character in front
      return node.attrs.label
    },
    suggestion: {
      allowSpaces: true,
      items: async (data: {editor:Editor | null, query: string }): Promise<IUser[]> => {
        if (data.query) {
          return getSuggestedUsers({
            query: data.query,
            params: { limit: 3 },
            allowInactiveInteraction: vm.realmSettings.user_admin.allow_inactive_user_interaction,
            knowledgeSpace: vm.knowledgeSpace
          })
        }
        if (vm.question?.value?.id) {
          const { data: involvedUsers } = await questionsApi.cached.getInvolvedUsers(vm.question.value.id)
          let involvedUsersForEditor: IUser[] = []
          if (involvedUsers) {
            const allInvolvedUsers = [
              ...involvedUsers.solution_comment_author,
              ...involvedUsers.solution_author,
              ...involvedUsers.comment_author
            ]
            involvedUsersForEditor = allInvolvedUsers.filter((involvedUser, index) => index === allInvolvedUsers.findIndex((val) => involvedUser.id === val.id) && !involvedUser.is_deleted && !involvedUser.is_technical_user)
          }
          if (involvedUsersForEditor.length) {
            return involvedUsersForEditor
          }
          const { data: userRecommendations } = await userApi.cached.getRecentMentions(vm.knowledgeSpace?.is_closed ? vm.knowledgeSpace.id : undefined)
          if (userRecommendations.length) {
            return userRecommendations
          }
        }
        const { data: { items } } = await userApi.getUsers({ query: '', limit: 5 })
        return items
      },
      render() {
        return {
          onStart(props): void {
            vm.isMentioning = true
            vm.suggestionProps = props
          },
          onUpdate(props): void {
            vm.suggestionProps = props
          },
          onKeyDown({ event }): boolean {
            if (event.key === 'Enter') {
              const { id, firstname, lastname } = (vm.suggestionProps.items as IUser[])[vm.selectedSuggestionIndex]
              vm.suggestionProps.command({
                id,
                label: getFullName(firstname, lastname)
              })
              vm.trackUserMention()
              return true
            }
            if (event.key === 'ArrowDown') {
              vm.selectedSuggestionIndex = (vm.selectedSuggestionIndex + 1) % vm.suggestionProps.items.length
              return true
            }
            if (event.key === 'ArrowUp') {
              vm.selectedSuggestionIndex = (vm.selectedSuggestionIndex + vm.suggestionProps.items.length - 1) % vm.suggestionProps.items.length
              return true
            }
            return false
          },
          onExit(props): void {
            vm.suggestionProps = props
            vm.selectedSuggestionIndex = 0
            vm.isMentioning = false
          }
        }
      }
    } as Omit<SuggestionOptions, 'editor'> & {
      items: AsyncSuggestionItem;
    }
  })
}
