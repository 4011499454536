
























import { defineComponent } from '@vue/composition-api'
import SMDialog from '@src/ui/SMDialog/index.vue'

export default defineComponent({
  name: 'SMLightboxWrapper',
  components: {
    SMDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      type: String,
      default: ''
    }
  }
})
