import { Store, MutationPayload } from 'vuex'
import { IRootState } from '@src/types/store.types'

const subscribeUserMutation = 'user/setUser'

/**
 * subscribe user props changes and update i18n locale as soon as language changes
 */
const userLanguageHook = (store: Store<IRootState>): void => {
  store.subscribe((mutation: MutationPayload) => {
    const { type, payload } = mutation

    if (type === subscribeUserMutation) {
      const { language } = payload
      store.dispatch('language/setLanguage', language)
    }
  })
}

export default userLanguageHook
