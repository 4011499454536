import { Ref, ref } from '@vue/composition-api'
import { computedAsync } from '@vueuse/core'
import { searchApi } from '@src/api/apiModuleInstances'

export function useQuestionAutocompleteSearch(searchTerm: Ref<string | null>) {
  const evaluating = ref(false)
  const searchResults = computedAsync(
    async (onCancel) => {
      const abortController = new AbortController()
      if (searchTerm.value && searchTerm.value.length > 1) {
        const { data: { items: questions } } = await searchApi.getQuestionAutocomplete(searchTerm.value, 7, abortController)
        return questions
      }

      onCancel(() => abortController.abort())
      return []
    },
    [],
    evaluating
  )

  return { searchResults, evaluating }
}
