


















































import { defineComponent, onMounted } from '@vue/composition-api'
import { useFeedbackTracking } from '@src/ui/SMFeedbackDialog/composables/useFeedbackTracking'
import { useFeedback } from '@src/ui/SMFeedbackDialog/composables/useFeedback'

export default defineComponent({
  name: 'SmFeedbackDialogContent',
  setup() {
    const tracking = useFeedbackTracking()
    const feedback = useFeedback()
    onMounted(tracking.viewFeedback)
    return {
      ...feedback
    }
  }
})
