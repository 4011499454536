import useRealmSettings from '@src/composables/realmSettings'
import { computed } from '@vue/composition-api'
import { PrivilegedUserRoles } from '@src/types/user.types'
import { isVSMSetupPageEnabled } from '@src/utilities/vsm'

export interface IMenuItem {
  title: string;
  routerLinkTo?: string;
  roles: PrivilegedUserRoles[];
  isEnabled: boolean;
  subsections: IMenuItem[] | undefined;
}

export default function useAdminMenu() {
  const { realmSettings } = useRealmSettings()
  const goLiveDate = computed(() => realmSettings.value?.metadata.go_live_date)
  const vsmEnabled = computed(() => !!realmSettings.value?.vsm.vsm_enabled)
  const categoriesInPricingPlan = computed(() => !!realmSettings.value?.categories.categories_in_pricing_plan)
  const ksInPricingPlan = computed(() => !!realmSettings.value?.knowledge_spaces.knowledge_spaces_in_pricing_plan)
  const userScoreEnabled = computed(() => !!realmSettings.value?.gamification.user_score_enabled)
  const workspacesEnabled = computed(() => !!realmSettings.value?.workspaces.enabled)
  const solutionAuthorityInPricingPlan = computed(() => !!realmSettings.value?.solution_authority.solution_authority_in_pricing_plan)
  const termsInPricingPlan = computed(() => !!realmSettings.value?.terms_of_use.in_pricing_plan)
  const translationEnabled = computed(() => !!realmSettings.value?.translation.translations_rolled_out
    && !realmSettings.value?.translation.automatic_translations_enabled
    && !realmSettings.value?.translation.automatic_translations_in_pricing_plan)
  const cannyEnabled = computed(() => !!realmSettings.value?.canny.enabled)

  const vsmSetupEnabled = computed(() => {
    const showVsmSetupPage = isVSMSetupPageEnabled(goLiveDate.value)
    return showVsmSetupPage && vsmEnabled.value
  })

  const menuItems = computed<IMenuItem[]>(() => [
    {
      title: 'admin.vsm.setup.navigation.title',
      routerLinkTo: '/admin/vsm/setup',
      roles: [ PrivilegedUserRoles.NetworkStatisticsAdmin ],
      isEnabled: vsmSetupEnabled.value,
      subsections: undefined
    },
    {
      title: 'admin.vsm.metrics.navigation.title',
      routerLinkTo: '/admin/vsm/metrics',
      roles: [ PrivilegedUserRoles.NetworkStatisticsAdmin ],
      isEnabled: vsmEnabled.value,
      subsections: undefined
    },
    {
      title: 'admin.users',
      routerLinkTo: '/admin/users',
      roles: [ PrivilegedUserRoles.UserAdmin ],
      isEnabled: true,
      subsections: undefined
    },
    {
      title: 'admin.content',
      roles: [ PrivilegedUserRoles.ContentAdmin ],
      isEnabled: true,
      subsections: [
        {
          title: 'admin.content.questions',
          routerLinkTo: '/admin/content/questions',
          roles: [ PrivilegedUserRoles.ContentAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.content.tags',
          routerLinkTo: '/admin/content/tags',
          roles: [ PrivilegedUserRoles.ContentAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.content.categories',
          routerLinkTo: '/admin/content/categories',
          roles: [ PrivilegedUserRoles.ContentAdmin ],
          isEnabled: categoriesInPricingPlan.value,
          subsections: undefined
        },
        {
          title: 'admin.content.ks.menu',
          routerLinkTo: '/admin/content/knowledge-spaces',
          roles: [ PrivilegedUserRoles.ContentAdmin ],
          isEnabled: ksInPricingPlan.value,
          subsections: undefined
        }
      ]
    },
    {
      title: 'admin.statistics',
      roles: [
        PrivilegedUserRoles.NetworkStatisticsAdmin,
        PrivilegedUserRoles.UserStatisticsAdmin
      ],
      isEnabled: true,
      subsections: [
        {
          title: 'admin.statistics.analytics-dashboard.title',
          routerLinkTo: '/admin/statistics/dashboard',
          roles: [ PrivilegedUserRoles.NetworkStatisticsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.statistics.cockpit',
          routerLinkTo: '/admin/statistics/cockpit/overview',
          roles: [ PrivilegedUserRoles.NetworkStatisticsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.statistics.kpi',
          routerLinkTo: '/admin/statistics/kpi',
          roles: [ PrivilegedUserRoles.NetworkStatisticsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.analytics.user.export.menu',
          routerLinkTo: '/admin/gamification/export',
          roles: [ PrivilegedUserRoles.UserStatisticsAdmin ],
          isEnabled: userScoreEnabled.value,
          subsections: undefined
        }
      ]
    },
    {
      title: 'admin.settings',
      roles: [ PrivilegedUserRoles.SettingsAdmin ],
      isEnabled: true,
      subsections: [
        {
          title: 'admin.settings.algorithm',
          routerLinkTo: '/admin/settings/algorithm',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.auth',
          routerLinkTo: '/admin/settings/auth',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.workspace',
          routerLinkTo: '/admin/settings/workspace',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: workspacesEnabled.value,
          subsections: undefined
        },
        {
          title: 'admin.settings.content',
          routerLinkTo: '/admin/settings/content',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.mail',
          routerLinkTo: '/admin/settings/mail',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.network',
          routerLinkTo: '/admin/settings/network',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.user',
          routerLinkTo: '/admin/settings/user',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        }
      ]
    },
    {
      title: 'admin.extensions',
      routerLinkTo: '/admin/announcements',
      roles: [
        PrivilegedUserRoles.SettingsAdmin,
        PrivilegedUserRoles.CommunicationAdmin
      ],
      isEnabled: true,
      subsections: [
        {
          title: 'admin.announcements',
          routerLinkTo: '/admin/announcements',
          roles: [ PrivilegedUserRoles.CommunicationAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.solution-validation',
          routerLinkTo: '/admin/settings/solution-validation',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.solution-value',
          routerLinkTo: '/admin/settings/solution-value',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.extensions.solution-approval.title',
          routerLinkTo: '/admin/settings/approval-group',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: solutionAuthorityInPricingPlan.value,
          subsections: undefined
        },
        {
          title: 'admin.extensions.cds.menu',
          routerLinkTo: '/admin/settings/approval-message',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: termsInPricingPlan.value,
          subsections: undefined
        },
        {
          title: 'admin.guidelines.title',
          routerLinkTo: '/admin/guidelines',
          roles: [ PrivilegedUserRoles.CommunicationAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.context-message.title',
          routerLinkTo: '/admin/context-message',
          roles: [ PrivilegedUserRoles.CommunicationAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.user-score',
          routerLinkTo: '/admin/settings/user-score',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.kpi',
          routerLinkTo: '/admin/settings/kpi',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.question-escalation',
          routerLinkTo: '/admin/settings/question-escalation',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.flagging',
          routerLinkTo: '/admin/settings/flagging',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.automatic-translations',
          routerLinkTo: '/admin/settings/translations',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: translationEnabled.value,
          subsections: undefined
        }
      ]
    },
    {
      title: 'admin.integration',
      roles: [ PrivilegedUserRoles.SettingsAdmin ],
      isEnabled: true,
      subsections: [
        {
          title: 'admin.settings.connectors',
          routerLinkTo: '/admin/settings/connectors',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.opensearch',
          routerLinkTo: '/admin/settings/open-search',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.microsoft.title',
          routerLinkTo: '/admin/settings/microsoft',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.rss',
          routerLinkTo: '/admin/settings/rss',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        },
        {
          title: 'admin.settings.web-hook',
          routerLinkTo: '/admin/settings/web-hook',
          roles: [ PrivilegedUserRoles.SettingsAdmin ],
          isEnabled: true,
          subsections: undefined
        }
      ]
    },
    {
      title: 'admin.feature-requests',
      routerLinkTo: '/admin/feature-requests', // this is used when the menu item has no sections
      roles: [ PrivilegedUserRoles.SuperAdmin, PrivilegedUserRoles.ContentAdmin, PrivilegedUserRoles.CommunicationAdmin, PrivilegedUserRoles.NetworkStatisticsAdmin, PrivilegedUserRoles.SettingsAdmin, PrivilegedUserRoles.UserAdmin, PrivilegedUserRoles.UserStatisticsAdmin ], // needs just one of the privileged roles
      isEnabled: cannyEnabled.value,
      subsections: undefined
    }
  ])

  return {
    menuItems
  }
}
