



















import { defineComponent, ref } from '@vue/composition-api'
import SMConfirmationDialog from '@src/ui/SMConfirmationDialog/index.vue'

export default defineComponent({
  name: 'SMClearRecentSearchesDialog',
  components: { SMConfirmationDialog },
  setup() {
    const dialog = ref(false)

    return {
      dialog
    }
  }
})
