import { ITrackingProps } from '@src/types/events.types'

export const CLICK_CATEGORY = 'clickCategory' // TODO: shouldn't we also track changes to the knowledge space similarly?
export const SELECT_CATEGORY = 'clickCategoryItem'
export const SELECT_KNOWLEDGE_SPACE = 'selectKnowledgeSpace'
export const EXPAND_SIMILAR_QUESTIONS = 'clickExpandSimilarQuestions'
export const CLICK_SIMILAR_QUESTION = 'clickQuestion'
export const ADD_ATTACHMENT = 'clickAddAttachment'
export const SUBMIT_ATTACHMENT = 'submitAddAttachment'
export const CLICK_IN_TITLE = 'clickInNewQuestionTitleInput'
export const SUBMIT_QUESTION = 'submitQuestion'
export const VIEW_ERROR = 'errorNewQuestion'
export const BACK_TO_ASK_VIEW = 'clickBackInSimilarQuestionOverlay'
export const VIEW_DOUBLECHECK = 'viewSimilarQuestionOverlay'
export const VIEW_IDENTICAL_WARNING = 'viewIdenticalQuestionWarning'
export const ADD_TAG = 'submitAddQuestionTag'
export const REMOVE_TAG = 'removeQuestionTag'
export const CANCEL = 'clickCancel'
export const VIEW_ASK = 'viewAsk'
export const CLICK_ASK = 'clickAsk'
export const CLICK_EDIT_QUESTION = 'clickEdit'

export interface ICustomProps {
  is_new_question: boolean;
}

export interface IClickInInputProps extends ICustomProps {
  question_id?: number;
  character_count: number;
}

export interface IClickCategoryProps extends ICustomProps {
  tag_id?: string;
}

export interface ISelectCategoryProps extends IClickCategoryProps {
  category_id: number | null;
}

export interface ISubmitQuestionProps extends ICustomProps {
  total_tags: number;
  question_quality: number;
  similar_question_combined_score?: number;
  similar_question_clicked: boolean;
  is_similar_question: boolean;
  click_ask_id: string;
  knowledge_space_id: number | null;
  extracted_experts_visible: boolean;
  is_first_question_by_user: boolean;
  knowledge_space_modified: boolean;
}

export interface IQuestionErrorProps extends ICustomProps {
  component: string;
  element: string;
  form_field: string;
  error: string;
}

export interface ISimilarQuestionProps extends ICustomProps {
  total_similar_questions: number;
}

export interface ICancelProps extends ICustomProps {
  entity: string;
  state: string;
}

export interface IViewAskProps extends ICustomProps {
  click_ask_id: string;
}

interface ITagMutationProps extends ICustomProps {
  tag_id: string;
}

export interface ITagAddProps extends ITagMutationProps {
  is_first_tag: boolean;
}

export type ITagRemoveProps = ITagMutationProps

export interface IClickSimilarQuestionProps extends ICustomProps {
  is_identical_question: boolean;
  is_similar_question: boolean;
  is_question_author: boolean;
  question_id: number;
  question_status: 'answered' | 'unanswered',
  similar_question_combined_score?: number;
  similar_question_ranking?: number;
  total_answers: number;
}

export interface IClickAskProps {
  element: 'text_icon_button' | 'button';
  component: string;
  click_ask_id: string;
}

export interface IClickEditProps {
  is_author: boolean;
  entity: 'question' | 'answer' | 'comment';
}

export interface ISelectKnowledgeSpaceProps {
  knowledge_space_id: number | null;
}

const commonProps = {
  state: 'ask'
}

export default {
  SMAsk: {
    [CLICK_CATEGORY]: (customProps: IClickCategoryProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'dropdown_menu',
      component: 'new_question_body'
    }),
    [SELECT_CATEGORY]: (customProps: ISelectCategoryProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'list_item',
      component: 'dropdown_menu'
    }),
    [EXPAND_SIMILAR_QUESTIONS]: (customProps: ICustomProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button'
    }),
    [CLICK_IN_TITLE]: (customProps: IClickInInputProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      component: 'new_question_header',
      type: 'click',
      element: 'input_field'
    }),
    [SUBMIT_QUESTION]: (customProps: ISubmitQuestionProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      component: 'new_question_footer',
      type: 'click',
      element: 'button'
    }),
    [VIEW_ERROR]: (customProps: IQuestionErrorProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'view'
    }),
    [BACK_TO_ASK_VIEW]: (customProps: ISimilarQuestionProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      state: 'ask_second_step',
      component: 'new_question_footer',
      type: 'click',
      element: 'button'
    }),
    [VIEW_DOUBLECHECK]: (customProps: ISimilarQuestionProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      state: 'ask_second_step',
      type: 'view'
    }),
    [VIEW_IDENTICAL_WARNING]: (customProps: ICustomProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'view'
    }),
    [CANCEL]: (customProps: ICancelProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click'
    }),
    [VIEW_ASK]: (customProps: IViewAskProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'view'
    }),
    [ADD_TAG]: (customProps: ITagAddProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'enter',
      element: 'input_field',
      component: 'tag_overlay'
    }),
    [REMOVE_TAG]: (customProps: ITagRemoveProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'icon',
      component: 'tag_overlay'
    }),
    [CLICK_SIMILAR_QUESTION]: (customProps: IClickSimilarQuestionProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'list_item',
      component: 'similar_questions_box'
    }),
    [CLICK_ASK]: (customProps: IClickAskProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click'
    }),
    [CLICK_EDIT_QUESTION]: (customProps: IClickAskProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      state: 'question',
      component: 'menu',
      element: 'button'
    }),
    [ADD_ATTACHMENT]: (customProps = { entity: 'question' }): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      component: 'new_question_footer',
      element: 'icon'
    }),
    [SUBMIT_ATTACHMENT]: (customProps = { entity: 'question' }): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      component: 'attachment_overlay',
      element: 'button'
    }),
    [SELECT_KNOWLEDGE_SPACE]: (customProps: ISelectKnowledgeSpaceProps): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'list_item',
      component: 'dropdown_menu'
    })
  }
}
