




















































































import { defineComponent } from '@vue/composition-api'
import SMAvatar from '@src/ui/SMAvatar/index.vue'
import SMWhatsNewBadge from '@src/ui/SMWhatsNew/components/SMWhatsNewBadge/index.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

import {
  ON_MENU_TOGGLE,
  ON_SETTINGS_CLICK,
  ON_SIGN_OUT_CLICK,
  ON_PROFILE_CLICK
} from '@src/plugins/analytics/events/ui/SMHeaderProfileMenu.events'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import { logout } from '@src/utilities/auth'
import useSocialDialogTracking from '@src/ui/SMSocialDialog/composables/useSocialDialogTracking'

interface IMenuItem {
  title: string;
  event: () => void;
  target: string;
  subtitle?: string;
  hasAvatar?: boolean;
  hasBadge?: boolean;
  icon?: string;
  hidden?: boolean;
  name: string; // required for integration tests to select item un-ambiguously
}

export default defineComponent({
  name: 'SMHeaderProfileMenu',
  components: {
    SMAvatar,
    SMWhatsNewBadge
  },
  setup() {
    const { clickInvite } = useSocialDialogTracking()
    function trackInviteClick() {
      clickInvite({ component: 'navigation_menu' })
    }

    return { trackInviteClick }
  },
  computed: {
    ...mapGetters('user', [ 'getUserFetchError', 'getUserFullName', 'getUserLoading' ]) as {
      getUserFetchError: () => boolean;
      getUserLoading: () => boolean;
      getUserFullName: () => string;
    },
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings | undefined;
    },
    menuItems(): IMenuItem[] {
      return [
        {
          title: this.getUserFullName,
          event: this.viewProfileClick,
          target: '_self',
          subtitle: this.$t('global.view-profile').toString(),
          hasAvatar: true,
          name: 'headerMenu-profile'
        },
        {
          title: this.$t('global.settings').toString(),
          event: this.settingsOverviewClick,
          target: '_self',
          icon: 'ico-sm-settings-line',
          name: 'headerMenu-settings'
        },
        {
          title: this.$t('user-guide.header.tooltip.title').toString(),
          event: this.showDrawer,
          target: '_self',
          icon: 'ico-sm-user-guide-line',
          name: 'headerMenu-user-guide',
          hasBadge: true
        },
        {
          title: this.$t('global.invite').toString(),
          event: this.inviteClick,
          target: '_self',
          icon: 'ico-sm-recommend-line',
          hidden: !this.realmSettings?.user_admin.invitation_enabled,
          name: 'headerMenu-invite'
        },
        {
          title: this.$t('dashboard.header.menu.logout').toString(),
          event: this.signOutClick,
          target: '_self',
          icon: 'ico-sm-sign-out',
          name: 'headerMenu-logout'
        }
      ]
    }
  },
  methods: {
    ...mapMutations('socialDialog', [ 'toggleInviteDialog' ]) as {
      toggleInviteDialog: (isOpen: boolean) => void;
    },
    ...mapActions('userGuideSidenav', [ 'showDrawer' ]) as {
      showDrawer: () => Promise<void>;
    },
    viewProfileClick(): void {
      this.$trackEvent(ON_PROFILE_CLICK, this.$options.name)
      this.$router.push({ path: '/user/' })
    },
    settingsOverviewClick(): void {
      this.$trackEvent(ON_SETTINGS_CLICK, this.$options.name)
      this.$router.push({ path: '/settings/' })
    },
    inviteClick(): void {
      this.toggleInviteDialog(true)
      this.trackInviteClick()
    },
    signOutClick(): void {
      this.$trackEvent(ON_SIGN_OUT_CLICK, this.$options.name)
      logout()
    },
    trackToggleMenu(element: 'avatar'| 'icon'): void {
      this.$trackEvent(ON_MENU_TOGGLE, this.$options.name, { element })
    }
  }
})
