
































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SMCarouselDelimiter',
  props: {
    value: {
      type: Number,
      required: true
    },
    length: {
      type: Number,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    withArrows: {
      type: Boolean,
      default: false
    },
    withEmptyCircle: {
      type: Boolean,
      default: false
    },
    withSmallerStartEndPoints: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    function getPointSize(index: number) {
      if (props.withSmallerStartEndPoints && (index === 0 || index === props.length - 1)) {
        return 6
      }
      return 8
    }

    function getPointIcon(index: number) {
      if (props.withSmallerStartEndPoints && (props.value > index)) {
        return 'ico-sm-circle-fill'
      }
      if (index !== props.value && props.withEmptyCircle) {
        return 'ico-sm-check-empty'
      }
      return 'ico-sm-circle-fill'
    }

    function getPointColor(index: number) {
      if (index === props.value || (props.withSmallerStartEndPoints && index === props.length - 1 && props.value >= props.length)) {
        return props.color
      }
      if (props.withEmptyCircle) {
        return ''
      }
      return 'grey lighten-1'
    }

    return {
      getPointSize,
      getPointIcon,
      getPointColor
    }
  }
})
