import { IRootState } from '@src/types/store.types'
import {
  MutationTree, ActionTree, Module
} from 'vuex'
import { ActivationFlowStepStatus, ActivationFlowStep } from '@src/types/activationFlow.types'
import userApi from '@src/api/user'
import awaitGetterValue from '@src/utilities/storeUtils/index'
import { IUser } from '@src/types/user.types'
import { userGraphQlApi } from '@src/api/apiModuleInstances'

interface IActivationFlowLanguageSelectionState {
  isDialogVisible: boolean;
  isLoading: boolean;
}

const state: IActivationFlowLanguageSelectionState = {
  isDialogVisible: false,
  isLoading: false
}

const actions: ActionTree<IActivationFlowLanguageSelectionState, IRootState> = {
  async possiblyShowLanguageSelection({ commit, dispatch, rootGetters }) {
    if (rootGetters['activationFlow/shouldShowOnboardingDialogs']) {
      commit('showDialog')
    } else {
      dispatch('next', ActivationFlowStepStatus.ALREADY_DONE)
    }
  },
  async updateUser({ dispatch, commit, rootGetters }, emailsEnabled = false) {
    const language: string = rootGetters['language/getCurrentLanguage']
    try {
      commit('setLoading', true)
      const { global_user_id } = await awaitGetterValue<IUser>('user/getUser')
      if (!emailsEnabled) {
        await userApi.setEmailSettings({
          digest_email_enabled: emailsEnabled
        })
      }
      await userGraphQlApi.saveUser({ global_user_id, language })
      dispatch('next', ActivationFlowStepStatus.STEPPED_THROUGH_COMPLETE)
    } catch {
      dispatch('next', ActivationFlowStepStatus.ERROR)
    }
    commit('setLoading', false)
    commit('hideDialog')
  },
  next({ dispatch }, status: ActivationFlowStepStatus) {
    dispatch('activationFlow/next', { status, step: ActivationFlowStep.LANGUAGE_SELECTION }, { root: true })
  }
}

const mutations: MutationTree<IActivationFlowLanguageSelectionState> = {
  showDialog(state) {
    state.isDialogVisible = true
  },
  hideDialog(state) {
    state.isDialogVisible = false
  },
  setLoading(state, value: boolean) {
    state.isLoading = value
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
} as Module<IActivationFlowLanguageSelectionState, IRootState>
