import { ITrackingProps } from '@src/types/events.types'

export const CLICK_BADGE = 'clickBadge'

const commonProps = {
  state: 'main.user.contribution'
}

interface IClickBadgeParam {badge_type: string; badge_status: 'owned'| 'available'}

export default {
  BadgesCard: {
    [CLICK_BADGE]: ({ badge_type, badge_status }: IClickBadgeParam):
    ITrackingProps & IClickBadgeParam => ({
      ...commonProps,
      type: 'click',
      badge_type,
      badge_status
    })
  }
}
