import { ISkills, ITag } from '@src/types/user.types'
import { ITagWithExpertise } from '@src/types/tags.types'
import {
  GetterTree, Module, MutationTree, ActionTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'

import userApi from '@src/api/user'

export interface ISkillsState {
  skills: Partial<ISkills>;
  loading: boolean;
  error: boolean;
}

const state: ISkillsState = {
  skills: { tags: [] },
  loading: true,
  error: false
}

const getters: GetterTree<ISkillsState, IRootState> = {
  getSkills(state) {
    return state.skills
  },
  getLoading(state) {
    return state.loading
  },
  getFetchError(state) {
    return state.error
  }
}

const actions: ActionTree<ISkillsState, IRootState> = {
  async loadSkills({ commit }, userId: number): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { data } = await userApi.getSkills(userId || 0)
      commit('setSkills', data)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
  },
  async addSkill({ commit, rootState }, tagWithExpertise: ITagWithExpertise): Promise<boolean> {
    const { user: { id } } = rootState.user
    let addSkillSuccess = false
    try {
      if (id) {
        const { status } = await userApi.addSkill(id, tagWithExpertise.tag)
        if (status === 200) {
          addSkillSuccess = true
          const newSkill = {
            confirmed: tagWithExpertise.user_expertise.confirmed,
            label: tagWithExpertise.tag,
            display_score: tagWithExpertise.user_expertise.display_score,
            score: tagWithExpertise.user_expertise.score,
            details: tagWithExpertise.user_expertise.details,
            isNewExpertise: true
          }
          commit('addNewSkill', newSkill)
        }
      }
    } catch {
      addSkillSuccess = false
    }
    return addSkillSuccess
  },
  async removeSkill({ commit, rootState }, skillTag: ITag): Promise<void> {
    const { user: { id } } = rootState.user
    try {
      if (id) {
        commit('removeSkill', skillTag)
        await userApi.removeSkill(id, skillTag.label)
      }
    } catch {
      commit('addNewSkill', skillTag)
    }
  }
}

const mutations: MutationTree<ISkillsState> = {
  setLoading: (state, loading: boolean): void => {
    state.loading = loading
  },
  setError: (state, error: boolean): void => {
    state.error = error
  },
  setSkills: (state, skills: ISkills): void => {
    state.skills = skills
  },
  addNewSkill: (state, skill: ITag): void => {
    if (state.skills.tags) {
      const newSkills = [ ...state.skills.tags, skill ].sort((a, b) => b.display_score - a.display_score)

      state.skills.tags = newSkills
    }
  },
  removeSkill: (state, skillToRemove: ITag): void => {
    state.skills.tags = state.skills.tags?.filter(({ label }) => label !== skillToRemove.label)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<ISkillsState, IRootState>
