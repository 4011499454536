import { IVSMMetricSimpleValue, VSMMetricId } from '@src/types/metric.types'

const metric: IVSMMetricSimpleValue = {
  id: VSMMetricId.AVERAGE_DAILY_ACTIVE_USERS,
  name: 'admin.vsm.metrics.average-daily-active-users.name',
  currentValue: 0,
  unit: 'absolute',
  loading: true
}

export default metric
