






















import Vue from 'vue'
import { mapGetters } from 'vuex'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'

const millisecondsInADay = 24 * 60 * 60 * 1000
const trialLength = 30 * millisecondsInADay // 30 days
const remainingDaysThreshold = 15

export default Vue.extend({
  name: 'SMTrialBanner',
  computed: {
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings | undefined;
    },
    isTrial(): boolean {
      return !!this.realmSettings?.metadata.is_trial
    },
    trialDaysRemaining(): number {
      return Math.max(
        0,
        Math.floor(
          ((new Date(this.realmSettings?.metadata.creation_date as string)).valueOf()
            + trialLength
            - Date.now()
          )
          / millisecondsInADay
        )
      )
    },
    isEndOfPeriod(): boolean {
      return this.trialDaysRemaining > remainingDaysThreshold
    }
  }
})
