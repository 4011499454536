import Vue from 'vue'
import {
  MutationTree, Module, ActionTree, GetterTree
} from 'vuex'
import sanitizeHtml from 'sanitize-html'
import * as Sentry from '@sentry/browser'
import _get from 'lodash/get'
import { IRootState } from '@src/types/store.types'
import { IUpdate } from '@src/types/featureUpdates'
import { IUser } from '@src/types/user.types'
import featureUpdatesApi, { MAX_UNREAD_COUNT, WHATS_NEW_LIMIT } from '@src/api/featureUpdates'
import awaitGetterValue from '@src/utilities/storeUtils'
import { FeatureToggleVariants } from '@src/plugins/featureToggles/featureToggle.variants'
import { ActivationFlowStepStatus } from '@src/types/activationFlow.types'
import { getWindowLocationObject } from '@src/utilities/iframeDetection'
import { featureToggleList } from '@src/utilities/featureToggle'

export interface IWhatsNewState {
  isLoading: boolean;
  isWhatsNewDialogOpen: boolean;
  lastSeenUser?: string;
  offset: number;
  reachedEnd: boolean;
  updates: IUpdate[];
  wasOpenedAutomatically: boolean;
}

const isVisible = (hasUpdateFilter: string | undefined, isEnabled: boolean) => (hasUpdateFilter === undefined ? true : isEnabled)
export const hasLoaded = (v:boolean): boolean => !v
const processDetails = (details: string): string => sanitizeHtml(details, {
  allowedTags: [ 'img', 'h4', 'p', 'a', 'b', 'br', 'i' ],
  allowedAttributes: {
    img: [ 'src', 'alt', 'title', 'class' ],
    a: [ 'href' ]
  }
})

export const state: IWhatsNewState = {
  isLoading: true,
  isWhatsNewDialogOpen: false,
  lastSeenUser: undefined,
  offset: 0,
  reachedEnd: false,
  updates: [],
  wasOpenedAutomatically: false
}

const getters: GetterTree<IWhatsNewState, IRootState> = {
  updates(state, localGetters, rootState, rootGetters): IUpdate[] {
    return state.updates.filter((update: IUpdate): boolean => {
      const {
        featureToggleId, experimentId, realmSetting
      } = update
      const isRealmSettingEnabled: boolean = _get(rootGetters['realmSettings/realmSettings'], realmSetting ?? '', false)
      const isFeatureToggleEnabled = featureToggleId && featureToggleList(rootGetters['featureToggles/getToggles']).findByName(featureToggleId).isEnabled()
      const isExperimentEnabled = experimentId && featureToggleList(rootGetters['featureToggles/getExperiments']).findByName(experimentId).getExperimentVariant() === FeatureToggleVariants.enabled
      return (
        isVisible(realmSetting, isRealmSettingEnabled)
        && isVisible(featureToggleId, !!isFeatureToggleEnabled)
        && isVisible(experimentId, !!isExperimentEnabled)
      )
    })
  },
  unread(state, localGetters): IUpdate[] {
    return localGetters.updates.filter(({ read }: IUpdate) => !read)
  },
  unreadCount(state, localGetters): number {
    return localGetters.unread.length
  },
  unreadCountString(state, localGetters): string {
    const { unreadCount } = localGetters
    if (unreadCount) {
      return unreadCount <= MAX_UNREAD_COUNT
        ? `${unreadCount}`
        : `${MAX_UNREAD_COUNT}+`
    }
    return ''
  },
  // what's new dialog has not been seen at least for x days
  hasNotBeenSeen(state) {
    return (days: number): boolean => {
      if (!state.lastSeenUser) return false
      const lastSeen = new Date(state.lastSeenUser).getTime()
      const now = Date.now()
      return (now - (days * 24 * 60 * 60 * 1000)) > lastSeen
    }
  },
  hasUpdates(state, localGetters): boolean {
    return !!(localGetters.unreadCount && localGetters.hasNotBeenSeen(14) && getWindowLocationObject().pathname !== '/externalapp/')
  }
}

const actions: ActionTree<IWhatsNewState, IRootState> = {
  async loadFeatureUpdates({ commit, state }): Promise<void> {
    try {
      commit('setLoading', true)
      const { event_tracking_id, language }: IUser = await awaitGetterValue('user/getUser')
      const { data: { updates, lastSeenUser } } = await featureUpdatesApi.getFeatures(event_tracking_id, state.offset, language)
      commit('incrementOffset')
      commit('setFeatureUpdates', updates)
      commit('setLastSeenUser', lastSeenUser)
    } catch (error) {
      Sentry.captureException(new Error(`loading of what's new failed: ${error}`), (scope: any) => {
        scope.setTag('whats_new', true)
        return scope
      })
    } finally {
      commit('setLoading', false)
    }
  },
  async loadDependencies({ dispatch }): Promise<void> {
    await dispatch('loadFeatureUpdates')
    await dispatch('markAllAsReadForNewUsers')
    await awaitGetterValue('realmSettings/settingsLoading', hasLoaded)
    await awaitGetterValue('featureToggles/getLoading', hasLoaded)
  },
  async loadMore({ commit, state }): Promise<void> {
    try {
      if (state.isLoading || state.reachedEnd) return
      commit('setLoading', true)
      const { event_tracking_id, language }: IUser = await awaitGetterValue('user/getUser')
      const { data: { updates } } = await featureUpdatesApi.getFeatures(event_tracking_id, state.offset, language)
      if (updates.length) {
        commit('incrementOffset')
        commit('setFeatureUpdates', updates)
      } else {
        commit('setReachedEnd')
      }
    } catch (error) {
      Sentry.captureException(new Error(`loading more items in what's new failed: ${error}`), (scope: any) => {
        scope.setTag('whats_new', true)
        return scope
      })
    } finally {
      commit('setLoading', false)
    }
  },
  async openWhatsNewDialog({
    commit, dispatch, getters: localGetters
  }, openOnlyIfUpdates?: boolean): Promise<void> {
    try {
      await dispatch('loadDependencies')
      if (openOnlyIfUpdates ? localGetters.hasUpdates : true) {
        commit('setWhatsNewDialog', true)
        commit('setWasOpenedAutomatically', !!openOnlyIfUpdates)
      } else {
        dispatch('activationFlow/whatsNew/whatsNewCompleted', ActivationFlowStepStatus.ALREADY_DONE, { root: true })
      }
    } catch (error) {
      Sentry.captureException(new Error(`auto opening evaluation of what's new failed: ${error}`), (scope: any) => {
        scope.setTag('whats_new', true)
        return scope
      })
    }
  },
  async closeWhatsNewDialog({ dispatch, commit, state }): Promise<void> {
    commit('setWhatsNewDialog', false)
    if (state.wasOpenedAutomatically) {
      dispatch('activationFlow/whatsNew/whatsNewCompleted', ActivationFlowStepStatus.STEPPED_THROUGH_COMPLETE, { root: true })
    }
  },
  async markAsRead({ commit }): Promise<void> {
    const { event_tracking_id }: IUser = await awaitGetterValue('user/getUser')
    const unreadUpdates: IUpdate[] = await awaitGetterValue('whatsNew/unread')
    await featureUpdatesApi.markAsRead(event_tracking_id, unreadUpdates.map((update: IUpdate) => update.id))
    commit('markAllAsRead')
  },
  async markAllAsReadForNewUsers({ commit }): Promise<void> {
    if (!state.lastSeenUser) {
      const { event_tracking_id }: IUser = await awaitGetterValue('user/getUser')
      // an empty array as payload will mark all updates as read in the what's new service
      await featureUpdatesApi.markAsRead(event_tracking_id, [])
      commit('markAllAsRead')
    }
  }
}

const mutations: MutationTree<IWhatsNewState> = {
  setLoading(state, isLoading: boolean): void {
    state.isLoading = isLoading
  },
  setFeatureUpdates(state, updates: IUpdate[]): void {
    const sanitizedUpdates = updates.map((update) => ({
      ...update,
      details: processDetails(update.details)
    }))
    Vue.set(state, 'updates', [ ...state.updates, ...sanitizedUpdates ])
  },
  incrementOffset(state): void {
    state.offset += WHATS_NEW_LIMIT
  },
  setReachedEnd(state): void {
    state.reachedEnd = true
  },
  markAllAsRead(state): void {
    Vue.set(state, 'updates', state.updates.map((update: IUpdate) => ({ ...update, read: true })))
  },
  setLastSeenUser(state, lastSeenUser?: string): void {
    state.lastSeenUser = lastSeenUser
  },
  setWhatsNewDialog(state, open: boolean): void {
    state.isWhatsNewDialogOpen = open
  },
  setWasOpenedAutomatically(state, wasOpenedAutomatically: boolean): void {
    state.wasOpenedAutomatically = wasOpenedAutomatically
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<IWhatsNewState, IRootState>
