




import useRealmSettings from '@src/composables/realmSettings'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SMAutoCompleteLabel',
  setup() {
    const { realmSettings } = useRealmSettings()
    const networkName = computed(() => realmSettings.value?.metadata.realm_name ?? '')

    return { networkName }
  }
})
