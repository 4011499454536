




import Vue from 'vue'
import Duration from '@src/utilities/duration'

export default Vue.extend({
  name: 'SMTimeAgo',
  props: {
    dateString: {
      type: String,
      default: ''
    },
    updateInterval: {
      type: Number,
      default: 60000
    },
    showAgoString: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timestampDate: 0,
    timestampNow: 0,
    intervalId: 0
  }),
  computed: {
    timeAgo(): string {
      const duration = Duration.fromMillis(this.timestampNow - this.timestampDate)
      const translationKey = this.showAgoString ? `ago.${duration.unit}-ago` : `ago.${duration.unit}`
      return this.$tc(translationKey, duration.value)
    }
  },
  watch: {
    dateString(): void {
      this.updateTimestamp()
    }
  },
  created(): void {
    this.updateTimestamp()
    this.intervalId = window.setInterval(() => this.updateTimestamp(), this.updateInterval)
  },
  destroyed(): void {
    window.clearInterval(this.intervalId)
  },
  methods: {
    updateTimestamp(): void {
      if (this.dateString) {
        this.timestampDate = Date.parse(this.dateString)
        this.timestampNow = Date.now()
      }
    }
  }
})
