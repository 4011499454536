import { AxiosInstance } from 'axios'
import { ConnectorIdentifier } from '../../../types/connector.types'

interface IConnectorCreateResponse {
  authorisation_redirect_uri: string;
}

export function connectorsApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/connectors'
  const methods = {
    create(identifier: ConnectorIdentifier) {
      return api.post<IConnectorCreateResponse>(baseUrl, { identifier })
    }
  }
  return methods
}
