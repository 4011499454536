// This is needed for the unit tests to work, because the testEnvironment is 'jsdom'
// and 'jsdom' is missing an implementation of DOMRect, so we fall back to an object with the same interface in unit tests.
// See https://stackoverflow.com/questions/71521976/referenceerror-domrect-is-not-defined
export function getDOMRect() {
  return global.DOMRect ? new DOMRect() : {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
    x: 0,
    y: 0
  } as DOMRect
}
