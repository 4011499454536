import { Module, MutationTree } from 'vuex'
import { IRootState } from '@src/types/store.types'

export interface IFeedbackDialogState {
  isDialogOpen: boolean;
  message: string;
}

const state: IFeedbackDialogState = {
  isDialogOpen: false,
  message: ''
}

const mutations: MutationTree<IFeedbackDialogState> = {
  toggleFeedbackDialog(state, isOpen: boolean) {
    state.isDialogOpen = isOpen
  },
  setMessage(state, message: string) {
    state.message = message
  }
}

export default {
  namespaced: true,
  state,
  mutations
} as Module<IFeedbackDialogState, IRootState>
