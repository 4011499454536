








































import Vue from 'vue'
import { Editor } from '@tiptap/vue-2'
import { CLICK_ADD_EMOJI } from '@src/plugins/analytics/events/ui/SMTipTapToolbar.events'
import SMTipTapToolbarButton from '../SMTipTapToolbarButton.vue'

import Emojis from './Emojis.vue'

export default Vue.extend({
  name: 'SMTipTapToolbarEmojiButton',
  components: {
    SMTipTapToolbarButton,
    Emojis
  },
  props: {
    editor: {
      type: Object as () => Editor,
      required: true
    }
  },
  data() {
    return {
      isOpen: false,
      CLICK_ADD_EMOJI
    }
  }
})
