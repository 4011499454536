import { useRoute } from '@src/composables/router'
import { computed } from '@vue/composition-api'

export function useAngularRouterState() {
  const route = useRoute()

  const isInsightsStateActive = computed(() => route.path.startsWith('/insights'))
  const isAdminStateActive = computed(() => route.path.startsWith('/admin'))

  return {
    isInsightsStateActive,
    isAdminStateActive
  }
}
