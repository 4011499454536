import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { dismissedExpertiseFeedbackApi } from '@src/api/apiModuleInstances'

export const useDismissedExpertiseFeedback = defineStore('dismissedExpertiseFeedback', () => {
  const dismissedExpertiseFeedbacks = ref<number[]>([])

  async function loadDismissedExpertiseFeedback() {
    const { data } = await dismissedExpertiseFeedbackApi.getDismissed()
    dismissedExpertiseFeedbacks.value = data
  }

  async function dismissExpertiseFeedback(questionId: number) {
    await dismissedExpertiseFeedbackApi.dismiss(questionId)
    loadDismissedExpertiseFeedback()
  }

  return {
    dismissedExpertiseFeedbacks,
    loadDismissedExpertiseFeedback,
    dismissExpertiseFeedback
  }
})
