import {
  IStandardizedConceptAllLanguages, IStandardizedConceptSingleLanguage, IStandardizedConceptLanguageObject
} from '@src/types/concept.types'

type ILanguageArrayLike = ILanguageObjectLike[]
type ILanguageObjectLike = {
  // Language identifier
  language_id?: string;
  language?: string;

  // What we actually display / any of them can theoretically be empty
  matches?: string[];
  aliases?: string[];
  labels?: string[];

  // Primary label
  primary_label?: string | null;

  // Description (the only seemingly consistent label yaaaayy)
  description?: string | null;
}

interface IConceptLike extends ILanguageObjectLike {
  // ID field
  concept_id?: string;
  global_concept_id?: string;

  // Language array
  languages?: ILanguageArrayLike;
  labels_for_languages?: ILanguageArrayLike;
  label_match?: ILanguageArrayLike | string; // This one's tricky: in some cases this is an entire language array, in other cases it's the string label

  // "Label" to display
  match?: string;
  label?: string;
  alias?: string;
}

/**
 * This lets us parse any concept like object we're getting from the API
 * and transform it to a concept with standardized field names
 * or flatten it to have labels with only the preferred language
 *
 * Usage: parseConcept(concept).withAllLanguages(): IStandardizedConceptFields
 * Usage: parseConcept(concept, 'en').withLanguage(): IStandardizedConceptFieldsFlat
 *
 * @param conceptLike a concept in an arbitrary form
 * @param preferredLanguage for flattened concept with only one language
 */
export function parseConcept(conceptLike: IConceptLike) {
  const concept_id = conceptLike.concept_id || conceptLike.global_concept_id
  if (!concept_id) {
    throw new Error('cannot parse concept without an id')
  }

  const conceptLabelFlat = (typeof conceptLike.label_match === 'string' ? conceptLike.label_match : undefined)
    ?? conceptLike.label
    ?? conceptLike.match
    ?? conceptLike.alias

  const languagesField = conceptLike.languages || conceptLike.labels_for_languages || (typeof conceptLike.label_match === 'object' ? conceptLike.label_match : undefined)
  if (!languagesField && !conceptLabelFlat) {
    throw new Error('got concept without any label')
  }
  if (typeof languagesField === 'object' && !languagesField.length) {
    throw new Error('got concept without languages')
  }

  const languageList: IStandardizedConceptLanguageObject[] = languagesField
  // In case languageField is an array of languageObjectLike
    ? languagesField.map((languageObjectLike) => {
      const language_id = languageObjectLike.language_id || languageObjectLike.language
      const primary_label = languageObjectLike.primary_label || null
      const aliases = languageObjectLike.matches || languageObjectLike.aliases || languageObjectLike.labels
      const { description } = languageObjectLike

      if (!language_id) {
        throw new Error('concept must have a language id')
      }

      const aliasesWithPrimaryLabelIfNotDefined = aliases || (primary_label && [ primary_label ]) || []

      return {
        language: language_id!, labels: aliasesWithPrimaryLabelIfNotDefined, primary_label, description
      }
    })
  // In case the concept is a flat list, pick what we have and put it as single entry into the language field
    : [ {
      language: conceptLike.language || conceptLike.language_id || 'xx',
      labels: [ conceptLabelFlat as string ],
      primary_label: conceptLike.primary_label,
      description: conceptLike.description
    } ]

  function withAllLanguages(): IStandardizedConceptAllLanguages {
    return {
      concept_id: concept_id!,
      labels_for_languages: languageList!.map((language) => {
        if (!language.labels.length && !language.primary_label) {
          throw new Error('concept doesnt have any human-readable string to display (label or primary label)')
        }
        return {
          ...language,
          labels: language.labels
        }
      })
    }
  }

  function withLanguage(preferredLanguage = 'xx') {
    const language = languageList.find((langObj) => langObj.language === preferredLanguage) || languageList[0]

    if (!language.labels.length && !language.primary_label) {
      throw new Error('concept doesnt have any human-readable string to display (label or primary label)')
    }

    const standardizedConceptSingleLanguage: IStandardizedConceptSingleLanguage = {
      concept_id: concept_id!,
      language: language.language,
      primary_label: language.primary_label,
      description: language.description,
      label_match: language.labels.length ? language.labels[0] : language.primary_label!
    }

    return {
      // returns a concept with one label (the first from the alias list)
      // { aliases: ['Foo', 'Bar'] } => { label: 'Foo' }
      getOne(): IStandardizedConceptSingleLanguage {
        return standardizedConceptSingleLanguage
      },
      // returns a list of the same concepts with each one having a different label
      // { aliases: ['Foo', 'Bar'] } => [{ label: 'Foo' }, { label: 'Bar' }]
      // make sure to use the label as key prop for v-for, as concept ids may be duplicated
      getAll(): IStandardizedConceptSingleLanguage[] {
        if (language.labels.length) {
          return language.labels.map((label): IStandardizedConceptSingleLanguage => ({
            ...standardizedConceptSingleLanguage,
            label_match: label
          }))
        }
        return [ standardizedConceptSingleLanguage ]
      }
    }
  }

  return {
    withAllLanguages,
    withLanguage
  }
}

// TODO: we can probably reduce the parser a bit as much functionality is not needed anymore
export function parseConceptList(list: IConceptLike[]) {
  const mapped = list.map((conceptLike) => parseConcept(conceptLike))

  return {
    withAllLanguages() {
      return mapped.map(((concept) => concept.withAllLanguages()))
    },
    withLanguage(preferredLanguage?: string) {
      const withLanguage = mapped.map((concept) => concept.withLanguage(preferredLanguage))

      return {
        mapFirstLabel() {
          return withLanguage.map((concept) => concept.getOne())
        },
        mapAllLabels() {
          return withLanguage.flatMap((concept) => concept.getAll())
        }
      }
    }
  }
}
