import _mapValues from 'lodash/mapValues'
import _memoize from 'lodash/memoize'
import _throttle from 'lodash/throttle'

const THROTTLE_DELAY = 5000

type IApiService<T> = {
  [P in keyof T]: T[P];
}

type CachedApi<T> = {
  cached: IApiService<T>;
}

type ThrottledApi<T> = {
  throttled: IApiService<T>;
}

type IOptimizedApi<T> = CachedApi<T> & ThrottledApi<T> & T

/*
  Wraps lodash's memoize function and creates the cache key from stringifying the argument list
  instead of just the first argument of the call to this function. Please be aware that calling
  this function with large objects can impact the performance.
*/
export function memoizeWithMultipleArgs(fn: (...args: any) => any): (...args: any) => any {
  return _memoize(fn, (...args) => JSON.stringify(args))
}

export function throttleWithMultipleArgs(fn: (...args: any) => any): (...args: any) => any {
  let lastThrottleFunctionCallSignature: string
  const throttled = _throttle(fn, THROTTLE_DELAY, { trailing: false })
  return (...args: any): any => {
    if (lastThrottleFunctionCallSignature !== JSON.stringify(args)) {
      throttled.cancel()
    }
    lastThrottleFunctionCallSignature = JSON.stringify(args)
    return throttled(...args)
  }
}

function createThrottledAPI<T>(api: IApiService<T>): IApiService<T> {
  return _mapValues(api, (fn: any) => (throttleWithMultipleArgs(fn) as unknown)) as IApiService<T>
}

function createCachedAPI<T>(api: IApiService<T>): IApiService<T> {
  return _mapValues(api, (fn: any) => (memoizeWithMultipleArgs(fn) as unknown)) as IApiService<T>
}

/*
  Usage: exampleApi.cached.get()
  Use it for requests that are very unlikely to change and can not be changed by the current user directly such as settings, features, etc.

  Usage: exampleApi.throttled.get()
  Use it for commonly requested resources that can be changed by the current user within a session such as user info, language, etc.

  A cached API is always created from a throttled one such that following code doesn't result in two API calls:
  exampleApi.throttled.get()
  exampleApi.cached.get() // delegates to throttled before request is made
*/
export function createOptimizedAPI<T>(api: IApiService<T>): IOptimizedApi<T> {
  const throttled = createThrottledAPI(api)
  const cached = createCachedAPI(throttled)
  return { throttled, cached, ...api }
}
