import { computed, ref } from '@vue/composition-api'
import jobSkillApi from '@src/api/jobSkill'
import { IJobSkill, JobToSkillWizardSteps } from '@src/types/jobSkill'
import useStore from '@src/store'
import useUser from '@src/composables/user'

export function useJobWizard() {
  const store = useStore()
  const { getEditUser } = useUser()

  const isLoadingSkills = ref(false)
  const isLoadingUser = ref(false)
  const skills = ref<IJobSkill[]>([])
  const jobTitle = computed<string>(() => store.getters['activationFlow/jobWizard/getJobTitle'])
  const isDialogVisible = computed<boolean>(() => store.getters['activationFlow/jobWizard/getIsDialogVisible'])
  const currentStep = computed<JobToSkillWizardSteps>(() => store.getters['activationFlow/jobWizard/getCurrentStep'])

  async function stepCompleted(step: JobToSkillWizardSteps) {
    if (step === JobToSkillWizardSteps.Tags) {
      store.dispatch('activationFlow/jobWizard/completeJobWizard')
    } else {
      await updateUser(jobTitle.value)
      await getSkills(jobTitle.value)
      store.commit('activationFlow/jobWizard/setCurrentStep', JobToSkillWizardSteps.Tags)
    }
  }

  async function updateUser(title: string): Promise<void> {
    getEditUser.value.position = title
    isLoadingUser.value = true
    try {
      await store.dispatch('user/saveUser')
    } finally {
      isLoadingUser.value = false
    }
  }

  async function getSkills(title: string): Promise<void> {
    isLoadingSkills.value = true
    try {
      const { data } = await jobSkillApi.getSkills(title, 5)
      skills.value = data.values
    } finally {
      isLoadingSkills.value = false
    }
  }

  function setJobTitle(title: string) {
    store.commit('activationFlow/jobWizard/setJobTitle', title)
  }

  return {
    currentStep,
    isDialogVisible,
    isLoadingSkills,
    isLoadingUser,
    skills,
    jobTitle,
    setJobTitle,
    getSkills,
    stepCompleted
  }
}
