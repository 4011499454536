import { useRoute, useRouter } from '@src/composables/router'
import {
  Ref, computed, onMounted, ref, watch
} from '@vue/composition-api'
import i18n, { useI18n } from '@src/plugins/i18n'
import {
  IAuthRedirectMessage, IRouterNavigationMessage, ITranslationLabelsRequestMessage, PostMessageType
} from '@src/types/postMessage.types'

export function useRouterIframeCommunication(iframeElement: Ref<HTMLIFrameElement | null>) {
  const route = useRoute()
  const router = useRouter()
  const isRouterReady = ref(false)
  const iframeRoute = ref('/angular/blank')

  const isPegasusRoute = computed(() => {
    return route.matched.some((r) => r.name)
  })
  const fullPath = computed(() => {
    return route.fullPath || route.path
  })

  onMounted(async () => {
    await router.isReady()
    isRouterReady.value = true

    window.addEventListener('message', (event: MessageEvent<IRouterNavigationMessage>) => {
      if (
        event.origin === window.origin
        && event.data.type === PostMessageType.ROUTER_NAVIGATION
        && event.data.newLocation
        && event.data.newLocation.replace(/\/$/, '') !== route.fullPath.replace(/\/$/, '')
      ) {
        router.push(event.data.newLocation)
      }
      if (event.data.notFoundInAngular) {
        const routeExistsInPegasus = !!router.resolve(event.data.notFoundInAngular).route.name
        if (routeExistsInPegasus) {
          router.push(event.data.notFoundInAngular)
        } else {
          router.push('/error/404')
        }
      }
    })

    watch(fullPath, async (path, oldPath) => {
      if (!path.includes('/angular/angular') && path !== oldPath && !isPegasusRoute.value) {
        iframeElement.value?.contentWindow?.postMessage({ newLocation: `/angular${path}` })
      }
    }, { immediate: true })

    // on mounted if is not a pegasus route, set the iframe to the current route
    if (!isPegasusRoute.value) {
      iframeRoute.value = `/angular${fullPath.value}`
    }
  })

  return {
    isPegasusRoute,
    isRouterReady,
    iframeRoute
  }
}

export function useTranslationIframeCommunication(iframeElement: Ref<HTMLIFrameElement | null>) {
  const { locale } = useI18n()

  const translationsReady = ref(false)

  onMounted(() => {
    window.addEventListener('message', (event: MessageEvent<ITranslationLabelsRequestMessage>) => {
      if (event.origin === window.origin && event.data.type === PostMessageType.TRANSLATION_LABELS_REQUEST) {
        translationsReady.value = true // set this to true the first time the translations are requested from Angular, to prevent FOUC
        const translations = i18n.getLocaleMessage(event.data.newLanguage)
        iframeElement.value?.contentWindow?.postMessage({ newTranslations: translations, newLanguage: event.data.newLanguage })
      }
    })

    watch(locale, (newLanguage) => {
      iframeElement.value?.contentWindow?.postMessage({ newLanguage })
    }, { immediate: true })
  })

  return { translationsReady }
}

/**
 * Listens to postMessage from the AngularJS frame in case it requires a redirect of its parent window.
 * This is required to circumvent iFrame restrictions of the browser that occur in case a redirect is triggered from the AngularJS frame.
 */
export function useAuthIframeCommunication() {
  function receiveMessage(event: MessageEvent<IAuthRedirectMessage>) {
    if (event.origin !== window.origin) {
      // Security check: Only accept messages from the same origin as the Vue.js window
      return
    }

    if (event.data.type === PostMessageType.AUTH_REDIRECT) {
      window.location.assign(event.data.url)
    }
  }

  onMounted(() => {
    window.addEventListener('message', receiveMessage)
  })
}
