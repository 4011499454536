















































import {
  computed, defineComponent, toRefs, watch, ref, PropType
} from '@vue/composition-api'
import { useI18n } from '@src/plugins/i18n'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import useKnowledgeSpaces from '@src/composables/knowledgeSpaces'
import useRealmSettings from '@src/composables/realmSettings'
import { IFormError } from '@src/ui/SMAsk/composables/validation'

interface IGeneralKnowledgeSpace {
  name: string,
  is_closed: boolean,
  id: null
}
export default defineComponent({
  name: 'KnowledgeSpaceSelect',
  props: {
    knowledgeSpaceId: {
      type: Number as PropType<number | null>,
      default: null
    },
    generalFeedSelected: {
      type: Boolean,
      default: false
    },
    error: {
      type: Object as PropType<IFormError | undefined>,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const { generalFeedSelected, knowledgeSpaceId } = toRefs(props)
    const { t } = useI18n()
    const { realmSettings } = useRealmSettings()
    const { currentKnowledgeSpace, ownKnowledgeSpaces } = useKnowledgeSpaces()
    const generalKnowledgeSpace: IGeneralKnowledgeSpace = {
      name: t('ks.picker.default-ks').toString(),
      is_closed: false,
      id: null
    }

    const questionKnowledgeSpaceMandatoryEnabled = computed(() => !!realmSettings.value?.knowledge_spaces.question_knowledge_space_mandatory)
    const initialKnowledgeSpaceId = computed(() => knowledgeSpaceId.value || currentKnowledgeSpace.value?.id)
    const errorMessages = computed(() => (props.error?.translatedError ? [ props.error?.translatedError ] : undefined))
    const knowledgeSpaceItems = computed<(IGeneralKnowledgeSpace | IKnowledgeSpace)[]>(() => (questionKnowledgeSpaceMandatoryEnabled.value && ownKnowledgeSpaces.value.length ? ownKnowledgeSpaces.value : [ generalKnowledgeSpace, ...ownKnowledgeSpaces.value ]))
    const selectedKnowledgeSpace = ref<IKnowledgeSpace | IGeneralKnowledgeSpace | undefined>(undefined)

    function selectKnowledgeSpace(id: number | null) {
      selectedKnowledgeSpace.value = id === null ? generalKnowledgeSpace : ownKnowledgeSpaces.value.find((ks) => ks.id === id)
      emit('generalFeedSelectedValue', id === null)
      emit('input', id)
    }

    watch((initialKnowledgeSpaceId), (id) => {
      if (ownKnowledgeSpaces.value.length === 0 || generalFeedSelected.value) {
        selectKnowledgeSpace(null)
      } else if (knowledgeSpaceItems.value.length === 1) {
        selectKnowledgeSpace(knowledgeSpaceItems.value[0].id)
      } else if (id) {
        selectKnowledgeSpace(id)
      }
    }, { immediate: true })

    return {
      selectedKnowledgeSpace,
      errorMessages,
      knowledgeSpaceItems,
      selectKnowledgeSpace
    }
  }
})
