import { AxiosInstance, AxiosPromise } from 'axios'
import { IExpertSearchQuery } from '../../../types/experts.types'

export function expertSearchesApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/expert-searches'
  const methods = {
    getExpertSearches(): AxiosPromise<IExpertSearchQuery[]> {
      return api.get<IExpertSearchQuery[]>(baseUrl)
    },
    saveExpertSearch(expertSearch: IExpertSearchQuery): AxiosPromise<IExpertSearchQuery[]> {
      return api.post<IExpertSearchQuery[]>(baseUrl, expertSearch)
    }
  }
  return methods
}
