import { ITrackingProps } from '@src/types/events.types'

export interface IEventTagCustomProps {
  tag: string;
  tag_status: TagStatus;
  total_tags_accepted: number;
  total_tags_pending: number;
  total_tags: number;
}
type UserSkillTrackingProps = ITrackingProps & Partial<IEventTagCustomProps>

// Partial<IFeatures>

export const USER_SKILLS_CLICK_TAG_ACTION = 'clickProfileTagAction'
export const USER_SKILLS_EDIT_TAGS = 'editProfileTags'
export const USER_SKILLS_SELECT_TAG = 'selectProfileTag'
export const USER_SKILLS_OPEN_TAG_DROPDOWN = 'openProfileTagDropdown'

const commonProps = {
  component: 'expert_profile',
  state: 'expert_profile'
}

type TagStatus = 'pending' | 'accepted'

export default {
  UserSkills: {
    [USER_SKILLS_EDIT_TAGS]: (customProps: { numTotal: number; numPending: number; numAccepted: number }): UserSkillTrackingProps => ({
      ...commonProps,
      element: 'button',
      total_tags_accepted: customProps.numAccepted,
      total_tags_pending: customProps.numPending,
      total_tags: customProps.numTotal,
      type: 'click'
    })
  },
  AddSkillInput: {
    [USER_SKILLS_SELECT_TAG]: (customProps: { tagStatus: TagStatus; tag: string }): UserSkillTrackingProps => ({
      ...commonProps,
      type: 'click',
      element: 'dropdown_menu',
      tag: customProps.tag,
      tag_status: customProps.tagStatus
    })
  },
  SkillActions: {
    [USER_SKILLS_CLICK_TAG_ACTION]: (customProps: { tag: string; element: string }): UserSkillTrackingProps => ({
      ...commonProps,
      element: customProps.element,
      tag: customProps.tag,
      type: 'click'
    })
  },
  SkillPanel: {
    [USER_SKILLS_OPEN_TAG_DROPDOWN]: (customProps: { tagStatus: TagStatus; tag: string }): UserSkillTrackingProps => ({
      ...commonProps,
      element: 'dropdown_button',
      tag: customProps.tag,
      tag_status: customProps.tagStatus,
      type: 'click'
    })
  }
}
