import {
  Ref, ref, computed, SetupContext
} from '@vue/composition-api'
import { useConceptSearch } from '@src/composables/useConceptSearch'
import { IJobSkill } from '@src/types/jobSkill'
import { IInitialExpertise, InitialExpertiseSourceType } from '@src/types/initialExpertise.types'
import useUser from '@src/composables/user'
import { topicLabelsToString } from '@src/utilities/conceptSearch'
import { IComboboxSearchResultItem } from '@src/types/concept.types'

const DEFAULT_TAG_SUGGESTIONS = [ 'IT Security', 'Competitor Analysis', 'Customer Experience', 'Market Research', 'Financial Statements' ]
const GERMAN_TAG_SUGGESTIONS = [ 'IT-Sicherheit', 'Konkurrenzanalyse', 'Kundenerfahrung', 'Marktforschung', 'Finanzberichte' ]

const ONTOLOGY_IDS : { [key: string]: string } = {
  'IT Security': 'Q3510521',
  'IT-Sicherheit': 'Q3510521',
  'Competitor Analysis': 'Q624630',
  Konkurrenzanalyse: 'Q624630',
  'Customer Experience': 'Q984142',
  Kundenerfahrung: 'Q984142',
  'Market Research': 'Q913709',
  Marktforschung: 'Q913709',
  'Financial Statements': 'Q192907',
  Finanzberichte: 'Q192907'
}

// Handles the conversion of skills and tags to expertise and the addition and removal
// of skills, tags and manually searched concepts
// The addition and removal actions are tested in corresponding test suite
export default function useUserExpertise(userJobSkills: Ref<IJobSkill[]>, context: SetupContext, selectedExpertise: Ref<IInitialExpertise[]>) {
  const newExpertise = ref<string | null>('')
  const query = ref('')
  const { user } = useUser()
  const userLanguage = computed(() => user.value.language)
  const tagSuggestions = computed(() => (userLanguage.value === 'de' ? GERMAN_TAG_SUGGESTIONS : DEFAULT_TAG_SUGGESTIONS))
  const { searchResults: conceptsSearchResults, onSelectSearchResult } = useConceptSearch(query, userLanguage)
  const searchResults = computed(() => conceptsSearchResults.value.map((result) => {
    return {
      text: topicLabelsToString(result),
      value: result
    }
  }))

  // create vals for just the strings of user job skills and suggested tags
  const userJobSkillsLabels = computed(() => userJobSkills.value.map((skill) => skill.label))

  // Remove duplicates - if suggested has any duplicates with user job skills
  const suggestedTagsLabels = computed(() => tagSuggestions.value.filter((label) => !userJobSkillsLabels.value.some((skillLabel) => skillLabel === label)))

  // Convert suggestions and job skills to expertise
  // Source type here is StarmindSuggested as the concept is part of the predefined array of suggested
  // expertise shown to all users
  const suggestedTagsExpertise = ref<IInitialExpertise[]>(suggestedTagsLabels.value.map((label) => {
    return {
      label,
      language_id: userLanguage.value === 'de' ? 'de' : 'en',
      source: InitialExpertiseSourceType.StarmindSuggested,
      ontology_id: ONTOLOGY_IDS[label]
    }
  }))
  const userSkillsExpertise = computed<IInitialExpertise[]>(() => userJobSkills.value.map((skill) => {
    // Source type here is Search as the concept was found via starminds job skills search
    return {
      label: skill.label,
      language_id: user.value.language,
      source: InitialExpertiseSourceType.Search
    }
  }))

  // Create value that holds all suggestions
  const suggestedExpertise = ref([ ...userSkillsExpertise.value, ...suggestedTagsExpertise.value ])

  // Used so we don't show duplicates when user selects a suggested tag
  function removeTagFromSuggestions(expertise: IInitialExpertise) {
    suggestedExpertise.value = suggestedExpertise.value.filter((val) => {
      return val.label !== expertise.label
    })
  }

  // Used to handle the users manual input
  const addNewTag = async (item: IComboboxSearchResultItem | string) => {
    if (item) {
      const conceptualTopic = await onSelectSearchResult(typeof item === 'object' ? item.value : item)
      // Convert the item to expertise
      // Source type here is Manual as the user manually searches for and adds the concept themselves
      const initialExpertise: IInitialExpertise = {
        label: conceptualTopic.label_match,
        language_id: conceptualTopic.language || 'xx',
        concept_id: conceptualTopic.concept_id,
        source: InitialExpertiseSourceType.Manual
      }

      // If input already exists in the suggestions we remove it so we don't show duplicates
      if (suggestedTagsLabels.value.some((tag) => tag === initialExpertise.label) || userJobSkillsLabels.value.some((tag) => tag === initialExpertise.label)) {
        removeTagFromSuggestions(initialExpertise)
      }
      // Update selectedExpertise and reset values
      context.emit('input', [ ...selectedExpertise.value, initialExpertise ])
      context.emit('addExpertise', conceptualTopic.label_match, false)
      newExpertise.value = null
      query.value = ''
    }
  }

  // Used to handle the users interaction with the topic chips
  function toggleTagSubscription(expertise: IInitialExpertise) {
    // If tag is already selected we remove it from selected tags
    if (selectedExpertise.value.some((exp) => exp.label === expertise.label)) {
      // Remove from selected expertise
      const newSelectedExpertise = selectedExpertise.value.filter((val) => {
        return val.label !== expertise.label
      })
      // If it is a suggested expertise we add it back to the suggested expertise array
      if (expertise.source === InitialExpertiseSourceType.StarmindSuggested || expertise.source === InitialExpertiseSourceType.Search) {
        suggestedExpertise.value = [ ...suggestedExpertise.value, expertise ]
      }
      // Update the selected expertise
      context.emit('input', newSelectedExpertise)
    } else {
      // Otherwise we are adding tag to selected tags and removing it from suggestions
      removeTagFromSuggestions(expertise)
      context.emit('input', [ ...selectedExpertise.value, expertise ])
      context.emit('addExpertise', expertise.label, true)
    }
  }

  return {
    suggestedExpertise,
    query,
    searchResults,
    newExpertise,
    addNewTag,
    toggleTagSubscription
  }
}
