import { FeatureToggleNames } from '@src/plugins/featureToggles/featureToggle.names'
import useStore from '@src/store'
import { IFeatureToggle } from '@src/types/featureToggles.types'
import { computed, watch } from '@vue/composition-api'
import { featureToggleList } from '@src/utilities/featureToggle'
import { FeatureToggleVariants } from '@src/plugins/featureToggles/featureToggle.variants'

export function useFeatureToggles() {
  const store = useStore()
  const releaseToggles = computed<IFeatureToggle[]>(() => store.getters['featureToggles/getToggles'])
  const loading = computed<boolean>(() => store.getters['featureToggles/getLoading'])

  const llmConversationsEnabled = computed(() => featureToggleList(releaseToggles.value).findByName(FeatureToggleNames.RELEASE_LLM_CONVERSATIONS).isEnabled())
  const automaticConnectorDeploymentEnabled = computed(() => featureToggleList(releaseToggles.value).findByName(FeatureToggleNames.RELEASE_AUTOMATIC_CONNECTOR_DEPLOYMENT).isEnabled())
  const insightsKillSwitchEnabled = computed(() => featureToggleList(releaseToggles.value).findByName(FeatureToggleNames.KILL_SWITCH_INSIGHTS).isEnabled())
  const userProfileV2Enabled = computed(() => featureToggleList(releaseToggles.value).findByName(FeatureToggleNames.RELEASE_USER_PROFILE_V2).isEnabled())
  const fakeDoorDiscussionEnabled = computed(() => featureToggleList(releaseToggles.value).findByName(FeatureToggleNames.RELEASE_FAKE_DOOR_DISCUSSION).isEnabled())

  return {
    llmConversationsEnabled,
    automaticConnectorDeploymentEnabled,
    insightsKillSwitchEnabled,
    userProfileV2Enabled,
    fakeDoorDiscussionEnabled,
    loading
  }
}

export function useExperiment<T = FeatureToggleVariants>(name: FeatureToggleNames, automaticImpressionTracking = true) {
  const store = useStore()
  const experiments = computed<IFeatureToggle[]>(() => store.getters['featureToggles/getExperiments'])
  const loading = computed<boolean>(() => store.getters['featureToggles/getLoading'])

  const variant = computed(() => featureToggleList(experiments.value).findByName(name).getExperimentVariant<T>())

  function trackImpression() {
    store.dispatch('featureToggles/impressions/trackImpression', {
      experimentName: name,
      experimentVariant: variant.value
    })
  }

  // Automatically tracks the impression whereever this experiment composable is used
  watch(loading, (value) => {
    if (!value && automaticImpressionTracking && variant.value) {
      trackImpression()
    }
  }, { immediate: true })

  return {
    variant,
    loading,
    trackImpression
  }
}
