export type TimeUnit = 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds'

export interface IDuration {
  value: number;
  unit: TimeUnit;
}

const fromMillis = (durationMillis: number, unit?: TimeUnit): IDuration => {
  const seconds = Math.floor(Math.abs(durationMillis) / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 31)
  const years = Math.floor(days / 365)

  const units: TimeUnit[] = [ 'years', 'months', 'days', 'hours', 'minutes', 'seconds' ]
  const values: number[] = [ years, months, days, hours, minutes, seconds ]

  let index = values.length - 1
  if (unit) {
    index = units.indexOf(unit)
  } else {
    for (let i = 0; i < values.length; i += 1) {
      if (values[i] > 0) {
        index = i
        break
      }
    }
  }

  return {
    value: values[index],
    unit: units[index]
  }
}

const fromSeconds = (durationSeconds: number, unit?: TimeUnit): IDuration => fromMillis(durationSeconds * 1000, unit)

export default {
  fromMillis,
  fromSeconds
}
