import {
  computed, Ref
} from '@vue/composition-api'
import sanitizeHtml from 'sanitize-html'
import findLast from 'lodash/fp/findLast'
import clamp from 'lodash/fp/clamp'
import sum from 'lodash/fp/sum'
import { IStandardizedConceptSingleLanguage } from '@src/types/concept.types'

const titleQualityTable = [
  { length: 0, score: 0 },
  { length: 10, score: 10 },
  { length: 40, score: 30 },
  { length: 80, score: 50 }
]

const descriptionQualityTable = [
  { length: 0, score: 0 },
  { length: 5, score: 20 }
]

const conceptsQualityTable = [
  { count: 0, score: 0 },
  { count: 1, score: 5 },
  { count: 3, score: 20 }
]

const categoryQualityTable = [
  { isSet: false, score: 0 },
  { isSet: true, score: 5 }
]

const similarQuestionsQualityTable = [
  { hasSimilarQuestions: false, score: 10 },
  { hasSimilarQuestions: true, score: 0 }
]

export default function useQuestionQuality({
  title,
  description,
  concepts,
  category,
  hasSimilarQuestions
} : {
  title: Ref<string>,
  description: Ref<string>,
  concepts: Ref<IStandardizedConceptSingleLanguage[]>,
  category: Ref<undefined | number | null>,
  hasSimilarQuestions: Ref<boolean>
}) {
  function getQuestionQuality({
    titleValue,
    descriptionValue,
    conceptsValue,
    categoryValue,
    hasSimilarQuestionsValue
  } : {
    titleValue: string,
    descriptionValue: string,
    conceptsValue: IStandardizedConceptSingleLanguage[],
    categoryValue: number | undefined | null,
    hasSimilarQuestionsValue: boolean
  }): number {
    const plaintextDescription = sanitizeHtml(descriptionValue, {
      allowedTags: []
    })

    const titleScore = findLast<{ length: number; score: number}>(({ length }) => titleValue.length >= length)(titleQualityTable)?.score ?? 0
    const descriptionScore = findLast<{ length: number; score: number}>(({ length }) => plaintextDescription.length >= length)(descriptionQualityTable)?.score ?? 0
    const conceptsScore = findLast<{ count: number; score: number}>(({ count }) => conceptsValue.length >= count)(conceptsQualityTable)?.score ?? 0
    const categoryScore = findLast<{ isSet: boolean; score: number}>({ isSet: !!categoryValue })(categoryQualityTable)?.score ?? 0
    const similarQuestionsScore = titleValue.length >= 5 ? findLast<{ hasSimilarQuestions: boolean; score: number}>({ hasSimilarQuestions: hasSimilarQuestionsValue })(similarQuestionsQualityTable)?.score ?? 0 : 0

    return clamp(0)(100)(sum([
      titleScore,
      descriptionScore,
      conceptsScore,
      categoryScore,
      similarQuestionsScore
    ]))
  }

  const questionQuality = computed(() => getQuestionQuality({
    titleValue: title.value,
    descriptionValue: description.value,
    conceptsValue: concepts.value,
    categoryValue: category.value,
    hasSimilarQuestionsValue: hasSimilarQuestions.value
  }))

  return {
    questionQuality
  }
}
