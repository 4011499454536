import { Node } from '@tiptap/core'
import { RawCommands } from '@tiptap/vue-2'

export interface IFrameOptions {
  allowFullscreen: boolean;
  HTMLAttributes: {
    [key: string]: any;
  };
}

declare module '@tiptap/core' {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Commands<ReturnType> {
    iframe: {
      /**
       * Add an iframe
       */
      setIframe: (options: { src: string }) => ReturnType;
    };
  }
}

export default Node.create({
  name: 'iframe',

  group: 'block',

  atom: true,

  addOptions(): IFrameOptions {
    return {
      allowFullscreen: true,
      HTMLAttributes: {
        class: 'iframe-wrapper'
      }
    }
  },

  addAttributes() {
    return {
      src: {
        default: null
      },
      frameborder: {
        default: 0
      },
      allowfullscreen: {
        default: this.options.allowFullscreen,
        parseHTML: (): {allowfullscreen: boolean} => {
          return {
            allowfullscreen: this.options.allowFullscreen
          }
        }
      }
    }
  },

  parseHTML() {
    return [ {
      tag: 'iframe'
    } ]
  },

  renderHTML({ HTMLAttributes }) {
    return [ 'div', this.options.HTMLAttributes, [ 'iframe', HTMLAttributes ] ]
  },

  addCommands() {
    return {
      setIframe: (options: { src: string }) => ({ tr, dispatch }: any): boolean => {
        const { selection } = tr
        const node = this.type.create(options)

        if (dispatch) {
          tr.replaceRangeWith(selection.from, selection.to, node)
        }

        return true
      }
    } as Partial<RawCommands>
  }
})
