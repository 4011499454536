/* eslint-disable no-undef */
import {
  ActionTree, GetterTree, MutationTree, Module
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import companyGuidelinesApi from '@src/api/web/companyGuidelines'

export enum UserGuideListType {
  WHATS_NEW = 'whats-new',
  COMPANY_GUIDELINES = 'company-guidelines',
  GETTING_STARTED = 'getting-started',
  LEAVE_FEEDBACK = 'leave-feedback',
  ABOUT_STARMIND = 'about-starmind'
}

export type UserGuideIds =
  UserGuideListType.WHATS_NEW |
  UserGuideListType.COMPANY_GUIDELINES |
  UserGuideListType.GETTING_STARTED |
  UserGuideListType.LEAVE_FEEDBACK |
  UserGuideListType.ABOUT_STARMIND

export interface IUserGuideListItem{
  id: UserGuideIds;
  title: string;
  text: string;
  tracking: {
    label: string;
    menuItemId: number;
  };
  badge?: string;
  icon?: string;
}

export const initialListItems: IUserGuideListItem[] = [
  {
    id: UserGuideListType.WHATS_NEW,
    title: 'user-guide.sidenav.whats-new.title',
    text: 'user-guide.sidenav.whats-new.text',
    badge: '',
    tracking: {
      label: 'latest_changes',
      menuItemId: 1
    }
  },
  {
    id: UserGuideListType.COMPANY_GUIDELINES,
    title: 'user-guide.sidenav.company-guidelines.title',
    text: 'user-guide.sidenav.company-guidelines.text',
    tracking: {
      label: 'company_guidelines',
      menuItemId: 2
    }
  },
  {
    id: UserGuideListType.GETTING_STARTED,
    title: 'user-guide.sidenav.getting-started.title',
    text: 'user-guide.sidenav.getting-started.text',
    tracking: {
      label: 'getting_started',
      menuItemId: 3
    }
  },
  {
    id: UserGuideListType.LEAVE_FEEDBACK,
    title: 'user-guide.sidenav.leave-feedback.title',
    text: 'user-guide.sidenav.leave-feedback.text',
    tracking: {
      label: 'feedback',
      menuItemId: 4
    }
  },
  {
    id: UserGuideListType.ABOUT_STARMIND,
    title: 'user-guide.sidenav.about-starmind.title',
    text: 'user-guide.sidenav.about-starmind.text',
    icon: 'ico-sm-new-window',
    tracking: {
      label: 'about',
      menuItemId: 5
    }
  }
]
export interface IUserGuideSidenavState {
  isOpen: boolean;
  isInitialized: boolean;
  isLoading: boolean;
  listItems: IUserGuideListItem[];
}

const state: IUserGuideSidenavState = {
  isOpen: false,
  isInitialized: false,
  isLoading: true,
  listItems: initialListItems
}

const getters: GetterTree<IUserGuideSidenavState, IRootState> = {
  getIsDrawerOpen(state) {
    return state.isOpen
  },
  getListItems() {
    return state.listItems
  },
  getIsInitialized() {
    return state.isInitialized
  },
  getIsLoading() {
    return state.isLoading
  }
}

const mutations: MutationTree<IUserGuideSidenavState> = {
  showDrawer: (state: IUserGuideSidenavState): void => {
    state.isOpen = true
  },
  hideDrawer: (state: IUserGuideSidenavState): void => {
    state.isOpen = false
  },
  setIsLoading: (state: IUserGuideSidenavState, isLoading): void => {
    state.isLoading = isLoading
  },
  removeListItems: (state: IUserGuideSidenavState, listItemsId: UserGuideIds): void => {
    state.listItems = state.listItems.filter((listItem) => listItem.id !== listItemsId)
  },
  setIsInitialized: (state: IUserGuideSidenavState, isInitialized: boolean): void => {
    state.isInitialized = isInitialized
  }
}

const actions: ActionTree<IUserGuideSidenavState, IRootState> = {
  async showDrawer({
    commit, state, rootGetters
  }): Promise<void> {
    try {
      commit('setIsLoading', true)
      commit('showDrawer')
      if (!state.isInitialized) {
        const isGuidelineEnabled = (rootGetters['realmSettings/realmSettings'] as IUnauthenticatedRealmSettings).company_guidelines.enabled
          && await (companyGuidelinesApi.cached.getGuideline((rootGetters['language/getCurrentLanguage'] as string)))
            .then(() => true)
            .catch(() => false)

        if (!isGuidelineEnabled) {
          commit('removeListItems', UserGuideListType.COMPANY_GUIDELINES)
        }
        if (!rootGetters['realmSettings/isWhatsNewEnabled']) {
          commit('removeListItems', UserGuideListType.WHATS_NEW)
        }
        commit('setIsInitialized', true)
      }
    } catch {
      // in case of error still load but remove the whats new and company guidelines
      commit('removeListItems', UserGuideListType.COMPANY_GUIDELINES)
      commit('removeListItems', UserGuideListType.WHATS_NEW)
      commit('setIsInitialized', false)
    } finally {
      commit('setIsLoading', false)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<IUserGuideSidenavState, IRootState>
