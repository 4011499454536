import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'
import { SortingFilterValues } from '../../../types/filters.types'

export interface ISortingPreference {
  value: SortingFilterValues | null
}
export function sortingPreferenceApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/sorting-preference'
  const methods = {
    getSortingPreference() {
      return api.get<ISortingPreference>(baseUrl)
    },
    setSortingPreference(value: ISortingPreference) {
      return api.post<ISortingPreference>(baseUrl, value)
    }
  }
  return createOptimizedAPI(methods)
}
