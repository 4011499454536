




























































import { createNamespacedHelpers } from 'vuex'
import { ActivationFlowStep } from '@src/types/activationFlow.types'
import { COMPLETE_LAST_STEP_ONBOARDING_WIZARD } from '@src/plugins/analytics/events/ui/SMActivationFlow.events'
import { defineComponent } from '@vue/composition-api'
import { useOnboardingChecklist } from '@src/pinia/onboardingChecklist'
import ActivationFlowHeader from './components/ActivationFlowHeader.vue'
import SMActivationFlowTermsDialog from './components/TermsDialog.vue'
import SMActivationFlowLanguageDialog from './components/LanguageDialog.vue'
import SMActivationFlowJobWizardDialog from './components/JobWizardDialog.vue'
import SMEducationDialog from './components/EducationDialog.vue'
import useActivationFlowSteps from './composables/useActivationFlowSteps'

const { mapActions } = createNamespacedHelpers('activationFlow')
const { mapState, mapActions: mapEducationDialogActions } = createNamespacedHelpers('activationFlow/educationDialog')

export default defineComponent({
  name: 'SMActivationFlow',
  components: {
    ActivationFlowHeader,
    SMActivationFlowTermsDialog,
    SMActivationFlowLanguageDialog,
    SMActivationFlowJobWizardDialog,
    SMEducationDialog
  },
  setup() {
    const { steps, totalSteps } = useActivationFlowSteps()
    const { setDialogAsComplete } = useOnboardingChecklist()

    return {
      steps,
      totalSteps,
      setDialogAsComplete
    }
  },
  data() {
    return {
      ActivationFlowStep
    }
  },
  computed: {
    ...mapState([ 'isExpertsDialogVisible', 'isUserInteractionDialogVisible' ]) as {
      isExpertsDialogVisible: () => boolean;
      isUserInteractionDialogVisible: () => boolean;
    }
  },
  created() {
    this.init()
  },
  methods: {
    ...mapActions([ 'init' ]) as {
      init: () => void;
    },
    ...mapEducationDialogActions([ 'completeDialog' ]) as {
      completeDialog: (step: ActivationFlowStep) => void;
    },
    trackOnboardingCompletion(): void {
      this.$trackEvent(
        COMPLETE_LAST_STEP_ONBOARDING_WIZARD,
        this.$options.name
      )
    }
  }
})
