




























import Vue, { PropType } from 'vue'
import { InterfaceLanguage } from '@src/types/language.types'
import { mapGetters } from 'vuex'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import {
  ITranslationButton,
  ITranslationButtonComponent,
  CLICK_TRANSLATION_ORIGINAL,
  CLICK_TRANSLATION_BUTTON
} from '@src/plugins/analytics/events/ui/SMTranslationToggle.events'
import pickBy from 'lodash/pickBy'

export default Vue.extend({
  name: 'SMTranslationToggle',
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    target: {
      type: String as PropType<InterfaceLanguage>,
      default: 'en'
    },
    isTranslated: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    toggle: {
      type: Function as PropType<() => void>,
      default: () => (): void => {}
    },
    color: {
      type: String,
      default: 'grey'
    },
    trackingState: {
      type: String as PropType<string>,
      default: null
    },
    trackingComponent: {
      type: String as PropType<ITranslationButtonComponent>,
      default: null
    },
    trackingQuestionId: {
      type: Number as PropType<number | null>,
      default: null
    },
    trackingSolutionId: {
      type: Number as PropType<number | null>,
      default: null
    },
    trackingCommentId: {
      type: Number as PropType<number | null>,
      default: null
    }
  },
  computed: {
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings | undefined;
    },
    show(): boolean {
      if (!this.realmSettings?.translation.automatic_translations_enabled) { return false }
      if (this.readOnly) { return this.isTranslated }
      return true
    },
    getTarget(): string { return this.target.toUpperCase() }
  },
  methods: {
    trackClick(): void {
      if (!this.readOnly) { this.track(this.isTranslated ? CLICK_TRANSLATION_ORIGINAL : CLICK_TRANSLATION_BUTTON) }
    },
    track(event: string): void {
      this.$trackEvent(
        event,
        this.$options.name,
        pickBy({
          state: this.trackingState,
          component: this.trackingComponent,
          question_id: this.trackingQuestionId,
          answer_id: this.trackingSolutionId,
          comment_id: this.trackingCommentId
        } as ITranslationButton)
      )
    }
  }
})
