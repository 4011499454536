import announcementsApi from '@src/api/web/announcements'
import { onMounted, Ref, ref } from '@vue/composition-api'
import { IAnnouncementComment } from '@src/types/announcements.types'
import useSnackbar from '@src/composables/snackbar'
import useTracking from '@src/composables/tracking/tracking'
import { useAnnouncementsStore } from '@src/pinia/announcements'

export function useAnnouncementComments(announcementId: Ref<number>) {
  const comments = ref<IAnnouncementComment[]>([])
  const loading = ref<boolean>(false)

  const { showSnackbar } = useSnackbar()
  const { reloadUserAnnouncements } = useAnnouncementsStore()
  const { trackMixpanelEvent } = useTracking()

  onMounted(loadAnnouncementComments)
  async function loadAnnouncementComments() {
    try {
      loading.value = true
      const { data } = await announcementsApi.getComments(announcementId.value)
      comments.value = data
    } finally {
      loading.value = false
    }
  }

  async function postAnnouncementComment(comment: string) {
    try {
      loading.value = true
      trackMixpanelEvent('postAnnouncementComment', {
        element: 'announcement',
        announcementId: announcementId.value
      })
      await announcementsApi.postComment(announcementId.value, comment)
      // Awaiting the reload to match loader with content update timing
      await loadAnnouncementComments()
    } finally {
      loading.value = false
    }
    showSnackbar('announcements.toast.comment-created')
    reloadUserAnnouncements()
  }

  async function deleteAnnouncementComment(commentId: number) {
    try {
      loading.value = true
      await announcementsApi.deleteComment(announcementId.value, commentId)
      trackMixpanelEvent('deleteAnnouncementComment', {
        element: 'announcement',
        announcementId: announcementId.value
      })
      await loadAnnouncementComments()
    } finally {
      loading.value = false
    }
    showSnackbar('announcements.toast.comment-deleted')
    reloadUserAnnouncements()
  }

  async function updateAnnouncementComment(commentId: number, comment: string) {
    try {
      loading.value = true
      await announcementsApi.updateComment(announcementId.value, commentId, comment)
      await loadAnnouncementComments()
    } finally {
      loading.value = false
    }
    showSnackbar('announcements.toast.comment-updated')
    // Not reloading announcements since comment content update won't modify them
  }

  return {
    updateAnnouncementComment,
    postAnnouncementComment,
    deleteAnnouncementComment,
    comments,
    loading
  }
}
