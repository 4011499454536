/* eslint-disable import/prefer-default-export */
export interface ICategory {
  id: number;
  name: string;
  color: string;
  translations: ICategoryTranslation[];
  description?: string;
  label?: string;
}

export interface ICategoryTranslation {
  name: string;
  language: string;
  description: null | string;
}

export const translateCategory = (category: ICategory, language: string): string => {
  const translation = category.translations.find((t) => t.language === language)
  return translation ? translation.name : category.name
}
