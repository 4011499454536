import { IRootState } from '@src/types/store.types'
import {
  ActionTree, MutationTree, GetterTree, Module
} from 'vuex'
import isEqual from 'lodash/isEqual'
import streamApi from '@src/api/stream'
import { IFeedItem } from '@src/types/feedItem.types'
import { IStreamEndpointParams } from '@src/types/filters.types'
import logQueryTypeModule, { LogQueryTypeParam } from './logQueryType'

export interface ISearchV2QuestionState {
  previousSearchParams?: IStreamEndpointParams,
  searchResults: IFeedItem[]
  searchLoading: boolean
  searchQueryId?: number
  totalResults: number
  offset: number
}

const state: ISearchV2QuestionState = {
  previousSearchParams: undefined,
  searchResults: [],
  searchLoading: false,
  searchQueryId: undefined,
  totalResults: 0,
  offset: 0
}

const LIMIT = 30

const getters: GetterTree<ISearchV2QuestionState, IRootState> = {
  getResults(state) {
    return state.searchResults
  },
  getSearchId(state) {
    return state.searchQueryId
  },
  getResultsLoading(state) {
    return state.searchLoading
  },
  getTabCountString(state) {
    if (state.searchLoading) {
      return ''
    }
    return `(${state.totalResults})`
  },
  hasNextBatch(state) {
    return state.totalResults > state.searchResults.length
  }
}

const actions: ActionTree<ISearchV2QuestionState, IRootState> = {
  async fetchSearchResults({ commit, state, getters: localGetters }, { params, force = false }: { params: IStreamEndpointParams; force: boolean; }) {
    if (params && isEqual(params, state.previousSearchParams) && !force) {
      return
    }
    commit('setQuestionSearchLoading', true)
    commit('setPreviousSearchParams', params)
    commit('resetOffset')
    try {
      const logQueryType: LogQueryTypeParam = localGetters['logQueryType/lastQueryType']
      const { data } = await streamApi.getFeedItems({
        ...params,
        limit: LIMIT,
        logQueryType
      })
      commit('setSearchQueryId', data.search_query_id)
      commit('setResults', data.items)
      commit('setTotalResults', data.total)
    } finally {
      commit('setQuestionSearchLoading', false)
    }
  },
  async loadNextBatch({ commit, state, getters: localGetters }) {
    if (localGetters.hasNextBatch) {
      commit('incrementOffset')
      const { data } = await streamApi.getFeedItems({
        ...state.previousSearchParams,
        offset: state.offset,
        limit: LIMIT
      })
      commit('appendResults', data.items)
    }
  }
}

const mutations: MutationTree<ISearchV2QuestionState> = {
  setResults(state, results: IFeedItem[]): void {
    state.searchResults = results.map((result) => {
      return {
        ...result,
        has_been_clicked: false
      }
    })
  },
  setHasBeenClicked(state, id: number): void {
    const itemIndex = state.searchResults.findIndex((item) => item.id === id)
    state.searchResults[itemIndex].has_been_clicked = true
  },
  setPreviousSearchParams(state, params: IStreamEndpointParams): void { state.previousSearchParams = params },
  setQuestionSearchLoading(state, loading: boolean): void { state.searchLoading = loading },
  setSearchQueryId(state, id?: number): void { state.searchQueryId = id },
  setTotalResults(state, total: number): void { state.totalResults = total },
  resetOffset(state) { state.offset = 0 },
  incrementOffset(state) { state.offset += 1 },
  appendResults(state, results: IFeedItem[]): void {
    const resultsWithHasBeenClicked = results.map((result) => {
      return {
        ...result,
        has_been_clicked: false
      }
    })
    state.searchResults = [ ...state.searchResults, ...resultsWithHasBeenClicked ]
  }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  getters,
  mutations,
  actions,
  modules: {
    logQueryType: logQueryTypeModule
  }
} as Module<ISearchV2QuestionState, IRootState>
