import { ITrackingProps } from '@src/types/events.types'

export const ON_MENU_TOGGLE = 'clickUserNavigationMenu'
export const ON_SETTINGS_CLICK = 'clickSettings'
export const ON_SIGN_OUT_CLICK = 'signOut'
export const ON_PROFILE_CLICK = 'clickUserNavigationItem'

const commonProps = {
  component: 'navigation_menu',
  element: 'menu_item',
  type: 'click'
} as ITrackingProps

export default {
  SMHeaderProfileMenu: {
    [ON_MENU_TOGGLE]: (customProps: { element: string }): ITrackingProps => ({
      ...commonProps,
      component: 'menu',
      ...customProps
    }),
    [ON_SETTINGS_CLICK]: (): ITrackingProps => ({
      ...commonProps
    }),
    [ON_SIGN_OUT_CLICK]: (): ITrackingProps => ({
      ...commonProps
    }),
    [ON_PROFILE_CLICK]: (): ITrackingProps => ({
      ...commonProps
    })
  }
}
