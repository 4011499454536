import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'
import {
  INotificationsApiResp,
  INotificationUpdateStatus,
  INotificationsCount
} from '../../../types/notifications.types'

export function notificationsApiFactory(api: AxiosInstance) {
  const baseUrl = '/api/v2/notifications'
  const methods = {
    getList(ignoreRead: boolean, offset = 0, limit = 1000) {
      return api.get<INotificationsApiResp>(baseUrl, {
        params: {
          offset,
          limit: limit < 100 ? limit : 100,
          ignore_read: ignoreRead
        }
      })
    },
    getUnseenCount() {
      return api.get<INotificationsCount>(`${baseUrl}/count/unseen`)
    },
    markAllAsRead() {
      return api.put<INotificationUpdateStatus>(baseUrl)
    },
    markAsRead(id: number) {
      return api.put<INotificationUpdateStatus>(`${baseUrl}/${id}/read`)
    },
    markAllAsSeen() {
      return api.put<INotificationUpdateStatus>(`${baseUrl}/seen`)
    }
  }

  return createOptimizedAPI(methods)
}
