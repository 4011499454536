import {
  INotificationsApi
} from '@src/types/notifications.types'
import createAxiosInstance, { createOptimizedAPI } from '.'

const api = createAxiosInstance('notifications')

const notificationsApi: INotificationsApi = {
  getList(ignoreRead, offset = 0, limit = 20) {
    return api.get('', {
      params: {
        offset,
        limit: limit < 100 ? limit : 100,
        ignore_read: ignoreRead
      }
    })
  },
  getUnseenCount() {
    return api.get('count/unseen')
  },
  markAllAsRead() {
    return api.put('')
  },
  markAsRead(id) {
    return api.put(`${id}/read`)
  },
  markAllAsSeen() {
    return api.put('seen')
  }
}

export default {
  ...notificationsApi,
  ...createOptimizedAPI(notificationsApi)
}
