



























































import {
  defineComponent, PropType, toRefs
} from '@vue/composition-api'
import SMTopicsChip from '@src/ui/SMTopicsChip/index.vue'
import { IJobSkill } from '@src/types/jobSkill'
import useTracking from '@src/composables/tracking/tracking'
import { IInitialExpertise } from '@src/types/initialExpertise.types'
import SMConceptSearchResultItem from '@src/ui/SMTopicsInput/components/SMConceptSearchResultItem.vue'
import useUserExpertise from '../composables/useUserExpertise'

export default defineComponent({
  name: 'TopicsInput',
  components: {
    SMTopicsChip,
    SMConceptSearchResultItem
  },
  props: {
    skills: {
      type: Array as PropType<IJobSkill[]>,
      default: () => []
    },
    value: {
      type: Array as PropType<IInitialExpertise[]>,
      default: () => []
    }
  },
  setup(props, context) {
    const { skills, value } = toRefs(props)
    const { trackMixpanelEvent } = useTracking()
    const {
      newExpertise, query, searchResults, suggestedExpertise, addNewTag, toggleTagSubscription
    } = useUserExpertise(skills, context, value)

    function trackTopicChipClick(label: string) {
      trackMixpanelEvent('topicClick', {
        type: 'click',
        tag: label,
        state: 'onboarding_dialogs',
        component: 'job_wizard'
      })
    }

    return {
      newExpertise,
      query,
      searchResults,
      suggestedExpertise,
      addNewTag,
      toggleTagSubscription,
      trackTopicChipClick
    }
  }
})
