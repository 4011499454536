


















import {
  defineComponent
} from '@vue/composition-api'

import { VNavigationDrawer } from 'vuetify/lib'
import SMSidenavCore from '@src/ui/SMSidenav/SMSidenavCore/index.vue'
import SMAdminMenu from '@src/ui/SMAdminMenu/index.vue'
import { useSidenavState } from '@src/ui/SMSidenav/composables/useSidenavState'
import { useAngularRouterState } from '../composables/useAngularRouterState'

export default defineComponent({
  name: 'SMSidenavMobile',
  components: {
    SMSidenavCore,
    VNavigationDrawer,
    SMAdminMenu
  },
  setup() {
    const { isSidenavOpen, toggleSidenav } = useSidenavState()
    const { isAdminStateActive } = useAngularRouterState()

    return {
      isSidenavOpen,
      isAdminStateActive,
      toggleSidenav
    }
  }
})
