import { ITrackingProps } from '@src/types/events.types'

export const VSM_METRICS_INFO_READ_MORE = 'clickReadMore'
export const VSM_METRICS_INFO_CLOSE = 'clickClose'
export const VSM_METRIC_MENU_ACTION_SELECTED = 'clickVsmContextMenuAction'
export const VSM_METRIC_PRIMARY_ACTION_SELECTED = 'clickVsmPrimaryAction'
export const VSM_METRIC_MENU_OPENED = 'clickVsmContextMenu'

const commonProps = {
}

export default {
  vsmMetrics: {
    [VSM_METRICS_INFO_READ_MORE]: (): ITrackingProps => ({
      ...commonProps,
      element: 'button',
      type: 'click',
      component: 'admin.vsm.metrics.welcome'
    }),
    [VSM_METRICS_INFO_CLOSE]: (): ITrackingProps => ({
      ...commonProps,
      element: 'button',
      type: 'click',
      component: 'admin.vsm.metrics.welcome'
    }),
    [VSM_METRIC_MENU_ACTION_SELECTED]: (customProps: { actionId: string; metricId: number }): ITrackingProps => ({
      ...commonProps,
      element: 'drop_down',
      type: 'click',
      component: `admin.vsm.metrics.${customProps.metricId}`,
      value: customProps.actionId
    }),
    [VSM_METRIC_PRIMARY_ACTION_SELECTED]: (customProps: { actionId: string; metricId: number }): ITrackingProps => ({
      ...commonProps,
      element: 'button',
      type: 'click',
      component: `admin.vsm.metrics.${customProps.metricId}`,
      value: customProps.actionId
    }),
    [VSM_METRIC_MENU_OPENED]: (customProps: { metricId: number }): ITrackingProps => ({
      ...commonProps,
      element: 'button',
      type: 'click',
      component: `admin.vsm.metrics.${customProps.metricId}`
    })
  }
}
