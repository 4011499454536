








































































































import {
  defineComponent, computed, inject, PropType
} from '@vue/composition-api'
import SMEditor from '@src/ui/SMEditor/index.vue'
import KnowledgeSpaceSelect from '@src/views/KnowledgeSpaces/components/ui/KnowledgeSpaceSelect.vue'
import SMCategorySelector from '@src/ui/SMCategorySelector/index.vue'
import { IUser } from '@src/types/user.types'
import { ISimilarQuestion } from '@src/types/question.types'
import { questionAndSolutionExtensions } from '@src/ui/SMEditor/TipTap/components/toolbar/toolbarExtensions'
import useRealmSettings from '@src/composables/realmSettings'
import { IComputedAttachment } from '@src/types/attachments.types'
import SMAttachmentEditableList from '@src/ui/SMAttachments/components/SMAttachmentEditableList.vue'
import SMAttachmentUploadButton from '@src/ui/SMAttachments/components/SMAttachmentUploadButton.vue'
import SMDraftList from '@src/ui/SMAsk/components/askQuestion/SMDraftList.vue'
import SMConceptInput from '@src/ui/SMAsk/components/common/SMConceptInput.vue'
import { IStandardizedConceptSingleLanguage } from '@src/types/concept.types'
import SMQuestionTitleInput from './SMQuestionTitleInput.vue'
import SMSimilarQuestionsDisplay from './SMSimilarQuestionsDisplay.vue'
import SMIdenticalQuestionDisplay from './SMIdenticalQuestionDisplay.vue'
import SMQuestionExpertsDisplay from './SMQuestionExpertsDisplay.vue'
import { IUseTracking } from '../../composables/tracking'
import { IFormError } from '../../composables/validation'
import { SMAskInjectables } from '../../constants'

export default defineComponent({
  name: 'SMQuestionForm',
  components: {
    SMConceptInput,
    SMDraftList,
    SMQuestionTitleInput,
    SMSimilarQuestionsDisplay,
    SMIdenticalQuestionDisplay,
    SMEditor,
    SMCategorySelector,
    KnowledgeSpaceSelect,
    SMQuestionExpertsDisplay,
    SMAttachmentUploadButton,
    SMAttachmentEditableList
  },
  props: {
    attachmentError: {
      type: String,
      default: undefined
    },
    attachments: {
      type: Array as () => IComputedAttachment[],
      default: () => []
    },
    categoryError: {
      type: Object as PropType<IFormError | undefined>,
      default: undefined
    },
    descriptionError: {
      type: Boolean,
      default: false
    },
    experts: {
      type: Array as () => IUser[],
      default: () => [] as IUser[]
    },
    hasSimilarQuestions: {
      type: Boolean,
      default: false
    },
    hideConceptInput: {
      type: Boolean,
      default: false
    },
    hideDescriptionUnlessMandatory: {
      type: Boolean,
      default: false
    },
    identicalQuestion: {
      type: Object as () => ISimilarQuestion | undefined,
      default: undefined
    },
    knowledgeSpaceMandatoryError: {
      type: Object as PropType<IFormError | undefined>,
      default: undefined
    },
    questionCategory: {
      type: Number as () => number | undefined | null,
      default: undefined
    },
    questionDescription: {
      type: String,
      default: ''
    },
    questionId: {
      type: Number as () => number | undefined,
      default: undefined
    },
    questionKnowledgeSpace: {
      type: Number as () => number | undefined | null,
      default: undefined
    },
    questionTitle: {
      type: String,
      default: ''
    },
    questionLanguage: {
      type: String,
      default: 'xx'
    },
    showAttachmentUploadButton: {
      type: Boolean,
      default: true
    },
    showDrafts: {
      type: Boolean,
      default: false
    },
    similarQuestions: {
      type: Array as () => ISimilarQuestion[],
      default: () => [] as ISimilarQuestion[]
    },
    concepts: {
      type: Array as PropType<IStandardizedConceptSingleLanguage[]>,
      default: () => []
    },
    conceptsError: {
      type: Object as PropType<IFormError | undefined>,
      default: undefined
    },
    titleError: {
      type: Object as () => IFormError | undefined,
      default: undefined
    },
    generalFeedSelected: {
      type: Boolean,
      default: false
    },
    isFullscreen: {
      type: Boolean,
      default: false
    },
    containerClass: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const { realmSettings } = useRealmSettings()

    const descriptionPlaceholder = computed(() => (realmSettings.value?.question.mandatory_question_description ? 'ask-overlay.form.description.placeholder.mandatory' : 'ask-overlay.form.description.placeholder.optional'))
    const isDescriptionVisible = computed(() => (props.hideDescriptionUnlessMandatory ? realmSettings.value?.question.mandatory_question_description : true))
    const categoriesEnabled = computed(
      () => !!realmSettings.value?.categories.categories_enabled
    )
    const knowledgeSpacesEnabled = computed(
      () => !!realmSettings.value?.knowledge_spaces.knowledge_spaces_enabled
    )

    const {
      trackSelectCategory,
      trackClickCategory,
      trackAddAttachment,
      trackSubmitAttachment,
      trackSelectKnowledgeSpace
    } = inject(SMAskInjectables.Tracking) as IUseTracking

    return {
      categoriesEnabled,
      descriptionPlaceholder,
      knowledgeSpacesEnabled,
      questionAndSolutionExtensions,
      isDescriptionVisible,
      trackSelectCategory,
      trackClickCategory,
      trackAddAttachment,
      trackSubmitAttachment,
      trackSelectKnowledgeSpace
    }
  }
})
