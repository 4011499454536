import { Ref, watch } from '@vue/composition-api'
import useTracking from '@src/composables/tracking/tracking'
import _once from 'lodash/once'
import { IUser } from '@src/types/user.types'

type QueryType = 'manual' | 'autocomplete' | 'recent'

export const AUTOCOMPLETE_MAX_RESULTS_SYNC = 7

export default function useAutoComplete(questionSearchResults: Ref<string[]>, userSearchResults: Ref<IUser[]>, searchTerm: Ref<string | null>) {
  const { trackMixpanelEvent } = useTracking()

  function trackViewResults() {
    trackMixpanelEvent('viewAutocompleteSearchResults', {
      total_question_results: questionSearchResults.value.length,
      total_people_results: userSearchResults.value.length
    })
  }
  function trackSubmit(selectedSearchResultString: string, queryType: QueryType) {
    trackMixpanelEvent('submitSearch', {
      character_length_typed_by_user: searchTerm.value?.length,
      character_length_query: selectedSearchResultString.length,
      query_type: queryType
    })
  }
  function trackFocus() {
    trackMixpanelEvent('clickSearch', {})
  }

  const trackViewResultsOnce = _once(trackViewResults)

  watch(questionSearchResults, (results) => {
    if (results.length) {
      trackViewResultsOnce()
    }
  })

  return {
    trackSubmit,
    trackFocus
  }
}
