const arduino = require('highlight.js/lib/languages/arduino')
const bash = require('highlight.js/lib/languages/bash')
const c = require('highlight.js/lib/languages/c')
const cpp = require('highlight.js/lib/languages/cpp')
const csharp = require('highlight.js/lib/languages/csharp')
const css = require('highlight.js/lib/languages/css')
const diff = require('highlight.js/lib/languages/diff')
const go = require('highlight.js/lib/languages/go')
const ini = require('highlight.js/lib/languages/ini')
const java = require('highlight.js/lib/languages/java')
const javascript = require('highlight.js/lib/languages/javascript')
const json = require('highlight.js/lib/languages/json')
const kotlin = require('highlight.js/lib/languages/kotlin')
const less = require('highlight.js/lib/languages/less')
const lua = require('highlight.js/lib/languages/lua')
const makefile = require('highlight.js/lib/languages/makefile')
const markdown = require('highlight.js/lib/languages/markdown')
const objectivec = require('highlight.js/lib/languages/objectivec')
const perl = require('highlight.js/lib/languages/perl')
const php = require('highlight.js/lib/languages/php')
const phpTemplate = require('highlight.js/lib/languages/php-template')
const plaintext = require('highlight.js/lib/languages/plaintext')
const python = require('highlight.js/lib/languages/python')
const pythonRepl = require('highlight.js/lib/languages/python-repl')
const r = require('highlight.js/lib/languages/r')
const ruby = require('highlight.js/lib/languages/ruby')
const rust = require('highlight.js/lib/languages/rust')
const scala = require('highlight.js/lib/languages/scala')
const scss = require('highlight.js/lib/languages/scss')
const shell = require('highlight.js/lib/languages/shell')
const sql = require('highlight.js/lib/languages/sql')
const swift = require('highlight.js/lib/languages/swift')
const typescript = require('highlight.js/lib/languages/typescript')
const vbnet = require('highlight.js/lib/languages/vbnet')
const xml = require('highlight.js/lib/languages/xml')
const yaml = require('highlight.js/lib/languages/yaml')
const lowlight = require('lowlight/lib/core')

lowlight.registerLanguage('arduino', arduino)
lowlight.registerLanguage('bash', bash)
lowlight.registerLanguage('c', c)
lowlight.registerLanguage('cpp', cpp)
lowlight.registerLanguage('csharp', csharp)
lowlight.registerLanguage('css', css)
lowlight.registerLanguage('diff', diff)
lowlight.registerLanguage('go', go)
lowlight.registerLanguage('ini', ini)
lowlight.registerLanguage('java', java)
lowlight.registerLanguage('javascript', javascript)
lowlight.registerLanguage('json', json)
lowlight.registerLanguage('kotlin', kotlin)
lowlight.registerLanguage('less', less)
lowlight.registerLanguage('lua', lua)
lowlight.registerLanguage('makefile', makefile)
lowlight.registerLanguage('markdown', markdown)
lowlight.registerLanguage('objectivec', objectivec)
lowlight.registerLanguage('perl', perl)
lowlight.registerLanguage('php', php)
lowlight.registerLanguage('php-template', phpTemplate)
lowlight.registerLanguage('plaintext', plaintext)
lowlight.registerLanguage('python', python)
lowlight.registerLanguage('python-repl', pythonRepl)
lowlight.registerLanguage('r', r)
lowlight.registerLanguage('ruby', ruby)
lowlight.registerLanguage('rust', rust)
lowlight.registerLanguage('scala', scala)
lowlight.registerLanguage('scss', scss)
lowlight.registerLanguage('shell', shell)
lowlight.registerLanguage('sql', sql)
lowlight.registerLanguage('swift', swift)
lowlight.registerLanguage('typescript', typescript)
lowlight.registerLanguage('vbnet', vbnet)
lowlight.registerLanguage('xml', xml)
lowlight.registerLanguage('yaml', yaml)

export default lowlight
