






































import {
  defineComponent, PropType
} from '@vue/composition-api'
import { IComputedAttachment } from '@src/types/attachments.types'
import VClamp from 'vue-clamp/Clamp'

export default defineComponent({
  name: 'SMAttachmentEditableList',
  components: { VClamp },
  props: {
    value: {
      type: Array as PropType<IComputedAttachment[]>,
      default: () => []
    },
    attachmentError: {
      type: String,
      default: undefined
    }
  }
})
