export type ICategoryType = number | null | 'uncategorized'

export enum SortingFilterValues {
  Activity = 'last_activity.desc',
  DatePublished = 'date_published.desc',
  Relevance = 'relevance.desc', // TODO this does not exist in the backend
  Id = 'id.desc',
  SolutionCount = 'solution_count.desc',
  ViewCount = 'view_count.desc',
  Interest = 'interest.desc',
  ActivityAndInterest = 'activity_and_interest.desc',
}

export interface IFilterValue {
  knowledgeSpaceId: number | null;
  categoryIds: ICategoryType[];
  filter: FilterValues[];
  topics: string[];
  includeArchived: boolean
}

export interface ISavedFilter extends IFilterValue {
  filterName: string;
}

export interface IDeeplinkFilterParams {
  query?: string;
  search?: string; // Legacy search parameter that we still support for compatibilty. It's an alias for "query".
  space?: string;
  categories?: string;
  filter?: string;
  sort?: SortingFilterValues;
  tags?: string;
  concepts?: string;
  include_archived?: string
}

export interface IStreamEndpointParams {
  query?: string;
  knowledgeSpace?: number;
  categories?: string;
  include_uncategorized?: boolean;
  filter?: string;
  sort?: SortingFilterValues;
  tags?: string[];
  concepts?: string[];
  include_archived?: boolean
}

export enum FilterValues {
  All = 'all',
  Unsolved = 'unsolved',
  Solved = 'solved',
  Accepted = 'accepted',
  Verified = 'verified',
  MyQuestions = 'my-questions',
  MySolutions = 'my-solutions',
  Following = 'following',
  Trending = 'trending',
  Flagged = 'flagged'
}
