/* eslint-disable import/prefer-default-export */
import { ITipTapPlugins } from '@src/types/tiptap.types'

export type EditorExtensionsType = 'SOLUTION_EXTENSIONS' | 'QUESTION_EXTENSIONS' | 'COMMENT_EXTENSIONS' | 'ADMIN_EXTENSIONS'

export const basicExtensions = [
  ITipTapPlugins.bold,
  ITipTapPlugins.italic,
  ITipTapPlugins.underline,
  ITipTapPlugins.strike,
  ITipTapPlugins.link,
  ITipTapPlugins.history,
  ITipTapPlugins.mention
]

export const questionAndSolutionExtensions = [
  ITipTapPlugins.bold,
  ITipTapPlugins.bulletList,
  ITipTapPlugins.codeHighlight,
  ITipTapPlugins.history,
  ITipTapPlugins.image,
  ITipTapPlugins.italic,
  ITipTapPlugins.link,
  ITipTapPlugins.markdown,
  ITipTapPlugins.mention,
  ITipTapPlugins.orderedList,
  ITipTapPlugins.strike,
  ITipTapPlugins.typography,
  ITipTapPlugins.underline,
  ITipTapPlugins.video
]

export const commentExtensions = [
  ITipTapPlugins.mention,
  ITipTapPlugins.link
]

export const adminExtensions = [
  ITipTapPlugins.bold,
  ITipTapPlugins.bulletList,
  ITipTapPlugins.history,
  ITipTapPlugins.image,
  ITipTapPlugins.italic,
  ITipTapPlugins.link,
  ITipTapPlugins.orderedList,
  ITipTapPlugins.strike,
  ITipTapPlugins.typography,
  ITipTapPlugins.underline,
  ITipTapPlugins.video
]
