import { Store, MutationPayload } from 'vuex'
import { IRootState } from '@src/types/store.types'
import * as Sentry from '@sentry/vue'

const subscribeUserMutation = 'user/setUser'

/**
 * Sets the sentry context to provide further context information to Sentry tracking such as the user event_tracking_id.
 */
const sentryContextHook = (store: Store<IRootState>): void => {
  store.subscribe((mutation: MutationPayload) => {
    const { type, payload } = mutation

    if (type === subscribeUserMutation) {
      const { event_tracking_id } = payload
      Sentry.setUser({ id: event_tracking_id })
    }
  })
}

export default sentryContextHook
