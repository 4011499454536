import feedbackApi from '@src/api/web/feedback'
import { computed, ref, toRefs } from '@vue/composition-api'
import useStore from '@src/store'
import { useI18n } from '@src/plugins/i18n'
import useSnackbar from '@src/composables/snackbar'
import { useFeedbackTracking } from './useFeedbackTracking'

export function useFeedback() {
  const store = useStore()
  const tracking = useFeedbackTracking()
  const { t } = useI18n()
  const { showSnackbar } = useSnackbar()

  const { isDialogOpen, message: storeMessage } = toRefs(store.state.feedbackDialog)
  const busy = ref(false)
  const rules = {
    required: (value: string) => !!value || `${t('api.error.validation.required.msg')}`
  }

  const message = computed({
    set: (value) => store.commit('feedbackDialog/setMessage', value),
    get: () => storeMessage.value
  })

  const feedbackDialogOpenModel = computed({
    set: toggleFeedbackDialog,
    get: () => isDialogOpen.value
  })

  const canSubmit = computed(() => rules.required(message.value) === true)

  async function submit(): Promise<void> {
    if (canSubmit.value && !busy.value) {
      tracking.submitFeedback()
      try {
        busy.value = true
        const { data: { id } } = await feedbackApi.sendFeedback(message.value)
        await feedbackApi.publishFeedback(id)
        await showSnackbar({ translationKey: 'feedback.successful' })
        toggleFeedbackDialog(false)
      } catch {
        await showSnackbar({ translationKey: 'error.undefined' })
      } finally {
        busy.value = false
      }
    }
  }

  function cancel() {
    toggleFeedbackDialog(false)
  }

  function toggleFeedbackDialog(isOpen: boolean) {
    if (!isOpen) {
      reset()
    }
    store.commit('feedbackDialog/toggleFeedbackDialog', isOpen)
  }

  function reset() {
    message.value = ''
  }

  return {
    message,
    rules,
    canSubmit,
    submit,
    cancel,
    busy,
    feedbackDialogOpenModel
  }
}
