import useTracking from '@src/composables/tracking/tracking'
import { IQuestionComplete } from '@src/types/question.types'
import { Ref } from '@vue/composition-api'
import { UserChip } from '@src/ui/SMSocialDialog/types'
import { IUser } from '@src/types/user.types'
import { ISolutionComplete } from '@src/types/solution.types'
import { ICommentComplete } from '@src/types/comment.types'
import { InteractionType } from '@src/types/events.types'

export default function useSocialDialogTracking(entity?: Ref<IQuestionComplete | ISolutionComplete | ICommentComplete | undefined>) {
  const { trackMixpanelEvent } = useTracking()

  const buttonClickProps = {
    type: 'click' as InteractionType,
    element: 'button'
  }

  function getQuestionDialogProps(userList: UserChip[]) {
    return {
      total_recipients: userList.length,
      total_external_recipients: userList.filter((u) => !(u as IUser).id).length,
      question_status: (entity?.value as IQuestionComplete)?.solution_count ? 'answered' : 'unanswered'
    }
  }

  function track(name: string, props: any) {
    trackMixpanelEvent(name, {
      type: 'click',
      component: 'social_dialog',
      ...props
    })
  }
  function trackClickRecommendExpert() {
    track('clickRecommendExpert', {
      entity: 'question',
      question_status: (entity?.value as IQuestionComplete)?.solution_count ? 'answered' : 'unanswered'
    })
  }

  function trackClickShare(entityType: string) {
    track('clickShare', {
      entity: entityType
    })
  }

  function trackSubmitRecommendExpert(userList: UserChip[]) {
    track('submitRecommendExperts', {
      entity: 'question',
      ...getQuestionDialogProps(userList)
    })
  }

  function trackSubmitShare(entityType: string, userList: UserChip[]) {
    track('submitShare', {
      ...getQuestionDialogProps(userList),
      entity: entityType
    })
  }

  function submitInvite(props: { total_external_recipients: number }) {
    return trackMixpanelEvent('submitInvite', {
      ...props,
      ...buttonClickProps
    })
  }

  function clickCancel() {
    return trackMixpanelEvent('clickCancel', {
      ...buttonClickProps
    })
  }

  function clickInvite(props: Partial<{ state: string, component: string }>) {
    return trackMixpanelEvent('clickInvite', {
      feature: 'invite',
      ...props,
      ...buttonClickProps
    })
  }

  function trackUserSelection(isExistingUser: boolean) {
    track('userSelectedSocialDialog', {
      isExistingUser
    })
  }

  function trackUserRemoval(isExistingUser: boolean) {
    track('userRemovedSocialDialog', {
      isExistingUser
    })
  }

  return {
    trackClickRecommendExpert,
    trackSubmitRecommendExpert,
    trackSubmitShare,
    trackClickShare,
    trackUserSelection,
    trackUserRemoval,
    submitInvite,
    clickCancel,
    clickInvite
  }
}
