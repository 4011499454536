


















import {
  computed,
  defineComponent
} from '@vue/composition-api'

export default defineComponent({
  name: 'SMDialog',
  // Don't automatically apply arguments to the root element of this component. We take care of merging them first and then apply them explicitly.
  // See https://vuejs.org/guide/components/attrs.html#disabling-attribute-inheritance
  inheritAttrs: false,
  setup(props, { attrs }) {
    const mergedAttrs = computed(() => ({
      'overlay-color': '#000000',
      'overlay-opacity': 0.38,
      'retain-focus': false,
      width: 640,
      scrollable: true,
      ...attrs
    }))
    return { mergedAttrs }
  }
})
