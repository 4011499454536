
















import { computed, defineComponent, ref } from '@vue/composition-api'
import useAskDialog from '@src/composables/askDialog'
import SMDialog from '@src/ui/SMDialog/index.vue'
import SMEditQuestionDialog from './SMEditQuestionDialog.vue'

export default defineComponent({
  name: 'SMEditQuestionButton',
  components: {
    SMEditQuestionDialog,
    SMDialog
  },
  setup() {
    const {
      isEditDialogOpen, existingQuestionId, closeEditDialog
    } = useAskDialog()

    const isFullscreen = ref(false)

    const isDialogOpen = computed<boolean>({
      get() {
        return isEditDialogOpen.value
      },
      set(value) {
        if (!value) {
          closeEditDialog()
        }
      }
    })

    return {
      isDialogOpen,
      existingQuestionId,
      isFullscreen
    }
  }
})
