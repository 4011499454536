import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'

export function discussionParticipationUsersApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/discussion-participation-users'
  const methods = {
    getDiscussionParticipationUser() {
      return api.get<boolean>(baseUrl)
    },
    addDiscussionParticipationUser() {
      return api.post<void>(baseUrl)
    }
  }
  return createOptimizedAPI(methods)
}
