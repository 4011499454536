








































import {
  defineComponent, inject
} from '@vue/composition-api'
import { ISimilarQuestion } from '@src/types/question.types'
import { SMAskInjectables } from '../../constants'
import { IUseTracking } from '../../composables/tracking'

export default defineComponent({
  name: 'SMIdenticalQuestionDisplay',
  props: {
    identicalQuestion: {
      type: Object as () => ISimilarQuestion,
      required: true
    }
  },
  setup() {
    const { trackClickSimilarQuestion } = inject(SMAskInjectables.Tracking) as IUseTracking

    return {
      trackClickSimilarQuestion
    }
  }
})
