var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SMDialog',{attrs:{"value":_vm.value},on:{"input":function (val) { return _vm.$emit('input', val); }}},[_c('v-card',{staticClass:"card overflow-auto"},[_c('v-card-title',{staticClass:"text-h6 title font-weight-regular pa-6"},[_vm._v("\n      "+_vm._s(_vm.$t(_vm.title))+"\n    ")]),_vm._v(" "),_c('v-card-text',[_c('div',{staticClass:"pa-6"},[_c('v-combobox',{attrs:{"value":_vm.selected,"items":_vm.filteredSuggestions,"item-value":"id","item-title":"id","outlined":"","multiple":"","no-filter":"","append-icon":"","label":_vm.$t(_vm.inputLabel),"chips":"","hide-details":"","data-cy":"sd-input","search-input":_vm.searchInput},on:{"update:search-input":_vm.handleInputChange,"input":function (newList) {
            _vm.trackUserAddition(newList)
            _vm.selected = _vm.filterInvalidEmails(newList);
            _vm.searchInput = ''
          }},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-chip',_vm._b({key:JSON.stringify(data.item),staticClass:"pl-0 py-0",attrs:{"outlined":"","close":"","data-cy":_vm.getDataCy('sd-chip', data.item)},on:{"click:close":function () {
                data.parent.selectItem(data.item)
                _vm.trackUserRemoval(!!data.item.id)
              }}},'v-chip',data.attrs,false),[(_vm.getChipAvatar(data.item))?_c('v-img',{staticClass:"avatar",attrs:{"src":_vm.getChipAvatar(data.item),"height":30,"width":30}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"ml-2"},[_vm._v("\n                "+_vm._s(_vm.getChipLabel(data.item))+"\n              ")])],1)]}},{key:"item",fn:function(data){return [_c('div',{key:data.item.id,staticClass:"d-flex flex-grow-1 align-center py-1",attrs:{"data-cy":_vm.getDataCy('sd-list-item', data.item)}},[_c('v-avatar',{staticClass:"mr-4"},[_c('v-img',{attrs:{"src":_vm.getChipAvatar(data.item),"size":48}})],1),_vm._v("\n              "+_vm._s(_vm.getChipLabel(data.item))+"\n            ")],1)]}}])}),_vm._v(" "),(_vm.validDomains.length)?_c('div',{staticClass:"text-caption light-grey ml-3 my-2"},[_vm._v("\n          "+_vm._s(_vm.$t('invite.email-domains', { domain: _vm.domain }))+"\n        ")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"px-6"},[_c('v-textarea',{attrs:{"outlined":"","no-resize":"","label":_vm.$t('social-dialog.add-message')},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1)]),_vm._v(" "),_c('v-card-actions',{staticClass:"d-flex flex-column flex-sm-row-reverse align-stretch justify-start px-6 py-6 actions"},[_vm._t("cta",null,{"selectedUsers":_vm.selected,"message":_vm.message}),_vm._v(" "),_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v("\n        "+_vm._s(_vm.$t('cancel'))+"\n      ")])],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }