import axios, { AxiosPromise } from 'axios'
import { IImpression, IImpressions } from '@src/types/tracking.types'
import { FeatureToggleNames } from '@src/plugins/featureToggles/featureToggle.names'

interface ITrackingApiEnabled {
  getImpressions(userTrackingId: string): AxiosPromise<IImpressions>;
  trackImpression(experimentName: FeatureToggleNames, userTrackingId: string, experimentVariant: string): AxiosPromise<IImpression>;
}

interface ITrackingApiDisabled {
  getImpressions(userTrackingId?: string): Promise<{ data: [] }>;
  trackImpression(experimentName?: FeatureToggleNames, userTrackingId?: string, experimentVariant?: string): Promise<null>;
}

const isEnabled = window.UNLEASH_ENABLED
const baseURL = window.IMPRESSION_TRACKING_ENDPOINT
const api = axios.create({ baseURL })

const trackingApiEnabled: ITrackingApiEnabled = {
  getImpressions(userTrackingId) {
    return api.get(`users/${userTrackingId}/impressions`)
  },
  trackImpression(experimentName, userTrackingId, experimentVariant) {
    return api.post('impressions', { experimentName, userTrackingId, experimentVariant })
  }
}

const trackingApiDisabled: ITrackingApiDisabled = {
  async getImpressions() {
    return { data: [] }
  },
  async trackImpression() {
    return null
  }
}

export default (baseURL && isEnabled) ? trackingApiEnabled : trackingApiDisabled
