import { ITrackingProps } from '@src/types/events.types'

const commonProps = {
  state: 'question',
  component: 'new_question_follow_up_module',
  element: 'link',
  type: 'click'
} as ITrackingProps

export const CLICK_SETTINGS_NOTIFICATIONS = 'clickSettingsNotifications'

export default {
  SMNewQuestionFollowUp: {
    [CLICK_SETTINGS_NOTIFICATIONS]: (): ITrackingProps => ({
      ...commonProps
    })
  }
}
