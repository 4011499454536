import { AxiosPromise } from 'axios'
import { IFeedback, IFeedbackResponse } from '@src/types/feedback.types'
import createAxiosInstance from '.'

interface IFeedbackApi {
  sendFeedback(feedback: IFeedback): AxiosPromise<IFeedbackResponse>;
}

const api = createAxiosInstance('feedback', { web: true })

const feedbackApi: IFeedbackApi = {
  sendFeedback(feedback) {
    return api.post('', feedback)
  }
}

export default feedbackApi
