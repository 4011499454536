
















import { computed, defineComponent, ref } from '@vue/composition-api'
import useAskDialog from '@src/composables/askDialog'
import useSnackbar from '@src/composables/snackbar'
import SMDialog from '@src/ui/SMDialog/index.vue'
import streamApi from '@src/api/stream'
import { isEditorContentEmpty } from '@src/utilities/editor'
import { questionsApi } from '@src/api/apiModuleInstances'
import SMAskDialog from './SMAskDialog.vue'

export default defineComponent({
  name: 'SMAskDialogAnchor',
  components: {
    SMAskDialog,
    SMDialog
  },
  setup() {
    const {
      currentDraftQuestionId, isAskDialogOpen, closeAskDialog, resetCurrentDraftQuestionId
    } = useAskDialog()
    const { showSnackbar } = useSnackbar()

    const isFullscreen = ref(false)

    const isDialogOpen = computed<boolean>({
      get() {
        return isAskDialogOpen.value
      },
      set(value) {
        if (!value) {
          closeAskDialog()
          showSnackbarForSavedNonEmptyDraft()
          cleanUpDrafts()
        }
      }
    })

    async function showSnackbarForSavedNonEmptyDraft() {
      const { data } = await streamApi.throttled.getQuestionDrafts()
      const currentDraft = data.items.find((draft) => draft.id === currentDraftQuestionId?.value)
      if (currentDraft?.title || !isEditorContentEmpty(currentDraft?.description ?? '')) {
        showSnackbar('ask-overlay.drafts.snackbar.saved')
      } else {
        resetCurrentDraftQuestionId()
      }
    }

    async function cleanUpDrafts() {
      const { data } = await streamApi.throttled.getQuestionDrafts()
      data.items.forEach((draft) => {
        if (!draft.title && isEditorContentEmpty(draft.description)) {
          questionsApi.deleteQuestion(draft.id)
        }
      })
    }

    return {
      isDialogOpen,
      currentDraftQuestionId,
      isFullscreen
    }
  }
})
