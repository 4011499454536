import Vue, { CreateElement } from 'vue'
import Router from 'vue-router'
import { isAuthenticated } from '@src/utilities/auth'
import generateStore from '@src/store'
import awaitGetterValue from '@src/utilities/storeUtils'
import { IFeatureToggle } from '@src/types/featureToggles.types'
import { FeatureToggleNames } from '@src/plugins/featureToggles/featureToggle.names'
import { featureToggleList } from '@src/utilities/featureToggle'
import { usePreferredSorting } from '@src/router/sorting'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import getAdminRouteConfig from './admin'
import roleBasedNavigationGuard from './roleBasedNavigationGuard'

let routerCreated = false
const store = generateStore()

async function checkFeatureToggle(toggleName: FeatureToggleNames) {
  const toggles = await awaitGetterValue<IFeatureToggle[]>('featureToggles/getToggles')
  return featureToggleList(toggles).findByName(toggleName).isEnabled()
}

export const createRouterForVueNativeMode = (realmSettings?: IUnauthenticatedRealmSettings) => {
  if (routerCreated) {
    throw new Error('The router was already created. Use $router in components to get a reference.')
  }

  Vue.use(Router)
  const router = new Router({
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      }
      return { x: 0, y: 0 }
    },
    mode: 'history',
    routes: [
      {
        path: '',
        component: () => import(
          /* webpackChunkName: "MainLayout" */ '@src/layouts/MainLayout.vue'
        ),
        children: [
          {
            path: '/question/:id',
            name: 'question',
            component: () => import(/* webpackChunkName: "question" */ '@src/views/Question/index.vue'),
            beforeEnter: usePreferredSorting
          },
          {
            path: '/stream',
            name: 'stream',
            alias: '/feed',
            component: () => import(/* webpackChunkName: "search" */ '@src/views/Stream/index.vue'),
            beforeEnter: usePreferredSorting
          },
          // homepage
          {
            path: '/',
            name: 'homepage',
            beforeEnter(to, from, next) {
              if (realmSettings?.homepage.enabled) {
                next()
              } else if (isAuthenticated()) {
                next('/stream')
              }
            },
            component: () => import(/* webpackChunkName: "homepage" */ '@src/views/Homepage/index.vue')
          },
          {
            path: 'dashboard',
            name: 'dashboard',
            redirect: { name: 'homepage' }
          },
          {
            path: 'search/',
            props: (route) => ({ query: route.query.query || '' }),
            component: () => import(/* webpackChunkName: "search" */ '@src/views/Search/index.vue'),
            children: [
              {
                path: '',
                name: 'overview',
                props: (route) => ({ query: route.query.query || '' }),
                component: () => import(/* webpackChunkName: "search" */ '@src/views/Search/Overview/index.vue')
              },
              {
                path: 'people',
                name: 'people',
                component: () => import(/* webpackChunkName: "search" */ '@src/views/Search/People/index.vue')
              },
              {
                path: 'experts',
                name: 'experts',
                beforeEnter(to, from, next) {
                  if (realmSettings?.expert_search.show_expert_search) {
                    next()
                  } else {
                    next('/error/403')
                  }
                },
                component: () => import(/* webpackChunkName: "search" */ '@src/views/Search/Experts/index.vue')
              },
              {
                path: 'questions',
                name: 'questions',
                component: () => import(/* webpackChunkName: "search" */ '@src/views/Search/Questions/index.vue')
              }
            ]
          },
          {
            path: 'settings',
            name: 'settings',
            component: () => import(/* webpackChunkName: "settings" */ '@src/views/Settings/Overview/index.vue')
          },
          {
            path: 'settings/password',
            name: 'settings-password',
            component: () => import(/* webpackChunkName: "settings-password" */ '@src/views/Settings/Password/index.vue')
          },
          {
            path: 'settings/language',
            name: 'settings-language',
            component: () => import(/* webpackChunkName: "settings-language" */ '@src/views/Settings/SMLanguageSettings/index.vue')
          },
          {
            path: 'settings/communication',
            name: 'settings-communication',
            component: () => import(/* webpackChunkName: "settings-communication" */ '@src/views/Settings/Communication/index.vue')
          },
          {
            path: 'settings/profile',
            name: 'settings-profile',
            component: () => import(/* webpackChunkName: "settings-profile" */ '@src/views/Settings/Profile/index.vue')
          },
          {
            path: 'spaces',
            name: 'spaces',
            component: () => import(/* webpackChunkName: "spaces" */ '@src/views/KnowledgeSpaces/components/list/KnowledgeSpacesList.vue')
          },
          {
            path: 'conversation/:conversationId?',
            name: 'conversation',
            async beforeEnter(to, from, next) {
              if (await checkFeatureToggle(FeatureToggleNames.RELEASE_LLM_CONVERSATIONS)) {
                next()
              } else {
                next('/error/403')
              }
            },
            props: (route) => ({
              conversationId: route.params.conversationId
            }),
            component: () => import(/* webpackChunkName: "conversations" */ '@src/views/Conversations/index.vue')
          },
          {
            path: 'concept/:id',
            name: 'concept-overview',
            component: () => import(/* webpackChunkName: "concept-overview" */ '@src/views/ConceptOverview/index.vue')
          },
          {
            path: 'expert-connect',
            name: 'expert-connect',
            beforeEnter(to, from, next) {
              if (realmSettings?.expert_search.show_expert_search) {
                next()
              } else {
                next('/error/403')
              }
            },
            component: () => import(/* webpackChunkName: "expert-search" */ '@src/views/ExpertSearch/index.vue')
          },
          {
            path: 'user/:id(\\d+)?',
            props: (route) => ({ userId: route.params.id ? Number(route.params.id) : undefined }), // TODO: props are not needed anymore when the old user profile is removed
            component: async () => {
              if (await checkFeatureToggle(FeatureToggleNames.RELEASE_USER_PROFILE_V2)) {
                return import(/* webpackChunkName: "user-profile" */ '@src/views/UserProfileV2/index.vue')
              }
              return import(/* webpackChunkName: "user" */ '@src/views/User/index.vue')
            },
            children: [
              {
                path: '',
                name: 'user/profile',
                props: (route) => ({ userId: route.params.id ? Number(route.params.id) : undefined }), // TODO: props are not needed anymore when the old user profile is removed
                component: async () => {
                  if (await checkFeatureToggle(FeatureToggleNames.RELEASE_USER_PROFILE_V2)) {
                    return import(/* webpackChunkName: "user-profile" */ '@src/views/UserProfileV2/components/UserProfileMain.vue')
                  }
                  return import(/* webpackChunkName: "user" */ '@src/views/User/components/profile/index.vue')
                }
              },
              {
                path: 'activity',
                name: 'user/activity',
                props: (route) => ({ userId: route.params.id ? Number(route.params.id) : undefined }), // TODO: props are not needed anymore when the old user profile is removed
                component: () => import(/* webpackChunkName: "user" */ '@src/views/User/components/activity/index.vue')
              },
              {
                path: 'following',
                name: 'user/following',
                props: (route) => ({ userId: route.params.id ? Number(route.params.id) : undefined }), // TODO: props are not needed anymore when the old user profile is removed
                component: () => import(/* webpackChunkName: "user" */ '@src/views/User/components/following/index.vue')
              },
              {
                path: 'contribution',
                name: 'user/contribution',
                props: (route) => ({ userId: route.params.id ? Number(route.params.id) : undefined }), // TODO: props are not needed anymore when the old user profile is removed
                component: () => import(/* webpackChunkName: "user" */ '@src/views/User/components/contribution/index.vue')
              },
              {
                path: 'subscription',
                name: 'user/subscription',
                props: (route) => ({ userId: route.params.id ? Number(route.params.id) : undefined }), // TODO: props are not needed anymore when the old user profile is removed
                component: () => import(/* webpackChunkName: "user" */ '@src/views/User/components/subscription/index.vue')
              }
            ]
          },
          {
            path: 'user/edit',
            name: 'user/edit',
            component: () => import(/* webpackChunkName: "user" */ '@src/views/User/edit/index.vue')
          },
          {
            path: 'admin',
            name: 'admin',
            component: () => import(/* webpackChunkName: "admin" */ '@src/views/Admin/index.vue'),
            children: getAdminRouteConfig(realmSettings)
          }
        ]
      },
      {
        path: '/logout/',
        name: 'logout',
        component: () => import(
          /* webpackChunkName: "logout" */ '@src/views/Logout/index.vue'
        )
      },
      {
        path: '/invite/set-password/:token',
        name: 'set-password-token',
        props: (route) => ({
          token: route.params.token,
          onError: (() => router.push({ name: 'error-page-token' })) as any
        }),
        component: () => import(
          /* webpackChunkName: "set-password-token" */ '@src/views/SetPassword/index.vue'
        )
      },
      {
        path: '/invite/create-user/:token',
        name: 'create-user-token',
        props: (route) => ({
          token: route.params.token,
          onError: () => router.push({ name: 'error-page-token' })
        }),
        component: () => import(
          /* webpackChunkName: "create-user-from-token" */ '@src/views/CreateUserFromToken/index.vue'
        )
      },
      {
        path: '/confirm-email/:token',
        name: 'confirm-email-token',
        props: (route) => ({
          token: route.params.token,
          onComplete: () => {
            // TODO once we migrate the homepage use router.push({ name: 'homepage' })
            window.location.assign('/')
          }
        }),
        component: () => import(
          /* webpackChunkName: "confirm-email-token" */ '@src/views/ConfirmEmail/index.vue'
        )
      },
      {
        path: '/external-app/',
        name: 'external-app',
        component: () => import(
          /* webpackChunkName: "external-app" */ '@src/views/ExternalApp/index.vue'
        )
      },
      {
        path: '/invite/error/token/:token',
        name: 'error-page-token',
        component: () => import(
          /* webpackChunkName: "error-page-token" */ '@src/views/ErrorPage/InvalidToken/index.vue'
        )
      },
      {
        path: '/error/',
        component: {
          // Inline declaration of a component that renders our <router-view>
          render: (c: CreateElement) => c('router-view')
        },
        children: [
          {
            path: '401',
            name: 'error401',
            props: (route) => ({ errorCode: route.query.code }),
            component: () => import(/* webpackChunkName: "error401" */ '@src/views/ErrorPage/401/index.vue')
          },
          {
            path: '503',
            name: 'error503',
            component: () => import(/* webpackChunkName: "error503" */ '@src/views/ErrorPage/503/index.vue')
          },
          {
            path: '500',
            name: 'error500',
            component: () => import(/* webpackChunkName: "error500" */ '@src/views/ErrorPage/500/index.vue')
          },
          {
            path: '403',
            name: 'error403',
            component: () => import(/* webpackChunkName: "error403" */ '@src/views/ErrorPage/403/index.vue')
          },
          {
            path: '404',
            name: 'error404',
            component: () => import(/* webpackChunkName: "error404" */ '@src/views/ErrorPage/404/index.vue')
          }
        ]
      },
      {
        path: '/notifications',
        name: 'notifications',
        beforeEnter(to, from, next) {
          store.dispatch('notifications/showDrawer')
          next({ path: '/' })
        }
      },
      {
        path: '/ask',
        name: 'ask',
        beforeEnter(to, from, next) {
          const { title } = to.query
          store.dispatch('ask/openAskDialog', {
            questionTitle: title,
            element: 'url'
          })
          next({ path: '/' })
        }
      },
      {
        path: '/announcement/:id',
        name: 'announcement',
        beforeEnter(to, from, next) {
          const { id } = to.params
          next({ path: `/?announcementId=${id}` })
        }
      },
      {
        path: '/question/:id/edit',
        name: 'question-draft-edit',
        beforeEnter(to, from, next) {
          const { id } = to.params
          store.dispatch('ask/openAskDialog', {
            questionDraftId: id,
            element: 'url'
          })
          next({ path: '/' })
        }
      }
    ]
  })

  router.beforeEach(roleBasedNavigationGuard) // ensures that the requiredRole 'meta' prop of each route is enforced

  routerCreated = true

  return router
}
