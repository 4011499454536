import { AxiosInstance } from 'axios'
import { IPersistedModule } from '../../../types/module.types'
import { createOptimizedAPI } from '../utils'

export function homepageModulesApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/homepage-modules'
  const methods = {
    getHomepageModules() {
      return api.get<IPersistedModule[]>(baseUrl)
    },
    setHomepageModules(modules: IPersistedModule[]) {
      return api.post<IPersistedModule[]>(baseUrl, modules)
    }
  }
  return createOptimizedAPI(methods)
}
