import { PrivilegedUserRoles, ManagerUserRoles, IUser } from '@src/types/user.types'
import _some from 'lodash/some'
import { IInteractions } from '@src/types/question.types'
import _isEmpty from 'lodash/isEmpty'
import i18n from '@src/plugins/i18n'

export enum PermissionLevel {
  Admin = 'admin',
  Manager = 'manager',
  User = 'user'
}

export enum ExpertiseFeedbackExpertLevel {
  INTEREST = 'interest',
  EXPERT = 'expert'
}

export function getFullName(firstName?: string, lastName?: string, callInexistentUserQuestionPoser = false): string {
  if (!firstName || !lastName) {
    return callInexistentUserQuestionPoser ? i18n.t('question.poser').toString() : i18n.t('user.deactivated').toString()
  }

  return `${firstName} ${lastName}`
}

export function getAvatarUrl(unprocessedUrl: string, size: number): string {
  if (!unprocessedUrl) {
    return ''
  }
  const imageSize = size.toString()
  const url = new URL(unprocessedUrl)

  url.searchParams.set('nocrop', 'false')
  url.searchParams.set('width', imageSize)
  url.searchParams.set('height', imageSize)
  return url.toString()
}

export function isMine(currentUser: IUser, userToShowId: number | string | undefined): boolean {
  const myUserId = currentUser.id
  const otherUserId = Number(userToShowId)
  return myUserId === otherUserId || otherUserId === 0 || currentUser.global_user_id === userToShowId
}

export function getPermissionLevel(roles: string[]): PermissionLevel {
  let permissionLevel

  const isAdmin = _some(
    PrivilegedUserRoles,
    (role) => roles.indexOf(PrivilegedUserRoles.SuperAdmin) > -1 || roles.indexOf(role) > -1
  )
  const isManager = roles.indexOf(ManagerUserRoles.Manager) > -1

  if (isAdmin) {
    permissionLevel = PermissionLevel.Admin
  } else if (isManager) {
    permissionLevel = PermissionLevel.Manager
  } else {
    permissionLevel = PermissionLevel.User
  }

  return permissionLevel
}

export function isSuperAdmin(roles: string[]): boolean {
  return roles.indexOf(PrivilegedUserRoles.SuperAdmin) > -1
}

export function userIsExpert(interactions: IInteractions) {
  let expertLevel = null
  if (interactions.is_primary_expert || !_isEmpty(interactions.recommended_by)) {
    expertLevel = ExpertiseFeedbackExpertLevel.EXPERT
  } else if (interactions.is_expert) {
    expertLevel = ExpertiseFeedbackExpertLevel.INTEREST
  }
  return expertLevel
}

export function userHasInteracted(interactions: IInteractions) {
  return !!interactions.solutions_rated
      || !!interactions.solutions || interactions.has_rejected
      || !_isEmpty(interactions.has_recommended)
}
