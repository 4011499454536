import { ITrackingProps } from '@src/types/events.types'

enum Component {
  Question = 'question_for_you_module',
  RemoveQuestion = 'remove_question_for_you_module'
}

const clickProps = {
  element: 'button',
  type: 'click'
} as ITrackingProps

export default {
  QuestionItem: {
    cancelRemoveQuestionsForYou: (customProps: any): ITrackingProps => ({
      ...clickProps,
      component: Component.RemoveQuestion,
      questions_for_you_ranking: customProps.rank,
      question_id: customProps.id
    }),
    clickQuestion: (customProps: any): ITrackingProps => ({
      ...clickProps,
      component: Component.Question,
      questions_for_you_ranking: customProps.rank,
      question_id: customProps.id,
      is_auto_translated: customProps.isAutoTranslated
    }),
    clickRemoveQuestionsForYou: (customProps: any): ITrackingProps => ({
      ...clickProps,
      component: Component.RemoveQuestion,
      questions_for_you_ranking: customProps.rank,
      question_id: customProps.id
    }),
    submitRemoveQuestionsForYou: (customProps: any): ITrackingProps => ({
      ...clickProps,
      component: Component.Question,
      questions_for_you_ranking: customProps.rank,
      question_id: customProps.id,
      remove_option: customProps.reason
    })
  }
}
