// Generated by https://quicktype.io

import { AxiosPromise } from 'axios'
import { IUser } from './user.types'
import { IPaginatedApiResp } from './api.types'

export type INotificationsApiResp = IPaginatedApiResp<INotificationItem[]>;
export interface INotificationItem<NotificationUser = IUser> {
  content: string;
  created: string;
  icon: string;
  id: number;
  is_read: boolean;
  personal_message: null;
  question_id: number | null;
  target_id: number | null;
  target_type: NotificationTargetType;
  type: NotificationType;
  url: string;
  showAsPlainText?: boolean;
  user: NotificationUser | null;
  knowledge_space_name: string | null;
  v2?: boolean
}

export enum NotificationTargetType {
  Question = 'question',
  UserProfile = 'user_profile',
  KnowledgeSpace = 'knowledge_space',
  SingleSolutionUniqueViews = 'single_solution_unique_views'
}

export interface INotificationsCount {
  notifications: number;
}

export interface INotificationUpdateStatus {
  updated: number;
}

export interface INotificationsApi {
  getList(ignoreRead: boolean, offset: number, limit?: number): AxiosPromise<INotificationsApiResp>;
  getUnseenCount(): AxiosPromise<INotificationsCount>;
  markAllAsRead(): AxiosPromise<INotificationUpdateStatus>;
  markAsRead(id: number): AxiosPromise<INotificationUpdateStatus>;
  markAllAsSeen(): AxiosPromise<INotificationUpdateStatus>;
}

export enum NotificationType {
  NewCommentToSolution = 'new_comment_to_solution',
  NewCommentToYourSolution = 'new_comment_to_your_solution',
  NewCommentToQuestion = 'new_comment_to_question',
  NewCommentToYourQuestion = 'new_comment_to_your_question',
  NewSolution = 'new_solution',
  NewSolutionToYourQuestion = 'new_solution_to_your_question',
  TagSubscription = 'tag_subscription',
  QuestionForwarded = 'question_forwarded',
  ValidationConfirmation = 'validation_confirmation',
  ValidationRequest = 'validation_request',
  NewQuestion = 'new_question',
  NewQuestionRandomUser = 'new_question_random_user',
  SolutionRated = 'solution_rated',
  SolutionRatedByQuestionPoser = 'solution_rated_by_question_poser',
  SolutionMarkedOutdated = 'solution_marked_outdated',
  SolutionFromComment = 'solution_from_comment',
  QuestionEscalation = 'question_escalation',
  Validation = 'validation',
  ValidationToYourQuestion = 'validation_to_your_question',
  QuestionForwardedInactive = 'question_forwarded_inactive',
  NewUserScoreLevelReached = 'new_user_score_level_reached',
  NewUserScoreLevelReachedEmailHeadline = 'new_user_score_level_reached-email-headline',
  NewUserScoreLevelReachedText = 'new_user_score_level_reached-text',
  MentionInQuestion = 'mention_in_question',
  MentionInSolution = 'mention_in_solution',
  MentionInComment = 'mention_in_comment',
  ShareQuestionInApp = 'share_question-in-app',
  ShareSolutionInApp = 'share_solution-in-app',
  ShareQuestion = 'share_question',
  ShareSolution = 'share_solution',
  ValidationNewExpert = 'validation_new_expert',
  QuestionChangedToSolutionAuthor = 'question_changed_to_solution_author',
  SolutionChangedToQuestionPoser = 'solution_changed_to_question_poser',
  SolutionChangedToSolutionRater = 'solution_changed_to_solution_rater',
  FollowNewUserFollowsMe = 'follow_new_user_follows_me',
  FollowNewSolutionFromSubject = 'follow_new_solution_from_subject',
  FollowNewUserFollowsMeText = 'follow_new_user_follows_me-text',
  QuestionForwardedFollowup = 'question_forwarded-followup',
  NewQuestionFollowup = 'new_question-followup',
  NewQuestionNewUser = 'new_question_new_user',
  NewBadgeObtained = 'new_badge_obtained',
  NewKnowledgeSpaceMembershipRequest = 'new_knowledge_space_membership_request',
  KnowledgeSpaceMembershipGranted = 'knowledge_space_membership_granted',
  NewKudos = 'new_kudos'
}

export type IChannelNotifications = {
  [key in NotificationType]: boolean;
}

export interface INotificationChannelSettings {
  announcement_email_enabled?: boolean;
  digest_email_enabled?: boolean;
  notifications: Partial<IChannelNotifications>;
}

export interface INotificationsSettings {
  email: INotificationChannelSettings
  webPush: INotificationChannelSettings
}
