import { computed, toRefs } from '@vue/composition-api'
import useStore from '@src/store'
import { IUpdate } from '@src/types/featureUpdates'

export default function useWhatsNew() {
  const store = useStore()
  const {
    isWhatsNewDialogOpen
  } = toRefs(store.state.whatsNew)

  const closeWhatsNewDialog = () => store.dispatch('whatsNew/closeWhatsNewDialog')
  const markAsRead = () => store.dispatch('whatsNew/markAsRead')
  const loadMore = (entries: IntersectionObserverEntry, observer: IntersectionObserver, isIntersecting: boolean) => {
    if (isIntersecting) { store.dispatch('whatsNew/loadMore') }
  }
  const unreadCountString = computed<string>(() => store.getters['whatsNew/unreadCountString'])
  const updates = computed<IUpdate[]>(() => store.getters['whatsNew/updates'])

  return {
    isWhatsNewDialogOpen,
    closeWhatsNewDialog,
    markAsRead,
    loadMore,
    unreadCountString,
    updates
  }
}
