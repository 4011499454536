import {
  Ref,
  ref
} from '@vue/composition-api'

export default function(hasVerySimilarQuestion: Ref<boolean>) {
  const isShowingVerySimilarQuestionDoubleCheck = ref(false)

  function shouldShowVerySimilarQuestionDoublecheck() {
    if (hasVerySimilarQuestion.value) {
      isShowingVerySimilarQuestionDoubleCheck.value = !isShowingVerySimilarQuestionDoubleCheck.value
    }
    return isShowingVerySimilarQuestionDoubleCheck.value
  }
  return {
    isShowingVerySimilarQuestionDoubleCheck,
    shouldShowVerySimilarQuestionDoublecheck
  }
}
