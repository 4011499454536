import { sortingPreferenceApi } from '@src/api/apiModuleInstances'
import { NavigationGuardNext, Route } from 'vue-router'

export async function usePreferredSorting(to: Route, from: Route, next: NavigationGuardNext) {
  if (!to.query.sort) {
    try {
      const response = await sortingPreferenceApi.getSortingPreference()
      if (!response.data?.value) {
        next()
      } else {
        const modifiedNext = {
          ...to,
          name: to.name || undefined,
          query: {
            ...to.query,
            sort: response.data?.value || ''
          }
        }
        next(modifiedNext)
      }
    } catch (e) {
      next()
    }
  } else {
    next()
  }
}
