export enum InterfaceLanguages {
  DE = 'de',
  EN = 'en',
  ES = 'es',
  FR = 'fr',
  HR = 'hr',
  IT = 'it',
  PT = 'pt',
  RO = 'ro',
  ZH = 'zh'
}

export type InterfaceLanguage =
  InterfaceLanguages.DE |
  InterfaceLanguages.EN |
  InterfaceLanguages.ES |
  InterfaceLanguages.FR |
  InterfaceLanguages.HR |
  InterfaceLanguages.IT |
  InterfaceLanguages.PT |
  InterfaceLanguages.RO |
  InterfaceLanguages.ZH
