








import Vue from 'vue'

export default Vue.extend({
  name: 'SMSpinner',
  props: {
    size: {
      type: String as () => 'small' | 'medium' | 'large',
      default: 'medium'
    },
    color: {
      type: String,
      default: 'rgba(0,0,0,0.24)'
    }
  },
  computed: {
    styles(): { [key: string]: string } {
      return {
        borderTopColor: this.color
      }
    }
  }
})
