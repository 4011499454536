





























































import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { IUserGuideListItem } from '@src/store/modules/userGuideSidenav'
import SMAttributionsButton from '@src/ui/SMAttributionsButton/index.vue'
import SMTermsViewButton from '@src/ui/SMTermsViewButton/index.vue'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import SMUserGuideSidenavItem from './SMUserGuideSidenavItem.vue'

export default Vue.extend({
  name: 'SMUserGuideSidenav',
  components: {
    SMUserGuideSidenavItem,
    SMTermsViewButton,
    SMAttributionsButton
  },
  computed: {
    ...mapGetters('userGuideSidenav', [ 'getListItems', 'getIsDrawerOpen', 'getIsLoading' ]) as {
      getListItems: () => IUserGuideListItem[];
      getIsDrawerOpen: () => boolean;
      getIsLoading: () => boolean;
    },
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings | undefined;
    },
    navigationDrawerWidth(): string {
      return this.$vuetify.breakpoint.smAndUp ? '400' : '100%'
    },
    networkName(): string {
      return this.realmSettings?.metadata.realm_name || ''
    },
    isLoading(): boolean {
      return this.getIsLoading && !window.IS_ANGULAR_ROUTE
    }
  },
  mounted() {
    this.loadShouldDisplayStep()
  },
  methods: {
    ...mapMutations('userGuideSidenav', [ 'hideDrawer' ]) as {
      hideDrawer: () => void;
    },
    ...mapActions('activationFlow/terms', [ 'loadShouldDisplayStep' ]) as {
      loadShouldDisplayStep: () => void;
    },
    userGuideSidenavDrawerToggle(isUserGuideSidenavDrawerToggled: boolean): void {
      // handle the case when user closes drawer via backdrop
      if (!isUserGuideSidenavDrawerToggled) {
        this.hideDrawer()
      }
    }
  }
})
