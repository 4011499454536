import { ref } from '@vue/composition-api'

const isSidenavOpen = ref(false)

function toggleSidenav() {
  isSidenavOpen.value = !isSidenavOpen.value
}

export function useSidenavState() {
  return {
    isSidenavOpen,
    toggleSidenav
  }
}
