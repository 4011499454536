import { getApiTokenAsPromise } from '@src/helpers/cookie'
import EventSourcePolyfill from 'eventsource'
import * as Sentry from '@sentry/browser'

export type NotificationMessageHandler = (ev: MessageEvent) => void
const MAX_AMOUNT_OF_ERRORS = 6
let errorCount = 0

async function startNotificationConnection(messageHandler: NotificationMessageHandler, fallbackHandler: () => void): Promise<((() => void) | null)> {
  try {
    const apiToken = await getApiTokenAsPromise()
    const connection = new EventSourcePolyfill('/api/v1/notifications/unseen', {
      headers: { Authorization: `Bearer ${apiToken}` }
    })
    connection.onmessage = (ev): void => {
      if (ev.data) { // heartbeats
        messageHandler.apply(ev, [ ev ])
      }
    }
    connection.onerror = (err: any): void => {
      errorCount += 1
      if (errorCount > MAX_AMOUNT_OF_ERRORS) {
        connection.close()
        // fallback to interval in case of too many errors
        fallbackHandler()
        Sentry.captureMessage('SSE_FALLBACK_TO_INTERVAL', { level: 'warning' })
      }
      if (err?.message) {
        Sentry.captureException(new Error(err.message), (scope: any) => {
          scope.setTag('SSE_ERROR', true)
          return scope
        })
      }
    }
    const closeConnection = (): void => {
      connection.close()
    }
    return closeConnection
  } catch {
    Sentry.captureMessage('SSE_API_TOKEN_MISSING', { level: 'warning' })
    return (): null => null
  }
}

export default startNotificationConnection
