














import {
  defineComponent, ref, toRefs
} from '@vue/composition-api'

export default defineComponent({
  name: 'SMGifViewOnce',
  props: {
    gifSrc: {
      type: String,
      required: true
    },
    imgSrc: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      required: true
    },
    timeout: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const { timeout } = toRefs(props)
    const showGif = ref(false)

    function startGif() {
      showGif.value = true
      setTimeout(() => {
        showGif.value = false
        emit('gifHasFinished')
      }, timeout.value)
    }

    return {
      showGif,
      startGif
    }
  }
})
