import {
  ref, watch, Ref, computed
} from '@vue/composition-api'
import questionsApi from '@src/api/questions'
import { IUser } from '@src/types/user.types'
import useRealmsettings from '@src/composables/realmSettings'
import { IStandardizedConceptSingleLanguage } from '@src/types/concept.types'

export default function useConceptualExpertSearch(
  questionId: Ref<number | undefined>,
  concepts: Ref<IStandardizedConceptSingleLanguage[]>
) {
  const { realmSettings } = useRealmsettings()
  const expertsEnabled = computed(() => realmSettings.value?.expert_search.show_expert_users_enabled)

  const conceptualExperts = ref<IUser[]>([])
  const getExperts = async () => {
    if (questionId.value && expertsEnabled.value) {
      const { data } = await questionsApi.getQuestionExperts(questionId.value)
      conceptualExperts.value = data
    }
  }

  watch(concepts, () => getExperts())

  return {
    conceptualExperts
  }
}
