import { IRootState } from '@src/types/store.types'
import { MutationTree, Module, GetterTree } from 'vuex'

export enum LogQueryTypeParam {
  MANUAL = 'manual',
  AUTOCOMPLETE = 'autocomplete',
  URL = 'url'
}

export interface ISearchV2QuestionLogQueryTypeState {
  lastQueryType: LogQueryTypeParam
}

const state: ISearchV2QuestionLogQueryTypeState = {
  lastQueryType: LogQueryTypeParam.URL // this is the default
}

const mutations: MutationTree<ISearchV2QuestionLogQueryTypeState> = {
  setLastQueryType(state, lastQueryType: LogQueryTypeParam) { state.lastQueryType = lastQueryType }
}

const getters: GetterTree<ISearchV2QuestionLogQueryTypeState, IRootState> = {
  lastQueryType(state) {
    return state.lastQueryType
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters
} as Module<ISearchV2QuestionLogQueryTypeState, IRootState>
