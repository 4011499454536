import { isAuthenticated } from '@src/utilities/auth'
import awaitGetterValue from '@src/utilities/storeUtils'
import { NavigationGuard } from 'vue-router'

const roleBasedNavigationGuard: NavigationGuard = async (to, from, next) => {
  if (!isAuthenticated()) { next() }

  const userRoles = await awaitGetterValue<string[]>('user/getRoles')
  const requiredRoles: string[] = to.matched
    .map((matchedRoute) => matchedRoute.meta.requiredRole)
    .filter((role) => !!role) // filter out undefined values

  if (requiredRoles.every((requiredRole) => userRoles.includes(requiredRole))) {
    next()
  } else {
    next('/error/403')
  }
}

export default roleBasedNavigationGuard
