import axios, { AxiosPromise } from 'axios'
import * as Sentry from '@sentry/browser'
import { IFeatureToggles } from '@src/types/featureToggles.types'

interface IUnleashApiEnabled {
  get(userTrackingId: string, networkId: string, language: string): AxiosPromise<IFeatureToggles>;
}

interface IUnleashApiDisabled {
  get(userTrackingId?: string, networkId?: string): Promise<{ data: { toggles: [] } }>;
}

const appName = 'Pegasus'
const isEnabled = window.UNLEASH_ENABLED
const baseURL = window.UNLEASH_ENDPOINT
const apiKey = window.UNLEASH_TOKEN
const clusterName = window.CLUSTER_NAME || undefined

// if Unleash is enabled but url and/or apiKey are not defined then send exception to sentry
if (isEnabled && (!baseURL || !apiKey)) {
  Sentry.captureException(new Error('UNLEASH_TOKEN and/or UNLEASH_ENDPOINT not defined'), (scope: any) => {
    scope.setTag('feature_toggles', true)
    return scope
  })
}
const api = axios.create({ baseURL })

const unleashApiEnabled: IUnleashApiEnabled = {
  get(userTrackingId, networkId, language) {
    return api.get('', {
      params: {
        userId: userTrackingId,
        realmId: networkId,
        language,
        appName,
        clusterName
      },
      headers: {
        Authorization: apiKey as string,
        'Kong-Debug': '1'
      }
    })
  }
}

const unleashApiDisabled: IUnleashApiDisabled = {
  async get() {
    return { data: { toggles: [] } }
  }
}

export default (isEnabled && baseURL && apiKey) ? unleashApiEnabled : unleashApiDisabled
