import { ITrackingProps } from '@src/types/events.types'

const commonProps = {
}

export const MENTION_USER = 'mentionUser'

export default {
  mentionUser: {
    [MENTION_USER]: (
      customProps:
      {
        type: 'inline'|'dialog';
        entity: 'question' | 'answer' | 'comment';
        state: 'ask' | 'question';
      }
    ): ITrackingProps => ({
      ...commonProps,
      ...customProps
    })
  }
}
