import { toRefs } from '@vue/composition-api'
import useStore from '@src/store'
import { IOpenAskDialogParams } from '@src/store/modules/ask'
import { IframeEnvironment, getCurrentWindowEnvironment } from '@src/utilities/iframeDetection'
import { useRouter } from './router'

export default function useAskDialog() {
  const store = useStore()
  const router = useRouter()

  const {
    isAskDialogOpen, isEditDialogOpen, currentDraftQuestionId, existingQuestionId, trackingClickAskId, initialQuestionTitle
  } = toRefs(store.state.ask)

  const openAskDialog = (params: IOpenAskDialogParams) => {
    if (getCurrentWindowEnvironment() === IframeEnvironment.ANGULAR_IFRAME) {
      // We need to navigate outside of the Angular iframe, because the ask dialog is not mounted into the Angular stack

      // Why? As the Vuex store state is synced between the frames, the ask dialog would open in both frames and
      // event would be tracked twice. There's a lot of undesired behaviour if it opens twice.

      // Another workaround would be to use the Pinia store for this as its state is isolated per frame,
      // but as we don't even Vue.use Pinia in the Angular stack, it doesn't work as well.
      router.push('/')
    }
    store.dispatch('ask/openAskDialog', params)
  }
  const openEditDialog = (questionId: number) => store.dispatch('ask/openEditDialog', questionId)
  const closeAskDialog = () => store.commit('ask/closeAskDialog')
  const closeEditDialog = () => store.commit('ask/closeEditDialog')
  const removeClickAskId = () => store.commit('ask/removeClickAskId')
  const createClickAskId = () => store.dispatch('ask/createClickAskId')
  const resetCurrentDraftQuestionId = () => store.commit('ask/resetCurrentDraftQuestionId')

  return {
    currentDraftQuestionId,
    existingQuestionId,
    isAskDialogOpen,
    isEditDialogOpen,
    trackingClickAskId,
    initialQuestionTitle,
    openAskDialog,
    openEditDialog,
    closeAskDialog,
    closeEditDialog,
    createClickAskId,
    removeClickAskId,
    resetCurrentDraftQuestionId
  }
}
