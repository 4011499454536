// copied from vue2-helpers since the getCurrentInstance approach was not reactive(https://github.com/vuejs/composition-api/issues/809) https://github.com/ambit-tsai/vue2-helpers/blob/for-vue-2.6/src/vue-router.ts
import {
  getCurrentInstance,
  effectScope,
  reactive
} from '@vue/composition-api'
import VueRouter, {
  Route,
  NavigationGuard,
  RouterOptions as RawRouterOptions,
  RouteConfig as RouteRecordRaw
} from 'vue-router'
import Vue from 'vue'

export type { NavigationGuard, RouteRecordRaw }
export type RouteLocationNormalized = Route;
export type RouteLocationNormalizedLoaded = Route;
export type RouteRecordName = string | symbol;

/*
* This composable always returns the router in the correct mode, 'abstract' in custom elements and 'history' for vue native bundle.
* When upgrading to Vue 3, useRouter can directly be imported from vue-router and it's not necessary anymore to provide it using provide/inject.
*/

export const { warn } = console

export const OUT_OF_SCOPE = 'method can only be used inside setup() or functional components'

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface RouterOptions extends RawRouterOptions {
    routes: RouteRecordRaw[]
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface Router extends VueRouter {
    isReady(): Promise<void>;

    /** @deprecated */
    app: VueRouter['app'];

    /** @deprecated use `currentRoute.matched` instead */
    getMatchedComponents: VueRouter['getMatchedComponents'];

    /** @deprecated use `isReady` instead */
    onReady: VueRouter['onReady'];
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
VueRouter.prototype.isReady = function() {
  return new Promise((resolve, reject) => {
    this.onReady(resolve as () => void, reject)
  })
}

export function createRouter(options: RouterOptions) {
  Vue.use(VueRouter)
  return new VueRouter(options) as Router
}

export function useRouter(): Router {
  const inst = getCurrentInstance()
  if (inst) {
    return inst.proxy.$router as Router
  }
  warn(OUT_OF_SCOPE)
  return undefined as any
}

let currentRoute: Route

export function useRoute(): RouteLocationNormalizedLoaded {
  const inst = getCurrentInstance()
  if (!inst) {
    warn(OUT_OF_SCOPE)
    return undefined as any
  }
  if (!currentRoute) {
    const scope = effectScope(true)
    scope.run(() => {
      const { $router } = inst.proxy
      currentRoute = reactive(assign({}, $router.currentRoute)) as any
      $router.afterEach((to) => {
        assign(currentRoute, to)
      })
    })
  }
  return currentRoute
}

function assign(target: Record<string, any>, source: Record<string, any>) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(source)) {
    // eslint-disable-next-line no-param-reassign
    target[key] = source[key]
  }
  return target
}

export function onBeforeRouteLeave(leaveGuard: NavigationGuard) {
  const inst = getCurrentInstance()
  if (!inst) {
    warn(OUT_OF_SCOPE)
    return
  }
  const { options } = inst.proxy.constructor as any
  const hooks: any = options.beforeRouteLeave || []
  hooks.push(leaveGuard)
  options.beforeRouteLeave = hooks
}

export function onBeforeRouteUpdate(updateGuard: NavigationGuard) {
  const inst = getCurrentInstance()
  if (!inst) {
    warn(OUT_OF_SCOPE)
    return
  }
  const { options } = inst.proxy.constructor as any
  const hooks: any = options.beforeRouteUpdate || []
  hooks.push(updateGuard)
  options.beforeRouteUpdate = hooks
}
