















































































































import {
  defineComponent, ref, toRefs, PropType, watch, computed
} from '@vue/composition-api'
import SMDialog from '@src/ui/SMDialog/index.vue'
import { IUser } from '@src/types/user.types'
import useUserChip from '@src/ui/SMSocialDialog/composables/useUserChip'
import useUserSuggestions from '@src/ui/SMSocialDialog/composables/useUserSuggestions'
import _take from 'lodash/take'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import useSocialDialogTracking from './composables/useSocialDialogTracking'
import { UserChip, UserSuggestionType } from './types'

export default defineComponent({
  name: 'SMSocialDialog',
  components: { SMDialog },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    knowledgeSpace: {
      type: Object as PropType<IKnowledgeSpace | null | undefined>,
      default: undefined
    },
    userSuggestionType: {
      type: String as PropType<UserSuggestionType>,
      required: true
    }
  },
  setup(props) {
    const {
      value, knowledgeSpace, userSuggestionType
    } = toRefs(props)
    const message = ref<string>('')
    const selected = ref<UserChip[]>([])

    const filteredSuggestions = computed<IUser[]>(() => _take(suggestions.value.filter((user) => selected.value.indexOf(user) < 0), 3))
    const domain = computed(() => (validDomains.value.length > 1 ? validDomains.value.join(', ') : validDomains.value[0]))

    watch(value, (newValue) => {
      if (!newValue) {
        selected.value = []
        message.value = ''
      }
    })

    function trackUserAddition(newList: UserChip[]) {
      if (newList.length > selected.value.length) {
        const addedItem = newList[newList.length - 1]
        trackUserSelection(!!(addedItem as IUser).id)
      }
    }

    const {
      getChipLabel,
      getChipAvatar,
      filterInvalidEmails,
      getDataCy,
      inputLabel,
      validDomains
    } = useUserChip(userSuggestionType.value === UserSuggestionType.NoSuggestions)

    const {
      trackUserSelection,
      trackUserRemoval
    } = useSocialDialogTracking()

    const {
      suggestions,
      handleInputChange,
      searchInput
    } = useUserSuggestions(userSuggestionType, knowledgeSpace)

    return {
      selected,
      filteredSuggestions,
      message,
      getChipLabel,
      getChipAvatar,
      filterInvalidEmails,
      trackUserSelection,
      trackUserRemoval,
      trackUserAddition,
      getDataCy,
      inputLabel,
      handleInputChange,
      searchInput,
      validDomains,
      domain
    }
  }
})
