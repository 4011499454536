import { computed } from '@vue/composition-api'
import useStore from '@src/store'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'

export default function useRealmSettings() {
  const store = useStore()
  const realmSettings = computed<IUnauthenticatedRealmSettings | undefined>(() => store.getters['realmSettings/realmSettings'])
  const realmSettingsLoading = computed<boolean>(() => store.getters['realmSettings/settingsLoading'])
  const loginUrl = computed<string | undefined>(() => store.getters['realmSettings/getLoginUrl'])
  const isWhatsNewEnabled = computed<boolean>(() => store.getters['realmSettings/isWhatsNewEnabled'])

  return {
    realmSettings,
    realmSettingsLoading,
    loginUrl,
    isWhatsNewEnabled
  }
}
