














































































import { defineComponent, ref } from '@vue/composition-api'
import SMDialog from '@src/ui/SMDialog/index.vue'
import feedbackApi from '@src/api/feedback'
import { FeedbackType } from '@src/types/feedback.types'
import FeedbackSent from './FeedbackSent.vue'

export default defineComponent({
  name: 'FeatureFeedbackDialog',
  components: {
    SMDialog,
    FeedbackSent
  },
  setup(props, { emit }) {
    const isOpen = ref(false)
    const showFeedbackSent = ref(false)
    const feedbackMessage = ref<string>('')

    async function onSubmit() {
      await feedbackApi.sendFeedback({
        description: feedbackMessage.value,
        feedback_type: FeedbackType.PRODUCT
      })
      emit('submit', feedbackMessage.value)
      feedbackMessage.value = ''
      showFeedbackSent.value = true
    }

    return {
      isOpen,
      showFeedbackSent,
      feedbackMessage,
      onSubmit
    }
  }
})
