











import { computed, defineComponent } from '@vue/composition-api'
import useWhatsNew from '@src/composables/whatsNew'
import SMWhatsNewCard from '../SMWhatsNewCard/index.vue'

export default defineComponent({
  name: 'SMWhatsNewDialog',
  components: {
    SMWhatsNewCard
  },
  setup() {
    const {
      isWhatsNewDialogOpen, closeWhatsNewDialog, markAsRead
    } = useWhatsNew()

    const isDialogOpen = computed<boolean>({
      get() {
        return isWhatsNewDialogOpen.value
      },
      set(value) {
        if (!value) {
          closeWhatsNewDialog()
          markAsRead()
        }
      }
    })

    return {
      isDialogOpen
    }
  }
})
