





































import Vue, { PropType } from 'vue'
import { isUrl } from '@src/utilities/validations'

export default Vue.extend({
  name: 'SMImageInputUrl',
  props: {
    setImagePath: {
      type: Function as PropType<(imagePath: string, delay?: number) => void>,
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      url: ''
    }
  },
  computed: {
    rules(): ((value: string) => string | true)[] {
      return [
        (value: string): true | string => !!value || this.$t('tiptap.toolbar.required')?.toString(),
        (value: string): true | string => isUrl(value) || this.$t('tiptap.toolbar.link.invalid-url')?.toString()
      ]
    },
    isValidUrl(): boolean {
      return !!this.url && isUrl(this.url)
    }
  },
  watch: {
    isOpen(isOpen: boolean): void {
      if (!isOpen) {
        (this.$refs.form as HTMLFormElement).reset()
      }
    }
  }
})
