import { defineStore, storeToRefs } from 'pinia'
import {
  checkListBodies, checkListButtonLabels,
  checkListTitles, IOnboardingChecklist,
  IOnboardingChecklistInfo,
  IOnboardingChecklistItem,
  OnboardingChecklistItems
} from '@src/types/onboardingChecklist.types'
import {
  ref, computed, reactive
} from '@vue/composition-api'
import { useHomepageEditPanel } from '@src/pinia/homepageEditPanel'
import { Router } from '@src/composables/router'
import { Route } from 'vue-router'
import useStore from '@src/store'
import { useFeatureToggles } from '@src/composables/featureToggles'
import useRealmSettings from '@src/composables/realmSettings'
import { IOnboardingChecklistItemComplete } from '@src/utilities/onboardingChecklist'
import { onboardingChecklistApi, onboardingDialogApi } from '@src/api/apiModuleInstances'

export const useOnboardingChecklist = defineStore('onboardingChecklist', () => {
  const onboardingChecklistInfo = computed<IOnboardingChecklistInfo>(() => rawChecklistData.info)
  const checklistManuallyOpened = ref<boolean>(false)
  const rawChecklistData = reactive<IOnboardingChecklist>({
    checklist_items: [],
    info: {
      is_finished: false,
      user_has_seen: false
    }
  })
  const loading = ref<boolean>(true)
  const menu = ref(false)
  const dialogIsCompleted = ref(false)
  const { realmSettings } = useRealmSettings()
  const homepageEnabled = computed(() => realmSettings.value?.homepage.enabled)
  const { llmConversationsEnabled, userProfileV2Enabled } = useFeatureToggles()
  const { isVisible: isHomepageEditPanelVisible } = storeToRefs(useHomepageEditPanel())

  function findFirstNotCompletedIndex() {
    return onboardingChecklistItems.value.findIndex((item) => {
      return !item.completed
    })
  }

  async function loadOnboardingChecklist() {
    try {
      loading.value = true
      const { data } = await onboardingChecklistApi.getOnboardingChecklist()
      const { data: { completed_date } } = await onboardingDialogApi.throttled.getOnboardingDialogStatus()
      dialogIsCompleted.value = completed_date !== null
      rawChecklistData.info = data.info
      rawChecklistData.checklist_items = data.checklist_items
      currentActiveItemIndex.value = findFirstNotCompletedIndex()
    } finally {
      loading.value = false
    }
  }

  const onboardingChecklistItems = computed<IOnboardingChecklistItemComplete[]>(() => rawChecklistData.checklist_items
    .filter((item) => {
      return (
        item.checklist_item !== OnboardingChecklistItems.ASK_STARGPT || llmConversationsEnabled.value) && (
        item.checklist_item !== OnboardingChecklistItems.CUSTOMISE_HOME || homepageEnabled.value)
    })
    .map((item) => setChecklistItemComplete(item)) || [])

  const currentActiveItemIndex = ref(0)

  const menuModel = computed({
    get() {
      if (checklistManuallyOpened.value) {
        return true
      }
      if (!onboardingChecklistInfo.value.user_has_seen && dialogIsCompleted.value) {
        return !menu.value && !loading.value
      }
      return menu.value
    },
    set(value: boolean) {
      if (checklistManuallyOpened.value) {
        checklistManuallyOpened.value = false
      }
      if (!onboardingChecklistInfo.value.user_has_seen && dialogIsCompleted.value) {
        menu.value = !value
      } else {
        menu.value = value
      }
    }
  })
  const completedItems = computed(() => onboardingChecklistItems.value.filter((item) => item.completed))
  const progress = computed(() => {
    return (completedItems.value.length / onboardingChecklistItems.value.length) * 100
  })
  const readyToFinish = computed(() => progress.value === 100)

  const store = useStore()

  function setDialogAsComplete() {
    dialogIsCompleted.value = true
  }
  function setChecklistItemComplete(item: IOnboardingChecklistItem): IOnboardingChecklistItemComplete {
    return {
      checklist_item: item.checklist_item,
      title: checkListTitles[item.checklist_item],
      body: checkListBodies[item.checklist_item],
      button: checkListButtonLabels[item.checklist_item],
      action: (router: Router, route: Route, emit: (event: string, ...args: any[]) => void) => {
        if (item.checklist_item === OnboardingChecklistItems.ADD_TOPICS) {
          router.push({ path: '/user' })
        } else if (item.checklist_item === OnboardingChecklistItems.TAKE_PRODUCT_TOUR) {
          store.dispatch('activationFlow/tooltips/startOnboardingTooltipsManually', undefined, { root: true })
        } else if (item.checklist_item === OnboardingChecklistItems.ADD_PROFILE_PICTURE) {
          if (userProfileV2Enabled.value) {
            router.push({ path: '/user/?tooltip=1' })
          } else {
            router.push({ path: '/user/edit/?tooltip=1' })
          }
        } else if (item.checklist_item === OnboardingChecklistItems.UNDERSTAND_ANSWERS) {
          emit('openPrototype')
        } else if (item.checklist_item === OnboardingChecklistItems.ASK_STARGPT) {
          router.push({ path: '/conversation/?tooltip=1' })
        } else if (item.checklist_item === OnboardingChecklistItems.CUSTOMISE_HOME) {
          if (route.path !== '/') {
            router.push({ path: '/' })
          }
          isHomepageEditPanelVisible.value = true
        }
        menuModel.value = false
      },
      completed: item.completed
    }
  }

  async function updateChecklist(updatedChecklistItem?: IOnboardingChecklistItemComplete, isFinished = false) {
    const onboardingChecklist = {
      info: {
        is_finished: isFinished,
        user_has_seen: true
      },
      checklist_items: onboardingChecklistItems.value.map((item) => {
        return {
          checklist_item: item.checklist_item,
          completed: item.checklist_item === updatedChecklistItem?.checklist_item ? true : item.completed
        }
      })
    }

    loading.value = true
    try {
      const { data } = await onboardingChecklistApi.saveOnboardingChecklist(onboardingChecklist)
      rawChecklistData.checklist_items = data.checklist_items
      rawChecklistData.info = data.info
      currentActiveItemIndex.value = findFirstNotCompletedIndex()
    } finally {
      loading.value = false
    }

    menuModel.value = false
  }

  return {
    onboardingChecklistItems,
    onboardingChecklistInfo,
    currentActiveItemIndex,
    checklistManuallyOpened,
    loading,
    progress,
    readyToFinish,
    menu,
    menuModel,
    rawChecklistData,
    setDialogAsComplete,
    updateChecklist,
    loadOnboardingChecklist
  }
})
