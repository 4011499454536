import {
  GetterTree, MutationTree, ActionTree, Module
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { ICategory, translateCategory } from '@src/types/category.types'
import categoriesApi from '@src/api/categories'

export interface ICategoriesState {
  categories: ICategory[];
  loading: boolean;
  error: boolean;
}

const state: ICategoriesState = {
  categories: [],
  loading: false,
  error: false
}

const getters: GetterTree<ICategoriesState, IRootState> = {
  getCategories(state) {
    return state.categories
  },
  getCategoriesTranslated(state, localGetters, rootState, rootGetters) {
    const currentLanguage: string = rootGetters['language/getCurrentLanguage']
    return state.categories.map((category) => ({
      ...category,
      name: translateCategory(category, currentLanguage)
    }))
  }
}

const actions: ActionTree<ICategoriesState, IRootState> = {
  async loadCategories({ commit }): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { data: { items: categories } } = await categoriesApi.throttled.getCategories()
      commit('setCategories', categories)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations: MutationTree<ICategoriesState> = {
  setLoading: (state: ICategoriesState, loading: boolean): void => {
    state.loading = loading
  },
  setError: (state: ICategoriesState, error: boolean): void => {
    state.error = error
  },
  setCategories: (state: ICategoriesState, categories: ICategory[]): void => {
    state.categories = categories
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<ICategoriesState, IRootState>
