import {
  ApolloClient, ApolloLink, createHttpLink, InMemoryCache
} from '@apollo/client'
import { ContextSetter, setContext } from '@apollo/client/link/context'
import { ISocialLink } from '../../../types/user.types'

export function apolloClientFactory(authContextSetter: ContextSetter) {
  const authLink = setContext(authContextSetter)

  const httpLink = createHttpLink({
    uri: '/accounts/api/v1/graphql',
    fetch
  })

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const omitTypename = (key: string, value: any) => (key === '__typename' ? undefined : value)
      // eslint-disable-next-line no-param-reassign
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }
    return forward(operation).map((data) => {
      return data
    })
  })

  return new ApolloClient({
    link: authLink.concat(cleanTypeName).concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          // Apollo 3 uses requires a distinct field (default "id" or "_id") to merge cached objects.
          // We need to make sure the field "globalUserId" is present in all query responses.
          keyFields: [ 'globalUserId' ],
          fields: {
            socialLinks: {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              merge(_existing: ISocialLink[] = [], incoming: ISocialLink[] = []) {
                return incoming
              }
            }
          }
        }
      }
    })
  })
}
