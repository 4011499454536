



























import SMJobToSkillWizard from '@src/views/JobToSkillWizard/index.vue'
import { defineComponent } from '@vue/composition-api'
import SMDialog from '@src/ui/SMDialog/index.vue'
import { useJobWizard } from '../composables/useJobWizard'

export default defineComponent({
  name: 'SMActivationFlowJobWizardDialog',
  components: {
    SMJobToSkillWizard,
    SMDialog
  },
  setup() {
    const {
      currentStep,
      isDialogVisible,
      isLoadingSkills,
      isLoadingUser,
      skills,
      jobTitle,
      setJobTitle,
      getSkills,
      stepCompleted
    } = useJobWizard()

    return {
      currentStep,
      isDialogVisible,
      isLoadingSkills,
      isLoadingUser,
      skills,
      jobTitle,
      setJobTitle,
      getSkills,
      stepCompleted
    }
  }
})
