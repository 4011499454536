



















import { defineComponent } from '@vue/composition-api'
import useAskDialog from '@src/composables/askDialog'
import { createOnboardingTooltipDirective } from '@src/ui/SMOnboardingTooltip/directives/onboardingTooltip'

export default defineComponent({
  name: 'SMAskButton',
  directives: {
    onboardingTooltip: createOnboardingTooltipDirective()
  },
  setup() {
    const { openAskDialog } = useAskDialog()

    return {
      openAskDialog
    }
  }
})
