import { AxiosInstance, AxiosPromise } from 'axios'
import {
  IQuestion,
  IQuestionExpert,
  IQuestionComplete,
  IQuestionSearchResponse,
  IQuestionPayload,
  IQuestionFollowStatus,
  IQuestionFlaggingStatus,
  IInteractions,
  IRelatedQuestionResponse
} from '../../../types/question.types'
import { ISolutionFlat } from '../../../types/solution.types'
import { ValidationPeriodId } from '../../../types/realmSettings.types'
import { IAttachment } from '../../../types/attachments.types'
import { ICommentComplete } from '../../../types/comment.types'
import { IInvolvedUsers } from '../../../types/involvedUsers.types'
import { createOptimizedAPI } from '../utils'
import { IUser } from '../../../types/user.types'
import { FilterValues, SortingFilterValues } from '../../../types/filters.types'

export interface IQuestionLastValidationResponse {
  last_requested: string;
}

export interface ISolutionDraftResponse {
  activities:{
    author_id: number;
    last_update: string;
  }[]
}

type QuestionPublishSource = 'stargpt'

export function questionsApiFactory(questionsApi: AxiosInstance) {
  const baseUrl = '/api/v1/questions'
  const methods = {
    getQuestion<CompleteOptionalBoolean extends boolean>(questionId: number, complete: CompleteOptionalBoolean) {
      const completePath = complete ? '/complete' : ''
      return questionsApi.get<CompleteOptionalBoolean extends true ? IQuestionComplete : IQuestion>(`${baseUrl}/${questionId}${completePath}`)
    },
    getQuestionExperts(questionId: number) {
      return questionsApi.get<IUser[]>(`${baseUrl}/${questionId}/draft/experts`)
    },
    getQuestionExpert(questionId: number, userId: number) {
      return questionsApi.get<IQuestionExpert>(`${baseUrl}/${questionId}/experts/${userId}`)
    },
    getLastValidationRequest(questionId: number) {
      return questionsApi.get<IQuestionLastValidationResponse>(`${baseUrl}/${questionId}/request-validation`)
    },
    requestValidation(questionId: number) {
      return questionsApi.put<IQuestionLastValidationResponse>(`${baseUrl}/${questionId}/request-validation`)
    },
    getInvolvedUsers(questionId: number) {
      return questionsApi.get<IInvolvedUsers>(`${baseUrl}/${questionId}/involved-users`)
    },
    getQuestions({
      query, sort, filter, include_archived, limit = 5
    }: {
      query?: string,
      sort?: SortingFilterValues[];
      filter?: FilterValues[],
      limit?: number,
      include_archived?: boolean
    }) {
      return questionsApi.get<IQuestionSearchResponse>(baseUrl, {
        params: {
          limit,
          query,
          sort: sort?.join(','),
          filter: filter?.join(','),
          include_archived
        }
      })
    },
    createQuestion(question: IQuestionPayload) {
      return questionsApi.post<IQuestion>(baseUrl, question)
    },
    updateQuestion(id: number, question: IQuestionPayload) {
      return questionsApi.put<IQuestion>(`${baseUrl}/${id}`, question)
    },
    publishQuestion(questionId: number, source?: QuestionPublishSource) {
      return questionsApi.put<{ number_of_experts: number }>(`${baseUrl}/${questionId}/publish`, undefined, {
        params: { source }
      })
    },
    upvote(questionId: number) {
      return questionsApi.post<void>(`${baseUrl}/${questionId}/upvote`)
    },
    downvote(questionId: number) {
      return questionsApi.post<void>(`${baseUrl}/${questionId}/downvote`)
    },
    withdrawUpvote(questionId: number) {
      return questionsApi.delete<void>(`${baseUrl}/${questionId}/upvote`)
    },
    withdrawDownvote(questionId: number) {
      return questionsApi.delete<void>(`${baseUrl}/${questionId}/downvote`)
    },
    getFollowStatus(questionId: number) {
      return questionsApi.get<IQuestionFollowStatus>(`${baseUrl}/${questionId}/follow`)
    },
    follow(questionId: number) {
      return questionsApi.post<IQuestionFollowStatus>(`${baseUrl}/${questionId}/follow`)
    },
    unfollow(questionId: number) {
      return questionsApi.delete<IQuestionFollowStatus>(`${baseUrl}/${questionId}/follow`)
    },
    deleteQuestion(questionId: number) {
      return questionsApi.delete<void>(`${baseUrl}/${questionId}`)
    },
    getFlaggingStatus(questionId: number) {
      return questionsApi.get<IQuestionFlaggingStatus>(`${baseUrl}/${questionId}/flag`)
    },
    flag(questionId: number, message: string) {
      return questionsApi.put<IQuestionFlaggingStatus>(`${baseUrl}/${questionId}/flag`, { message })
    },
    getDraft(questionId: number) {
      return questionsApi.get<ISolutionFlat | void>(`${baseUrl}/${questionId}/solutions/draft`)
    },
    createSolutionDraft(questionId: number, payload: { description: string, validity_period?: ValidationPeriodId}) {
      return questionsApi.post<ISolutionFlat>(`${baseUrl}/${questionId}/solutions`, payload)
    },
    rejectExpertise(questionId: number) {
      return questionsApi.put<void>(`${baseUrl}/${questionId}/user-expertise-feedback`, {
        feedback_type: 'negative'
      })
    },
    acceptExpertise(questionId: number) {
      return questionsApi.put<void>(`${baseUrl}/${questionId}/user-expertise-feedback`, {
        feedback_type: 'positive'
      })
    },
    getInteractions(questionId: number) {
      return questionsApi.get<IInteractions>(`${baseUrl}/${questionId}/interactions`)
    },
    getSimilarQuestionsById(questionId: number, limit = 3) {
      return questionsApi.get<IRelatedQuestionResponse>(`${baseUrl}/${questionId}/similar`, {
        params: {
          limit
        }
      })
    },
    getTrendingQuestions() {
      return questionsApi.get<{ items: IQuestion[] }>(baseUrl, {
        params: {
          limit: 3,
          filter: 'trending'
        }
      })
    },
    getConceptQuestions(concept: string) {
      return questionsApi.get<{ items: IQuestion[] }>(baseUrl, {
        params: {
          limit: 3,
          filter: 'unsolved',
          concepts: concept
        }
      })
    },
    getAttachments(questionId: number) {
      return questionsApi.get<IAttachment[]>(`${baseUrl}/${questionId}/attachments`)
    },
    forward(questionId: number, userId: number, message: string): AxiosPromise<void> {
      return questionsApi.post<void>(`${baseUrl}/${questionId}/forward`, {
        recipient: userId,
        message
      })
    },
    share(questionId: number, userIds: number[], message: string) {
      return questionsApi.post<void>(`${baseUrl}/${questionId}/share`, {
        recipients: userIds,
        message
      })
    },
    createComment(questionId: number, description: string): AxiosPromise<ICommentComplete> {
      return questionsApi.post<ICommentComplete>(`${baseUrl}/${questionId}/comments`, {
        description
      })
    },
    registerView(questionId: number) {
      return questionsApi.post<void>(`${baseUrl}/${questionId}/view`)
    }
  }
  return createOptimizedAPI(methods)
}
