import useTracking from '@src/composables/tracking/tracking'

export function useFeedbackTracking() {
  const { trackMixpanelEvent } = useTracking()

  function submitFeedback() {
    trackMixpanelEvent('submitFeedback', {
      component: 'feedback_dialog',
      element: 'button',
      type: 'click',
      entity: 'navigation',
      is_empty: false
    })
  }

  function viewFeedback() {
    trackMixpanelEvent('viewFeedback', {
      type: 'view'
    })
  }

  return {
    submitFeedback,
    viewFeedback
  }
}
