import {
  ref, SetupContext
} from '@vue/composition-api'
import _pickBy from 'lodash/pickBy'
import _mapValues from 'lodash/mapValues'
import questionApi from '@src/api/questions'
import { useRouter } from '@src/composables/router'
import { IQuestionPayload } from '@src/types/question.types'
import useAskDialog from '@src/composables/askDialog'

export default function useSave({ emit }: SetupContext) {
  const router = useRouter()
  const { resetCurrentDraftQuestionId } = useAskDialog()
  const isLoading = ref(false)

  function closeDialogAndRedirect(questionId: number, questionContent: IQuestionPayload, isNewQuestion: boolean) {
    resetCurrentDraftQuestionId()
    emit('close')
    const queryParams = _mapValues(_pickBy({
      space: questionContent.knowledge_space,
      isUpdatedQuestion: !isNewQuestion ? new Date().getTime() : undefined
    }) as { [key: string]: number | boolean}, (value) => value.toString())
    router.push({
      name: 'question',
      query: queryParams,
      params: {
        id: questionId.toString(),
        isNewQuestion: isNewQuestion.toString()
      }
    })
  }

  async function updateExistingQuestion(questionId: number, questionContent: IQuestionPayload) {
    try {
      isLoading.value = true
      await questionApi.updateQuestion(questionId, questionContent)
      closeDialogAndRedirect(questionId, questionContent, false)
    } finally {
      isLoading.value = false
    }
  }

  async function publishNewQuestion(questionId: number | undefined, questionContent: IQuestionPayload) {
    if (questionId) {
      try {
        isLoading.value = true
        await questionApi.updateQuestion(questionId, questionContent)
        await questionApi.publishQuestion(questionId)
        closeDialogAndRedirect(questionId, questionContent, true)
      } finally {
        isLoading.value = false
      }
    }
  }

  return {
    updateExistingQuestion,
    publishNewQuestion,
    isLoading
  }
}
