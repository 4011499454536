import { ITrackingProps } from '@src/types/events.types'

export const FOLLOW_USER_CLICK = 'clickFollowUser'
export const UNFOLLOW_USER_CLICK = 'clickUnfollowUser'

const commonProps = {
  component: 'personal_information_card',
  element: 'button',
  state: 'user_profile',
  type: 'click'
} as ITrackingProps

export default {
  SMFollowUser: {
    [FOLLOW_USER_CLICK]: (): ITrackingProps => ({
      ...commonProps
    }),
    [UNFOLLOW_USER_CLICK]: (): ITrackingProps => ({
      ...commonProps
    })
  }
}
