import { Store, MutationPayload } from 'vuex'
import { IRootState } from '@src/types/store.types'
import { ISuperproperties } from '@src/types/superproperties.types'
import { IUser } from '@src/types/user.types'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import isEmpty from 'lodash/isEmpty'
import once from 'lodash/once'
import userApi from '@src/api/user'

/**
 * Subscribe to the store and wait for the user, realm settings and super properties to be set
 */
const askNicelyHook = (store: Store<IRootState>): void => {
  let superpropertiesLoaded = false
  let superproperties: ISuperproperties | undefined
  let user: IUser | undefined
  let realmSettings: IUnauthenticatedRealmSettings | undefined

  const initAskNicely = once(() => {
    if (realmSettings?.nps.enabled) {
      (global as typeof window & { asknicelySettings: any }).asknicelySettings = {
        autoload: false,
        domain_key: 'starmind'
      }

      const script = document.createElement('script')
      script.setAttribute('src', 'https://static.asknice.ly/js/websurvey.min.js')
      script.onload = async () => {
        const currentUser = user!
        const networkName = realmSettings?.metadata.realm_name
        const companyName = realmSettings?.metadata.company_name
        const { data: { email_hash } } = await userApi.getAskNicelyEmailHash(user!.id);
        (global as typeof window & { asknicelySettings: any }).asknicelySettings.show({
          name: `${currentUser.firstname} ${currentUser.lastname}`,
          email: currentUser.email,
          email_hash,
          created: Math.round((new Date(currentUser.first_seen ?? Date.now())).valueOf() / 1000),
          network_id: superproperties?.network_id,
          network_name: networkName,
          language: currentUser.language,
          gender: currentUser.gender,
          total_questions_sent: superproperties?.total_questions_sent,
          total_solutions_sent: superproperties?.total_solutions_sent,
          total_comments_sent: superproperties?.total_comments_sent,
          total_recommendations_sent: superproperties?.total_recommendations_sent,
          total_recommendations_received: superproperties?.total_recommendations_received,
          solutions_received: superproperties?.solutions_received,
          event_tracking_id: superproperties?.user_tracking_id,
          company_name: companyName,
          job_title: currentUser.position
        })
      }
      document.body.appendChild(script)
    }
  })

  function evaluateLoadingComplete() {
    if (superpropertiesLoaded && [ superproperties, user, realmSettings ].every((v) => !isEmpty(v))) {
      initAskNicely()
      unsubscribe()
    }
  }

  const unsubscribe = store.subscribe(({ type }: MutationPayload, state: IRootState) => {
    switch (type) {
      case 'analytics/setSuperpropertiesLoaded':
      case 'analytics/setSuperproperties':
      case 'user/setUser':
      case 'realmSettings/setSettings':
        superpropertiesLoaded = state.analytics.superpropertiesLoaded // superproperties can be set multiple times just partially, this boolean ensures they're loaded entirely
        superproperties = state.analytics.superproperties
        user = state.user.user as IUser
        realmSettings = state.realmSettings.unauthenticatedRealmSettings
        evaluateLoadingComplete()
        break
      default:
        break
    }
  })
}

export default askNicelyHook
