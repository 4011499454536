import { AxiosInstance } from 'axios'
import { IUserProject, IUserProjectResp } from '../../../types/user.types'

export function userProjectsApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/projects'
  const methods = {
    getProjects(id: string) {
      return api.get<IUserProjectResp[]>(baseUrl, {
        params: {
          id
        }
      })
    },
    createProject(project: IUserProject) {
      return api.post<{project: IUserProject}>(baseUrl, { project })
    },
    updateProjects(projects: IUserProjectResp[]) {
      return api.put<{project: IUserProject}>(baseUrl, { projects })
    }
  }
  return methods
}
