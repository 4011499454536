













import { defineComponent } from '@vue/composition-api'
import SMGifViewOnce from '@src/ui/SMGifViewOnce/index.vue'

export default defineComponent({
  name: 'FeedbackSent',
  components: { SMGifViewOnce }
})
