import sanitizeHtml from 'sanitize-html'

export default {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(
    [ 'p', 'img', 'span', 'iframe', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'h7', 'h8', 'pre', 'code', 'br', 's', 'em', 'strong', 'u', 'blockquote' ]
  ),
  allowedAttributes: {
    a: [ 'href', 'name', 'target' ],
    img: [ 'src', 'contenteditable', 'draggable' ],
    span: [ 'data-type', 'data-mention', 'data-id', 'data-label', 'class', 'contentditable' ],
    iframe: [ 'src', 'allowfullscreen', 'frameborder', 'width', 'height' ],
    code: [ 'class' ]
  },
  allowedSchemes: [ 'data', 'http', 'https', 'ftp', 'nntp', 'mailto' ]
}
