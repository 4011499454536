






















import { PropType, defineComponent } from '@vue/composition-api'
import { IAutocompleteItemModel } from '@src/types/autocomplete.types'

export default defineComponent({
  name: 'SMAlternativeQueries',
  props: {
    alternativeQueries: {
      type: Array as PropType<IAutocompleteItemModel[]>,
      default: () => []
    }
  }
})
