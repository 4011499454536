import { Ref, ref, watch } from '@vue/composition-api'
import { IAutocompleteItemModel } from '@src/types/autocomplete.types'
import { searchApi } from '@src/api/apiModuleInstances'

export function useRecentAutocompleteSearch(hasSearchTerm: Ref<boolean>) {
  const recentSearches = ref<IAutocompleteItemModel[]>([])

  watch(hasSearchTerm, async () => {
    if (!hasSearchTerm.value) {
      const { data: { items } } = await searchApi.throttled.getRecentSearches()
      const results: IAutocompleteItemModel[] = items.map((result) => ({ text: result.text, value: { image: null, text: result.text, isRecentSearch: true } }))
      recentSearches.value = results
    }
  }, { immediate: true })

  async function clearRecentSearches() {
    await searchApi.clearRecentSearches()
    recentSearches.value = []
  }
  return { recentSearches, clearRecentSearches }
}
