import { AxiosPromise } from 'axios'
import {
  IAnnouncementComment, IAdminAnnouncement, IAdminAnnouncementForm, IAnnonuncementDismissedInfo
} from '@src/types/announcements.types'
import createAxiosInstance, { createOptimizedAPI } from '..'

interface IAnnouncementsApi {
  getAll(): AxiosPromise<IAdminAnnouncement[]>;
  getById(id: number): AxiosPromise<IAdminAnnouncement>;
  view(id: number): AxiosPromise<void>;
  getComments(announcementId: number): AxiosPromise<IAnnouncementComment[]>
  postComment(announcementId: number, comment: string): AxiosPromise<boolean>
  updateComment(announcementId: number, commentId: number, comment: string): AxiosPromise<boolean>
  deleteComment(announcementId: number, commentId: number): AxiosPromise<boolean>
  create(announcemnt: IAdminAnnouncementForm): AxiosPromise<void>;
  update(id: number, announcemnt: IAdminAnnouncementForm): AxiosPromise<void>;
  delete(id: number): AxiosPromise<void>;
  dismiss(id: number, knowledgeSpaceId?: number): AxiosPromise<IAnnonuncementDismissedInfo>;
  getAllDismissed(): AxiosPromise<IAnnonuncementDismissedInfo[]>;
  react(id: number): AxiosPromise<void>;
  deleteReaction(id: number): AxiosPromise<void>
}

const api = createAxiosInstance('announcements')
const dismissedAnnouncementsApi = createAxiosInstance('dismissed-announcements', { web: true, v2: true })

const announcementsApi: IAnnouncementsApi = {
  getAll() {
    return api.get('')
  },
  getById(id) {
    return api.get(`${id}`)
  },
  view(id) {
    return api.post(`${id}/view`)
  },
  create(announcement) {
    return api.post('', announcement)
  },
  update(id, annoucement) {
    return api.put(`${id}`, annoucement)
  },
  delete(id) {
    return api.delete(`${id}`)
  },
  dismiss(id, knowledge_space_id) {
    return dismissedAnnouncementsApi.post('', { id, knowledge_space_id })
  },
  getAllDismissed() {
    return dismissedAnnouncementsApi.get('')
  },
  react(id) {
    return api.post(`${id}/react`)
  },
  deleteReaction(id) {
    return api.delete(`${id}/react`)
  },
  getComments(announcementId) {
    return api.get(`${announcementId}/comments`)
  },
  deleteComment(announcementId, commentId) {
    return api.delete(`${announcementId}/comments/${commentId}`)
  },
  postComment(announcementId, comment) {
    return api.post(`${announcementId}/comments`, {
      comment
    })
  },
  updateComment(announcementId, commentId, comment) {
    return api.put(`${announcementId}/comments/${commentId}`, {
      comment
    })
  }
}

export default {
  ...announcementsApi,
  ...createOptimizedAPI(announcementsApi)
}
