import { AxiosInstance } from 'axios'
import { IQuestionWithTranslation } from '../../../types/question.types'

export function phoenixPersonalisationApiFactory(api: AxiosInstance) {
  const baseUrl = '/api/v1/personalisation'
  const methods = {
    getQuestionSuggestions(limit = 20) {
      return api.get<{ items: IQuestionWithTranslation[] }>(`${baseUrl}/unsolved-questions`, {
        params: {
          limit,
          max_days_suggested_in_time_window: 5,
          time_window_days: 40
        }
      })
    },
    dismissQuestionSuggestion(questionId: number, reason: string) {
      return api.delete<void>(`${baseUrl}/unsolved-questions/${questionId}`, {
        params: {
          reason
        }
      })
    },
    logQuestionSuggestions(questionIds: number[]) {
      return api.post<void>(`${baseUrl}/unsolved-questions/logging`, {
        question_ids: questionIds
      })
    }
  }
  return methods
}
