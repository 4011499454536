








import {
  computed, defineComponent, onMounted, ref
} from '@vue/composition-api'

import { useAnnouncementsStore } from '@src/pinia/announcements'
import { storeToRefs } from 'pinia'
import { useRoute } from '@src/composables/router'
import SMAnnouncementDialog from './SMAnnouncementDialog.vue'

export default defineComponent({
  name: 'SMDeepLinkAnnouncementRenderer',
  components: {
    SMAnnouncementDialog
  },
  setup() {
    const dialog = ref(true)
    const deeplinkAnnouncementId = ref<number | undefined>()
    const announcementStore = useAnnouncementsStore()
    const { userAnnouncements } = storeToRefs(announcementStore)
    const { loadUserAnnouncements } = announcementStore
    const announcement = computed(() => userAnnouncements.value.find((a) => a?.id === deeplinkAnnouncementId.value))
    const isShowingDeeplinkDialog = computed({
      get() {
        return !!announcement.value && dialog.value
      },
      set(value: boolean) {
        dialog.value = value
      }
    })

    const route = useRoute()
    onMounted(() => {
      loadUserAnnouncements()
      if (route.query.announcementId) {
        deeplinkAnnouncementId.value = Number(route.query.announcementId)
      }
    })

    return {
      announcement,
      isShowingDeeplinkDialog
    }
  }
})
