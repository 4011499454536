import { AxiosInstance } from 'axios'
import { ISavedFilter } from '../../../types/filters.types'

export interface IOnboardingDialogStatus {
  completed_date: string;
}

export function savedFiltersApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/saved-filters'
  const methods = {
    getSavedFilters() {
      return api.get<ISavedFilter[]>(baseUrl)
    },
    saveAllFilters(filters: ISavedFilter[]) {
      return api.post<ISavedFilter[]>(baseUrl, filters)
    }
  }
  return methods
}
