















































import { IAnnouncementComplete } from '@src/types/announcements.types'
import {
  defineComponent, PropType, toRefs
} from '@vue/composition-api'
import SMTimeAgo from '@src/ui/SMTimeAgo/index.vue'
import SMEditor from '@src/ui/SMEditor/index.vue'
import { adminExtensions } from '@src/ui/SMEditor/TipTap/components/toolbar/toolbarExtensions'
import SMTranslationToggle from '@src/ui/SMTranslationToggle/index.vue'
import AnnouncementInteractions from './AnnouncementInteractions.vue'
import { useAnnouncementTranslation } from './composables/useAnnouncementTranslation'

export default defineComponent({
  name: 'SMAnnouncementContent',
  components: {
    SMTimeAgo,
    SMEditor,
    AnnouncementInteractions,
    SMTranslationToggle
  },
  props: {
    announcement: {
      type: Object as PropType<IAnnouncementComplete>,
      required: true
    },
    previewStep: {
      type: Boolean,
      default: false
    },
    reactionsEnabled: {
      type: Boolean,
      default: false
    },
    commentsEnabled: {
      type: Boolean,
      default: false
    },
    nonInteractive: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { announcement } = toRefs(props)
    const {
      isOriginal, translatedMessage, translatedTitle, toggleTranslation
    } = useAnnouncementTranslation(announcement)

    return {
      adminExtensions, isOriginal, translatedMessage, translatedTitle, toggleTranslation
    }
  }
})
