





















import { IComboboxSearchResultItem } from '@src/types/concept.types'
import {
  defineComponent, PropType
} from '@vue/composition-api'

export default defineComponent({
  name: 'SMConceptSearchResultItem',
  props: {
    item: {
      type: Object as PropType<IComboboxSearchResultItem>,
      required: true
    },
    tooltip: {
      type: Boolean,
      default: false
    }
  }
})
