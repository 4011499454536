























































import { computed, defineComponent } from '@vue/composition-api'
import useAskDialog from '@src/composables/askDialog'
import useRealmSettings from '@src/composables/realmSettings'
import useUser from '@src/composables/user'
import useTracking from '@src/composables/tracking/tracking'
import SMNotificationsButton from '@src/ui/SMNotificationsButton/index.vue'

import { PrivilegedUserRoles } from '@src/types/user.types'

import { createOnboardingTooltipDirective } from '@src/ui/SMOnboardingTooltip/directives/onboardingTooltip'
import { useSidenavState } from '@src/ui/SMSidenav/composables/useSidenavState'
import { useOAuthClientId } from '@src/composables/useOAuthClientId'
import { useFeatureToggles } from '@src/composables/featureToggles'

export default defineComponent({
  name: 'SMBottomNavigation',
  components: {
    SMNotificationsButton
  },
  directives: {
    onboardingTooltipAsk: createOnboardingTooltipDirective(),
    onboardingTooltipMore: createOnboardingTooltipDirective(),
    onboardingTooltipFeed: createOnboardingTooltipDirective()
  },
  setup() {
    const { openAskDialog } = useAskDialog()
    const { realmSettings } = useRealmSettings()
    const { roles } = useUser()
    const { trackMixpanelEvent } = useTracking()
    const { toggleSidenav } = useSidenavState()
    const { isMsTeamsPersonalApp } = useOAuthClientId()
    const { insightsKillSwitchEnabled } = useFeatureToggles()

    const isHomepageEnabled = computed(() => realmSettings.value?.homepage.enabled)
    const isExpertsEnabled = computed(() => realmSettings.value?.expert_search.show_expert_search || roles.value.includes(PrivilegedUserRoles.UserStatisticsAdmin))

    function trackMenuItemClick(eventName: string) {
      trackMixpanelEvent(eventName, {
        component: 'main_navigation',
        element: 'list_item',
        type: 'click'
      })
    }

    return {
      isHomepageEnabled,
      isExpertsEnabled,
      insightsKillSwitchEnabled,
      trackMenuItemClick,
      toggleSidenav,
      isMsTeamsPersonalApp,
      openAskDialog
    }
  }
})
