











































import Vue, { PropType } from 'vue'
import attachmentsApi from '@src/api/attachments'
import { EntityType } from '@src/types/tiptap.types'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'SMImageUpload',
  props: {
    entityType: {
      type: String as PropType<EntityType>,
      required: true
    },
    entityId: {
      type: Number,
      required: true
    },
    imagePath: {
      type: String,
      required: true
    },
    setImagePath: {
      type: Function as PropType<(imagePath: string, delay?: number) => void>,
      required: true
    }
  },
  data() {
    return {
      isDragging: false,
      hasError: false,
      isLoading: false
    }
  },
  computed: {
    getClasses(): {'is-dragging': boolean; 'is-successful': boolean; 'is-failure': boolean} {
      return {
        'is-dragging': this.isDragging,
        'is-successful': !!this.imagePath,
        'is-failure': this.hasError
      }
    },
    shouldShowImgUpload(): boolean {
      return !this.imagePath && !this.isDragging && !this.hasError && !this.isLoading
    },
    statusIcon(): {name: string; color: 'green'| 'red'} | null {
      if (this.imagePath) {
        return { name: 'ico-sm-check-fill', color: 'green' }
      }
      if (this.hasError) {
        return { name: 'ico-sm-close-fill', color: 'red' }
      }
      return null
    }
  },
  methods: {
    ...mapActions('snackbar', { showSnackbar: 'show' }) as {
      showSnackbar(message: string): void;
    },
    onChange(event: Event): void {
      if ((event.target as HTMLInputElement).files && ((event.target as HTMLInputElement).files as FileList).length) {
        const fileList = (event.target as HTMLInputElement).files as FileList
        this.handleFile(fileList[0])
      }
    },
    dragOver(): void {
      this.isDragging = true
    },
    dragLeave(): void {
      this.isDragging = false
    },
    drop(e: DragEvent): void {
      const { files } = e.dataTransfer as DataTransfer
      this.hasError = false
      // allows only 1 file
      if (files.length === 1) {
        const file = files[0]
        // allows image only
        this.handleFile(file)
      }
    },
    async handleFile(file: File): Promise<void> {
      if (file.type.includes('image/') && this.entityId && (
        this.entityType === 'solution' || this.entityType === 'question'
      )) {
        this.isLoading = true
        try {
          const entityTypeUrlParam = this.entityType === 'solution' ? 'solutions' : 'questions'
          const { data: uploadedImg } = await attachmentsApi.uploadEntityAttachment(entityTypeUrlParam, this.entityId, file)

          this.isDragging = false
          this.setImagePath(uploadedImg.link, 1000)
        } catch {
          this.triggerError()
        }
      } else if (file.type.includes('image/') && this.entityType === 'adminGeneric') {
        try {
          const { data: { link } } = await attachmentsApi.uploadInlineAttachment(file)
          this.isDragging = false
          this.setImagePath(link, 1000)
        } catch {
          this.triggerError()
        }
      } else {
        this.triggerError()
      }
      this.isLoading = false
    },
    triggerError(): void {
      this.showSnackbar(this.$t('tiptap.toolbar.image.upload.error.text')?.toString())
      this.hasError = true
      this.setImagePath('')
      this.isDragging = false
      setTimeout(() => {
        this.hasError = false
      }, 2000)
    }
  }
})
