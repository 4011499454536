






























import {
  defineComponent, computed, inject, ref
} from '@vue/composition-api'
import useRealmSettings from '@src/composables/realmSettings'
import autoQuestionMark from '../../directives/autoQuestionMark'
import { IUseTracking } from '../../composables/tracking'
import { IFormError } from '../../composables/validation'
import { SMAskInjectables } from '../../constants'
import SMTitleQualityIndicator from './SMTitleQualityIndicator.vue'

export default defineComponent({
  name: 'SMQuestionTitleInput',
  components: { SMTitleQualityIndicator },
  directives: {
    autoQuestionMark
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    error: {
      type: Object as () => IFormError | undefined,
      default: undefined
    },
    questionLanguage: {
      type: String,
      default: 'xx'
    }
  },
  setup(props, { emit }) {
    const { trackClickInTitle } = inject(SMAskInjectables.Tracking) as IUseTracking
    const { realmSettings } = useRealmSettings()

    const titleBlurred = ref(false)

    const questionTitle = computed({
      get() {
        return props.value
      },
      set(value: string) {
        emit('input', value)
      }
    })

    const questionTitleRemainingCharacters = computed(() => (realmSettings.value?.question.question_title_max_length ?? 0) - questionTitle.value.length)
    const errorMessages = computed(() => (props.error?.translatedError ? [ props.error?.translatedError ] : undefined))

    return {
      questionTitle,
      titleBlurred,
      questionTitleRemainingCharacters,
      errorMessages,
      trackClickInTitle
    }
  }
})
