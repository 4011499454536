import { IRootState } from '@src/types/store.types'
import {
  ActionTree, MutationTree, GetterTree, Module
} from 'vuex'
import { searchApi } from '@src/api/apiModuleInstances'
import { IUser } from '@src/types/user.types'

export interface ISearchV2QuestionState {
    searchTerm: string,
    searchResults: IUser[],
    searchLoading: boolean,
    totalResults: number,
    offset: number
}

const state: ISearchV2QuestionState = {
  searchTerm: '',
  searchResults: [],
  searchLoading: false,
  totalResults: 0,
  offset: 0
}
const LIMIT = 20

const getters: GetterTree<ISearchV2QuestionState, IRootState> = {
  getResults(state) {
    return state.searchResults
  },
  getResultsLoading(state) {
    return state.searchLoading
  },
  getTabCountString(state) {
    if (state.searchLoading) {
      return ''
    }
    return `(${state.totalResults})`
  },
  getSearchTerm(state) {
    return state.searchTerm
  },
  hasNextBatch(state) {
    return state.totalResults > state.searchResults.length
  }
}

const actions: ActionTree<ISearchV2QuestionState, IRootState> = {
  async fetchSearchResults({ commit, state }, query : string) {
    if (query === state.searchTerm) {
      return
    }
    commit('setQuestionSearchLoading', true)
    commit('resetOffset')
    try {
      const { data } = await searchApi.getUsersStream({ query, limit: LIMIT })
      commit('setSearchTerm', query)
      commit('setResults', data.items)
      commit('setTotalResults', data.total)
    } finally {
      commit('setQuestionSearchLoading', false)
    }
  },
  async loadNextBatch({ commit, state, getters: localGetters }) {
    if (localGetters.hasNextBatch) {
      commit('incrementOffset')
      const { data } = await searchApi.getUsersStream({
        query: state.searchTerm,
        limit: LIMIT,
        offset: state.offset
      })

      commit('appendResults', data.items)
    }
  }
}

const mutations: MutationTree<ISearchV2QuestionState> = {
  setResults(state, results: IUser[]): void { state.searchResults = results },
  setSearchTerm(state, term: string): void { state.searchTerm = term },
  setQuestionSearchLoading(state, loading: boolean): void { state.searchLoading = loading },
  setTotalResults(state, total: number): void { state.totalResults = total },
  resetOffset(state) { state.offset = 0 },
  incrementOffset(state) { state.offset += 1 },
  appendResults(state, results: IUser[]): void { state.searchResults = [ ...state.searchResults, ...results ] }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<ISearchV2QuestionState, IRootState>
