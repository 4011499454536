























































import {
  PropType,
  defineComponent,
  onMounted,
  readonly,
  ref,
  toRefs
} from '@vue/composition-api'

import useAttachments from '@src/composables/attachments'
import { IQuestionPayload } from '@src/types/question.types'
import SMQuestionDialog from '@src/ui/SMAsk/components/common/SMQuestionDialog.vue'
import SMQuestionDialogActions from '@src/ui/SMAsk/components/common/SMQuestionDialogActions.vue'
import SMQuestionQualityMeter from '@src/ui/SMAsk/components/common/SMQuestionQualityMeter.vue'
import useQuestionContent from '../../composables/questionContent'
import useValidation from '../../composables/validation'
import useQuestionQuality from '../../composables/questionQuality'
import useSave from '../../composables/save'
import useAskTracking from '../../composables/tracking'

import SMQuestionForm from '../common/SMQuestionForm.vue'
import { useConceptsForQuestionEntity } from '../../composables/conceptsForQuestionEntity'

export default defineComponent({
  name: 'SMEditQuestionDialog',
  components: {
    SMQuestionQualityMeter,
    SMQuestionDialogActions,
    SMQuestionDialog,
    SMQuestionForm
  },
  props: {
    existingQuestionId: {
      type: Number as PropType<number | undefined>,
      default: undefined
    },
    isFullscreen: {
      type: Boolean,
      required: true
    }
  },
  setup(props, context) {
    const { existingQuestionId } = toRefs(props)

    const {
      questionId,
      questionTitle,
      questionDescription,
      questionCategory,
      questionKnowledgeSpace,
      questionAttachments,
      initialKnowledgeSpace,
      generalFeedSelected,
      questionLanguage
    } = useQuestionContent(context, existingQuestionId)

    const { concepts } = useConceptsForQuestionEntity({
      questionId,
      questionTitle,
      questionDescription,
      autoSuggestionsBasedOnTitleAndDescription: false
    })

    const {
      titleError,
      descriptionError,
      conceptsError,
      categoryError,
      knowledgeSpaceMandatoryError,
      validate
    } = useValidation({
      title: questionTitle,
      description: questionDescription,
      concepts,
      category: questionCategory,
      knowledgeSpace: questionKnowledgeSpace,
      generalFeedSelected: ref(true)
    })
    const { questionQuality } = useQuestionQuality({
      title: questionTitle,
      description: questionDescription,
      concepts,
      category: questionCategory,
      hasSimilarQuestions: readonly(ref(false))
    })
    const {
      attachments, addFile, removeAttachment, attachmentError
    } = useAttachments(questionAttachments, questionId, 'questions')

    const { isLoading, updateExistingQuestion } = useSave(context)

    const { trackErrorView, trackViewAsk, trackSubmit } = useAskTracking({
      title: questionTitle,
      description: questionDescription,
      questionId,
      isNewQuestion: false,
      similarQuestions: readonly(ref([])),
      totalSimilarQuestions: readonly(ref(0)),
      isShowingVerySimilarQuestionDoubleCheck: ref(false),
      concepts,
      questionQuality,
      hasVerySimilarQuestion: ref(false),
      conceptualExperts: readonly(ref([])),
      initialKnowledgeSpace
    })

    const submit = () => {
      const isValid = validate()
      if (isValid) {
        const questionPayload: IQuestionPayload = {
          title: questionTitle.value,
          description: questionDescription.value,
          category: questionCategory.value,
          knowledge_space: questionKnowledgeSpace.value
        }
        updateExistingQuestion(questionId.value!, questionPayload)
        trackSubmit(questionPayload)
      } else {
        if (categoryError.value) {
          trackErrorView({
            component: 'new_question_footer',
            element: 'dropdown_menu',
            form_field: 'category',
            error: 'required'
          })
        }
        if (conceptsError.value) {
          trackErrorView({
            component: 'new_question_footer',
            element: 'icon',
            form_field: 'concept',
            error: 'min_length'
          })
        }
        if (descriptionError.value) {
          trackErrorView({
            component: 'new_question_body',
            element: 'input_field',
            form_field: 'description',
            error: 'required'
          })
        }
        if (titleError.value) {
          trackErrorView({
            component: 'new_question_header',
            element: 'input_field',
            form_field: 'title',
            error: titleError.value.errorType
          })
        }
        if (knowledgeSpaceMandatoryError.value) {
          trackErrorView({
            component: 'new_question_header',
            element: 'input_field',
            form_field: 'knowledge_space_id',
            error: 'required'
          })
        }
      }
    }

    onMounted(trackViewAsk)

    return {
      addFile,
      attachmentError,
      attachments,
      categoryError,
      descriptionError,
      isLoading,
      knowledgeSpaceMandatoryError,
      questionCategory,
      questionDescription,
      questionId,
      questionKnowledgeSpace,
      questionQuality,
      questionTitle,
      questionLanguage,
      generalFeedSelected,
      removeAttachment,
      submit,
      concepts,
      conceptsError,
      titleError
    }
  }
})
