import {
  Ref, computed, ref, watch
} from '@vue/composition-api'
import debounce from 'lodash/fp/debounce'

import { flatMapConceptsToConceptualTopicsWithLanguage } from '@src/utilities/conceptSearch'
import { IStandardizedConceptSingleLanguage } from '@src/types/concept.types'
import { entitiesApi } from '@src/api/apiModuleInstances'
import { TAG_SUGGESTIONS_DEBOUNCE_TIME } from '../constants'

interface IQuestionContext {
  questionId: Ref<number | undefined>;
  questionTitle: Ref<string>;
  questionDescription: Ref<string>;
  autoSuggestionsBasedOnTitleAndDescription: boolean;
}

export function useConceptsForQuestionEntity({
  questionId, questionTitle, questionDescription, autoSuggestionsBasedOnTitleAndDescription
}: IQuestionContext) {
  const entityAlias = computed(() => (questionId.value ? `question_${questionId.value}` : undefined))
  const storedConceptsForEntityAlias = ref<IStandardizedConceptSingleLanguage[]>([])

  const concepts = computed<IStandardizedConceptSingleLanguage[]>({
    get() {
      return storedConceptsForEntityAlias.value
    },
    async set(updatedList) {
      const addedConcepts = updatedList.filter(({ concept_id }) => !storedConceptsForEntityAlias.value.some((item) => item.concept_id === concept_id))
      const deletedConcepts = storedConceptsForEntityAlias.value.filter(({ concept_id }) => !updatedList.some((item) => item.concept_id === concept_id))
      storedConceptsForEntityAlias.value = updatedList

      // Only if there is a question id we do store the concepts
      // CAVEAT 1: when editing a question it is possible to delete all the concepts because we immediately remove them (and not in the moment the user clicks save)
      // CAVEAT 2: when the user starts the question with adding tags, no language id has been identified and therefore the language is 'xx' which yields low quality results
      // CAVEAT 3: when the user starts the question with adding tags, they are not stored with their entity alias. Only concepts that are added after there is an alias are persisted.
      if (entityAlias.value) {
        addedConcepts.forEach((item) => entitiesApi.createEntityConcept(entityAlias.value!, item.concept_id, {
          languages: [ { language_id: item.language, aliases: [ item.label_match ], primary_label: item.primary_label } ]
        }))
        deletedConcepts.forEach((item) => entitiesApi.deleteEntityConcept({
          questionAlias: entityAlias.value!,
          conceptId: item.concept_id,
          languageId: item.language,
          label_match: item.label_match
        }))
      }
    }
  })

  function createOrUpdateEntity(alias: string) {
    return entitiesApi.createOrUpdateEntity(alias, {
      entity_type: 'question',
      texts: [ {
        text: questionTitle.value,
        format: 'plain'
      }, {
        text: questionDescription.value,
        format: 'html_with_mention'
      } ]
    })
  }

  // Watches the question ID change and loads previously stored concepts for that alias
  watch(entityAlias, async (alias) => {
    if (alias) {
      try {
        const { data } = await entitiesApi.getEntityConcepts(alias)
        storedConceptsForEntityAlias.value = flatMapConceptsToConceptualTopicsWithLanguage(data)
      } catch (error: any) {
        if (error.response.status === 404) {
          createOrUpdateEntity(alias)
        }
      }
    }
  }, { immediate: true }) // immediate because when editing a question the id is already there

  const debouncedConceptSuggestions = debounce(TAG_SUGGESTIONS_DEBOUNCE_TIME)(async () => {
    if (entityAlias.value && autoSuggestionsBasedOnTitleAndDescription) {
      const { data } = await createOrUpdateEntity(entityAlias.value)
      storedConceptsForEntityAlias.value = flatMapConceptsToConceptualTopicsWithLanguage(data.concepts)
    }
  })

  // Watches title, description and question id change and loads suggestions
  watch([ questionTitle, questionDescription, questionId ], debouncedConceptSuggestions)

  return {
    concepts
  }
}
