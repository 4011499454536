import Vue, { VueConstructor } from 'vue'
import VueI18n from 'vue-i18n'

import _attempt from 'lodash/attempt'
import _first from 'lodash/first'
import { computed, getCurrentInstance, WritableComputedRef } from '@vue/composition-api'
import { CookieName, getCookie, setCookie } from '@src/helpers/cookie'
import userApi from '@src/api/user'
import { getPermissionLevel, PermissionLevel } from '@src/utilities/userUtils'
import { isAuthenticated } from '@src/utilities/auth'
import { realmSettingsApi, translationApi } from '@src/api/apiModuleInstances'
import Formatter from './formatter'
import { DEFAULT_LANGUAGE } from './constants'

Vue.use(VueI18n)

function postTranslationUntranslatedFn(string: string, key: string | undefined): string {
  return key || string
}

function postTranslationDefaultFn(value: any, key: string | undefined): string {
  // in case it has children ex: badges = ”Badge title” badges.button = ”Button text”
  if (typeof value === 'object' && '__title__' in value) {
    return value.__title__
  }
  return value || key
}

const loadedLanguages: string[] = []
const i18n = new VueI18n({
  postTranslation: /^\?untranslated/.test(window.location.search) ? postTranslationUntranslatedFn : postTranslationDefaultFn,
  fallbackLocale: DEFAULT_LANGUAGE,
  fallbackRoot: false,
  fallbackRootWithEmptyString: false,
  silentTranslationWarn: true
})
i18n.formatter = new Formatter({ locale: DEFAULT_LANGUAGE })

export const getBrowserLanguage = (): string => {
  const browserLang = _attempt(() => {
    const { language } = window.navigator
    return _first(language.split('-'))
  })
  return typeof browserLang === 'string' ? browserLang : DEFAULT_LANGUAGE
}

const setLanguage = (lang: string): string => {
  i18n.locale = lang
  i18n.formatter = new Formatter({ locale: lang })
  return lang
}

export const initLanguage = async (lang: string = (getCookie(CookieName.LANGUAGE) || getBrowserLanguage())): Promise<string> => {
  try {
    setCookie({
      name: CookieName.LANGUAGE,
      value: lang,
      expiration: new Date(Date.now() + 1000 * 60 * 60 * 24 * 365)
    })
  } catch (error) {
    // ignore
  }

  // If the same language
  if (i18n.locale === lang) {
    return lang
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return setLanguage(lang)
  }

  const useFormalLanguage = await shouldUseFormalLanguage()
  const { data: translations } = await translationApi.cached.get(lang === 'de' && !useFormalLanguage ? 'de-CH' : lang)

  if (await shouldIncludeAdminTranslations()) {
    const { data: adminTranslations } = await translationApi.cached.getAdmin()

    i18n.setLocaleMessage(lang, { ...translations, ...adminTranslations })
  } else {
    i18n.setLocaleMessage(lang, translations)
  }

  loadedLanguages.push(lang)
  return setLanguage(lang)
}

export function useI18n(): IComposer {
  const instance = getCurrentInstance()
  const vm = instance?.proxy
    || (instance as unknown as InstanceType<VueConstructor>)
    || new Vue({})

  const locale = computed({
    get() {
      return i18n.locale
    },
    set(value: string) {
      i18n.locale = value
    }
  })

  return {
    locale,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm)
  }
}

export interface IComposer {
  locale: WritableComputedRef<string>;
  t: typeof VueI18n.prototype.t;
  tc: typeof VueI18n.prototype.tc;
  te: typeof VueI18n.prototype.te;
  d: typeof VueI18n.prototype.d;
  n: typeof VueI18n.prototype.n;
}

async function shouldUseFormalLanguage() {
  try {
    const { data: { general: { uses_formal_language } } } = await realmSettingsApi.cached.get()
    return uses_formal_language
  } catch (error) {
    return true // to not piss off the Germans, otherwise they are very "empört" ;)
  }
}

async function shouldIncludeAdminTranslations() {
  if (isAuthenticated()) {
    try {
      const { data: roles } = await userApi.throttled.getRoles()
      return getPermissionLevel(roles) === PermissionLevel.Admin
    } catch (error) {
      return false
    }
  }
  return false
}

export default i18n
