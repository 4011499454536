import { snip } from 'js-snip'
import { VueSnipDirectiveHook } from '../types'

export const updated: VueSnipDirectiveHook = (el: HTMLElement, { value }: any, vNode: { children: { text: string }[] }) => {
  const textContent = vNode?.children?.[0]?.text ?? ''
  const {
    lines, mode, midWord, ellipsis, onSnipped
  } = value
  snip(el, {
    lines, mode, midWord, ellipsis, textContent
  }, onSnipped)
}
