


































































































































import {
  defineComponent, ref
} from '@vue/composition-api'
import VClamp from 'vue-clamp/Clamp'
import SMDialog from '@src/ui/SMDialog/index.vue'
import { format } from 'date-fns'
import useQuestionDrafts from '../../composables/questionDrafts'

export default defineComponent({
  name: 'SMDraftList',
  components: {
    SMDialog,
    VClamp
  },
  setup() {
    const isShowingQuestionDraftList = ref(false)

    const {
      loading, questionDrafts, hasDrafts, totalDrafts, currentlyEditingDraftId, deleteDraft, editDraft
    } = useQuestionDrafts()

    function getFormattedDate(date?: string) {
      return date ? format(new Date(date), 'd MMM yyyy') : ''
    }

    return {
      isShowingQuestionDraftList,
      loading,
      questionDrafts,
      hasDrafts,
      totalDrafts,
      currentlyEditingDraftId,
      editDraft,
      deleteDraft,
      getFormattedDate
    }
  }
})
