





















import {
  computed,
  defineComponent
} from '@vue/composition-api'

export default defineComponent({
  name: 'SMQuestionQualityMeter',
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const color = computed(() => {
      if (props.value <= 21) { return 'orange darken-2' }
      if (props.value <= 50) { return 'yellow darken-1' }
      if (props.value <= 81) { return 'lime darken-1' }
      return 'green'
    })

    return { color }
  }
})
