













































import {
  defineComponent
} from '@vue/composition-api'
import QuestionActionBarButton from '@src/views/Question/QuestionActionBar/QuestionActionBarButton.vue'

export default defineComponent({
  name: 'QuestionContextMenu',
  components: { QuestionActionBarButton }
})
