import {
  GetterTree, MutationTree, ActionTree, Module
} from 'vuex'
import notificationsApi from '@src/api/notifications'
import { notificationsV2Api } from '@src/api/apiModuleInstances'
import _get from 'lodash/get'
import _find from 'lodash/find'
import _orderBy from 'lodash/orderBy'
import _uniqBy from 'lodash/uniqBy'
import _concat from 'lodash/concat'
import { IRootState } from '@src/types/store.types'
import { INotificationItem, NotificationType } from '@src/types/notifications.types'
import { IPaginationLinks } from '@src/types/api.types'
import { getApiTokenAsPromise } from '@src/helpers/cookie'
import { getWindowObject } from '@src/utilities/iframeDetection'
import { INotificationV2User, IUser } from '@src/types/user.types'

export interface INotificationsState{
  notifications: INotificationItem<IUser | INotificationV2User>[];
  unseenNotificationsCount: number;
  links?: IPaginationLinks;
  drawerVisible: boolean;
  loading: boolean;
  loadingNewNotification: boolean;
  isFirstLoad: boolean;
}

const notificationsState: INotificationsState = {
  notifications: [],
  unseenNotificationsCount: 0,
  links: undefined,
  drawerVisible: false,
  loading: true,
  loadingNewNotification: false,
  isFirstLoad: true
}

function getCleanPageTitle() {
  return getWindowObject().document.title.replace(/\(\d+\)/, '').trim()
}

function getV2Notifications(limit?: number) {
  try {
    return notificationsV2Api.getList(false, 0, limit)
  } catch (error) {
    return { data: { items: [] } }
  }
}

const notificationsGetters: GetterTree<INotificationsState, IRootState> = {
  getLoading(state) {
    return state.loading
  },
  getLoadingNewNotifications(state) {
    return state.loadingNewNotification
  },
  getNotifications(state) {
    return _orderBy(state.notifications, [ 'is_read', 'created', 'id' ], [ 'asc', 'desc', 'desc' ])
  },
  getOffset(state): number|string|undefined {
    const regex = /offset=(\d+)/g
    const nextHref = _get(state, 'links.next.href')
    return state.links ? regex.exec(nextHref)?.[1] : 0
  },
  getUnseenNotificationsCount(state): number {
    return state.unseenNotificationsCount
  },
  getDrawerVisibility(state) {
    return state.drawerVisible
  },
  getNotificationsCountAsString(state): string | undefined {
    return state.unseenNotificationsCount > 99
      ? '99+' : state.unseenNotificationsCount?.toString()
  },
  getUnreadBadgesNotifications(state) {
    return state.notifications.filter((notification) => {
      return !notification.is_read
      && notification.type === NotificationType.NewBadgeObtained
    })
  }
}

const actions: ActionTree<INotificationsState, IRootState> = {
  async loadNotifications({ commit, state, getters }) {
    commit('setLoading', true)
    try {
      const offset = getters.getOffset
      if (offset !== undefined) {
        const { data } = await notificationsApi.getList(false, offset)
        if (state.isFirstLoad) {
          const { data: v2data } = await getV2Notifications()
          commit('setNotifications', _concat(data.items, v2data.items))
        } else {
          commit('setNotifications', data.items)
        }
        commit('setLinks', data._links)
      }
    } finally {
      commit('setLoading', false)
    }
  },
  async addNewNotifications({ commit }, { limitV1, limitV2 }: { limitV1: number, limitV2: number }) {
    commit('setLoadingNewNotifications', true)
    try {
      const res = await Promise.all([
        limitV1 ? notificationsApi.getList(false, 0, limitV1) : Promise.resolve({ data: { items: [] } }),
        limitV2 ? getV2Notifications(limitV2) : Promise.resolve({ data: { items: [] } })
      ])

      const newItems = res.reduce((acc, currentValue) => {
        return _concat(currentValue.data.items, acc)
      }, [] as INotificationItem[])

      commit('setNewNotifications', newItems)
    } finally {
      commit('setLoadingNewNotifications', false)
    }
  },
  async loadUnseenNotificationsCount({ commit, state, dispatch }) {
    try {
      const responses = await Promise.all([
        notificationsApi.getUnseenCount(),
        (() => {
          try {
            return notificationsV2Api.getUnseenCount()
          } catch (error) {
            return { data: { notifications: 0 } }
          }
        })()
      ])

      const v1Unseen = responses[0].data.notifications
      const v2Unseen = responses[1].data.notifications
      const unseenSum = v1Unseen + v2Unseen

      // if the number of new notifications is not equal to the current set new notifications
      if (unseenSum !== state.unseenNotificationsCount) {
        commit('setUnseenNotificationsCount', unseenSum)
        // load new notifications in also for the case when the drawer is open
        if (!state.isFirstLoad) {
          if (state.unseenNotificationsCount) {
            dispatch('addNewNotifications', { limitV1: v1Unseen, limitV2: v2Unseen })
          }
          if (state.drawerVisible) {
            dispatch('markAllAsSeen')
          }
        }
      }
    } catch {
      //
    }
  },
  async markNotificationAsRead({ commit, state }, id: number) {
    const notification = state.notifications.find((n) => n.id === id)
    if (notification && notification.v2) {
      await notificationsV2Api.markAsRead(id)
    } else {
      await notificationsApi.markAsRead(id)
    }
    commit('markNotificationAsRead', id)
  },
  async markAllAsRead({ commit }) {
    await Promise.all([ notificationsApi.markAllAsRead(), notificationsV2Api.markAllAsRead() ])
    commit('markAllNotificationsAsRead')
    commit('hideDrawer')
  },
  async markAllAsSeen({ commit }) {
    await Promise.all([ notificationsApi.markAllAsSeen(), notificationsV2Api.markAllAsSeen() ])
    commit('setUnseenNotificationsCount', 0)
  },
  async loadInitialNotifications({ commit, state, dispatch }) {
    try {
      if (state.isFirstLoad) {
        await getApiTokenAsPromise()
        await dispatch('loadNotifications')
        commit('setFirstLoad', false)
        await dispatch('loadUnseenNotificationsCount')
      }
    } catch {
      //
    }
  },
  async showDrawer({ state, commit, dispatch }) {
    commit('showDrawer')
    await dispatch('loadInitialNotifications')
    if (state.unseenNotificationsCount) {
      await dispatch('markAllAsSeen')
    }
  },
  async markBadgesNotificationsAsRead({
    commit, dispatch, getters
  }) {
    const unreadBadgesNotifications = getters.getUnreadBadgesNotifications as INotificationItem[]
    if (unreadBadgesNotifications.length) {
      try {
        const markUnreadBadgesAsReadPromises = unreadBadgesNotifications.map((badgeNotification) => notificationsApi.markAsRead(badgeNotification.id))
        await Promise.all(markUnreadBadgesAsReadPromises)

        commit('markAllBadgeNotificationsAsRead')
        dispatch('loadUnseenNotificationsCount')
      } catch {
        //
      }
    }
  },
  async executeNotificationClickCallback({ dispatch }, notification: INotificationItem<IUser | INotificationV2User>): Promise<void> {
    switch (notification.type) {
      // Add here custom behaviour dependent on the notification type
      case NotificationType.KnowledgeSpaceMembershipGranted:
        await dispatch('knowledgeSpaces/load', null, { root: true })
        break
      default:
        await Promise.resolve()
    }
  }
}

const mutations: MutationTree<INotificationsState> = {
  setNotifications(state, newNotifications: INotificationItem[]) {
    state.notifications = _uniqBy([ ...state.notifications, ...newNotifications ], 'id')
  },
  setNewNotifications(state, newnotifications: INotificationItem[]) {
    const notificationsIds = state.notifications.map((notification) => notification.id)
    const newFilteredNotifications = newnotifications.filter((notification) => !notificationsIds.includes(notification.id))
    state.notifications = [ ...newFilteredNotifications, ...state.notifications ]
  },
  setLinks(state, links: IPaginationLinks) {
    state.links = links
  },
  setLoading(state, loading: boolean) {
    state.loading = loading
  },
  setLoadingNewNotifications(state, loadingNewNotification: boolean) {
    state.loadingNewNotification = loadingNewNotification
  },
  markAllNotificationsAsRead(state) {
    state.notifications = state.notifications.map((notification) => {
      return {
        ...notification,
        is_read: true
      }
    })
  },
  markNotificationAsRead(state, id: number) {
    const notification = _find(state.notifications, { id })
    if (notification) {
      notification.is_read = true
    }
  },
  markAllBadgeNotificationsAsRead(state) {
    state.notifications = state.notifications.map((notification) => {
      if (notification.type === NotificationType.NewBadgeObtained) {
        return {
          ...notification,
          is_read: true
        }
      }
      return notification
    })
  },
  showDrawer(state) {
    state.drawerVisible = true
  },
  hideDrawer(state) {
    state.drawerVisible = false
  },
  incrementUnseenNotificationsCount(state) {
    state.unseenNotificationsCount += 1
    const pageTitle = getCleanPageTitle()
    getWindowObject().document.title = state.unseenNotificationsCount ? `(${state.unseenNotificationsCount}) ${pageTitle}` : pageTitle
  },
  setUnseenNotificationsCount(state, unseenNotificationsCount: number) {
    state.unseenNotificationsCount = unseenNotificationsCount
    const pageTitle = getCleanPageTitle()
    getWindowObject().document.title = unseenNotificationsCount ? `(${unseenNotificationsCount}) ${pageTitle}` : pageTitle
  },
  setFirstLoad(state, isFirstLoad: boolean) {
    state.isFirstLoad = isFirstLoad
  }
}

export default {
  namespaced: true,
  state: notificationsState,
  getters: notificationsGetters,
  actions,
  mutations
} as Module<INotificationsState, IRootState>
