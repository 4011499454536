import {
  ActionTree,
  GetterTree,
  Module, MutationTree
} from 'vuex'
import { union } from 'lodash/fp'
import { IRootState } from '@src/types/store.types'
import { IUser } from '@src/types/user.types'
import { FeatureToggleNames } from '@src/plugins/featureToggles/featureToggle.names'
import awaitGetterValue from '@src/utilities/storeUtils'
import trackingServiceApi from '@src/api/tracking'

export interface IImpressionsState {
  loading: boolean;
  error: boolean;
  impressions: FeatureToggleNames[];
}

export interface ITrackImpression {
  experimentName: FeatureToggleNames;
  experimentVariant: string;
}

export const state: IImpressionsState = {
  loading: true,
  error: false,
  impressions: []
}

const getters: GetterTree<IImpressionsState, IRootState> = {
  getImpressions(state): FeatureToggleNames[] {
    return state.impressions
  }
}

const actions: ActionTree<IImpressionsState, IRootState> = {
  async loadImpressions({ commit }): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { event_tracking_id } = await awaitGetterValue<IUser>('user/getUser')
      const { data: impressions } = await trackingServiceApi.getImpressions(event_tracking_id)
      commit('setImpressions', impressions)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
  },
  async trackImpression({ commit }, { experimentName, experimentVariant }: ITrackImpression): Promise<void> {
    const { event_tracking_id } = await awaitGetterValue<IUser>('user/getUser')
    const impression = await trackingServiceApi.trackImpression(experimentName, event_tracking_id, experimentVariant)
    if (impression) { commit('setImpressions', [ impression.data.experimentName ]) }
  }
}

const mutations: MutationTree<IImpressionsState> = {
  setImpressions(state, impressions: FeatureToggleNames[]): void {
    state.impressions = union(state.impressions, impressions)
  },
  setLoading(state, loading): void {
    state.loading = loading
  },
  setError(state, error): void {
    state.error = error
  }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  getters,
  actions,
  mutations
} as Module<IImpressionsState, IRootState>
