export enum FeatureToggleNames {
  RELEASE_LLM_CONVERSATIONS = 'release_dev-7973-llm-conversations',
  RELEASE_AUTOMATIC_CONNECTOR_DEPLOYMENT = 'dev-9320_automatic-connector-deployment',
  RELEASE_SEARCH_V2 = 'release_dev-7915_search-v2',
  EXPERIMENT_SOLUTION_RATING = 'experiment_dev-9323_solution_rating_stars',
  RELEASE_USER_PROFILE_V2 = 'release_dev-9289_user_profile_v2',
  // for unit tests
  UNIT_TEST = 'unit_test',
  KILL_SWITCH_INSIGHTS = 'kill_switch_dev-9253_insights',
  RELEASE_FAKE_DOOR_DISCUSSION = 'release_dev-9437_fake_door_discussion'
}
