import {
  computed,
  Ref, ref, SetupContext, watch
} from '@vue/composition-api'
import questionApi from '@src/api/questions'
import useSnackbar from '@src/composables/snackbar'
import useAskDialog from '@src/composables/askDialog'
import useStore from '@src/store'
import { IAttachment } from '@src/types/attachments.types'
import { useRoute } from '@src/composables/router'

export default function useQuestionContent({ emit }: SetupContext, draftQuestionId: Ref<number | undefined>) {
  const { initialQuestionTitle } = useAskDialog()
  const { showSnackbar } = useSnackbar()
  const store = useStore()
  const route = useRoute()

  const newlyCreatedQuestionId = ref<number | undefined>(undefined)
  const questionId = computed(() => draftQuestionId.value || newlyCreatedQuestionId.value)

  const questionTitle = ref('')
  const questionDescription = ref('')
  const questionCategory = ref<number | undefined | null>(undefined)
  const questionKnowledgeSpace = ref<number | null>(null)
  const initialKnowledgeSpace = ref<number | null>(null) // for tracking
  const questionAttachments = ref<IAttachment[]>([])
  const generalFeedSelected = ref<boolean>(false)
  const questionLanguage = ref('xx')

  async function createNewQuestion() {
    questionTitle.value = initialQuestionTitle?.value ?? ''
    questionDescription.value = ''
    questionCategory.value = undefined
    questionKnowledgeSpace.value = null
    initialKnowledgeSpace.value = null
    questionAttachments.value = []
    const knowledgeSpaceId = typeof route?.query.space === 'string' ? Number(route.query.space) : null
    const { data: { id } } = await questionApi.createQuestion({
      title: initialQuestionTitle?.value ?? '',
      description: '',
      category: undefined,
      knowledge_space: knowledgeSpaceId
    })
    newlyCreatedQuestionId.value = id

    // The last draft id is stored in the store such that the user can continue editing
    // if they have closed the dialog and re-opened it.
    store.commit('ask/setCurrentDraftQuestionId', id)
  }

  // load existing, whether it's a draft or a published one
  async function loadExistingQuestion(id: number) {
    try {
      const {
        title,
        description,
        category,
        knowledge_space_id,
        attachments,
        language
      } = await loadPlainQuestionAndDependencies(id)
      questionTitle.value = title
      questionDescription.value = description
      questionCategory.value = category ? category.id : category // category can be null or undefined, there is a difference
      questionKnowledgeSpace.value = knowledge_space_id
      initialKnowledgeSpace.value = knowledge_space_id
      questionAttachments.value = attachments
      questionLanguage.value = language
    } catch {
      showSnackbar('ask-overlay.error.question-loading')
      emit('close')
    }
  }

  async function loadPlainQuestionAndDependencies(id: number) {
    const { data: question } = await questionApi.getQuestion(id, false)
    const attachments = await questionApi.getAttachments(id).then(({ data }) => data).catch(() => [])
    return { ...question, attachments }
  }

  watch(draftQuestionId, (id) => {
    if (id) {
      loadExistingQuestion(id)
    } else {
      createNewQuestion()
    }
  }, { immediate: true })

  return {
    questionId,
    questionTitle,
    questionDescription,
    questionCategory,
    questionKnowledgeSpace,
    questionAttachments,
    initialKnowledgeSpace,
    generalFeedSelected,
    questionLanguage
  }
}
