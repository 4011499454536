






















































































































import Vue, { PropType } from 'vue'
import { Editor } from '@tiptap/vue-2'
import { ITipTapPlugins, EntityType } from '@src/types/tiptap.types'
import { getEntity } from '@src/utilities/tipTap'
import { mapGetters } from 'vuex'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import SMTipTapToolbarButton from './components/SMTipTapToolbarButton.vue'
import SMTipTapToolbarButtonLink from './components/SMTipTapToolbarButtonLink.vue'
import SMTipTapToolbarImageButton from './components/SMTipTapToolbarImageButton/index.vue'
import SMTipTapToolbarVideoButton from './components/SMTipTapToolbarVideoButton/index.vue'
import SMTipTapToolbarEmojiButton from './components/SMTipTapToolbarEmojiButton/index.vue'
import { EditorExtensionsType } from './toolbarExtensions'

export default Vue.extend({
  name: 'SMTipTapToolbar',
  components: {
    SMTipTapToolbarButton,
    SMTipTapToolbarButtonLink,
    SMTipTapToolbarImageButton,
    SMTipTapToolbarVideoButton,
    SMTipTapToolbarEmojiButton
  },
  props: {
    editor: {
      type: Object as () => Editor,
      required: true
    },
    extensions: {
      type: Array as () => ITipTapPlugins[],
      required: true
    },
    entityType: {
      type: String as PropType<EntityType>,
      required: false,
      default: ''
    },
    entityId: {
      type: Number,
      required: false,
      default: undefined
    },
    extensionType: {
      type: String as PropType<EditorExtensionsType>,
      default: ''
    }
  },
  data() {
    return {
      ITipTapPlugins
    }
  },
  computed: {
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings;
    },
    isImageUploadEnabled(): boolean {
      return !!this?.realmSettings?.content?.attachment_upload_enabled && this.extensions.includes(ITipTapPlugins.image)
    },
    shortcutOSKey(): string {
      const os = window.navigator.userAgent.toLowerCase()
      if (os.includes('mac')) {
        return '⌘'
      }
      return 'Ctrl'
    }
  },
  methods: {
    trackToolbarIconClick(eventName: string): void {
      if (eventName) {
        this.$trackEvent(eventName, this.$options.name, {
          state: getEntity(this.extensionType as EditorExtensionsType)
        })
      }
    }
  }
})
