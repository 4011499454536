import useTracking from '@src/composables/tracking/tracking'
import { ITrackingProps } from '@src/types/events.types'
import { ITooltipContextRefs, OnboardingTooltipFlow } from '@src/types/onboardingTooltips.types'
import { computed, watch } from '@vue/composition-api'

export function useEducationalTooltipsTracking(contextRefs: ITooltipContextRefs) {
  const { trackMixpanelEvent } = useTracking()
  const isLastTooltip = computed(() => contextRefs.currentTooltipIndex.value + 1 >= contextRefs.totalTooltipsOfCurrentFlow.value)

  const commonClickEventProps = {
    component: 'educational_tooltip',
    element: 'button',
    type: 'click'
  } as ITrackingProps

  function trackEducationalTooltipView() {
    trackMixpanelEvent('viewEducationalTooltips', {
      tooltip_flow: contextRefs.currentTooltipFlow.value,
      automatic: true,
      component: 'educational_tooltip',
      type: 'view'
    })
  }

  function trackClickDismiss() {
    trackMixpanelEvent('clickDismissEducationalTooltips', {
      onboarding_step: contextRefs.currentTooltipIndex.value + 1,
      tooltip_flow: contextRefs.currentTooltipFlow.value,
      ...commonClickEventProps
    })
  }

  function trackClickNext() {
    if (isLastTooltip.value) {
      trackMixpanelEvent('clickCompleteEducationalTooltips', {
        tooltip_flow: contextRefs.currentTooltipFlow.value,
        ...commonClickEventProps
      })
    } else {
      trackMixpanelEvent('clickNextEducationalTooltips', {
        onboarding_step: contextRefs.currentTooltipIndex.value + 1,
        tooltip_flow: contextRefs.currentTooltipFlow.value,
        ...commonClickEventProps
      })
    }
  }

  watch(contextRefs.isHighlighting, (value) => {
    if (value && ![ OnboardingTooltipFlow.Desktop, OnboardingTooltipFlow.Mobile ].includes(contextRefs.currentTooltipFlow.value)) {
      trackEducationalTooltipView()
    }
  })

  return {
    trackClickDismiss,
    trackClickNext
  }
}
