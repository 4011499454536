export function sameMonthAndYear(date1: string, date2:string) {
  const d1 = new Date(date1)
  const d2 = new Date(date2)
  const m1 = d1.getMonth()
  const m2 = d2.getMonth()
  const y1 = d1.getFullYear()
  const y2 = d2.getFullYear()
  return m1 === m2 && y1 === y2
}

type TimeUnit = 'seconds' | 'minutes' | 'hours' | 'days' | 'weeks'
const millisecondsMappingTable: Record<TimeUnit, number> = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  days: 1000 * 60 * 60 * 24,
  weeks: 1000 * 60 * 60 * 24 * 7
}

export function subtractFromDate(date: Date, amount: number, unit: TimeUnit): Date {
  const millisToSubtract = amount * millisecondsMappingTable[unit]
  return new Date(date.valueOf() - millisToSubtract)
}

export function addToDate(date: Date, amount: number, unit: TimeUnit): Date {
  const millisToAdd = amount * millisecondsMappingTable[unit]
  return new Date(date.valueOf() + millisToAdd)
}

export function timeDiff(date1: string, date2: string, unit: TimeUnit): number {
  const millisecondsDifference = Math.abs(new Date(date2).valueOf() - new Date(date1).valueOf())
  return millisecondsDifference / millisecondsMappingTable[unit]
}

export function withinDateRange(startDate: string, endDate: string, currentDate: string): boolean {
  return new Date(currentDate) >= new Date(startDate) && new Date(currentDate) <= new Date(endDate)
}
