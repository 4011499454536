





































import {
  defineComponent
} from '@vue/composition-api'
import announcementsApi from '@src/api/web/announcements'
import useTracking from '@src/composables/tracking/tracking'
import { useAnnouncementsStore } from '@src/pinia/announcements'

export default defineComponent({
  name: 'AnnouncementInteractions',
  props: {
    announcementId: {
      type: Number,
      required: true
    },
    totalreactions: {
      type: Number,
      default: 0
    },
    userHasReacted: {
      type: Boolean,
      default: false
    },
    totalComments: {
      type: Number,
      default: 0
    },
    reactionsEnabled: {
      type: Boolean,
      default: false
    },
    commentsEnabled: {
      type: Boolean,
      default: false
    },
    nonInteractive: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { reloadUserAnnouncements } = useAnnouncementsStore()
    const { trackMixpanelEvent } = useTracking()

    async function react() {
      if (props.userHasReacted) {
        await announcementsApi.deleteReaction(props.announcementId)
        trackMixpanelEvent('removeAnnouncementClap', {
          element: 'announcement',
          announcementId: props.announcementId
        })
        reloadUserAnnouncements()
      } else {
        await announcementsApi.react(props.announcementId)
        trackMixpanelEvent('addAnnouncementClap', {
          element: 'announcement',
          announcementId: props.announcementId
        })
        reloadUserAnnouncements()
      }
    }
    return {
      react
    }
  }
})
