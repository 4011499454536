







import Vue from 'vue'
import Header from './Header.vue'

export default Vue.extend({
  name: 'PlainLayout',
  components: {
    Header
  }
})
