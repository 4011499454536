














import Vue from 'vue'

export default Vue.extend({
  name: 'Badge',
  props: {
    badge: {
      type: Object,
      default: () => ({})
    },
    size: {
      type: Number,
      default: 70
    }
  }
})
