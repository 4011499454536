

































import Vue from 'vue'

export default Vue.extend({
  name: 'SMSidenavMenuItem',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    routerLinkTo: {
      type: String,
      default: ''
    },
    isEnabled: {
      type: Boolean,
      default: true
    }
  }
})
