




























































import { createNamespacedHelpers } from 'vuex'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import {
  EXPAND, COLLAPSE, SELECT, CLICK_ALL
} from '@src/plugins/analytics/events/views/knowledgeSpaces.events'
import { defineComponent } from '@vue/composition-api'
import useKnowledgeSpaces from '@src/composables/knowledgeSpaces'

const {
  mapState, mapActions, mapGetters
} = createNamespacedHelpers('knowledgeSpaces')

export default defineComponent({
  name: 'KnowledgeSpacesSidenavMenuItem',
  setup() {
    const { currentKnowledgeSpaceId } = useKnowledgeSpaces()

    return { currentKnowledgeSpaceId }
  },
  data() {
    return {
      expanded: true
    }
  },
  computed: {
    ...mapState([ 'ownKnowledgeSpaces' ]) as {
      ownKnowledgeSpaces: () => IKnowledgeSpace[];
    },
    ...mapGetters([ 'hasKnowledgeSpaces' ]) as {
      hasKnowledgeSpaces: () => boolean;
    },
    isAllKsActive(): boolean {
      return this.$route.name === 'spaces'
    }
  },
  created() {
    this.load()
  },
  methods: {
    ...mapActions([ 'load' ]) as {
      load: () => Promise<void>;
    },
    enterKnowledgeSpace(id: number): void {
      this.$emit('click') // used to tell the parent to close the sidenav
      this.$trackEvent(SELECT, this.$options.name, {
        knowledge_space_id: id,
        element: 'list_item'
      })
      this.$router.push(`/stream?space=${id}`)
    },
    toggle(): void {
      const value = !this.expanded
      this.expanded = value
      this.$trackEvent(value ? EXPAND : COLLAPSE, this.$options.name, {
        knowledge_space_id: this.currentKnowledgeSpaceId
      })
    },
    goToList(): void {
      this.$emit('click') // used to tell the parent to close the sidenav
      this.$trackEvent(CLICK_ALL, this.$options.name)
      this.$router.push('/spaces')
    }
  }
})
