












import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { ISnackbar } from '@src/types/snackbar.types'

const { mapGetters } = createNamespacedHelpers('snackbar')

export default Vue.extend({
  name: 'SMSnackbarRenderer',
  computed: {
    ...mapGetters([ 'isSnackbarVisible', 'getSnackbar' ]) as {
      isSnackbarVisible: () => boolean;
      getSnackbar: () => ISnackbar | undefined;
    }
  }
})
