































































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import useUser from '@src/composables/user'
import useTracking from '@src/composables/tracking/tracking'
import { useRoute } from '@src/composables/router'
import SMAdminMenuItem from './components/SMAdminMenuItem.vue'
import useAdminMenu, { IMenuItem } from './composables/useAdminMenu'

export default defineComponent({
  name: 'SMAdminMenu',
  components: {
    SMAdminMenuItem
  },
  setup() {
    const route = useRoute()
    const { menuItems } = useAdminMenu()
    const { trackMixpanelEvent } = useTracking()
    const { roles } = useUser()
    const expandedItems = ref<IMenuItem[]>([])

    // If it's mounted with the path of a subitem, the menu starts already
    // expanded so we need to add the expanded item to the array
    onMounted(() => {
      menuItems.value.forEach((item) => {
        const hasActiveSubItems = !!item.subsections?.find((subsection) => isActive(subsection))
        if (hasActiveSubItems) {
          expandedItems.value = [ ...expandedItems.value, item ]
        }
      })
    })

    function handleListGroupClick(item: IMenuItem) {
      if (isExpanded(item)) {
        expandedItems.value = expandedItems.value.filter((i) => i.title !== item.title)
      } else {
        expandedItems.value = [ ...expandedItems.value, item ]
      }
    }

    function isExpanded(item: IMenuItem) {
      return expandedItems.value.includes(item)
    }

    function hasPrivilege(item: IMenuItem) {
      return item.roles.some((role) => roles.value.includes(role))
    }

    function isActive(item: IMenuItem) {
      if (item.subsections) {
        return item.subsections?.map(({ routerLinkTo }) => routerLinkTo).some((routerLinkTo) => route.path.includes(routerLinkTo!))
      }
      return item.routerLinkTo && route.path.includes(item.routerLinkTo)
    }

    function trackAdminMenuClick(item: IMenuItem) {
      trackMixpanelEvent('clickInAdminMenu', {
        component: 'menu',
        element: 'menu',
        type: 'click',
        label: item.title
      })
    }

    return {
      menuItems,
      isExpanded,
      hasPrivilege,
      isActive,
      trackAdminMenuClick,
      handleListGroupClick
    }
  }
})
