










































import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { INotificationItem } from '@src/types/notifications.types'
import startNotificationConnection from '@src/api/sse/notifications'
import { getCurrentWindowEnvironment, IframeEnvironment } from '@src/utilities/iframeDetection'
import { INotificationV2User, IUser } from '@src/types/user.types'
import NotificationHeader from './NotificationsHeader/index.vue'
import NotificationsListItem from './NotificationsListItem/index.vue'
import NotificationsDialog from './NotificationsDialog/index.vue'

const LOAD_UNSEEN_NOTIFICATIONS_INTERVAL = 30000

export default Vue.extend({
  name: 'SMNotifications',
  components: {
    NotificationHeader,
    NotificationsListItem,
    NotificationsDialog
  },
  data() {
    return {
      intervalId: 0 as number,
      closeSocketConnection: null as (() => void) | null
    }
  },
  computed: {
    ...mapGetters('notifications', [ 'getNotifications', 'getLoading', 'getLoadingNewNotifications', 'getDrawerVisibility' ]) as {
      getNotifications: () => INotificationItem<IUser | INotificationV2User>[];
      getLoading: () => boolean;
      getLoadingNewNotifications: () => boolean;
      getDrawerVisibility: () => boolean;
    },
    navigationDrawerWidth(): string {
      return this.$vuetify.breakpoint.smAndUp ? '400' : '100%'
    }
  },
  async created(): Promise<void> {
    this.loadInitialNotifications()
    // so that we tackle the case where each employee have a connection open(inside iframe) for each tab of their intranet
    const environment = getCurrentWindowEnvironment()
    if (environment === IframeEnvironment.VUE_STANDALONE) {
      this.fetchNotificationWithSSE()
    } else {
      this.fetchNotificationWithInterval()
    }
  },
  destroyed(): void {
    if (this.intervalId) {
      window.clearInterval(this.intervalId)
    }
    if (this.closeSocketConnection !== null) {
      this.closeSocketConnection()
    }
  },
  methods: {
    ...mapActions('notifications', [ 'loadNotifications', 'loadUnseenNotificationsCount', 'loadInitialNotifications' ]) as {
      loadNotifications: () => Promise<void>;
      loadUnseenNotificationsCount: () => Promise<void>;
      loadInitialNotifications: () => Promise<void>;
    },
    ...mapMutations('notifications', [ 'hideDrawer', 'setNewNotifications', 'setLoadingNewNotifications', 'incrementUnseenNotificationsCount' ]) as {
      hideDrawer: () => void;
      setNewNotifications: (nofications: INotificationItem[]) => void;
      setLoadingNewNotifications: (loadingNewNotification: boolean) => void;
      incrementUnseenNotificationsCount: () => void;
    },
    startNotificationConnection,
    fetchNotificationWithInterval(): void {
      this.intervalId = window.setInterval(() => this.loadUnseenNotificationsCount(), LOAD_UNSEEN_NOTIFICATIONS_INTERVAL)
    },
    async fetchNotificationWithSSE(): Promise<void> {
      this.closeSocketConnection = await this.startNotificationConnection((event) => {
        if (event.data) {
          const notification = JSON.parse(event.data) as INotificationItem
          this.setLoadingNewNotifications(true)
          this.setNewNotifications([ notification ])
          this.incrementUnseenNotificationsCount()
          this.setLoadingNewNotifications(false)
        }
      }, this.fetchNotificationWithInterval)
    },
    async onScroll(e: any): Promise<void> {
      const scrolledHeight = e.target.scrollTop + e.target.clientHeight
      if (scrolledHeight === e.target.scrollHeight && !this.getLoading) {
        await this.loadNotifications()
      }
    },
    navigationDrawerToggle(isNavigationDrawerToggled: boolean): void {
      // handle the case when user closes drawer via backdrop
      if (!isNavigationDrawerToggled) {
        this.hideDrawer()
      }
    }
  }
})
