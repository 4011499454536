import { IVSMMetricSimpleValue, VSMMetricId } from '@src/types/metric.types'

const metric: IVSMMetricSimpleValue = {
  id: VSMMetricId.AVERAGE_QUESTION_VIEWS,
  name: 'admin.vsm.metrics.average-question-views.name',
  currentValue: 0,
  unit: 'absolute',
  loading: true
}

export default metric
