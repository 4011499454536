import { IPaginatedApiResp } from '@src/types/api.types'
import { IFeedItem } from '@src/types/feedItem.types'
import { AxiosPromise } from 'axios'
import createAxiosInstance, { createOptimizedAPI } from '.'

const api = createAxiosInstance('stream')

function getFeedItems({
  query, limit = 3, offset = 0, logQueryType = 'manual', abortController, concepts,
  tags, filter, categories, include_uncategorized, include_archived, minRating, maxRating, sort, knowledgeSpace
}:{
  query?: string;
  limit?: number;
  offset?: number;
  logQueryType?: string;
  abortController?:AbortController,
  tags?: string[],
  concepts?: string[],
  filter?: string,
  categories?: string,
  include_uncategorized?: boolean,
  minRating?: number,
  maxRating?: number,
  sort?: string,
  knowledgeSpace?: number,
  include_archived?: boolean
}):
  AxiosPromise<IPaginatedApiResp<IFeedItem[]>> {
  return api.get('', {
    params: {
      limit,
      log_query_type: logQueryType,
      offset,
      query: query || undefined,
      tags: tags?.join(','),
      concepts: concepts?.join(','),
      filter,
      categories,
      include_uncategorized,
      min_avg_rating: minRating,
      max_avg_rating: maxRating,
      sort,
      include_archived,
      knowledge_space: knowledgeSpace
    },
    signal: abortController ? abortController.signal : undefined
  })
}

function fetchStreamUpdates(since: string):AxiosPromise<{updates: number}> {
  return api.get('updates', { params: { since } })
}

function fetchAlternativeQueries(query: string):AxiosPromise<{alternative_queries: string[]}> {
  return api.get('alternative-queries', { params: { query } })
}

function getQuestionDrafts(): AxiosPromise<IPaginatedApiResp<IFeedItem[]>> {
  return api.get('question-drafts')
}

const streamApi = {
  fetchAlternativeQueries,
  fetchStreamUpdates,
  getFeedItems,
  getQuestionDrafts
}

export default {
  ...streamApi,
  ...createOptimizedAPI(streamApi)
}
