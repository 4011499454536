import { ITrackingProps } from '@src/types/events.types'

export const USER_INFO_EDIT = 'editProfileInformation'
export const USER_INFO_TAB_NAV_SUBSCRIPTION = 'clickUserSubscription'
export const USER_INFO_TAB_NAV_CONTRIBUTIONS = 'clickUserContribution'
export const USER_INFO_TAB_NAV_ACTIVITY = 'clickUserActivity'
export const USER_INFO_TAB_NAV_FOLLOWING = 'clickUserFollowing'

const commonProps = {
  component: 'personal_information_card'
}

export default {
  UserInfo: {
    [USER_INFO_EDIT]: (customProps: { component: string; element: string }): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      state: 'user_profile',
      type: 'click'
    })
  },
  UserTabNavigation: {
    [USER_INFO_TAB_NAV_SUBSCRIPTION]: ({ is_own_profile = true }): ITrackingProps => ({
      ...commonProps,
      component: 'tab_menu',
      element: 'tab',
      state: 'user_profile',
      type: 'click',
      is_own_profile
    }),
    [USER_INFO_TAB_NAV_CONTRIBUTIONS]: ({ is_own_profile = true }): ITrackingProps => ({
      ...commonProps,
      component: 'tab_menu',
      element: 'tab',
      type: 'click',
      is_own_profile
    }),
    [USER_INFO_TAB_NAV_ACTIVITY]: ({ is_own_profile = true }): ITrackingProps => ({
      ...commonProps,
      component: 'tab_menu',
      element: 'tab',
      type: 'click',
      is_own_profile
    }),
    [USER_INFO_TAB_NAV_FOLLOWING]: (): ITrackingProps => ({
      ...commonProps,
      component: 'tab_menu',
      element: 'tab',
      state: 'user_profile',
      type: 'click'
    })
  }
}
