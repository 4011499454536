import { computed, onMounted, ref } from '@vue/composition-api'
import sanitizeHtml from 'sanitize-html'
import streamApi from '@src/api/stream'
import questionsApi from '@src/api/questions'
import useStore from '@src/store'
import { IFeedItem } from '@src/types/feedItem.types'

export default function useQuestionDrafts() {
  const store = useStore()
  const loading = ref(false)
  const totalDrafts = ref(0)
  const questionDrafts = ref<IFeedItem[]>([])
  const hasDrafts = computed(() => totalDrafts.value > 0)
  const currentlyEditingDraftId = computed(() => store.state.ask.currentDraftQuestionId)

  async function getQuestionDrafts() {
    const { data: { total, items } } = await streamApi.getQuestionDrafts()
    totalDrafts.value = total
    questionDrafts.value = items.map((item) => ({ ...item, description: sanitizeHtml(item.description, { allowedTags: [] }) }))
  }

  async function deleteDraft(id: number) {
    await questionsApi.deleteQuestion(id)
    getQuestionDrafts()
  }

  function editDraft(id: number) {
    store.commit('ask/setCurrentDraftQuestionId', id)
  }

  onMounted(async () => {
    loading.value = true
    await getQuestionDrafts()
    loading.value = false
  })

  return {
    totalDrafts,
    hasDrafts,
    loading,
    questionDrafts,
    currentlyEditingDraftId,
    deleteDraft,
    editDraft
  }
}
