import { ITrackingProps } from '@src/types/events.types'

export const VIEW_USER_CONTRIBUTION = 'viewUserContribution'

const commonProps = {
  state: 'main.user.contribution'
}

export default {
  Contribution: {
    [VIEW_USER_CONTRIBUTION]: ({ is_own_profile }: {is_own_profile: boolean}): ITrackingProps => ({
      ...commonProps,
      type: 'view',
      is_own_profile
    })
  }
}
