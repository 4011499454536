import { Module, MutationTree } from 'vuex'
import { IRootState } from '@src/types/store.types'

export interface ISocialDialogState {
  isInviteDialogOpen: boolean;
}

const state: ISocialDialogState = {
  isInviteDialogOpen: false
}

const mutations: MutationTree<ISocialDialogState> = {
  toggleInviteDialog(state, isOpen: boolean) {
    state.isInviteDialogOpen = isOpen
  }
}

export default {
  namespaced: true,
  state,
  mutations
} as Module<ISocialDialogState, IRootState>
