





























































import Vue from 'vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default Vue.extend({
  name: 'NotificationsHeader',

  computed: {
    ...mapGetters('notifications', [ 'getUnseenNotificationsCount', 'getNotificationsCountAsString' ]) as {
      getUnseenNotificationsCount: () => number;
      getNotificationsCountAsString: () => string;
    }
  },
  methods: {
    ...mapMutations('notifications', [ 'hideDrawer' ]) as {
      hideDrawer: () => void;
    },
    ...mapActions('notifications', [ 'markAllAsRead' ]) as {
      markAllAsRead: () => Promise<void>;
    }
  }
})
