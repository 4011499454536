import { ref } from '@vue/composition-api'
import { defineStore } from 'pinia'
import { IAnnonuncementDismissedInfo, IAnnouncementComplete } from '@src/types/announcements.types'
import announcementApi from '@src/api/web/announcements'
import { usersApi } from '@src/api/apiModuleInstances'

export const useAnnouncementsStore = defineStore('announcements', () => {
  const loading = ref(false)
  const error = ref(false)
  const userAnnouncements = ref<IAnnouncementComplete[]>([])
  const userAnnouncementsLoaded = ref(false)
  const dismissedAnnouncements = ref<IAnnonuncementDismissedInfo[]>([])
  const dismissedAnnouncementsLoaded = ref(false)

  async function loadUserAnnouncements() {
    loading.value = true
    if (!userAnnouncementsLoaded.value) {
      try {
        const { data } = await usersApi.getAnnouncements()
        userAnnouncements.value = data
        error.value = false
      } catch {
        error.value = true
      } finally {
        userAnnouncementsLoaded.value = true
        loading.value = false
      }
    }
  }
  async function reloadUserAnnouncements() {
    loading.value = true
    try {
      const { data } = await usersApi.getAnnouncements()
      userAnnouncements.value = data
      error.value = false
    } catch {
      error.value = true
    } finally {
      loading.value = false
    }
  }
  async function loadDismissedAnnouncements() {
    if (!dismissedAnnouncementsLoaded.value) {
      try {
        const { data } = await announcementApi.getAllDismissed()
        dismissedAnnouncements.value = data
      } catch {
        error.value = true
      } finally {
        dismissedAnnouncementsLoaded.value = true
      }
    }
  }
  async function dismissAnnouncement({ id, knowledge_space_id } : { id: number, knowledge_space_id?: number }) {
    const { data } = await announcementApi.dismiss(id, knowledge_space_id)
    dismissedAnnouncements.value = [ ...dismissedAnnouncements.value, data ]
  }

  return {
    loading,
    error,
    userAnnouncements,
    userAnnouncementsLoaded,
    dismissedAnnouncements,
    dismissedAnnouncementsLoaded,
    loadUserAnnouncements,
    reloadUserAnnouncements,
    loadDismissedAnnouncements,
    dismissAnnouncement
  }
})
