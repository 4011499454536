import { ITrackingProps } from '@src/types/events.types'

export const SELECT_LANGUAGE = 'selectLanguage'

export default {
  LanguageSelection: {
    [SELECT_LANGUAGE]: (customProps: { selected_language: string }): ITrackingProps => ({
      state: 'language-select',
      component: 'language_screen',
      element: 'dropdown',
      type: 'click',
      ...customProps
    })
  }
}
