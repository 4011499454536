import { IAttachment } from '@src/types/attachments.types'
import { AxiosPromise } from 'axios'
import createAxiosInstance from '.'

export interface IAttachmentsApi {
  uploadEntityAttachment(attachmentEndpointPrefix: 'solutions'|'questions', entityId: number, file: File): AxiosPromise<IAttachment>;
  deleteAttachment(attachmentId: number): AxiosPromise<void>;
  uploadInlineAttachment(file: File): AxiosPromise<{ link: string }>;
}

const api = createAxiosInstance('')
const webApi = createAxiosInstance('upload', { web: true })

const attachmentsApi: IAttachmentsApi = {
  uploadEntityAttachment(attachmentEndpointPrefix, entityId, file) {
    const formData = new FormData()
    formData.append('file', file)
    return api.post(`${attachmentEndpointPrefix}/${entityId}/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  deleteAttachment(attachmentId) {
    return api.delete(`attachments/${attachmentId}`)
  },
  uploadInlineAttachment(file) {
    const formData = new FormData()
    formData.append('file', file)
    return webApi.post('inline', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}

export default attachmentsApi
