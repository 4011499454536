import { Module } from 'vuex'

import { IRootState } from '@src/types/store.types'
import metrics, { IVSMMetricsState } from './metrics'

export interface IVSMState {
  metrics: IVSMMetricsState;
}

export default {
  namespaced: true,
  modules: {
    metrics
  }
} as Module<IVSMState, IRootState>
