import { CookieName, getCookie, setCookie } from '@src/helpers/cookie'
import { MixpanelTrackingFn } from '@src/types/events.types'
import { sha256 } from 'crypto-hash'
import { JwtPayload, jwtDecode } from 'jwt-decode'

export async function trackSignInEvent(trackMixpanelEvent: MixpanelTrackingFn<{ clientId: string }>, cookieSameSiteNone = false) {
  const apiToken = getCookie(CookieName.API_TOKEN)

  if (apiToken) {
    const apiTokenHash = await sha256(apiToken)

    if (getCookie(CookieName.AUTH_TRACKING) !== apiTokenHash) {
      const { azp } = jwtDecode<JwtPayload & { azp: string }>(apiToken)
      trackMixpanelEvent('signIn', {
        clientId: azp
      })
      setCookie({
        name: CookieName.AUTH_TRACKING,
        value: apiTokenHash,
        expiration: undefined,
        sameSite: cookieSameSiteNone ? 'None' : 'Strict'
      })
    }
  }
}
