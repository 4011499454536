export enum IframeEnvironment {
  VUE_STANDALONE = 'VUE_STANDALONE',
  VUE_INSIDE_IFRAME_INTEGRATION = 'VUE_INSIDE_IFRAME_INTEGRATION',
  ANGULAR_IFRAME = 'ANGULAR_IFRAME'
}

export function isInCrossDomainIframe() {
  try {
    return window.top?.location.origin !== window.location.origin
  } catch (error) {
    // Accessing window.top.location.origin on a different origin throws an error.
    // Then we know Starmind is being embedded on a different origin like a customer intranet.
    return true
  }
}

export function getWindowLocationObject(): Location {
  return getWindowObject().location
}

export function sameOriginParentExists() {
  return {
    [IframeEnvironment.ANGULAR_IFRAME]: true,
    [IframeEnvironment.VUE_INSIDE_IFRAME_INTEGRATION]: false,
    [IframeEnvironment.VUE_STANDALONE]: false
  }[getCurrentWindowEnvironment()]
}

export function getCurrentWindowEnvironment(): IframeEnvironment {
  try {
    if (window.parent && window.parent.location.origin === window.location.origin) {
      if (window.parent === window) {
        // parent is the same as current window
        // this means we are inside the root Vue application
        return IframeEnvironment.VUE_STANDALONE
      }
      // parent window is a different one than the current window
      // this means we are inside the Angular iframe
      return IframeEnvironment.ANGULAR_IFRAME
    }
    throw new Error('Parent origin is different')
  } catch (error) {
    // when the Vue application is embedded on a different origin as an iframe integration (e.g. MS teams)
    return IframeEnvironment.VUE_INSIDE_IFRAME_INTEGRATION
  }
}

export function getWindowObject(): Window {
  if (sameOriginParentExists()) {
    return window.parent
  }
  return window
}
