
















































import {
  defineComponent, inject, toRefs, watch
} from '@vue/composition-api'
import { ISimilarQuestion } from '@src/types/question.types'
import { IUseTracking } from '../../composables/tracking'
import { SMAskInjectables } from '../../constants'

export default defineComponent({
  name: 'SMVerySimilarQuestionDoubleCheck',
  props: {
    questionTitle: {
      type: String,
      required: true
    },
    verySimilarQuestion: {
      type: Object as () => ISimilarQuestion | undefined,
      default: undefined
    },
    value: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const {
      trackBackFromSimliarQuestionDoubleCheck,
      trackViewSimliarQuestionDoubleCheck
    } = inject(SMAskInjectables.Tracking) as IUseTracking
    const { value } = toRefs(props)

    watch(value, (v) => {
      if (v) {
        trackViewSimliarQuestionDoubleCheck()
      }
    })

    return {
      trackBackFromSimliarQuestionDoubleCheck
    }
  }
})
