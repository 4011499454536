import {
  MutationTree, ActionTree, Module, GetterTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import usersApi from '@src/api/user'
import awaitGetterValue from '@src/utilities/storeUtils'
import _isEmpty from 'lodash/isEmpty'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'

export interface IKnowledgeSpacesState {
  ownKnowledgeSpaces: IKnowledgeSpace[];
  knowledgeSpacesLoaded: boolean
}

const state: IKnowledgeSpacesState = {
  ownKnowledgeSpaces: [],
  knowledgeSpacesLoaded: false
}

const actions: ActionTree<IKnowledgeSpacesState, IRootState> = {
  async load({ commit }) {
    try {
      const { knowledge_spaces: { knowledge_spaces_enabled } } = await awaitGetterValue<IUnauthenticatedRealmSettings>('realmSettings/realmSettings')
      if (knowledge_spaces_enabled) {
        const { data: { items } } = await usersApi.getKnowledgeSpaces()
        commit('setOwnKnowledgeSpacesSorted', items)
      }
    } catch {
      // eslint-disable-next-line no-empty
    } finally {
      commit('setKnowledgeSpacesLoaded')
    }
  }
}

const mutations: MutationTree<IKnowledgeSpacesState> = {
  setOwnKnowledgeSpacesSorted(state, knowledgeSpaces: IKnowledgeSpace[]) {
    knowledgeSpaces.sort((a, b) => a.name.localeCompare(b.name))
    state.ownKnowledgeSpaces = knowledgeSpaces
  },
  setKnowledgeSpacesLoaded(state) {
    state.knowledgeSpacesLoaded = true
  }
}

const getters: GetterTree<IKnowledgeSpacesState, IRootState> = {
  hasKnowledgeSpaces(state) {
    return !_isEmpty(state.ownKnowledgeSpaces)
  }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  actions,
  mutations,
  getters
} as Module<IKnowledgeSpacesState, IRootState>
