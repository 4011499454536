import { IVSMMetric, VSMMetricId } from '@src/types/metric.types'

const metric: IVSMMetric = {
  id: VSMMetricId.USERS_WITH_TOPICS,
  name: 'admin.vsm.metrics.users-with-topics.name',
  description: 'admin.vsm.metrics.users-with-topics.description',
  targetValue: 100,
  currentValue: 0,
  percentageValue: 0,
  unit: 'percentage',
  color: 'blue',
  loading: true,
  status: 'default',
  actions: [
    {
      id: 'manage_categories',
      label: 'admin.vsm.metrics.actions.manage-categories',
      primary: true,
      link: {
        url: '/admin/content/categories/'
      }
    },
    {
      id: 'learn_more',
      label: 'admin.vsm.metrics.actions.learn-more',
      icon: 'ico-sm-help-outline',
      link: {
        url: 'https://help.starmind.com/docs/community-management-best-practices-2',
        target: 'blank'
      }
    }
  ]
}

export default metric
