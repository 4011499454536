




































import {
  defineComponent
} from '@vue/composition-api'

import SMFullscreenHeader from '@src/ui/SMAsk/components/common/SMFullscreenHeader.vue'
import SMQuestionFormHeader from '@src/ui/SMAsk/components/common/SMQuestionFormHeader.vue'

export default defineComponent({
  name: 'SMQuestionDialog',
  components: {
    SMQuestionFormHeader,
    SMFullscreenHeader
  },
  props: {
    isFullscreen: {
      type: Boolean,
      required: true
    },
    hideActions: {
      type: Boolean,
      default: false
    },
    existingQuestionId: {
      type: Number,
      default: undefined
    }
  }
})
