export enum ITipTapPlugins {
  bold = 'bold',
  italic = 'italic',
  underline = 'underline',
  strike = 'strike',
  orderedList = 'orderedList',
  bulletList = 'bulletList',
  link = 'link',
  image = 'image',
  video = 'video',
  history = 'history',
  codeHighlight = 'codeHighlight',
  typography = 'typography',
  markdown = 'markdown',
  mention = 'mention',
  emoji = 'emoji'
}

export type EntityType = 'solution' | 'question' | 'adminGeneric'
