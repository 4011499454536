import { isAuthenticated } from './utilities/auth'
import { versionApi } from './api/apiModuleInstances'

// If we deploy a new version, we should make sure from time to time that the client gets refreshed
// in order to not load any old JS chunks that only existed in the previous version
export default function periodicVersionCheck() {
  const check = async () => {
    if (isAuthenticated()) {
      try {
        await versionApi.getVersion(window.APP_VERSION)
      } catch (error) {
        // do nothing
      }
    }
  }

  // check every 2 minutes
  setInterval(check, 1000 * 60 * 2)
}
