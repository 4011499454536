import { IRootState } from '@src/types/store.types'
import {
  MutationTree, ActionTree, Module, GetterTree
} from 'vuex'
import _find from 'lodash/find'
import _first from 'lodash/first'
import awaitGetterValue from '@src/utilities/storeUtils'
import { ActivationFlowStepStatus, ActivationFlowStep } from '@src/types/activationFlow.types'
import { ITermsTranslations } from '@src/types/terms.types'
import { IUser } from '@src/types/user.types'
import appWrapperHook from '@src/utilities/appWrapper'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import { InterfaceLanguage } from '@src/types/language.types'
import { termsGraphQlApi, userGraphQlApi } from '@src/api/apiModuleInstances'

export interface IActivationFlowTermsState {
  shouldDisplayStep: boolean;
  nextStatus?: ActivationFlowStepStatus;
  isDialogVisible: boolean;
  isDialogReadonly: boolean;
  latestPublishedTermsTranslations?: ITermsTranslations[];
  latestPublishedTermsId?: number;
  globalUserId?: string;
}

const state: IActivationFlowTermsState = {
  shouldDisplayStep: false,
  isDialogVisible: false,
  isDialogReadonly: false,
  latestPublishedTermsId: undefined,
  latestPublishedTermsTranslations: [],
  globalUserId: undefined,
  nextStatus: undefined
}

const actions: ActionTree<IActivationFlowTermsState, IRootState> = {
  async possiblyShowTerms({ commit, dispatch, state }) {
    if (state.shouldDisplayStep) {
      commit('showTermsDialog')
    }
    if (state.nextStatus) {
      dispatch('next', state.nextStatus)
    }
  },
  async acceptTerms({ commit, dispatch, state }) {
    if (state.globalUserId && state.latestPublishedTermsId) {
      try {
        await userGraphQlApi.acceptTerms(state.globalUserId, state.latestPublishedTermsId)
        dispatch('next', ActivationFlowStepStatus.STEPPED_THROUGH_COMPLETE)
      } catch {
        dispatch('next', ActivationFlowStepStatus.ERROR)
      }
      commit('hideTermsDialog')
    } else {
      dispatch('next', ActivationFlowStepStatus.ERROR)
      commit('hideTermsDialog')
    }
  },
  showTermsReadonly({ commit }) {
    commit('showTermsDialog', true)
  },
  next({ dispatch }, status: ActivationFlowStepStatus) {
    appWrapperHook('userHasAgreed')
    dispatch('activationFlow/next', { status, step: ActivationFlowStep.TERMS }, { root: true })
  },
  async loadShouldDisplayStep({ commit }) {
    const { terms_of_use: { enabled } } = await awaitGetterValue<IUnauthenticatedRealmSettings>('realmSettings/realmSettings')

    if (enabled) {
      const { global_user_id } = await awaitGetterValue<IUser>('user/getUser')
      commit('setGlobalUserId', global_user_id)
      try {
        const { user: { latestTermsApproved } } = await userGraphQlApi.getUserLatestTermsApproved(global_user_id)
        const { latestPublishedTerms } = await termsGraphQlApi.getLatestPublishedTerms()
        if (latestPublishedTerms) {
          const { translations, id } = latestPublishedTerms
          commit('setLatestPublishedTermsId', id)
          commit('setLatestPublishedTermsTranslations', translations)
        }
        if (latestTermsApproved) {
          commit('setShouldDisplayStep', false)
          commit('saveNext', ActivationFlowStepStatus.ALREADY_DONE)
        } else if (latestPublishedTerms) {
          commit('setShouldDisplayStep', true)
        } else {
          commit('setShouldDisplayStep', false)
          commit('saveNext', ActivationFlowStepStatus.INACTIVE) // feature enabled but there were never terms published
        }
      } catch {
        commit('setShouldDisplayStep', false)
        commit('saveNext', ActivationFlowStepStatus.ERROR)
      }
    } else {
      commit('setShouldDisplayStep', false)
      commit('saveNext', ActivationFlowStepStatus.INACTIVE)
    }
  }
}

const mutations: MutationTree<IActivationFlowTermsState> = {
  setShouldDisplayStep(state, value) {
    state.shouldDisplayStep = value
  },
  showTermsDialog(state, readonly = false) {
    state.isDialogVisible = true
    state.isDialogReadonly = readonly
  },
  hideTermsDialog(state) {
    state.isDialogVisible = false
  },
  setLatestPublishedTermsId(state, id: number) {
    state.latestPublishedTermsId = id
  },
  setLatestPublishedTermsTranslations(state, translations: ITermsTranslations[]) {
    state.latestPublishedTermsTranslations = translations
  },
  setGlobalUserId(state, globalUserId: string) {
    state.globalUserId = globalUserId
  },
  saveNext(state, next: ActivationFlowStepStatus) {
    state.nextStatus = next
  }
}

const getters: GetterTree<IActivationFlowTermsState, IRootState> = {
  termsInUserLanguage(state, localGetters, rootState, rootGetters): ITermsTranslations | undefined {
    const currentLanguage = rootGetters['language/getCurrentLanguage'] as InterfaceLanguage
    const realmSettings = rootGetters['realmSettings/realmSettings'] as IUnauthenticatedRealmSettings | undefined
    const defaultLanguage = realmSettings?.general.default_language

    return _find(state.latestPublishedTermsTranslations, ({ language }) => language === currentLanguage)
      ?? _find(state.latestPublishedTermsTranslations, ({ language }) => language === defaultLanguage)
      ?? _first(state.latestPublishedTermsTranslations) // Don't really know which language, just pick the first
  },
  shouldDisplayStep(state) {
    return state.shouldDisplayStep
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
} as Module<IActivationFlowTermsState, IRootState>
