import {
  MutationTree, Module
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { ICompanyGuideline } from '@src/types/companyGuidelines.types'

export interface ICompanyGuidelinesState{
  isDialogOpen: boolean;
  isShowingPreview: boolean;
  preview: ICompanyGuideline
}

const state: ICompanyGuidelinesState = {
  isDialogOpen: false,
  isShowingPreview: false,
  preview: { title: '', description: '' }
}

const mutations: MutationTree<ICompanyGuidelinesState> = {
  setDialogState(state, isOpen: boolean) {
    state.isDialogOpen = isOpen
    state.isShowingPreview = false
  },
  // convenience method for external calls
  openCompanyGuidelines(state) {
    state.isDialogOpen = true
    state.isShowingPreview = false
  },
  showPreview(state, preview: ICompanyGuideline) {
    state.preview = preview
    state.isShowingPreview = true
    state.isDialogOpen = true
  }
}

export default {
  namespaced: true,
  state,
  mutations
} as Module<ICompanyGuidelinesState, IRootState>
