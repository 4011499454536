import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'

export interface IOnboardingDialogStatus {
  completed_date: string;
}

export function onboardingDialogApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/onboarding-dialog'
  const methods = {
    getOnboardingDialogStatus() {
      return api.get<IOnboardingDialogStatus>(baseUrl)
    },
    completeOnboardingDialog() {
      return api.post<IOnboardingDialogStatus>(baseUrl)
    },
    resetOnboardingDialog() {
      return api.delete<void>(baseUrl)
    }
  }
  return createOptimizedAPI(methods)
}
