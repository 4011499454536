



































import {
  computed, defineComponent, ref, watch
} from '@vue/composition-api'
import { useI18n } from '@src/plugins/i18n'
import useStore from '@src/store'
import { ICompanyGuideline } from '@src/types/companyGuidelines.types'
import companyGuidelinesApi from '@src/api/web/companyGuidelines'
import SMSimpleEditorOutputView from '@src/ui/SMEditor/TipTap/components/SMEditorOutputView.vue'
import SMDialog from '@src/ui/SMDialog/index.vue'

export default defineComponent({
  name: 'SMCompanyGuidelines',
  components: {
    SMSimpleEditorOutputView,
    SMDialog
  },
  setup() {
    const { locale } = useI18n()
    const store = useStore()

    const isLoading = ref(true)
    const isPreview = computed(() => store.state.companyGuidelines.isShowingPreview)
    const preview = computed(() => store.state.companyGuidelines.preview)
    const isDialogOpen = computed({
      get() {
        return store.state.companyGuidelines.isDialogOpen
      },
      set(value: boolean) {
        store.commit('companyGuidelines/setDialogState', value)
      }
    })
    const guideline = ref<ICompanyGuideline>({ title: '', description: '' })
    const contentToShow = computed<ICompanyGuideline | null>(() => (isPreview.value ? preview.value : guideline.value))

    watch(isDialogOpen, async (isOpen) => {
      if (isOpen) {
        isLoading.value = true
        try {
          const { data } = await companyGuidelinesApi.cached.getGuideline(locale.value)
          guideline.value = data
        } finally {
          isLoading.value = false
        }
      }
    })

    return {
      isDialogOpen,
      isLoading,
      contentToShow
    }
  }
})
