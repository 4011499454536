



























































import {
  computed, defineComponent, ref, watch
} from '@vue/composition-api'
import useRealmSettings from '@src/composables/realmSettings'
import useWorkspaceSwitchTracking from './useWorkspaceSwitchTracking'

export default defineComponent({
  name: 'SMWorkspaceSwitch',
  setup() {
    const logo = '/public/network-logo'
    const showMenu = ref(false)
    const {
      realmSettings, realmSettingsLoading
    } = useRealmSettings()
    const {
      trackViewWorkspaceMenu,
      trackClickWorkspaceMenu,
      trackClickInWorkspaceMenu
    } = useWorkspaceSwitchTracking()

    const networkName = computed(() => realmSettings.value?.metadata?.realm_name)
    const listedWorkspaces = computed(() => realmSettings.value?.workspaces?.listed_workspaces)
    const workspacesEnabled = computed(() => realmSettings.value?.workspaces?.enabled)
    const hasWorkspaces = computed(() => !!listedWorkspaces?.value?.length)

    watch(showMenu, (shouldShowMenu) => {
      if (shouldShowMenu) {
        if (listedWorkspaces?.value) {
          trackViewWorkspaceMenu({ total_workspaces: listedWorkspaces?.value?.length })
        }
      }
    })

    return {
      networkName,
      logo,
      listedWorkspaces,
      workspacesEnabled,
      hasWorkspaces,
      realmSettingsLoading,
      showMenu,
      trackClickWorkspaceMenu,
      trackClickInWorkspaceMenu
    }
  }
})
