import Vue from 'vue'
import _once from 'lodash/once'
import _memoize from 'lodash/memoize'
import _isString from 'lodash/isString'
import _forEach from 'lodash/forEach'
import _isObject from 'lodash/isObject'
import _set from 'lodash/set'
import _unset from 'lodash/unset'
import _some from 'lodash/some'
import * as Sentry from '@sentry/vue'
import VueRouter from 'vue-router'
import { breadcrumbsIntegration } from '@sentry/browser'
import { sha512_256 } from 'js-sha512'

const DEFAULT_ERROR_SAMPLE_RATE = 0.2
const DEFAULT_TRACES_SAMPLE_RATE = 0.2

/**
 * VueBrowserTracingIntegration does extract all url parameter values and adds them as query.* context tract attributes.
 * In order to not expose sensitive data, we can filter using this black list.
 */
const BlackList = [ /query/ ]

export const initSentryOnce = _once(initSentry)

/**
 * Returns a sha512_256 of the provide input. The result is cached.
 */
const sanitizeCache = _memoize((data: string) => {
  return sha512_256(data)
})

interface IScrubFromValueCallback {
    (value: string): string
}

function traverseAndFilter(
  obj: { [key: string]: any },
  keyBlacklist: RegExp[],
  scrubFromValueCallback?: IScrubFromValueCallback
) {
  _forEach(obj, (value: object, key: string) => {
    if (_isObject(value)) {
      traverseAndFilter(value, keyBlacklist, scrubFromValueCallback)
    } else if (isJsonObject(value)) {
      const parsedJSON = JSON.parse(value)
      traverseAndFilter(parsedJSON, keyBlacklist, scrubFromValueCallback)
      _set(obj, key, JSON.stringify(parsedJSON))
    } else {
      const isBlacklist = _some(keyBlacklist, (reg) => reg.test(key))
      if (isBlacklist) {
        _unset(obj, key)
      } else if (scrubFromValueCallback) {
        _set(obj, key, scrubFromValueCallback(value))
      }
    }
  })
}

/**
 * Scrub sensitive data out from Sentry event objects.
 * Filters through both keys and values, recursively.
 * - deleting a key/value if the key name is found in a blacklist
 * - applies a callback function on all non blacklisted primitive values, replacing the original value
 *
 * @param {object} initObj Object to scrub data from
 * @param {array<string | regex> | null} keyBlacklist Array of blacklisted object keys
 * @param {function(primitive) => string | undefined} scrubFromValueCallback A function that filters through primitive values for non blacklisted values
 * @returns {object} Filtered object
 */
export function scrubData(initObj: Sentry.Event, keyBlacklist: RegExp[], scrubFromValueCallback: IScrubFromValueCallback): Promise<Sentry.Event | null> {
  return new Promise((resolve) => {
    try {
      // clone and serialize the object, removing functions and other non JSON-conform values
      const eventObj = JSON.parse(JSON.stringify(initObj))
      traverseAndFilter(eventObj, keyBlacklist, scrubFromValueCallback)
      resolve(eventObj) // return cloned and modified object
    } catch {
      // not a valid JSON it seems, skip sentry event from being sent
      resolve(null)
    }
  })
}

/**
 * Check if valid JSON object or array
 * Fails for any other valid json structure like null, false, true,...
 * Fails when single quotes are used instead of double quotes
 * @see https://stackoverflow.com/a/32278428
 * @param {string} str
 * @returns {boolean}
 */
function isJsonObject(str: string) {
  try {
    const parsedJSON = JSON.parse(str)
    return (!!(parsedJSON && str) && ((parsedJSON instanceof Array || parsedJSON instanceof Object)))
  } catch (e) {
    return false
  }
}

export function sanitizeEventValue(value: string) {
  // eslint-disable-next-line max-params
  function replaceValue(_match: any, urlSeparator: string, paramKey: string, paramValue: string) {
    const maskedValue = sanitizeCache(paramValue)
    return `${urlSeparator}${paramKey}=${maskedValue}`
  }

  const queryParamsRegex = /([?&])([^=]+)=([^&]*)/g
  // we care only for string values
  if (_isString(value)) {
    return value.replaceAll(queryParamsRegex, replaceValue)
  }

  // return raw value
  return value
}

/**
 * Initialises sentry client for vue.js client.
 */
function initSentry(router: VueRouter) {
  if (window.SENTRY_ENABLED && window.SENTRY_PEGASUS_URL) {
    Sentry.init({
      Vue,
      trackComponents: true,
      dsn: window.SENTRY_PEGASUS_URL,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        breadcrumbsIntegration({
          console: false,
          fetch: false,
          history: false, // might cause intellectual property concerns when capturing url query params
          xhr: false // might cause intellectual property concerns when capturing url query params
        })
      ],
      sampleRate: DEFAULT_ERROR_SAMPLE_RATE,
      tracesSampleRate: DEFAULT_TRACES_SAMPLE_RATE,
      release: window.APP_VERSION,
      environment: window.location.hostname,
      sendDefaultPii: false,
      ignoreErrors: [ /Non-Error promise rejection captured with value:/, /NavigationDuplicated/ ], // fixes DEV-7098,
      beforeSend(event) {
        return scrubData(event, BlackList, sanitizeEventValue)
      },
      beforeSendTransaction(event) {
        return scrubData(event, BlackList, sanitizeEventValue)
      },
      tracesSampler(samplingContext) {
        // This is a server-sent event endpoint, which leaves the connection open
        if (samplingContext.transactionContext.name === '/api/v1/notifications/unseen') {
          return 0 // Drop the transaction
        }
        return DEFAULT_TRACES_SAMPLE_RATE
      }
    })
  }
}
