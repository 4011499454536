















import _debounce from 'lodash/debounce'
import jobSkillApi from '@src/api/jobSkill'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'JobTitleInput',
  data() {
    return {
      title: '',
      suggestions: [] as { title: string }[],
      isFocused: false,
      debouncedLoadSuggestions: null as any
    }
  },
  computed: {
    hasTitle(): boolean {
      return !!(this.title && this.title.length)
    },
    isSuggestion(): boolean {
      return this.suggestions.some((suggestion) => suggestion.title === this.title)
    }
  },
  watch: {
    title(): void {
      if (this.title) {
        if (!this.debouncedLoadSuggestions) {
          this.debouncedLoadSuggestions = _debounce(this.loadSuggestions, 300)
        }
        this.$emit('change', this.title, this.isSuggestion)
        this.debouncedLoadSuggestions()
      }
    }
  },
  methods: {
    async loadSuggestions(): Promise<void> {
      if (this.hasTitle) {
        try {
          const { data } = await jobSkillApi.getJobs(this.title)
          // The suggestion items must be an object since this is the only
          // way to differentiate between a manually typed job title (string)
          // the selection of a suggestion (object). Required for tracking.
          this.suggestions = data.values.map((title) => ({ title }))
        } catch {
          this.suggestions = []
        }
      }
    }
  }
})
