









import {
  defineComponent
} from '@vue/composition-api'
import { useSidenavState } from '@src/ui/SMSidenav/composables/useSidenavState'
import { useOAuthClientId } from '@src/composables/useOAuthClientId'

export default defineComponent({
  name: 'SMSidenavMobileActivatorButton',
  setup() {
    const { toggleSidenav } = useSidenavState()
    const { isMsTeamsPersonalApp } = useOAuthClientId()

    return {
      isMsTeamsPersonalApp,
      toggleSidenav
    }
  }
})
