import { OnboardingTooltipId } from '@src/types/onboardingTooltips.types'
import { watch } from '@vue/composition-api'
import { DirectiveOptions } from 'vue'
import _throttle from 'lodash/throttle'
import { useOnboardingTooltips } from '../composables/useOnboardingTooltips'

// useUpdateInterval makes sense for components in a scrollable area
export function createOnboardingTooltipDirective(useUpdateInterval = false, allowInIframe = false) {
  const {
    updateOverlayCutout, currentTooltipId, updateBreakpoint, isHighlighting
  } = useOnboardingTooltips() // eslint-disable-line vue-composable/composable-placement
  let unwatch: () => void
  let element: HTMLElement
  let tooltipId: OnboardingTooltipId
  let intervalId: ReturnType<typeof setInterval>

  const updateListener = _throttle(() => {
    updateOverlayCutout({
      dimensions: element.getBoundingClientRect(),
      tooltipId
    })
    updateBreakpoint(window.innerWidth) // used to dynamically switch between desktop- and mobile flow
  }, 1000)

  const isVueStandaloneAppNotIframe = window.self === window.parent

  return {
    inserted(el, binding) {
      if (isVueStandaloneAppNotIframe || allowInIframe) {
        element = el
        tooltipId = binding.value as OnboardingTooltipId

        unwatch = watch(currentTooltipId, () => {
          updateListener()
        }, { immediate: true })
        window.addEventListener('resize', updateListener)

        // for some components, if the tooltip is displayed too early and the component is still busy layouting
        // it loses its overlay cutout. we can mitigate this problem by updating it the "dumb" way
        if (useUpdateInterval) {
          intervalId = setInterval(() => {
            if (isHighlighting.value) {
              updateListener()
            }
          }, 1000)
        }
      }
    },
    unbind(el, binding) {
      if (isVueStandaloneAppNotIframe || allowInIframe) {
        // reset the dimensions if the element disappears,
        // such that the tooltip falls back to the center of the viewport
        updateOverlayCutout({
          dimensions: new DOMRect(),
          tooltipId
        })
        // In some cases the directive gets unbound for whatever reason I couldn't figure out
        // There we must add the 'forever' modifier v-onboarding-tooltip.forever="${tooltipId}"
        if (!binding.modifiers.forever) {
          unwatch()
          window.removeEventListener('resize', updateListener)
          if (useUpdateInterval) {
            clearInterval(intervalId)
          }
        }
      }
    }
  } as DirectiveOptions
}
