import { IQuestion } from './question.types'
import { ISolutionComplete } from './solution.types'
import { ITermsVersion } from './terms.types'

// User that can be fetched from either accounts or phoenix
export type IGlobalUser = Pick<IUser, 'firstname' | 'lastname' | 'email' | 'position'> & {
  globalUserId?: string,
  global_user_id?: string,
  avatarUrl?: string | undefined,
  image?: string
}

export interface IGlobalUserWithScore extends IGlobalUser {
  score: number
}

export interface IUser {
  about: string | null;
  company: string | null;
  country: string;
  customfields: object | null;
  department: string | null;
  email: string | null;
  event_tracking_id: string;
  first_seen: string | null;
  firstname: string;
  gender: string;
  global_user_id: string;
  id: number;
  image: string;
  is_anonymized: boolean | null;
  is_deleted: boolean;
  is_technical_user: boolean;
  language: string;
  last_seen: string | null;
  lastname: string;
  location: string;
  nps_class?: number | null;
  nps_score?: number | null;
  position: string | null;
  score_level: number;
  score_points: number;
  utc_offset_minutes: number | null;
}

export interface INotificationV2User {
  global_user_id: string;
  avatar: string;
  first_name: string;
  last_name: string;
}

export interface IUserWithTooltip extends IUser {
  tooltipText: string
}

export type MatchFields =
  | 'fullName'
  | 'firstName'
  | 'lastName'
  | 'department'
  | 'position'
  | 'expertise';
export interface IPerson {
  user: IUser;
  termMatch?: MatchFields[];
  expert_tags: {
    label: string;
  }[];
  type?: 'USER' | 'EXPERT';
}
export interface IAccountUserProps {
  socialLinks: ISocialLink[];
}

export const accountUserPropNames: (keyof IAccountUserProps)[] = [
  'socialLinks'
]

export type IEditUser = IUser & IAccountUserProps;

export interface ISocialLink {
  type: SocialLinkType;
  handle: string;
  name?: string;
}

export enum SocialLinkType {
  Website = 'website',
  GitHub = 'github',
  LinkedIn = 'linkedin',
  Xing = 'xing',
  Custom = 'custom',
}

export interface IFollow {
  is_following: boolean;
  last_follower_change: string;
}

export interface ISkills {
  tags: ITag[];
  total: number;
  user_defined: boolean;
}

export type ScoreDelta = 'same' | 'increase' | 'decrease';
export interface ITag {
  confirmed: boolean;
  display_score: number;
  id: number;
  label: string;
  score: number;
  user_defined: boolean;
  details: ITagDetails;
  isNewExpertise?: boolean;
  previous_display_score: number;
  previous_score: number;
  score_delta?: ScoreDelta;
}

export interface ITagDetails {
  average_rating: number;
  questions_answered: number;
  recommendations_received: number;
  connector_interactions_count: number;
  connector_interactions: IConnectorInteractions;
  from_job_description: boolean;
}
export interface IConnectorInteractions {
  [key: string]: number;
}

export interface IExpertiseTag {
  id: number;
  label: string;
  score: number;
}

export interface IOAuthClient {
  id: number;
  oauth_client_id: string;
  user_id: number;
  updated: string;
  revocation_date: null;
  created: string;
  device_name: null;
  device_type: null;
  model_name: null;
  model_identifier: null;
  last_refresh: string;
}

export enum PrivilegedUserRoles {
  SuperAdmin = 'super_admin',
  ContentAdmin = 'content_admin',
  CommunicationAdmin = 'communication_admin',
  NetworkStatisticsAdmin = 'network_statistics_admin',
  SettingsAdmin = 'settings_admin',
  UserAdmin = 'user_admin',
  UserStatisticsAdmin = 'user_statistics_admin',
}

export enum ManagerUserRoles {
  Manager = 'manager',
}

export interface IGetUsersParams {
  deleted?: boolean;
  technical?: boolean;
  sort?: 'id' | 'firstname' | 'lastname' | 'email' | 'first_seen' | 'last_seen';
  sortDirection?: 'asc' | 'desc';
  limit?: number;
  offset?: number;
  role?: string;
  query?: string;
  deanonymizeUsersEmail?: boolean;
  filter?: 'active' | 'inactive' | null;
}

export interface IGetUsersResponse {
  items: IUser[];
  total: number;
}

export interface IUserSettings {
  languages: IUserSettingsLanguages;
  profile: IUserSettingsProfile;
}

export interface IUserSettingsLanguages {
  content_languages: string[];
  has_all_languages: boolean;
  interface_language: string;
  translation_language: string;
}

export interface IUserSettingsProfile {
  show_following_list: boolean,
  show_follower_list: boolean,
}

export interface IUserActivity {
  created: string;
  icon: string;
  question: IQuestion;
  solution?: ISolutionComplete;
  type: 'solution' | 'question' | 'solution_comment' | 'question_comment';
  url: string;
}

export interface IUserFollowing {
  user: IUser;
  requesting_user_is_following: boolean;
  know_how: {
    suggestions: Pick<ITag, 'confirmed' | 'label' | 'score'>[];
  }
}

export interface IFederatedIdentityLink {
  identityProviderAlias: string;
  providerUserId: string;
  providerUsername: string;
}

export interface IGraphqlUserSchema {
  globalUserId: string;
  idpUserId: string;
  created: string;
  updated: string;
  firstSeen: string;
  email: string;
  isTechnicalUser: boolean;
  isFederated: boolean;
  dateDeleted: string;
  dateAnonymized: string;
  dateAnonymize: string;
  gdprAgreement: string;
  disclaimerAgreement: string;
  languageId: string;
  firstname: string;
  lastname: string;
  gender: string;
  about: string;
  countryId: string;
  location: string;
  company: string;
  department: string;
  position: string;
  employmentStart: string;
  customfields: string;
  externalId: string;
  utcOffsetMinutes: number;
  teams: {
    id: string;
    name: string;
    slots: number;
    clientId: string;
  }[];
  latestTermsApproved: boolean;
  termsOfUse: Partial<ITermsVersion> | null;
  socialLinks: ISocialLink[];
  avatarUrl?: string;
  federatedIdentityLinks: IFederatedIdentityLink[];
}

export interface ISettingsParams {
  languages: Partial<IUserSettingsLanguages>
  profile: IUserSettingsProfile
}

export interface IUserProject {
  title: string
  description: string
  url: string | null
}

export interface IUserProjectResp extends IUserProject {
  id: string
}
