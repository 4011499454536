export enum ActivationFlowStepStatus {
  ALREADY_DONE = 'ALREADY_DONE',
  STEPPED_THROUGH_COMPLETE = 'STEPPED_THROUGH_COMPLETE',
  STEPPED_THROUGH_INCOMPLETE = 'STEPPED_THROUGH_INCOMPLETE',
  INACTIVE = 'INACTIVE',
  ERROR = 'ERROR'
}

export enum ActivationFlowStep {
  LANGUAGE_SELECTION = 'LANGUAGE_SELECTION',
  TERMS = 'TERMS',
  ONBOARDING_TOOLTIPS = 'ONBOARDING_TOOLTIPS',
  JOB_WIZARD = 'JOB_WIZARD',
  WHATS_NEW = 'WHATS_NEW',
  ONBOARDING_CHECKLIST = 'ONBOARDING_CHECKLIST',
  EDUCATION_DIALOG_EXPERTS = 'EDUCATION_DIALOG_EXPERTS',
  EDUCATION_DIALOG_USER_INTERACTION = 'EDUCATION_DIALOG_USER_INTERACTION'
}

export type ActivationFlowPhoenixTriggerFn = () => Promise<ActivationFlowStepStatus>
