import BadgesCard from './views/BadgesCard.events'
import Contribution from './views/Contribution.events'
import knowledgeSpaces from './views/knowledgeSpaces.events'
import mentionUser from './views/mentionUser.events'
import NotificationsDialog from './ui/NotificationsDialog.events'
import NotificationsDialogItem from './ui/NotificationsDialogItem.events'
import NotificationsListItem from './ui/NotificationsListItem.events'
import questionEvents from './views/question.events'
import SearchResultPerson from './views/SearchResultPerson.events'
import SMActivationFlowEvents from './ui/SMActivationFlow.events'
import SMAskEvents from './ui/SMAsk.events'
import SMFollowUserEvents from './ui/SMFollowUser.events'
import SMHeaderProfileMenu from './ui/SMHeaderProfileMenu.events'
import SMLanguageSelectionEvents from './ui/SMLanguageSelection.events'
import SMNewQuestionFollowUpEvents from './ui/SMNewQuestionFollowUp.events'
import SMNotificationsButton from './ui/SMNotificationsButton.events'
import SMTipTapToolbar from './ui/SMTipTapToolbar.events'
import SMTranslationToggle from './ui/SMTranslationToggle.events'
import SMUserGuideSidenavItem from './ui/SMUserGuideSidenavItem.events'
import solutionApproval from './views/solutionApproval.events'
import termsEvents from './views/terms.events'
import userInfo from './views/userInfo.events'
import userSkills from './views/userSkills.events'
import vsmMetrics from './views/vsmMetrics.events'
import vsmSetup from './views/vsmSetup.events'

export default {
  ...BadgesCard,
  ...Contribution,
  ...knowledgeSpaces,
  ...mentionUser,
  ...NotificationsDialog,
  ...NotificationsDialogItem,
  ...NotificationsListItem,
  ...questionEvents,
  ...SearchResultPerson,
  ...SMActivationFlowEvents,
  ...SMAskEvents,
  ...SMFollowUserEvents,
  ...SMHeaderProfileMenu,
  ...SMLanguageSelectionEvents,
  ...SMNewQuestionFollowUpEvents,
  ...SMNotificationsButton,
  ...SMTipTapToolbar,
  ...SMTranslationToggle,
  ...SMUserGuideSidenavItem,
  ...solutionApproval,
  ...termsEvents,
  ...userInfo,
  ...userSkills,
  ...vsmMetrics,
  ...vsmSetup
}
