export enum InitialExpertiseSourceType {
  Search = 'search', // TODO: DEV-9286: seems to be set wrongly when starmind suggestions are picked
  StarmindSuggested = 'starmind_suggested',
  Manual = 'manual',
  ProfileV2 = 'profile_v2'
}
export interface IInitialExpertise {
  label: string,
  language_id: string,
  concept_id?: string,
  ontology_id?: string,
  source: InitialExpertiseSourceType
}
