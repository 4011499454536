import useTracking from '@src/composables/tracking/tracking'
import { InteractionType } from '@src/types/events.types'

export default function useWorkspaceSwitchTracking() {
  const { trackMixpanelEvent } = useTracking()

  const commonProps = {
    component: 'dropdown_menu',
    type: 'click' as InteractionType,
    state: 'profile'
  }

  function trackViewWorkspaceMenu({ total_workspaces } : {total_workspaces: number}) {
    return trackMixpanelEvent('viewWorkspaceMenu', {
      ...commonProps,
      type: 'view',
      total_workspaces
    })
  }

  function trackClickWorkspaceMenu() {
    return trackMixpanelEvent('clickWorkspaceMenu', {
      ...commonProps,
      component: 'navigation_menu',
      element: 'dropdown_menu'
    })
  }

  function trackClickInWorkspaceMenu({ list_position } : {list_position: number}) {
    return trackMixpanelEvent('viewWorkspaceMenu', {
      ...commonProps,
      element: 'list_item',
      list_position
    })
  }

  return {
    trackViewWorkspaceMenu,
    trackClickWorkspaceMenu,
    trackClickInWorkspaceMenu
  }
}
