





import { defineComponent } from '@vue/composition-api'
import SMFeedbackDialogAnchor from './components/SMFeedbackDialogAnchor.vue'
import SMFeedbackDialogContent from './components/SMFeedbackDialogContent.vue'

export default defineComponent({
  name: 'SmFeedbackDialog',
  components: {
    SMFeedbackDialogAnchor,
    SMFeedbackDialogContent
  }
})
