import { IRootState } from '@src/types/store.types'
import {
  MutationTree, ActionTree, Module, GetterTree
} from 'vuex'
import { ActivationFlowStepStatus, ActivationFlowStep } from '@src/types/activationFlow.types'
import { JobToSkillWizardSteps } from '@src/types/jobSkill'

interface IActivationFlowJobWizardState {
  isDialogVisible: boolean;
  currentStep: JobToSkillWizardSteps;
  hasTitleStep: boolean;
  jobTitle: string;
}

const state: IActivationFlowJobWizardState = {
  isDialogVisible: false,
  currentStep: JobToSkillWizardSteps.Title,
  hasTitleStep: true,
  jobTitle: ''
}

const actions: ActionTree<IActivationFlowJobWizardState, IRootState> = {
  possiblyShowJobWizard({ commit, dispatch, rootGetters }) {
    const shouldShowOnboardingDialogs = rootGetters['activationFlow/shouldShowOnboardingDialogs']

    if (!shouldShowOnboardingDialogs) { // already completed another time
      dispatch('next', ActivationFlowStepStatus.ALREADY_DONE)
    } else { // show it
      commit('showDialog')
    }
  },
  completeJobWizard({ dispatch, commit }) {
    commit('hideDialog')
    dispatch('next', ActivationFlowStepStatus.STEPPED_THROUGH_COMPLETE)
  },
  next({ dispatch }, status: ActivationFlowStepStatus) {
    dispatch('activationFlow/next', { status, step: ActivationFlowStep.JOB_WIZARD }, { root: true })
  }
}

const mutations: MutationTree<IActivationFlowJobWizardState> = {
  showDialog(state) {
    state.isDialogVisible = true
  },
  hideDialog(state) {
    state.isDialogVisible = false
  },
  setCurrentStep(state, value: JobToSkillWizardSteps) {
    state.currentStep = value
  },
  hideTitleStep(state) {
    state.hasTitleStep = false
    state.currentStep = JobToSkillWizardSteps.Tags
  },
  setJobTitle(state, value: string) {
    state.jobTitle = value
  }
}

const getters: GetterTree<IActivationFlowJobWizardState, IRootState> = {
  shouldDisplayTitleStep(state) {
    return state.hasTitleStep
  },
  getCurrentStep(state) {
    return state.currentStep
  },
  getJobTitle(state) {
    return state.jobTitle
  },
  getIsDialogVisible(state) {
    return state.isDialogVisible
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
} as Module<IActivationFlowJobWizardState, IRootState>
