import { ITrackingProps } from '@src/types/events.types'

export const VIEW_BADGE_NOTIFICATION = 'viewBadgeNotification'
export const CLOSE_BADGE_NOTIFICATION = 'closeBadgeNotification'

export default {
  NotificationsDialog: {
    [VIEW_BADGE_NOTIFICATION]: (
      customProps:
      {
        badge_type: boolean;
        badge_count: number;
      }
    ): ITrackingProps => ({
      type: 'view',
      ...customProps
    }),
    [CLOSE_BADGE_NOTIFICATION]: (
      customProps:
      {
        badge_type: boolean;
        badge_count: number;
        element: 'ok_button' |'close_button' |'backdrop';
      }
    ): ITrackingProps => ({
      type: 'click',
      component: 'notification',
      ...customProps
    })
  }
}
