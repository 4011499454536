/* eslint-disable import/prefer-default-export */
import {
  ActionTree, GetterTree, MutationTree, Module
} from 'vuex'
import Vue from 'vue'
import _floor from 'lodash/floor'
import _ceil from 'lodash/ceil'
import { IRootState } from '@src/types/store.types'
import vsmApi from '@src/api/vsm'
import userApi from '@src/api/user'
import statisticsApi from '@src/api/statistics'
import {
  IVSMMetric, IVSMMetricSimpleValue, VSMMetricId, VSMMetricStatus
} from '@src/types/metric.types'
import activatedUsersMetricData from './data/activatedUsersMetricData'
import usersWithJobTitleMetricData from './data/usersWithJobTitleMetricData'
import usersWithTopicsMetricData from './data/usersWithTopicsMetricData'
import answeredQuestionsMetricData from './data/answeredQuestionsMetricData'
import averageAnswerTimeMetricData from './data/averageAnswerTimeMetricData'
import averageSolutionRatingMetricData from './data/averageSolutionRatingMetricData'
import averageDailyActiveUsersMetricData from './data/averageDailyActiveUsersMetricData'
import averageQuestionViewsMetricData from './data/averageQuestionViewsMetricData'

export interface IVSMMetricsState {
  [id: string]: IVSMMetric | IVSMMetricSimpleValue;
}

interface IMetricUpdates {
  id: VSMMetricId;
  updates: Partial<IVSMMetric>;
}

const state: IVSMMetricsState = {
  [VSMMetricId.ACTIVATED_USERS]: activatedUsersMetricData,
  [VSMMetricId.USERS_WITH_JOB_TITLE]: usersWithJobTitleMetricData,
  [VSMMetricId.USERS_WITH_TOPICS]: usersWithTopicsMetricData,
  [VSMMetricId.ANSWERED_QUESTIONS]: answeredQuestionsMetricData,
  [VSMMetricId.AVERAGE_ANSWER_TIME]: averageAnswerTimeMetricData,
  [VSMMetricId.AVERAGE_SOLUTION_RATING]: averageSolutionRatingMetricData,
  [VSMMetricId.AVERAGE_DAILY_ACTIVE_USERS]: averageDailyActiveUsersMetricData,
  [VSMMetricId.AVERAGE_QUESTION_VIEWS]: averageQuestionViewsMetricData
}

const getters: GetterTree<IVSMMetricsState, IRootState> = {
  getMetric(state) {
    return (id: VSMMetricId): IVSMMetric | IVSMMetricSimpleValue => state[id]
  }
}

const mutations: MutationTree<IVSMMetricsState> = {
  updateMetric(state: IVSMMetricsState, { id, updates }: IMetricUpdates) {
    const metric = state[id] || {}
    Vue.set(state, id, { ...metric, ...updates })
  }
}

function getPercentage(value?: number, total?: number, defaultValue = 0): number {
  return Math.min(value && total ? _floor((value / total) * 100) : defaultValue, 100)
}

function getStatus(percentage: number, doneThreshold = 100, warnThreshold = 50): VSMMetricStatus {
  if (percentage >= doneThreshold) return 'done'
  if (percentage < warnThreshold) return 'warn'
  return 'default'
}

function getActivatedUsersMetricUpdates(metrics: { name: string; value: number }[] = []): IMetricUpdates {
  const activatedUsers = metrics.find((m) => m.name === 'total_users_activated')?.value
  const totalUsers = metrics.find((m) => m.name === 'total_users')?.value
  const value = getPercentage(activatedUsers, totalUsers)
  return {
    id: VSMMetricId.ACTIVATED_USERS,
    updates: {
      loading: false,
      currentValue: value,
      percentageValue: value,
      status: getStatus(value)
    }
  }
}

function getUsersWithJobTitleMetricUpdates(metrics: { name: string; value: number }[] = []): IMetricUpdates {
  const activatedUsers = metrics.find((m) => m.name === 'total_users_activated')?.value
  const usersWithJobTitle = metrics.find((m) => m.name === 'total_users_activated_with_job_title')?.value
  const value = getPercentage(usersWithJobTitle, activatedUsers)
  return {
    id: VSMMetricId.USERS_WITH_JOB_TITLE,
    updates: {
      loading: false,
      currentValue: value,
      percentageValue: value,
      status: getStatus(value)
    }
  }
}

function getUsersWithTopicsMetricUpdates(metrics: { name: string; value: number }[] = []): IMetricUpdates {
  const activatedUsers = metrics.find((m) => m.name === 'total_users_activated')?.value
  const usersWithSubscriptions = metrics.find((m) => m.name === 'total_users_activated_with_min_three_subscriptions')?.value
  const value = getPercentage(usersWithSubscriptions, activatedUsers)
  return {
    id: VSMMetricId.USERS_WITH_TOPICS,
    updates: {
      loading: false,
      currentValue: value,
      percentageValue: value,
      status: getStatus(value)
    }
  }
}

function getAnsweredQuestionsMetricUpdates(state: IVSMMetricsState, metrics: { name: string; value: number }[] = []): IMetricUpdates {
  const totalQuestions = metrics.find((m) => m.name === 'total_questions_published')?.value
  const answeredQuestions = metrics.find((m) => m.name === 'total_questions_answered')?.value
  const targetValue = (state[VSMMetricId.ANSWERED_QUESTIONS] as IVSMMetric)?.targetValue
  const value = getPercentage(answeredQuestions, totalQuestions)
  return {
    id: VSMMetricId.ANSWERED_QUESTIONS,
    updates: {
      loading: false,
      currentValue: value,
      percentageValue: value,
      status: getStatus(value, targetValue)
    }
  }
}

function getAverageAnswerTimeMetricUpdates(state: IVSMMetricsState, metrics: { name: string; value: number }[] = []): IMetricUpdates {
  const averageTimeInMinutes = metrics.find((m) => m.name === 'average_time_to_answer')?.value ?? 0
  const averageTimeInHours = averageTimeInMinutes / 60
  const targetValue = (state[VSMMetricId.AVERAGE_ANSWER_TIME] as IVSMMetric)?.targetValue
  const percentageValue = getPercentage(targetValue, averageTimeInHours)
  return {
    id: VSMMetricId.AVERAGE_ANSWER_TIME,
    updates: {
      loading: false,
      currentValue: _ceil(averageTimeInHours, 1),
      percentageValue,
      status: getStatus(percentageValue)
    }
  }
}

function getAverageSolutionRatingMetricUpdates(state: IVSMMetricsState, metrics: { name: string; value: number }[] = []): IMetricUpdates {
  const averageRating = metrics.find((m) => m.name === 'average_solution_rating')?.value ?? 0
  const targetValue = (state[VSMMetricId.AVERAGE_SOLUTION_RATING] as IVSMMetric)?.targetValue
  const percentageValue = getPercentage(averageRating, targetValue)
  return {
    id: VSMMetricId.AVERAGE_SOLUTION_RATING,
    updates: {
      loading: false,
      currentValue: _floor(averageRating, 1),
      percentageValue,
      status: getStatus(percentageValue)
    }
  }
}

function getAverageQuestionViewsMetricUpdates(metrics: { name: string; value: number }[] = []): IMetricUpdates {
  const value = metrics.find((m) => m.name === 'average_unique_daily_question_views_per_user')?.value
  return {
    id: VSMMetricId.AVERAGE_QUESTION_VIEWS,
    updates: {
      loading: false,
      currentValue: value
    }
  }
}

function getSimpleValueMetricUpdate(metric: VSMMetricId, value: number): IMetricUpdates {
  return {
    id: metric,
    updates: {
      loading: false,
      currentValue: value
    }
  }
}

const actions: ActionTree<IVSMMetricsState, IRootState> = {
  async loadAllMetrics({ commit, state }): Promise<void> {
    const [ { data: { total } }, { data: { metrics } }, averageDailyActiveUsers ] = await Promise.all([
      userApi.getUsers({ limit: 1, filter: null }),
      vsmApi.getMetrics(),
      statisticsApi.cached.getAvgDailyActiveUsersLast30Days()
    ])
    metrics.push({ name: 'total_users', value: total })

    commit('updateMetric', getActivatedUsersMetricUpdates(metrics))
    commit('updateMetric', getUsersWithJobTitleMetricUpdates(metrics))
    commit('updateMetric', getUsersWithTopicsMetricUpdates(metrics))
    commit('updateMetric', getAnsweredQuestionsMetricUpdates(state, metrics))
    commit('updateMetric', getAverageAnswerTimeMetricUpdates(state, metrics))
    commit('updateMetric', getAverageSolutionRatingMetricUpdates(state, metrics))
    commit('updateMetric', getSimpleValueMetricUpdate(VSMMetricId.AVERAGE_DAILY_ACTIVE_USERS, averageDailyActiveUsers))
    commit('updateMetric', getAverageQuestionViewsMetricUpdates(metrics))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<IVSMMetricsState, IRootState>
