import { ITrackingProps } from '@src/types/events.types'

// Admin
export const CREATE_NEW = 'clickNewKnowledgeSpace'
export const SAVE = 'clickSaveKnowledgeSpace'
export const CANCEL = 'clickCancelKnowledgeSpace'
export const REMOVE = 'clickDeleteKnowledgeSpace'
export const TOGGLE_AUTO_ASSIGNMENT = 'toggleAutoAssignmentKnowledgeSpace'

// Non-admin
export const CLICK_ALL = 'clickAllKnowledgeSpaces'
export const SELECT = 'clickKnowledgeSpace'
export const COLLAPSE = 'collapseKnowledgeSpaceList'
export const EXPAND = 'expandKnowledgeSpaceList'
export const CLICK_FEED = 'clickAnswerStream'
export const CANCEL_MEMBERS = 'closeMembersListKnowledgeSpace'
export const REQUEST_ACCESS = 'clickRequestAccessKnowledgeSpace'
export const CONFIRM_ACCESS = 'clickConfirmKnowledgeSpaceRequest'
export const CLICK_PROFILE = 'clickProfile'

// Add member dialog
export const ADD_MEMBER = 'clickAddMemberInDialogue'
export const REMOVE_MEMBER = 'clickRemoveMemberInDialogue'
export const SUBMIT_ADD_MEMBER = 'clickAddMembersToKnowledgeSpace'
export const CANCEL_ADD_MEMBER = 'clickCancelAddMembersToKnowledgeSpace'

const commonProps = {
  state: 'knowledge-spaces'
}

const commonPropsAdmin = {
  ...commonProps,
  state: 'admin.content.knowledge-spaces'
}

export default {
  AdminKnowledgeSpacesOverview: {
    [CREATE_NEW]: (): ITrackingProps => ({
      ...commonPropsAdmin,
      type: 'click',
      element: 'button',
      component: 'admin_knowledge_spaces_overview'
    })
  },
  AdminKnowledgeSpacesEdit: {
    [SAVE]: (customProps: { knowledge_space_id: number, knowledge_space_visibility: 'public' | 'private', knowledge_space_visibility_modified: boolean }): ITrackingProps => ({
      ...commonPropsAdmin,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_knowledge_spaces_edit'
    }),
    [CANCEL]: (customProps: { knowledge_space_id: number }): ITrackingProps => ({
      ...commonPropsAdmin,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_knowledge_spaces_edit'
    }),
    [REMOVE]: (customProps: { knowledge_space_id: number }): ITrackingProps => ({
      ...commonPropsAdmin,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_knowledge_spaces_edit'
    }),
    [TOGGLE_AUTO_ASSIGNMENT]: (customProps: { knowledge_space_id: number; value: boolean }): ITrackingProps => ({
      ...commonPropsAdmin,
      ...customProps,
      type: 'click',
      element: 'toggle',
      component: 'admin_knowledge_spaces_edit'
    })
  },
  KnowledgeSpacesSidenavMenuItem: {
    [CLICK_ALL]: (): ITrackingProps => ({
      ...commonProps,
      component: 'knowledge_spaces_sidenav',
      element: 'button',
      type: 'click'
    }),
    [EXPAND]: (): ITrackingProps => ({
      ...commonProps,
      component: 'knowledge_spaces_sidenav',
      element: 'drop_down',
      type: 'click'
    }),
    [COLLAPSE]: (): ITrackingProps => ({
      ...commonProps,
      component: 'knowledge_spaces_sidenav',
      element: 'drop_down',
      type: 'click'
    }),
    [SELECT]: (customProps: { knowledge_space_id: number; element: 'notification' | 'list_item' }): ITrackingProps => ({
      ...commonProps,
      component: 'knowledge_spaces_sidenav',
      type: 'click',
      ...customProps
    }),
    // taken over from next:navigation-service.js as this component replaces the feed sidenav menu entry
    [CLICK_FEED]: (): ITrackingProps => ({
      component: 'main_navigations',
      element: 'list_item',
      type: 'click'
    })
  },
  KnowledgeSpaceAddMemberDialog: {
    [ADD_MEMBER]: (customProps: { knowledge_space_id: number }): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'list_item',
      component: 'knowledge_spaces_add_members'
    }),
    [REMOVE_MEMBER]: (customProps: { knowledge_space_id: number }): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'icon',
      component: 'knowledge_spaces_add_members'
    }),
    [SUBMIT_ADD_MEMBER]: (customProps: { knowledge_space_id: number; new_member_count: number }): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'knowledge_spaces_add_members'
    }),
    [CANCEL_ADD_MEMBER]: (customProps: { knowledge_space_id: number; element: string }): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      component: 'knowledge_spaces_add_members'
    })
  },
  KnowledgeSpaceMemberDialog: {
    [CANCEL_MEMBERS]: (customProps: { knowledge_space_id: number; element: string }): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      component: 'knowledge_spaces_member_list'
    }),
    [CLICK_PROFILE]: (customProps: { knowledge_space_id: number; is_own_profile: boolean }): ITrackingProps => ({
      ...commonProps,
      component: 'knowledge_spaces_member_list',
      element: 'list_item',
      type: 'click',
      ...customProps
    })
  },
  KnowledgeSpaceRequestAccess: {
    [REQUEST_ACCESS]: (customProps: { knowledge_space_id: number }): ITrackingProps => ({
      ...commonProps,
      component: 'knowledge_space',
      element: 'button',
      type: 'click',
      ...customProps
    })
  },
  KnowledgeSpaceApproveMemberDialog: {
    [CONFIRM_ACCESS]: (customProps: { knowledge_space_id: number }): ITrackingProps => ({
      ...commonProps,
      component: 'knowledge_space',
      element: 'button',
      type: 'click',
      ...customProps
    })
  }
}
