import { addAxiosInterceptors, createOptimizedAPI } from '@src/api'
import axios, { AxiosPromise } from 'axios'

export interface IQuestionTitleIndicatorApi {
  getQuestionTitleQuality(question: string): AxiosPromise<{ is_question: boolean }>;
}

const api = addAxiosInterceptors(axios.create({ baseURL: '/experimental/api/question-classifier' }))

const questionTitleIndicatorApi: IQuestionTitleIndicatorApi = {
  getQuestionTitleQuality(question) {
    return api.post('', {
      question
    })
  }
}

export default {
  ...questionTitleIndicatorApi,
  ...createOptimizedAPI(questionTitleIndicatorApi)
}
