












import Vue from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import TermsDialog from '../SMActivationFlow/components/TermsDialog.vue'

export default Vue.extend({
  name: 'SMTermsViewButton',
  components: {
    TermsDialog
  },
  computed: {
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings | undefined;
    },
    ...mapState('activationFlow/terms', [ 'latestPublishedTermsId' ]) as {
      latestPublishedTermsId: () => number;
    },
    isButtonVisible(): boolean {
      return Boolean(this.realmSettings?.terms_of_use.enabled) && !!this.latestPublishedTermsId
    }
  },
  methods: {
    ...mapActions('activationFlow/terms', [ 'showTermsReadonly' ]) as {
      showTermsReadonly: () => void;
    }
  }
})
