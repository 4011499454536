/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  GetterTree,
  ActionTree,
  MutationTree,
  Module
} from 'vuex'

import { IRootState } from '@src/types/store.types'
import { ISuperproperties } from '@src/types/superproperties.types'
import { IUser } from '@src/types/user.types'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'

import userApi from '@src/api/user'
import statisticsApi from '@src/api/statistics'

import { PermissionLevel } from '@src/utilities/userUtils'

import _isEmpty from 'lodash/isEmpty'
import _get from 'lodash/get'
import { IUserActivityOverview } from '@src/types/statistics.types'
import { isInCrossDomainIframe } from '@src/utilities/iframeDetection'
import { personalisationApi } from '@src/api/apiModuleInstances'

export interface IAnalyticsParams {
  user: Partial<IUser>;
  permissionLevel: PermissionLevel;
  realmSettings?: IUnauthenticatedRealmSettings;
  knowledgeSpacesLoaded: boolean;
}

export interface IAnalyticsState {
  superproperties?: ISuperproperties;
  mixpanelToken?: string;
  trackingSalt?: string;
  trackingEnabled: boolean;
  loading: boolean;
  error: boolean;
  superpropertiesLoaded: boolean;
}

export interface ITrackingConfig {
  mixpanelToken: string;
  superproperties: ISuperproperties;
  trackingSalt?: string;
  trackingEnabled: boolean;
}

const state: IAnalyticsState = {
  superproperties: undefined,
  mixpanelToken: undefined,
  trackingSalt: undefined,
  trackingEnabled: false,
  loading: true,
  error: false,
  superpropertiesLoaded: false
}

const getters: GetterTree<IAnalyticsState, IRootState> = {
  getTrackingConfig(state): ITrackingConfig | undefined {
    const ready = !state.loading
      && !state.error
      && !_isEmpty(state.superproperties)
      && state.mixpanelToken
    return ready
      ? {
        superproperties: state.superproperties!,
        mixpanelToken: state.mixpanelToken!,
        trackingSalt: state.trackingSalt,
        trackingEnabled: state.trackingEnabled
      }
      : undefined
  },
  getSalt(state): string | undefined {
    return state.trackingSalt
  },
  getSuperproperties(state): ISuperproperties | undefined {
    return state.superpropertiesLoaded ? state.superproperties : undefined
  }
}

const actions: ActionTree<IAnalyticsState, IRootState> = {
  async loadAnalyticsConfig(
    {
      commit, rootGetters, rootState
    },
    {
      user,
      permissionLevel,
      realmSettings
    }: Omit<Required<IAnalyticsParams>, 'user'> & { user: IUser} // Required type because the analytics config hook only loads this when all params are loaded (incl. full user)
  ): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)
    try {
      const [ userActivity, numberOfSubscriptions, approximatedExpertTags ] = await Promise.all([
        statisticsApi.cached.getUserActivity(user.id)
          .then(({ data }) => data)
          .catch(() => ({} as Partial<IUserActivityOverview>)),
        personalisationApi.cached.getConceptSubscriptions(user.global_user_id)
          .then(({ data }) => data.length)
          .catch(() => 0),
        userApi.cached.getExpertiseTags(user.id)
          .then(({ data }) => data.approximate_total)
          .catch(() => undefined)
      ])

      const mergedProps: ISuperproperties = {
        approximated_expert_tags: approximatedExpertTags,
        network_id: realmSettings.metadata.realm_id,
        network_go_live_date: realmSettings?.metadata.go_live_date,
        user_tracking_id: user.event_tracking_id,
        new_user: rootGetters['user/getIsNewUserSevenDays'], // guaranteed to be set when user is set
        first_seen: user.first_seen,
        last_seen: user.last_seen,
        ui_language: user.language,
        permission_level: permissionLevel,
        user_type: realmSettings.metadata.is_trial ? 'trial' : 'paid',
        pricing_tier: realmSettings.metadata.pricing_tier,
        total_solutions_sent: userActivity.solutions,
        total_questions_sent: userActivity.questions,
        total_comments_sent: userActivity.comments,
        total_recommendations_sent: userActivity.recommendations_sent,
        total_recommendations_received: userActivity.recommendations_received,
        total_subscriptions: numberOfSubscriptions,
        total_knowledge_spaces_of_user: rootState.knowledgeSpaces.ownKnowledgeSpaces.length,
        nps_score: user.nps_score,
        solutions_received: userActivity.solutions_received,
        appType:
          _get(window, 'appWrapperContextVariables.appPlatform') || 'browser',
        appOs: _get(window, 'appWrapperContextVariables.appOs'),
        cross_domain_iframe_integration: isInCrossDomainIframe()
      }
      commit('setMixpanelToken', realmSettings.user_tracking.mixpanel_analytics_token)
      commit('setTrackingEnabled', realmSettings.user_tracking.enabled)
      commit('setSuperproperties', mergedProps)
      commit('setSuperpropertiesLoaded')
      commit('setTrackingSalt', realmSettings.user_tracking.tracking_salt)
    } catch (e) {
      commit('setError', e)
    } finally {
      commit('setLoading', false)
    }
  },
  conditionallyUpdateSuperproperties(
    { commit, state },
    eventName: string
  ): void {
    if (state.superproperties) {
      switch (eventName) {
        case 'submitAnswer':
          commit('setSuperproperties', {
            total_solutions_sent:
              (state.superproperties.total_solutions_sent ?? 0) + 1
          })
          break
        case 'submitQuestion':
          commit('setSuperproperties', {
            total_questions_sent:
              (state.superproperties.total_questions_sent ?? 0) + 1
          })
          break
        case 'submitComment':
          commit('setSuperproperties', {
            total_comments_sent: (state.superproperties.total_comments_sent ?? 0) + 1
          })
          break
        case 'submitRecommendExpert':
          commit('setSuperproperties', {
            total_recommendations_sent:
              (state.superproperties.total_recommendations_sent ?? 0) + 1
          })
          break
        default:
          break
      }
    }
  }
}

const mutations: MutationTree<IAnalyticsState> = {
  setLoading: (state, loading: boolean): void => {
    state.loading = loading
  },
  setError: (state, error: boolean): void => {
    state.error = error
  },
  setSuperproperties: (state, props: ISuperproperties): void => {
    const existingProps = state.superproperties ?? {}
    state.superproperties = { ...existingProps, ...props }
  },
  setMixpanelToken: (state, token: string): void => {
    state.mixpanelToken = token
  },
  setTrackingSalt: (state, salt: string): void => {
    state.trackingSalt = salt
  },
  setTrackingEnabled: (state, enabled: boolean): void => {
    state.trackingEnabled = enabled
  },
  setSuperpropertiesLoaded: (state): void => {
    state.superpropertiesLoaded = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<IAnalyticsState, IRootState>
