























import {
  computed, defineComponent, ref
} from '@vue/composition-api'
import useRealmSettings from '@src/composables/realmSettings'

export default defineComponent({
  name: 'SMAttachmentUploadButton',
  setup(props, { emit }) {
    const { realmSettings } = useRealmSettings()

    const isAttachmentUploadEnabled = computed(() => !!realmSettings.value?.content.attachment_upload_enabled)
    const fileInput = ref<HTMLInputElement | null>(null)

    function chooseFile() {
      fileInput.value?.click()
      emit('onAddAttachment')
    }

    function onFileInputChange() {
      const files = fileInput.value?.files
      if (files) {
        Array.from(files).forEach((file) => {
          emit('input', file)
        })
        emit('onSubmitAttachment')
      }
    }

    return {
      isAttachmentUploadEnabled,
      fileInput,
      chooseFile,
      onFileInputChange
    }
  }
})
