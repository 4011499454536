import {
  ref, computed, Ref
} from '@vue/composition-api'
import i18n from '@src/plugins/i18n'
import useRealmSettings from '@src/composables/realmSettings'
import { IStandardizedConceptSingleLanguage } from '@src/types/concept.types'
import { TITLE_MIN_LENGTH } from '../constants'

export interface IFormError {
  errorType: 'required' | 'min_length' | 'max_length';
  translatedError: string;
}

export default function useValidation({
  title,
  description,
  concepts,
  category,
  knowledgeSpace,
  generalFeedSelected,
  conceptsNotRequired = false
} : {
  title: Ref<string>,
  description: Ref<string>,
  concepts: Ref<IStandardizedConceptSingleLanguage[]>,
  category: Ref<undefined | number | null>,
  knowledgeSpace: Ref<number | null>,
  generalFeedSelected: Ref<boolean>,
  conceptsNotRequired?: boolean
}) {
  const { realmSettings } = useRealmSettings()
  const maxTitleCount = computed(() => realmSettings.value?.question.question_title_max_length ?? 0)
  const knowledgeSpacesEnabled = computed(() => !!realmSettings.value?.knowledge_spaces.knowledge_spaces_enabled)
  const submitted = ref(false)
  const descriptionError = computed(() => !!realmSettings.value?.question.mandatory_question_description && !description.value && submitted.value)
  const conceptsError = computed(() => {
    if (concepts.value.length < 1 && submitted.value && !conceptsNotRequired) {
      return { errorType: 'required', translatedError: i18n.t('ask.form.concept.error.required').toString() }
    }
    return undefined
  })
  const knowledgeSpaceMandatoryError = computed<IFormError | undefined>(() => {
    if (knowledgeSpace.value === null && submitted.value && !generalFeedSelected.value && knowledgeSpacesEnabled.value) {
      return { errorType: 'required', translatedError: i18n.t('ask.form.knowledge-space.error.required').toString() }
    }
    return undefined
  })
  const categoryError = computed<IFormError | undefined>(() => {
    if (!!realmSettings.value?.categories.categories_enabled && category.value === undefined && submitted.value) {
      return { errorType: 'required', translatedError: i18n.t('ask.form.category.error.required').toString() }
    }
    return undefined
  })
  const titleError = computed<IFormError | undefined>(() => {
    if (submitted.value) {
      if (!title.value) {
        return { errorType: 'required', translatedError: i18n.t('ask.form.title.error.required').toString() }
      }
      if (title.value.length < TITLE_MIN_LENGTH) {
        return { errorType: 'min_length', translatedError: i18n.t('ask.form.title.error.required').toString() }
      }
    }
    if (title.value.length > maxTitleCount.value) {
      const characterCount = maxTitleCount.value - title.value.length
      return { errorType: 'max_length', translatedError: `${characterCount} ${i18n.t('ask.form.title.error.maxlength').toString()}` }
    }
    return undefined
  })
  const isValid = computed(() => !titleError.value && !descriptionError.value && !conceptsError.value && !categoryError.value && !knowledgeSpaceMandatoryError.value)

  const validate = () => {
    submitted.value = true
    return isValid.value
  }

  return {
    titleError,
    descriptionError,
    conceptsError,
    categoryError,
    knowledgeSpaceMandatoryError,
    isValid,
    validate
  }
}
