import {
  DeepReadonly, Ref, provide, computed
} from '@vue/composition-api'
import _findIndex from 'lodash/findIndex'
import { searchApi } from '@src/api/apiModuleInstances'
import questionApi from '@src/api/questions'
import useAnalyticsTracking from '@src/composables/tracking/tracking'
import {
  ADD_ATTACHMENT,
  ADD_TAG,
  BACK_TO_ASK_VIEW,
  CANCEL,
  CLICK_CATEGORY,
  CLICK_IN_TITLE,
  CLICK_SIMILAR_QUESTION,
  EXPAND_SIMILAR_QUESTIONS,
  ICancelProps,
  IClickCategoryProps,
  IClickInInputProps,
  IClickSimilarQuestionProps,
  ICustomProps,
  IQuestionErrorProps,
  ISelectCategoryProps,
  ISelectKnowledgeSpaceProps,
  ISimilarQuestionProps,
  ISubmitQuestionProps,
  ITagAddProps,
  ITagRemoveProps,
  IViewAskProps,
  REMOVE_TAG,
  SELECT_CATEGORY,
  SELECT_KNOWLEDGE_SPACE,
  SUBMIT_ATTACHMENT,
  SUBMIT_QUESTION,
  VIEW_ASK,
  VIEW_DOUBLECHECK,
  VIEW_ERROR,
  VIEW_IDENTICAL_WARNING
} from '@src/plugins/analytics/events/ui/SMAsk.events'
import { IQuestionPayload, ISimilarQuestion } from '@src/types/question.types'
import useCategories from '@src/composables/categories'
import useStore from '@src/store'
import { IUser } from '@src/types/user.types'
import useAskDialog from '@src/composables/askDialog'
import throttle from 'lodash/throttle'
import { ComponentBasedTrackingFn } from '@src/types/events.types'
import { IStandardizedConceptSingleLanguage } from '@src/types/concept.types'
import { SMAskInjectables } from '../constants'

const TRACKING_COMPONENT = 'SMAsk'

interface IAskTrackingContext {
  concepts: Ref<IStandardizedConceptSingleLanguage[]>;
  description: Ref<string>;
  conceptualExperts: DeepReadonly<Ref<IUser[]>>
  hasVerySimilarQuestion: Ref<boolean>;
  isNewQuestion: boolean;
  isShowingVerySimilarQuestionDoubleCheck: Ref<boolean>;
  initialKnowledgeSpace: Ref<number | null>;
  questionId: Ref<number | undefined>;
  questionQuality: Ref<number>;
  similarQuestions: DeepReadonly<Ref<ISimilarQuestion[]>>;
  title: Ref<string>;
  totalSimilarQuestions: DeepReadonly<Ref<number>>;
}

export type IUseTracking = ReturnType<typeof useTracking>

function useTracking(trackingContext: IAskTrackingContext) { // If you find yourself making trackingContext optional or its type partial, you're probably doing something wrong ;) Ask your teammates.
  const { trackEvent } = useAnalyticsTracking()
  const { categories } = useCategories()

  const store = useStore()
  const { trackingClickAskId, removeClickAskId } = useAskDialog()
  const isFirstQuestionByUser = computed(() => !store.state.analytics.superproperties?.total_questions_sent)

  let similarQuestionClicked = false

  const contextualizedTrackingMethods = {
    trackClickCategory(categoryId: number) {
      (trackEvent as ComponentBasedTrackingFn<IClickCategoryProps>)(CLICK_CATEGORY, TRACKING_COMPONENT, {
        tag_id: categories.value.find(({ id }) => id === categoryId)?.name,
        is_new_question: trackingContext.isNewQuestion
      })
    },
    trackSelectCategory(categoryId: number) {
      (trackEvent as ComponentBasedTrackingFn<ISelectCategoryProps>)(SELECT_CATEGORY, TRACKING_COMPONENT, {
        tag_id: categories.value.find(({ id }) => id === categoryId)?.name,
        category_id: categoryId,
        is_new_question: trackingContext.isNewQuestion
      })
    },
    trackExpandSimilarQuestions() {
      (trackEvent as ComponentBasedTrackingFn<ICustomProps>)(EXPAND_SIMILAR_QUESTIONS, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion
      })
    },
    trackClickInTitle() {
      (trackEvent as ComponentBasedTrackingFn<IClickInInputProps>)(CLICK_IN_TITLE, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        character_count: trackingContext.title.value.length,
        question_id: trackingContext.questionId.value
      })
    },
    trackErrorView({
      component,
      element,
      form_field,
      error
    } : {
      component: string,
      element: string,
      form_field: string,
      error: string
    }) {
      (trackEvent as ComponentBasedTrackingFn<IQuestionErrorProps>)(VIEW_ERROR, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        component,
        element,
        form_field,
        error
      })
    },
    trackBackFromSimliarQuestionDoubleCheck() {
      (trackEvent as ComponentBasedTrackingFn<ISimilarQuestionProps>)(BACK_TO_ASK_VIEW, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        total_similar_questions: trackingContext.totalSimilarQuestions.value
      })
    },
    trackViewSimliarQuestionDoubleCheck() {
      (trackEvent as ComponentBasedTrackingFn<ISimilarQuestionProps>)(VIEW_DOUBLECHECK, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        total_similar_questions: trackingContext.totalSimilarQuestions.value
      })
    },
    trackViewIdenticalQuestionWarning() {
      (trackEvent as ComponentBasedTrackingFn<ICustomProps>)(VIEW_IDENTICAL_WARNING, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion
      })
    },
    trackClickCancel() {
      removeClickAskId();
      (trackEvent as ComponentBasedTrackingFn<ICancelProps>)(CANCEL, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        entity: 'askOverlay',
        state: trackingContext.isShowingVerySimilarQuestionDoubleCheck.value ? 'ask_second_step' : 'ask'
      })
    },
    trackViewAsk() {
      (trackEvent as ComponentBasedTrackingFn<IViewAskProps>)(VIEW_ASK, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        click_ask_id: trackingClickAskId.value
      })
    },
    trackAddTag(concept: IStandardizedConceptSingleLanguage) {
      (trackEvent as ComponentBasedTrackingFn<ITagAddProps>)(ADD_TAG, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        tag_id: concept.label_match,
        is_first_tag: trackingContext.concepts.value.length <= 1
      })
    },
    trackRemoveTag(concept: IStandardizedConceptSingleLanguage & { displayText: string }) {
      (trackEvent as ComponentBasedTrackingFn<ITagRemoveProps>)(REMOVE_TAG, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        tag_id: concept.displayText
      })
    },
    async trackClickSimilarQuestion(similarQuestion: ISimilarQuestion) {
      similarQuestionClicked = true
      const similarQuestionRank = _findIndex(trackingContext.similarQuestions.value, { id: similarQuestion.id }) + 1 || undefined
      searchApi.logSimilarQuestionClick({
        id: similarQuestion.id,
        title: trackingContext.title.value,
        description: trackingContext.description.value,
        rank: similarQuestionRank
      })

      const { data: { is_mine } } = await questionApi.getQuestion(similarQuestion.id, true);

      (trackEvent as ComponentBasedTrackingFn<IClickSimilarQuestionProps>)(CLICK_SIMILAR_QUESTION, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        is_identical_question: similarQuestion.isIdentical,
        is_similar_question: !similarQuestion.isIdentical,
        is_question_author: is_mine,
        question_id: similarQuestion.id,
        question_status: similarQuestion.solution_count ? 'answered' : 'unanswered',
        similar_question_combined_score: similarQuestion.search_metadata?.score,
        similar_question_ranking: similarQuestionRank,
        total_answers: similarQuestion.solution_count ?? 0
      })
    },
    trackSubmit(question: IQuestionPayload) {
      (trackEvent as ComponentBasedTrackingFn<ISubmitQuestionProps>)(SUBMIT_QUESTION, TRACKING_COMPONENT, {
        is_new_question: trackingContext.isNewQuestion,
        click_ask_id: trackingClickAskId.value,
        is_similar_question: trackingContext.hasVerySimilarQuestion.value,
        knowledge_space_id: question.knowledge_space ?? null,
        question_quality: trackingContext.questionQuality.value,
        similar_question_clicked: similarQuestionClicked,
        similar_question_combined_score: trackingContext.similarQuestions.value[0]?.search_metadata?.score,
        total_tags: trackingContext.concepts.value.length ?? 0,
        is_first_question_by_user: isFirstQuestionByUser.value,
        extracted_experts_visible: !!trackingContext.conceptualExperts.value.length,
        knowledge_space_modified: !trackingContext.isNewQuestion && trackingContext.initialKnowledgeSpace.value !== question.knowledge_space
      })
    },
    trackAddAttachment() {
      trackEvent(ADD_ATTACHMENT, TRACKING_COMPONENT)
    },
    trackSubmitAttachment() {
      trackEvent(SUBMIT_ATTACHMENT, TRACKING_COMPONENT)
    },
    // throttle because in some cases it is executed twice due to the way the knowledge space picker works
    trackSelectKnowledgeSpace: throttle((knowledgeSpaceId: number | null) => {
      (trackEvent as ComponentBasedTrackingFn<ISelectKnowledgeSpaceProps>)(SELECT_KNOWLEDGE_SPACE, TRACKING_COMPONENT, {
        knowledge_space_id: knowledgeSpaceId
      })
    }, 300, { trailing: false })
  }

  // In addition to returning all the methods and passing them down via props across multiple levels
  // we provide it to all child components in the hierarchy that can make use of them via inject()
  provide(SMAskInjectables.Tracking, contextualizedTrackingMethods)

  return contextualizedTrackingMethods
}

export default useTracking
