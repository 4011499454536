import {
  accountUserPropNames, IAccountUserProps, IEditUser, IUser
} from '@src/types/user.types'
import {
  ActionTree, GetterTree, Module, MutationTree
} from 'vuex'
import _omit from 'lodash/omit'
import _pick from 'lodash/pick'
import _isEmpty from 'lodash/isEmpty'
import { IRootState } from '@src/types/store.types'

import userApi from '@src/api/user'
import accountsApi from '@src/api/accounts'
import { getFullName, getPermissionLevel, isSuperAdmin } from '@src/utilities/userUtils'
import { initLanguage } from '@src/plugins/i18n'

import awaitGetterValue from '@src/utilities/storeUtils'
import { timeDiff } from '@src/utilities/date'
import { userGraphQlApi } from '@src/api/apiModuleInstances'
import approvalGroups from './approvalGroups'
import badges from './badges'
import communicationSettings from './communicationSettings'
import skills from './skills'
import settings from './settings'

export interface IUserState {
  user: Partial<IUser>;
  accountUserProps: IAccountUserProps;
  editUser: Partial<IEditUser>; // deprecated, will be removed when the old user profile is removed
  loading: boolean;
  error: boolean;
  roles: string[];
}

const state: IUserState = {
  user: {},
  accountUserProps: { socialLinks: [] },
  editUser: {}, // deprecated, will be removed when the old user profile is removed
  loading: true,
  error: false,
  roles: []
}

function getIsNewUserWithinDays(first_seen: string | null | undefined, days: number) {
  return !first_seen || timeDiff(first_seen, new Date().toISOString(), 'days') < days
}

const getters: GetterTree<IUserState, IRootState> = {
  getUser(state) {
    return state.user
  },
  getAccountUserProps(state) {
    return state.accountUserProps
  },
  getEditUser(state) {
    return state.editUser
  },
  getUserLoading(state) {
    return state.loading
  },
  getUserFullName({ user: { firstname, lastname } }) {
    return getFullName(firstname, lastname)
  },
  getUserFetchError(state) {
    return state.error
  },
  getUserImage({ user: { image } }) {
    return image
  },
  getUserId({ user: { id } }) {
    return id
  },
  getIsNewUserSevenDays({ user: { first_seen } }) {
    return getIsNewUserWithinDays(first_seen, 7)
  },
  getIsNewUserThirtyDays({ user: { first_seen } }) {
    return getIsNewUserWithinDays(first_seen, 30)
  },
  getRoles(state) {
    return state.roles
  },
  getPermissionLevel(state) {
    return getPermissionLevel(state.roles)
  },
  getIsSuperAdmin(state) {
    return isSuperAdmin(state.roles)
  }
}

const actions: ActionTree<IUserState, IRootState> = {
  async loadCurrentUser({ commit }): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { data: user } = await userApi.throttled.getCurrentUser()
      commit('setUser', user)
      commit('setEditUser', { ...user })
    } catch (e) {
      commit('setError', true)
      initLanguage() // use the browser language in case of not logged in instead of showing translation keys
    }

    try {
      const { data: roles } = await userApi.throttled.getRoles()
      if (_isEmpty(roles)) {
        throw new Error('could not get roles')
      }
      commit('setRoles', roles)
    } catch (e) {
      // ignore
    }
    commit('setLoading', false)
  },
  async loadAccountUserProps({ commit }): Promise<void> {
    const { global_user_id } = await awaitGetterValue<IUser>('user/getUser')
    const accountUserProps = _pick((await userGraphQlApi.getAccountUserProps(global_user_id)).user, accountUserPropNames)
    commit('setAccountUserProps', accountUserProps)
    commit('setEditUser', { ...accountUserProps })
  },
  async setUserImage({ commit, state }, { file }: { file: File}): Promise<void> {
    try {
      const { data } = await accountsApi.setAccountImage(state.user, file)
      commit('setUserImage', data.url)
    } catch {
      commit('setError', true)
    }
  },
  async deleteUserImage({ commit, state }): Promise<void> {
    commit('setError', false)
    try {
      const { data } = await accountsApi.deleteAccountImage(state.user)
      commit('setUserImage', data.url)
    } catch {
      commit('setError', true)
    }
  },
  async saveUser({ commit, state }): Promise<boolean> {
    commit('setError', false)
    let isUserSaved = false
    try {
      isUserSaved = await userGraphQlApi.saveUser(state.editUser)
      if (isUserSaved) {
        commit('setUser', _omit(state.editUser, accountUserPropNames))
        commit('setAccountUserProps', _pick(state.editUser, accountUserPropNames))
      }
    } catch {
      commit('setError', true)
    }
    return isUserSaved
  },

  // The actions for the new user profile
  // TODO: this store module should be re-worked a bit so that the state is always in sync with what the backend returns
  async saveUserPartialUpdate({ commit, state }, updatedUser: Partial<IUser>): Promise<boolean> {
    commit('setError', false)
    try {
      await userGraphQlApi.saveUser({ global_user_id: state.user.global_user_id, ...updatedUser })
      commit('setUser', { ...state.user, ...updatedUser })
      return true
    } catch {
      commit('setError', true)
      return false
    }
  }
}

const mutations: MutationTree<IUserState> = {
  setLoading: (state, loading: boolean): void => {
    state.loading = loading
  },
  setError: (state, error: boolean): void => {
    state.error = error
  },
  setUser: (state, user: IUser): void => {
    state.user = user
  },
  setAccountUserProps: (state, props: IAccountUserProps): void => {
    state.accountUserProps = props
  },
  setEditUser: (state: IUserState, editUser: IEditUser): void => {
    state.editUser = { ...state.editUser, ...editUser }
  },
  setEditUserLanguage: (state: IUserState, language: string): void => {
    state.editUser.language = language
  },
  resetEditUser: (state): void => {
    state.editUser = { ...state.user, ...state.accountUserProps }
  },
  setUserImage: (state, userImageSrc: string): void => {
    state.user.image = userImageSrc
    state.editUser.image = userImageSrc
  },
  setRoles: (state, roles: string[]): void => {
    state.roles = roles
  }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  getters,
  actions,
  mutations,
  modules: {
    approvalGroups,
    badges,
    communicationSettings,
    skills,
    settings
  }
} as Module<IUserState, IRootState>
