


































import Vue, { VueConstructor } from 'vue'
import { ITipTapPlugins } from '@src/types/tiptap.types'
import { PropType } from '@vue/composition-api'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import tipTapEditorMixin from './TipTap/mixins/tipTapEditorMixin'
import SMSimpleEditorOutputView from './TipTap/components/SMEditorOutputView.vue'
import SMTipTap from './TipTap/index.vue'

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof tipTapEditorMixin>
>).extend({
  name: 'SMEditor',
  components: {
    SMTipTap,
    SMSimpleEditorOutputView
  },
  mixins: [ tipTapEditorMixin ],
  props: {
    extensions: {
      type: Array as () => ITipTapPlugins[],
      default: (): ITipTapPlugins[] => [],
      required: true
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    knowledgeSpace: {
      type: Object as PropType<IKnowledgeSpace | null | undefined>,
      default: undefined
    }
  },
  data() {
    return {
      isMounted: false
    }
  },
  computed: {
    isIE(): boolean {
      return /Trident/.test(window.navigator.userAgent)
    },
    skeletonType(): string {
      if (this.extensionType === 'COMMENT_EXTENSIONS') {
        return this.isReadOnly ? 'text@2' : 'text'
      }
      return this.isReadOnly ? 'text@5' : 'image'
    },
    hasCodeBlock(): boolean {
      return this.value.includes('<pre>')
    }
  },
  methods: {
    setEditorAsMounted(): void {
      this.isMounted = true
    },
    setInput(value: string): void {
      this.$emit('input', value)
    }
  }
})
