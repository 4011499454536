


















































import Vue from 'vue'
import SMDialog from '@src/ui/SMDialog/index.vue'

/* Usage:
  <SMConfirmationDialog
    v-model="dialogVisible"
    @confirm="confirmCallbackFn"
    // { ...props }
  />
*/

export default Vue.extend({
  name: 'SMConfirmationDialog',
  components: {
    SMDialog
  },
  props: {
    title: {
      type: String,
      required: true
    },
    body: {
      type: String,
      default: ''
    },
    confirmationButtonLabel: {
      type: String,
      default: ''
    },
    cancelButtonLabel: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: false
    },
    confirmationButtonDisabled: {
      type: Boolean,
      default: false
    }
  }
})
