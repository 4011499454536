import { Ref, computed } from '@vue/composition-api'

export type VuetifyAutoCompleteElement = HTMLElement & { isMenuActive: boolean }

export function useVuetifyAutocompleteMenuHelper(refElement: Ref<VuetifyAutoCompleteElement | null>) {
  const isMenuActive = computed(() => refElement.value?.isMenuActive)

  return {
    isMenuActive
  }
}
