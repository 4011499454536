import useRealmSettings from '@src/composables/realmSettings'
import { computed } from '@vue/composition-api'
import useSocialDialogTracking from '../SMSocialDialog/composables/useSocialDialogTracking'
import { useInviteDialog } from './useInviteDialog'

export function useInviteButton(trackingContext: Partial<{ state: string, component: string }>) {
  const { realmSettings } = useRealmSettings()
  const { inviteDialogOpenModel } = useInviteDialog()
  const { clickInvite } = useSocialDialogTracking()
  const isInvitationEnabled = computed(() => realmSettings.value?.user_admin.invitation_enabled)

  function openInviteDialog() {
    inviteDialogOpenModel.value = true
    clickInvite(trackingContext)
  }
  return {
    isInvitationEnabled,
    openInviteDialog
  }
}
