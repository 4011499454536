import useStore from '@src/store'
import { computed } from '@vue/composition-api'
import { ActivationFlowStep } from '@src/types/activationFlow.types'

export default function useActivationFlowSteps() {
  const store = useStore()
  const shouldShowOnboardingDialogs = computed<boolean>(() => store.getters['activationFlow/shouldShowOnboardingDialogs'])
  const shouldShowTerms = computed<boolean>(() => store.getters['activationFlow/terms/shouldDisplayStep'])
  const shouldDisplayWizardTitleStep = computed<boolean>(() => store.getters['activationFlow/jobWizard/shouldDisplayTitleStep'])
  const currentWizardStep = computed<number>(() => store.getters['activationFlow/jobWizard/getCurrentStep'])

  const jobWizardStepIncrement = computed(() => (shouldDisplayWizardTitleStep.value ? currentWizardStep.value : 0))

  const steps = computed(() => {
    const stepArray = []
    if (shouldShowOnboardingDialogs.value) {
      stepArray.push(ActivationFlowStep.LANGUAGE_SELECTION)
    }
    if (shouldShowTerms.value) {
      stepArray.push(ActivationFlowStep.TERMS)
    }
    if (shouldShowOnboardingDialogs.value) {
      stepArray.push(ActivationFlowStep.EDUCATION_DIALOG_EXPERTS)
    }
    if (shouldShowOnboardingDialogs.value) {
      if (shouldDisplayWizardTitleStep.value) {
        stepArray.push(ActivationFlowStep.JOB_WIZARD)
      }
      stepArray.push(ActivationFlowStep.JOB_WIZARD)
    }
    if (shouldShowOnboardingDialogs.value) {
      stepArray.push(ActivationFlowStep.EDUCATION_DIALOG_USER_INTERACTION)
    }
    return stepArray
  })

  const totalSteps = computed(() => steps.value.length)

  return {
    steps,
    totalSteps,
    currentWizardStep,
    shouldDisplayWizardTitleStep,
    jobWizardStepIncrement
  }
}
