import { UserChip } from '@src/ui/SMSocialDialog/types'
import { IUser } from '@src/types/user.types'
import useRealmSettings from '@src/composables/realmSettings'
import { computed } from '@vue/composition-api'

export default function useUserChip(onlyEmails?: boolean) {
  const { realmSettings } = useRealmSettings()
  const isInvitationEnabled = computed(() => realmSettings.value?.user_admin.invitation_enabled)
  const validDomains = computed(() => realmSettings.value?.user_admin.invitation_email_allowed_domains ?? [])
  const inputLabel = computed(() => {
    if (onlyEmails) {
      return 'social-dialog.add-email'
    }
    if (!isInvitationEnabled.value) {
      return 'social-dialog.add-name'
    }
    return 'social-dialog.add-email-or-name'
  })
  function getChipLabel(user: UserChip) : string {
    if ((user as IUser).firstname && (user as IUser).lastname) {
      return `${(user as IUser).firstname} ${(user as IUser).lastname}`
    }
    return user as string
  }

  function getChipAvatar(user: UserChip) {
    return (user as IUser).image || null
  }

  function filterInvalidEmails(userList: UserChip[]) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return userList.filter((user) => {
      if ((user as IUser).id) {
        return !onlyEmails
      }
      if (!isInvitationEnabled.value || !emailRegex.test(user as string)) {
        return false
      }
      if (validDomains.value.length) {
        return validDomains.value.filter((dom) => (user as string).endsWith(dom)).length
      }
      return true
    })
  }

  function getDataCy(prefix: string, item: UserChip) {
    return `${prefix}-${getChipLabel(item).toLowerCase().replace(' ', '-')}`
  }

  return {
    inputLabel,
    validDomains,
    getDataCy,
    getChipLabel,
    getChipAvatar,
    filterInvalidEmails
  }
}
