import { ITrackingProps } from '@src/types/events.types'

export const VSM_SETUP_HELP = 'clickHelpButton'
export const VSM_SETUP_MORE = 'clickMoreTargets'

const commonProps = {
  component: 'admin.vsm.setup.welcome'
}

export default {
  vsmSetup: {
    [VSM_SETUP_HELP]: (): ITrackingProps => ({
      ...commonProps,
      element: 'button',
      type: 'click'
    }),
    [VSM_SETUP_MORE]: (): ITrackingProps => ({
      ...commonProps,
      element: 'button',
      type: 'click'
    })
  }
}
