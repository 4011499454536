import {
  ActionTree,
  GetterTree, Module, MutationTree
} from 'vuex'
import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import find from 'lodash/fp/find'
import { IRootState } from '@src/types/store.types'
import awaitGetterValue from '@src/utilities/storeUtils/index'
import userApi from '@src/api/user'
import { IUser } from '@src/types/user.types'
import {
  IBadgeDialog, IBadgeFamily, IBadgeFamilyRaw, IBadgesState, IGetBadge
} from '@src/types/badges.types'

export const emptyBadge: IBadgeFamily = {
  badgeFamily: '',
  icon: '',
  missing: [],
  obtained: [],
  progress: 0
}

export const state: IBadgesState = {
  loading: true,
  error: false,
  badges: [ emptyBadge ],
  dialog: {
    badgeFamily: '',
    show: false
  }
}

const getters: GetterTree<IBadgesState, IRootState> = {
  getLoading(state): boolean { return state.loading },
  getError(state): boolean { return state.error },
  getDialog(state): IBadgeDialog { return state.dialog },
  getObtainedBadges(state): IBadgeFamily[] {
    return filter(
      ({ obtained }: IBadgeFamily): boolean => !!obtained.length,
      state.badges
    )
  },
  getAvailableBadges(state): IBadgeFamily[] {
    return filter(
      ({ obtained, missing }: IBadgeFamily): boolean => !obtained.length && !!missing.length,
      state.badges
    )
  },
  getBadge(state: IBadgesState): IGetBadge {
    return (badgeFamily: string): IBadgeFamily | undefined => find({ badgeFamily }, state.badges)
  }
}

const actions: ActionTree<IBadgesState, IRootState> = {
  async loadBadges({ commit }, userId: number): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { id: currentUserId } = await awaitGetterValue<IUser>('user/getUser')
      const { data: { badges } } = await userApi.getBadges(userId || currentUserId) // if userId === 0 then use current user id
      commit('setBadges', badges)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations: MutationTree<IBadgesState> = {
  setBadges(state, badges: IBadgeFamilyRaw[]): void {
    state.badges = map(
      ({
        badge_family,
        ...badgeFamilyRaw
      }: IBadgeFamilyRaw): IBadgeFamily => ({
        badgeFamily: badge_family,
        ...badgeFamilyRaw
      }),
      badges
    )
  },
  setDialog(state, badgeDialog: IBadgeDialog): void {
    const existingProps = state.dialog
    state.dialog = { ...existingProps, ...badgeDialog }
  },
  setLoading(state, loading: boolean): void { state.loading = loading },
  setError(state, error: boolean): void { state.error = error }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  getters,
  actions,
  mutations
} as Module<IBadgesState, IRootState>
