








import { defineComponent } from '@vue/composition-api'
import SMWhatsNewCardHeader from './components/SMWhatsNewCardHeader.vue'
import SMWhatsNewList from './components/SMWhatsNewList.vue'

export default defineComponent({
  name: 'SMWhatsNewCard',
  components: {
    SMWhatsNewCardHeader,
    SMWhatsNewList
  }
})
