import { AxiosInstance } from 'axios'
import { ICareerItem, ICareerPayload } from '../../../views/UserProfileV2/components/Career/types'

export function careersApiFactory(api: AxiosInstance) {
  const baseUrl = '/accounts/api/v1/users'
  return {
    getCareers(userId: string) {
      return api.get<ICareerItem[]>(`${baseUrl}/${userId}/careers `)
    },
    createCareer(career: ICareerPayload) {
      return api.post(`${baseUrl}/me/careers`, career)
    },
    updateCareer(career: ICareerItem) {
      return api.put(`${baseUrl}/me/careers/${career.id}`, career)
    },
    deleteCareer(id: number) {
      return api.delete(`${baseUrl}/me/careers/${id}`)
    }
  }
}
