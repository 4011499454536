

























import { defineComponent, inject } from '@vue/composition-api'
import { SMAskInjectables } from '@src/ui/SMAsk/constants'
import { IUseTracking } from '@src/ui/SMAsk/composables/tracking'

export default defineComponent({
  name: 'SMFullscreenHeader',
  setup() {
    const { trackClickCancel } = inject(SMAskInjectables.Tracking) as IUseTracking

    return { trackClickCancel }
  }
})
