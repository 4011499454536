import Vue from 'vue'
import { mounted, updated, unmounted } from './directive'

/* *
 * This directive was taken from vue-snip and adapted to fix the following issue:
 * When the text was empty on the updated event, it threw an error.
 * A PR was submitted to address the issue. This code should be removed
 * and vue-snip restored once the the textContent destructuring is fixed
 * https://github.com/ajobi/vue-snip/pull/39
 * */
export default (() => ({
  install(_Vue: typeof Vue) {
    const isVue3 = parseFloat(Vue.version[0]) > 2

    _Vue.directive('snip', {
      [isVue3 ? 'mounted' : 'inserted']: mounted,
      [isVue3 ? 'updated' : 'update']: updated,
      [isVue3 ? 'unmounted' : 'unbind']: unmounted
    })
  }
}))()

export * from './types'
