/* eslint-disable */
import MessageFormat from 'messageformat'
import { DEFAULT_LANGUAGE } from './constants'

// Custom formater like in the example bellow that would allow also Component interpolation
// https://github.com/kazupon/vue-i18n/blob/7609dd3147698557ca81254233663868967af9c6/examples/formatting/custom/src/formatter.js
export default class CustomFormatter {
  private readonly _locale: string;

  private readonly _formatter: any;

  private readonly _caches = Object.create(null);

  constructor(options = {} as any) {
    this._locale = options.locale || DEFAULT_LANGUAGE
    this._formatter = new MessageFormat(this._locale, { returnType: 'values' } as any)
    this._caches = Object.create(null)
  }

  interpolate(message: string, values: any) { 
    let fn = this._caches[message]
    if (!fn) {
      fn = this._formatter.compile(message, this._locale)
      this._caches[message] = fn
    }

    if (values !== null && values !== undefined) {
      /**
       * Component interpolation in the following format passes `values` as an Array-like `Object`
       *
       * ```vue
       * <i18n path="terms" for="tos">
       *   I agree with the <a href="/terms">{{ $t('tos') }}</a>
       * </i18n>
       * ```
       *
       * VNodes _may_ be found when using interpolated values with either the deprecated `places` **or** the new slot
       * syntax
       */
      const valuesEntries = Object.entries(values)
      const isNonSlotInterpolation = valuesEntries.some((entry) => {
        const [ key, value ] = entry as any
        return value.hasOwnProperty('componentOptions') && isNaN(Number.parseFloat(key)) === false
      })

      if (isNonSlotInterpolation) {
        /**
         * In this case, we iterate over the VNode list and preserve **only** VNodes with tags and return them, wrapped
         * in a list.
         *
         * Rationale: If text nodes are kept, the positional lookup from vue-i18n will fail, rendering the first
         * child again (i.e `I agree with the, I agree with the`)
         */
        values = valuesEntries.map((entry) => {
          const [ , vnode ] = entry as any
          if (vnode.tag !== undefined) {
            return [ vnode ]
          }
        }).filter(Boolean)
      }
    }
    const result = fn(values).map((piece: any) => {
      if (!Array.isArray(piece)) {
        return piece
      }

      const hasVNodes = piece.some((part) => part.hasOwnProperty('componentOptions'))
      if (hasVNodes === false) {
        return piece.join('')
      }
      return piece
    })

    return result
  }
}
