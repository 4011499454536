import { AxiosPromise } from 'axios'
import { ICategory } from '@src/types/category.types'
import createAxiosInstance, { createOptimizedAPI } from '.'

interface ICategoriesApi {
  getCategories(): AxiosPromise<{items: ICategory[]}>;
}

const api = createAxiosInstance()

const categoriesApi: ICategoriesApi = {
  getCategories(): AxiosPromise<{items: ICategory[]}> {
    return api.get('categories')
  }
}

export default {
  ...categoriesApi,
  ...createOptimizedAPI(categoriesApi)
}
