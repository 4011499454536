import flatMap from 'lodash/fp/flatMap'
import uniqWith from 'lodash/fp/uniqWith'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { capitalizeFirstOnly } from '@src/utilities/tag'
import {
  IConceptSearchResultItem, IStandardizedConceptAllLanguages, IStandardizedConceptSingleLanguage
} from '@src/types/concept.types'
import { conceptsApi } from '@src/api/apiModuleInstances'

function containsAllSpecialChars(text: string) {
  const regex = /^[^a-zA-Z0-9]+$/
  return regex.test(text)
}

const uniqByLabelMatchPrimaryAndDescription = uniqWith((a: IConceptSearchResultItem, b: IConceptSearchResultItem) => isEqual(pick(a, [ 'label_match', 'primary_label', 'description' ]), pick(b, [ 'label_match', 'primary_label', 'description' ])))

export async function createConceptFromOntologyInULF(item: IConceptSearchResultItem): Promise<IStandardizedConceptAllLanguages[]> {
  const { data } = await conceptsApi.createConcept({
    ontology_id: item.ontology_id,
    languages: [ {
      language_id: item.language,
      primary_label: item.primary_label,
      aliases: [ item.label_match ]
    } ]
  })
  return data
}

export async function createConceptFromTextInULF(text: string, language: string): Promise<IStandardizedConceptAllLanguages[]> {
  if (text.length > 1 && !containsAllSpecialChars(text)) {
    const { data } = await conceptsApi.createConcept({
      languages: [ {
        language_id: language,
        aliases: [ capitalizeFirstOnly(text) ]
      } ]
    })
    return data
  }
  throw new Error('Cannot create concept as text is too short or contains only special characters')
}

export const flatMapConceptsToConceptualTopicsWithLanguage = flatMap<IStandardizedConceptAllLanguages, IStandardizedConceptSingleLanguage>((concept) => {
  return concept.labels_for_languages.map((language) => ({
    concept_id: concept.concept_id,
    label_match: language.labels[0],
    primary_label: language.primary_label,
    description: language.description,
    language: language.language
  }))
})

export function topicLabelsToString(selectedTopic: IConceptSearchResultItem) {
  if (selectedTopic.label_match === selectedTopic.primary_label || !selectedTopic.primary_label) {
    return selectedTopic.label_match
  }
  return `${selectedTopic.label_match} (${selectedTopic.primary_label})`
}

export function transformToQuestionConcept(concept: IStandardizedConceptSingleLanguage): IStandardizedConceptSingleLanguage {
  return {
    concept_id: concept.concept_id,
    label_match: concept.label_match,
    language: concept.language,
    primary_label: concept.primary_label,
    description: concept.primary_label
  }
}

export function removeAmbiguousAndDuplicateResults(results: IConceptSearchResultItem[]) {
  const uniqueLabels = uniqByLabelMatchPrimaryAndDescription(results)
  return uniqueLabels.filter((result) => {
    if (!result.description) {
      return !results.some((obj) => obj.concept_id === result.concept_id && obj !== result)
        || !results.some((obj) => obj.ontology_id === result.ontology_id && obj !== result)
    }
    return true
  })
}
