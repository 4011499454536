import _some from 'lodash/some'
import { IRootState } from '@src/types/store.types'
import {
  MutationTree, ActionTree, GetterTree, Module
} from 'vuex'
import { ActivationFlowStepStatus, ActivationFlowStep } from '@src/types/activationFlow.types'
import { isAuthenticated } from '@src/utilities/auth'
import userApi from '@src/api/user'
import awaitGetterValue from '@src/utilities/storeUtils'
import { onboardingDialogApi } from '@src/api/apiModuleInstances'
import terms from './modules/terms'
import languageSelection from './modules/languageSelection'
import jobWizard from './modules/jobWizard'
import tooltips from './modules/tooltips'
import whatsNew from './modules/whatsNew'
import onboardingChecklist from './modules/onboardingChecklist'
import educationDialog from './modules/educationDialog'

export interface IActivationFlowState {
  started: boolean;
  done: boolean;
  currentStep: number;
  stepStatus: {
    [key in ActivationFlowStep]: ActivationFlowStepStatus | null
  };
  onboardingCompletedDate: string | null;
  userExpertiseTotal: number | undefined
}

const ACTIVATION_FLOW_EXECUTION_ORDER = [
  {
    step: ActivationFlowStep.LANGUAGE_SELECTION,
    storeAction: 'languageSelection/possiblyShowLanguageSelection'
  },
  {
    step: ActivationFlowStep.TERMS,
    storeAction: 'terms/possiblyShowTerms'
  },
  {
    step: ActivationFlowStep.EDUCATION_DIALOG_EXPERTS,
    storeAction: 'educationDialog/possiblyShowExpertsDialog'
  },
  {
    step: ActivationFlowStep.JOB_WIZARD,
    storeAction: 'jobWizard/possiblyShowJobWizard'
  },
  {
    step: ActivationFlowStep.EDUCATION_DIALOG_USER_INTERACTION,
    storeAction: 'educationDialog/possiblyShowUserInteractionDialog'
  },
  {
    step: ActivationFlowStep.WHATS_NEW,
    storeAction: 'whatsNew/possiblyShowWhatsNew'
  },
  {
    step: ActivationFlowStep.ONBOARDING_CHECKLIST,
    storeAction: 'onboardingChecklist/possiblyShowOnboardingChecklist'
  }
]

const state: IActivationFlowState = {
  started: false,
  done: false,
  currentStep: 0,
  userExpertiseTotal: undefined,
  onboardingCompletedDate: null,
  stepStatus: {
    [ActivationFlowStep.LANGUAGE_SELECTION]: null,
    [ActivationFlowStep.TERMS]: null,
    [ActivationFlowStep.ONBOARDING_TOOLTIPS]: null,
    [ActivationFlowStep.JOB_WIZARD]: null,
    [ActivationFlowStep.WHATS_NEW]: null,
    [ActivationFlowStep.ONBOARDING_CHECKLIST]: null,
    [ActivationFlowStep.EDUCATION_DIALOG_EXPERTS]: null,
    [ActivationFlowStep.EDUCATION_DIALOG_USER_INTERACTION]: null
  }
}
const actions: ActionTree<IActivationFlowState, IRootState> = {
  async init({ dispatch, commit, rootGetters }) {
    if (isAuthenticated()) {
      try {
        const userId = await awaitGetterValue<number>('user/getUserId')
        const { data } = await userApi.cached.getSkills(userId)
        commit('setUserExpertiseTotal', data.total)
      } catch {
        // nothing the total value will remain undefined
      }

      commit('setStarted')

      await dispatch('terms/loadShouldDisplayStep')

      const { data: { completed_date } } = await onboardingDialogApi.throttled.getOnboardingDialogStatus()
      await awaitGetterValue<boolean>('user/getUserLoading', (loading) => !loading) // this makes sure user is loaded before we go on

      commit('setOnboardingCompletedDate', completed_date)

      const firstStep = ACTIVATION_FLOW_EXECUTION_ORDER[0]
      const getUser = rootGetters['user/getUser']
      if (getUser.position) {
        commit('jobWizard/setJobTitle', getUser.position)
        commit('jobWizard/hideTitleStep')
      }

      dispatch(firstStep.storeAction)
    } else {
      dispatch('done')
    }
  },
  next({ dispatch, commit, state }, { status, step }: {status: ActivationFlowStepStatus; step: ActivationFlowStep}) {
    commit('setStatus', { status, step })
    const newCurrentStep = state.currentStep + 1
    commit('setCurrentStep', newCurrentStep)
    const nextStep = ACTIVATION_FLOW_EXECUTION_ORDER[newCurrentStep]
    if (nextStep) {
      dispatch(nextStep.storeAction)
    } else {
      dispatch('done')
    }
  },
  done({ commit }) {
    commit('setDone')
  }
}

const mutations: MutationTree<IActivationFlowState> = {
  setStarted(state) {
    state.started = true
  },
  setDone(state) {
    state.done = true
  },
  setCurrentStep(state, currentStep) {
    state.currentStep = currentStep
  },
  setStatus(state, { status, step }: {status: ActivationFlowStepStatus; step: ActivationFlowStep}) {
    state.stepStatus[step] = status
  },
  setOnboardingCompletedDate(state, onboardingCompleted: string) {
    state.onboardingCompletedDate = onboardingCompleted
  },
  setUserExpertiseTotal(state, userExpertiseTotal) {
    state.userExpertiseTotal = userExpertiseTotal
  }
}

const getters: GetterTree<IActivationFlowState, IRootState> = {
  isSteppedThroughSomeSteps(state) {
    return _some(state.stepStatus, (step) => (
      step === ActivationFlowStepStatus.STEPPED_THROUGH_COMPLETE || step === ActivationFlowStepStatus.STEPPED_THROUGH_INCOMPLETE
    ))
  },
  isDone(state) {
    return state.done
  },
  shouldShowOnboardingDialogs(state, localGetters, rootState, rootGetters) {
    // This was released on 13.10.2023
    const isNewUserSevenDays: boolean = rootGetters['user/getIsNewUserSevenDays']

    if (isNewUserSevenDays) {
      // user is new and max. 7 days old
      return !state.onboardingCompletedDate && !state.done
    }
    // If user has no skills and has not completed the onboarding yet return true otherwise false
    return state.userExpertiseTotal === 0 && !state.onboardingCompletedDate && !state.done
  }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  actions,
  mutations,
  getters,
  modules: {
    terms,
    languageSelection,
    jobWizard,
    tooltips,
    whatsNew,
    onboardingChecklist,
    educationDialog
  }
} as Module<IActivationFlowState, IRootState>
