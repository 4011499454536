import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import { PrivilegedUserRoles } from '@src/types/user.types'
import { isVSMSetupPageEnabled } from '@src/utilities/vsm'
import { RouteConfig } from 'vue-router'

export default function getAdminRouteConfig(realmSettings?: IUnauthenticatedRealmSettings): RouteConfig[] {
  return [
    {
      path: 'content/knowledge-spaces',
      component: () => import(/* webpackChunkName: "admin" */ '@src/views/Admin/AdminContainer.vue'),
      meta: {
        requiredRole: PrivilegedUserRoles.ContentAdmin
      },
      async beforeEnter(_to, _from, next) {
        if (realmSettings?.knowledge_spaces.knowledge_spaces_in_pricing_plan) {
          next()
        } else {
          next('/error/403')
        }
      },
      children: [
        {
          path: '',
          name: 'admin/content/knowledge-spaces/overview',
          component: () => import(/* webpackChunkName: "admin" */ '@src/views/AdminKnowledgeSpaces/components/AdminKnowledgeSpacesOverview.vue')
        },
        {
          path: ':id',
          name: 'admin/content/knowledge-spaces/edit',
          component: () => import(/* webpackChunkName: "admin" */ '@src/views/AdminKnowledgeSpaces/components/AdminKnowledgeSpacesEdit.vue'),
          props: (route) => ({ spaceId: parseInt(route.params.id, 10) || undefined })
        }
      ]
    },
    {
      path: 'announcements',
      meta: {
        requiredRole: PrivilegedUserRoles.CommunicationAdmin
      },
      component: () => import(/* webpackChunkName: "admin" */ '@src/views/Admin/AdminContainer.vue'),
      children: [
        {
          path: '',
          name: 'admin/announcements/overview',
          component: () => import(/* webpackChunkName: "admin" */ '@src/views/AdminAnnouncement/AdminAnnouncementOverview.vue')
        },
        {
          path: ':id',
          name: 'admin/announcements/edit',
          component: () => import(/* webpackChunkName: "admin" */ '@src/views/AdminAnnouncement/AdminAnnouncementEdit.vue'),
          props: (route) => ({ announcementId: parseInt(route.params.id, 10) || undefined })
        }
      ]
    },
    {
      path: 'settings',
      name: 'admin/settings',
      meta: {
        requiredRole: PrivilegedUserRoles.SettingsAdmin
      },
      component: () => import(/* webpackChunkName: "admin" */ '@src/views/Admin/AdminContainer.vue'),
      children: [
        {
          path: 'algorithm',
          name: 'admin/settings/algorithm',
          component: () => import(/* webpackChunkName: "admin" */ '@src/views/AdminSettings/AdminAlgorithmSettings.vue')
        },
        {
          path: 'auth',
          name: 'admin/settings/auth',
          component: () => import(/* webpackChunkName: "admin" */ '@src/views/AdminSettings/AdminAuthSettings.vue')
        },
        {
          path: 'workspace',
          name: 'admin/settings/workspace',
          component: () => import(/* webpackChunkName: "admin" */ '@src/views/AdminSettings/AdminWorkspaceSettings.vue')
        },
        {
          path: 'content',
          name: 'admin/settings/content',
          component: () => import(/* webpackChunkName: "admin" */ '@src/views/AdminSettings/AdminContentSettings.vue')
        },
        {
          path: 'connectors',
          name: 'admin/settings/connectors',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/Admin/Connectors/index.vue')
        },
        {
          path: 'approval-message/',
          name: 'admin/settings/approval-message',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminTerms/components/AdminTermsOverview.vue')
        },
        {
          path: 'approval-message/:id(\\d+)',
          name: 'admin/settings/approval-message/edit',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminTerms/components/AdminTermsEdit.vue'),
          props: (route) => ({ version: Number(route.params.id) })
        },
        {
          path: 'approval-message/:id(\\d+)/:lang',
          name: 'admin/settings/approval-message/translation',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminTerms/components/AdminTermsTranslation.vue'),
          props: (route) => ({ version: Number(route.params.id), language: route.params.lang })
        },
        {
          path: 'approval-group',
          name: 'admin/settings/approval-group',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/SolutionApproval/index.vue'),
          async beforeEnter(_to, _from, next) {
            if (realmSettings?.solution_authority.solution_authority_in_pricing_plan) {
              next()
            } else {
              next('/error/403')
            }
          }
        }
      ]
    },
    {
      path: 'vsm',
      name: 'admin/vsm',
      meta: {
        requiredRole: PrivilegedUserRoles.NetworkStatisticsAdmin
      },
      component: () => import(/* webpackChunkName: "admin" */ '@src/views/Admin/AdminContainer.vue'),
      beforeEnter(to, from, next) {
        if (realmSettings?.vsm.vsm_enabled) {
          next()
        } else {
          next('/error/403')
        }
      },
      props: () => ({ cols: 12 }),
      children: [
        {
          path: 'setup',
          name: 'admin/vsm/setup',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/VSM/setupPage/index.vue'),
          beforeEnter(to, from, next) {
            if (isVSMSetupPageEnabled(realmSettings?.metadata.go_live_date)) {
              next()
            } else {
              next('/admin/vsm/metrics')
            }
          }
        },
        {
          path: 'metrics',
          name: 'admin/vsm/metrics',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/VSM/metricsPage/index.vue')
        }
      ]
    },
    {
      path: 'statistics/dashboard',
      meta: {
        requiredRole: PrivilegedUserRoles.NetworkStatisticsAdmin
      },
      component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminAnalyticsDashboard/index.vue'),
      beforeEnter(to, from, next) {
        // TODO: check analytics dashboard enabled
        next()
      },
      children: [
        {
          path: '',
          alias: 'overview',
          name: 'analytics-dashboard/overview',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminAnalyticsDashboard/AdminAnalyticsDashboardOverviewMetrics.vue')
        },
        {
          path: 'content',
          name: 'analytics-dashboard/content',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminAnalyticsDashboard/AdminAnalyticsDashboardContentMetrics.vue')
        },
        {
          path: 'views',
          name: 'analytics-dashboard/views',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminAnalyticsDashboard/AdminAnalyticsDashboardViewMetrics.vue')
        },
        {
          path: 'collaboration',
          name: 'analytics-dashboard/collaboration',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminAnalyticsDashboard/AdminAnalyticsDashboardCollaborationMetrics.vue')
        },
        {
          path: 'network-health',
          name: 'analytics-dashboard/network-health',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminAnalyticsDashboard/AdminAnalyticsDashboardHealthMetrics.vue')
        },
        {
          path: 'user',
          name: 'analytics-dashboard/user',
          component: () => import(/* webpackChunkName: "admin" */'@src/views/AdminAnalyticsDashboard/AdminAnalyticsDashboardUserMetrics.vue')
        }
      ]
    }
  ]
}
