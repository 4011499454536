
































import { IUser } from '@src/types/user.types'
import { computed, defineComponent } from '@vue/composition-api'
import SMUserAvatar from '@src/ui/SMUserAvatar/index.vue'
import { getFullName } from '@src/utilities/userUtils/index'

const MAX_EXPERTS_TO_SHOW = 5

export default defineComponent({
  name: 'SMQuestionExpertsDisplay',
  components: {
    SMUserAvatar
  },
  props: {
    experts: {
      type: Array as () => IUser[],
      default: () => []
    }
  },
  setup(props) {
    const truncatedExperts = computed(() => props.experts.slice(0, MAX_EXPERTS_TO_SHOW))
    const remainingExpertCount = computed(() => props.experts.length - truncatedExperts.value.length)
    return {
      truncatedExperts,
      remainingExpertCount,
      getFullName
    }
  }
})
