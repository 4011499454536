import Vue, { PropType } from 'vue'
import { EntityType } from '@src/types/tiptap.types'
import { EditorExtensionsType } from '../components/toolbar/toolbarExtensions'

export default Vue.extend({
  props: {
    errorMessages: {
      type: Array as () => string[],
      default: (): string[] => []
    },
    error: {
      type: Boolean,
      default: false
    },
    entityType: {
      type: String as PropType<EntityType>,
      required: false,
      default: ''
    },
    entityId: {
      type: Number,
      required: false,
      default: 0
    },
    extensionType: {
      type: String as PropType<EditorExtensionsType>,
      default: ''
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    shouldShowToolbar: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    }
  }
})
