import { gql, ApolloQueryResult } from '@apollo/client'
import _map from 'lodash/map'
import { ContextSetter } from '@apollo/client/link/context'
import { apolloClientFactory } from './apolloClientFactory'

export interface IUserInviteResponse {
  [inviteeAlias: string]: {
    email: string;
  };
}
export function inviteUserCreationGraphQlApiFactory(authContextSetter: ContextSetter) {
  const graphqlClient = apolloClientFactory(authContextSetter)

  return {
    async inviteUserWithEmail(recipients: string[], personalMessage: string): Promise<string[]> {
      const mutation = gql`
        mutation inviteUserWithEmail {
          ${recipients.map((email, index) => `
            invitee${index}: inviteUserWithEmail(input: { email: "${email}", personalMessage: "${personalMessage}" }) { email }
          `)}
        }
      `

      const response = await graphqlClient.mutate({
        mutation
      }) as ApolloQueryResult<IUserInviteResponse>
      return _map(response.data, ({ email }) => email)
    }
  }
}
