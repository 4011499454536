



























































import {
  defineComponent, ref, toRefs, watch, computed
} from '@vue/composition-api'
import questionTitleQualityIndicatorApi from '@src/api/experimental/questionTitleQualityIndicator'
import useQuestionTitleQualityIndicatorTracking from '../../composables/useQuestionTitleQualityIndicatorTracking'

export default defineComponent({
  name: 'SMTitleQualityIndicator',
  props: {
    questionTitle: {
      type: String,
      required: true
    },
    titleBlurred: {
      type: Boolean,
      required: true
    },
    questionLanguage: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const { trackViewQuestionTitleQualityIndicatorOnce, trackClickQuestionTitleQualityIndicatorOnce, trackChangeQuestionTitleOnce } = useQuestionTitleQualityIndicatorTracking()
    const {
      questionTitle, titleBlurred, questionLanguage
    } = toRefs(props)

    // TODO: Add back the 'or german condition' when BE is ready --> || questionLanguage.value === 'de'
    const questionLanguageIsValid = computed(() => questionLanguage.value === 'en')
    const checkQuestionQuality = computed(() => questionLanguageIsValid.value && titleBlurred.value && questionTitle.value.length > 0)
    const isVisible = computed(() => checkQuestionQuality.value && showTitleQualityWarning.value && !hasClosedTitleQualityWarning.value)

    const menu = ref(false)
    const showTitleQualityWarning = ref(false)
    const hasViewedTitleQualityWarning = ref(false)
    const hasClosedTitleQualityWarning = ref(false)

    watch((titleBlurred), async () => {
      if (checkQuestionQuality.value && !hasClosedTitleQualityWarning.value) {
        try {
          const { data: { is_question } } = await questionTitleQualityIndicatorApi.getQuestionTitleQuality(questionTitle.value)
          showTitleQualityWarning.value = !is_question
          if (showTitleQualityWarning.value) {
            trackViewQuestionTitleQualityIndicatorOnce()
          }
        } catch {
          showTitleQualityWarning.value = false
        }
      } else {
        showTitleQualityWarning.value = false
        menu.value = false
      }
    }, { immediate: true })

    watch((menu), (menuValue) => {
      if (menuValue) {
        trackClickQuestionTitleQualityIndicatorOnce()
        hasViewedTitleQualityWarning.value = true
      }
    }, { immediate: true })

    watch((questionTitle), () => {
      if (hasViewedTitleQualityWarning.value) {
        trackChangeQuestionTitleOnce()
      }
    })

    return {
      isVisible,
      menu,
      showTitleQualityWarning,
      hasClosedTitleQualityWarning
    }
  }
})
