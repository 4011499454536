export default function bytes(value: number): string {
  try {
    const log1024 = (v: number): number => Math.log(v) / Math.log(1024)
    const units = [ 'bytes', 'KB', 'MB', 'GB', 'TB', 'PB' ]
    const unitIndex = Math.floor(log1024(value))
    const fileSizeInUnit = value / (1024 ** unitIndex)
    return `${fileSizeInUnit.toFixed(1)} ${units[unitIndex]}`
  } catch {
    return '-'
  }
}
