



















import {
  computed, defineComponent
} from '@vue/composition-api'
import useRealmSettings from '@src/composables/realmSettings'
import SMSocialDialog from '@src/ui/SMSocialDialog/index.vue'
import { UserSuggestionType } from '@src/ui/SMSocialDialog/types'
import { useInviteDialog } from './useInviteDialog'

export default defineComponent({
  components: {
    SMSocialDialog
  },
  setup() {
    const { realmSettings } = useRealmSettings()
    const {
      inviteDialogOpenModel, invite, cancel
    } = useInviteDialog()
    const networkName = computed(() => realmSettings.value?.metadata.realm_name ?? '')
    const domains = computed(() => realmSettings.value?.user_admin.invitation_email_allowed_domains ?? [])
    return {
      inviteDialogOpenModel,
      networkName,
      domains,
      cancel,
      invite,
      UserSuggestionType
    }
  }
})
