import {
  IUnauthenticatedRealmSettings, ISolutionAuthorityRealmSettings, IKnowledgeSpacesRealmSettings, IKnowledgeSpacesReadOnlyRealmSettings, Environment
} from '@src/types/realmSettings.types'
import {
  GetterTree, MutationTree, ActionTree, Module
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { getLoginUrl, getLogoutUrl } from '@src/utilities/auth'
import { realmSettingsApi } from '@src/api/apiModuleInstances'

export interface IRealmSettingsState {
  unauthenticatedRealmSettings?: IUnauthenticatedRealmSettings;
  loading: boolean;
  error: boolean;
}

const state: IRealmSettingsState = {
  unauthenticatedRealmSettings: undefined,
  loading: true,
  error: false
}

const getters: GetterTree<IRealmSettingsState, IRootState> = {
  realmSettings(state) {
    return state.unauthenticatedRealmSettings
  },
  settingsLoading(state) {
    return state.loading
  },
  settingsError(state) {
    return state.error
  },
  isWhatsNewEnabled(state) {
    return state.unauthenticatedRealmSettings?.whats_new.enabled && state.unauthenticatedRealmSettings.general.environment !== Environment.ONPREM
  },
  getLoginUrl(state) {
    const canBuildUrl = !!state.unauthenticatedRealmSettings?.auth.keycloak_url && !!state.unauthenticatedRealmSettings?.metadata.realm_id
    return canBuildUrl ? getLoginUrl(state.unauthenticatedRealmSettings!) : undefined
  },
  getLogoutUrl(state) {
    const canBuildUrl = !!state.unauthenticatedRealmSettings?.auth.keycloak_url && !!state.unauthenticatedRealmSettings?.metadata.realm_id
    return canBuildUrl ? getLogoutUrl(state.unauthenticatedRealmSettings!) : undefined
  }
}

const actions: ActionTree<IRealmSettingsState, IRootState> = {
  async loadRealmSettings({ commit }): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { data } = await realmSettingsApi.throttled.get()
      commit('setSettings', data)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
  },
  async updateSolutionAuthorityRealmSettings({ commit }, updates: ISolutionAuthorityRealmSettings): Promise<void> {
    const { data } = await realmSettingsApi.setSolutionAuthoritySettings(updates)
    commit('setSolutionAuthorityRealmSettings', data)
  },
  async updateKnowledgeSpacesRealmSettings({ commit }, updates: IKnowledgeSpacesRealmSettings): Promise<void> {
    const { data } = await realmSettingsApi.setKnowledgeSpacesRealmSettings(updates)
    commit('setKnowledgeSpacesRealmSettings', data)
  }
}

const mutations: MutationTree<IRealmSettingsState> = {
  setLoading: (state, loading: boolean): void => {
    state.loading = loading
  },
  setError: (state, error: boolean): void => {
    state.error = error
  },
  setSettings: (state, settings: IUnauthenticatedRealmSettings): void => {
    state.unauthenticatedRealmSettings = settings
  },
  setSolutionAuthorityRealmSettings: (state, updates: ISolutionAuthorityRealmSettings): void => {
    state.unauthenticatedRealmSettings = {
      ...state.unauthenticatedRealmSettings!,
      solution_authority: updates
    }
  },
  setKnowledgeSpacesRealmSettings: (state, updates: IKnowledgeSpacesRealmSettings & IKnowledgeSpacesReadOnlyRealmSettings): void => {
    state.unauthenticatedRealmSettings = {
      ...state.unauthenticatedRealmSettings!,
      knowledge_spaces: updates
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<IRealmSettingsState, IRootState>
