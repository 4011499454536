/* eslint-disable import/prefer-default-export */
export function isUrl(str: string): boolean {
  let url

  try {
    url = new URL(str)
  } catch {
    return false
  }

  return url.protocol === 'http:' || url.protocol === 'https:'
}
