






























import Vue from 'vue'
import { PropType } from '@vue/composition-api'

export default Vue.extend({
  name: 'SMTipTapToolbarButton',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    shortcut: {
      type: String as PropType<string | undefined>,
      default: undefined
    },
    dataTest: {
      type: String as PropType<string | undefined>,
      default: undefined
    }
  }
})
