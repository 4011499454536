import {
  MutationTree, Module, ActionTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
import {
  CLICK_ASK, CLICK_EDIT_QUESTION, IClickAskProps, IClickEditProps
} from '@src/plugins/analytics/events/ui/SMAsk.events'
import { ComponentBasedTrackingFn } from '@src/types/events.types'

export interface IAskState {
  isAskDialogOpen: boolean;
  isEditDialogOpen: boolean;
  existingQuestionId?: number;
  currentDraftQuestionId?: number;
  initialQuestionTitle?: string;
  trackingClickAskId: string;
}

export interface IOpenAskDialogParams {
  questionTitle?: string;
  element: 'text_icon_button' | 'button';
  component: string;
  questionDraftId?: number;
}

const state: IAskState = {
  isAskDialogOpen: false,
  isEditDialogOpen: false,
  existingQuestionId: undefined,
  currentDraftQuestionId: undefined,
  initialQuestionTitle: undefined,
  trackingClickAskId: ''
}

const actions: ActionTree<IAskState, IRootState> = {
  openAskDialog({
    commit, dispatch, state
  }, {
    questionTitle, element, component, questionDraftId
  }: IOpenAskDialogParams) {
    if (questionDraftId) {
      commit('setCurrentDraftQuestionId', questionDraftId)
    }
    commit('openAskDialog', questionTitle)
    dispatch('createClickAskId');

    (Vue.prototype.$trackEvent as ComponentBasedTrackingFn<IClickAskProps>)(CLICK_ASK, 'SMAsk', {
      element,
      click_ask_id: state.trackingClickAskId,
      component
    })
  },
  openEditDialog({ commit }, questionId: number) {
    commit('openEditDialog', questionId);

    (Vue.prototype.$trackEvent as ComponentBasedTrackingFn<IClickEditProps>)(CLICK_EDIT_QUESTION, 'SMAsk', {
      entity: 'question',
      is_author: true
    })
  },
  createClickAskId({ commit }) {
    commit('setClickAskId', uuidv4())
  }
}

const mutations: MutationTree<IAskState> = {
  openAskDialog(state, questionTitle?: string) {
    Vue.set(state, 'initialQuestionTitle', questionTitle)
    state.isAskDialogOpen = true
  },
  closeAskDialog(state) {
    state.isAskDialogOpen = false
    Vue.set(state, 'initialQuestionTitle', undefined)
  },
  openEditDialog(state, questionId: number) {
    state.isEditDialogOpen = true
    Vue.set(state, 'existingQuestionId', questionId)
  },
  closeEditDialog(state) {
    state.isEditDialogOpen = false
    Vue.set(state, 'existingQuestionId', undefined)
  },
  setCurrentDraftQuestionId(state, questionId: number) {
    Vue.set(state, 'currentDraftQuestionId', questionId)
  },
  resetCurrentDraftQuestionId(state) {
    Vue.set(state, 'currentDraftQuestionId', undefined)
  },
  setClickAskId(state, clickAskId: string) {
    state.trackingClickAskId = clickAskId
  },
  removeClickAskId(state) {
    state.trackingClickAskId = ''
  }
}

export default {
  namespaced: true,
  actions,
  state,
  mutations
} as Module<IAskState, IRootState>
