import { Store } from 'vuex'
import _isEmpty from 'lodash/isEmpty'
import _once from 'lodash/once'

import { getPermissionLevel } from '@src/utilities/userUtils'

import { IAnalyticsParams } from '@src/store/modules/analytics'
import { IRootState } from '@src/types/store.types'

export function dependingStoreStateIsLoaded(params: IAnalyticsParams) {
  const hasUser = !_isEmpty(params.user)
  const hasRealmSettings = !_isEmpty(params.realmSettings)
  const knowledgeSpacesLoaded = params.realmSettings?.knowledge_spaces?.knowledge_spaces_enabled ? params.knowledgeSpacesLoaded : true
  return hasUser && hasRealmSettings && knowledgeSpacesLoaded
}

/**
 * watch state until user and settings are there to load the analytics config
 */
const analyticsConfigHook = (store: Store<IRootState>): void => {
  const dispatchOnce = _once(store.dispatch)
  const unwatch = store.watch(
    (state): IAnalyticsParams => ({
      user: state.user.user,
      permissionLevel: getPermissionLevel(state.user.roles),
      realmSettings: state.realmSettings.unauthenticatedRealmSettings,
      knowledgeSpacesLoaded: state.knowledgeSpaces.knowledgeSpacesLoaded
    }),
    (params: IAnalyticsParams) => {
      if (dependingStoreStateIsLoaded(params)) {
        unwatch()
        dispatchOnce('analytics/loadAnalyticsConfig', params)
      }
    }
  )
}

export default analyticsConfigHook
