import Vue from 'vue'
import _get from 'lodash/get'
import { sha512 } from 'js-sha512'
import generateOrGetSharedStore from '@src/store'
import { ITrackingConfig } from '@src/store/modules/analytics'
import { ITrackingProps, MixpanelTrackingFn } from '@src/types/events.types'
import { OverridedMixpanel } from 'mixpanel-browser'

import { ISuperproperties } from '@src/types/superproperties.types'
import _pick from 'lodash/pick'
import _mapValues from 'lodash/mapValues'
import { MutationPayload } from 'vuex'
import { IRootState } from '@src/types/store.types'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import { campaignParams } from './lastTouchUTMParams'
import events from './events'
import trackingDirective from './trackingDirective'
import { trackSignInEvent } from './trackSignInEvent'

let mixpanel: OverridedMixpanel | undefined
let trackingSalt: string | undefined
let trackingReadyResolve: (value?: unknown) => void
const trackingReady = new Promise((resolve) => {
  trackingReadyResolve = resolve
})
const store = generateOrGetSharedStore()

const utmParams = campaignParams()

export interface IAnalyticsPluginOptions {
  getTrackingState: () => string;
  debugMode: boolean;
}

interface IAnalyticsPostMessagePayload {
  event: string; props: ITrackingProps;
}
type AnalyticsPostMessageEvent = MessageEvent<{ trackMixpanelEvent?: IAnalyticsPostMessagePayload; trackComponentBasedEvent?: IAnalyticsPostMessagePayload & { component: string }, trackLegacyEvent?: IAnalyticsPostMessagePayload }>

interface IStoreWatcherReturnValue {
  trackingConfig?: ITrackingConfig;
  realmSettings?: IUnauthenticatedRealmSettings;
  featureTogglesLoading: boolean;
}

function pickUserProfileProperties(superproperties: Partial<ISuperproperties> = {}) {
  const propertiesToPick: (keyof ISuperproperties)[] = [
    'first_seen',
    'network_id',
    'user_tracking_id'
  ]
  return _pick(superproperties, propertiesToPick)
}

const hashSecretProps = (props: Partial<ITrackingProps> = {}): { [key: string]: any } => {
  const secretPropsMap: { [key: string]: string } = {
    tag: 'tag_hash',
    tag_id: 'tag_hash',
    tag_label: 'tag_hash',
    email: 'email_hash',
    topic: 'topic_hash',
    expertise: 'expertise_hash',
    id: 'id_hash',
    route_query_param_query: 'route_query_param_query_hash',
    route_query_param_search: 'route_query_param_search_hash'
  }
  return Object.keys(props).reduce((result: Partial<ITrackingProps>, key: string) => {
    const secretKey = secretPropsMap[key]
    const isPublic = !secretKey
    const anyValue = props[key]
    if (isPublic) {
      return { ...result, [key]: anyValue }
    }

    if (trackingSalt && anyValue) {
      const stringValue = JSON.stringify(anyValue)
      return { ...result, [secretKey]: sha512(`${stringValue}_${trackingSalt}`) }
    }

    return result
  }, {})
}

// This function is called from Phoenix
// The events come with all properties and don't have them pre-defined in a tracking event object
export async function trackLegacyEvent(event: string, customProps: Record<string, string | number | boolean | undefined | null> = {}): Promise<void> {
  await trackingReady
  const props = hashSecretProps(customProps)

  // mapping: always send "answer" instead of "solution"
  if (props.entity === 'solution') {
    props.entity = 'answer'
  }

  store.dispatch('analytics/conditionallyUpdateSuperproperties', event)

  return new Promise((resolve) => {
    // mixpanel is defined or not based on tracking_enabled
    if (mixpanel) {
      mixpanel.track(event, props, {}, () => resolve())
    } else {
      resolve()
    }
  })
}

export default {
  install(_Vue: typeof Vue, options: IAnalyticsPluginOptions): void {
    const trackMixpanelEvent: MixpanelTrackingFn = async <T>(event: string, props: T): Promise<void> => {
      await trackingReady
      return new Promise((resolve) => {
        // mixpanel is defined or not based on tracking_enabled
        if (mixpanel) {
          const hashedProps = hashSecretProps(props as Partial<ITrackingProps>)
          const trackedProps = {
            ...hashedProps,
            state: hashedProps.state || options.getTrackingState()
          }
          mixpanel.track(event, trackedProps, {}, () => resolve())
        } else {
          resolve()
        }
      })
    }

    const trackComponentBasedEvent = (event: string, component: string, customProps: Partial<ITrackingProps> = {}): Promise<void> => {
      const combineWithEventProps: (p: Partial<ITrackingProps>) => ITrackingProps = _get((events as any)[component], event)
      if (!combineWithEventProps) {
        throw new Error('Event name is not specified')
      }

      return trackMixpanelEvent(event, combineWithEventProps(customProps))
    }

    window.addEventListener('message', (event: AnalyticsPostMessageEvent) => {
      if (event.origin === window.origin) {
        if (event.data.trackMixpanelEvent) {
          trackMixpanelEvent(event.data.trackMixpanelEvent.event, event.data.trackMixpanelEvent.props)
        }

        if (event.data.trackLegacyEvent) {
          trackLegacyEvent(event.data.trackLegacyEvent.event, event.data.trackLegacyEvent.props)
        }

        if (event.data.trackComponentBasedEvent) {
          trackComponentBasedEvent(event.data.trackComponentBasedEvent.event, event.data.trackComponentBasedEvent.component, event.data.trackComponentBasedEvent.props)
        }
      }
    })

    trackingReady.then(() => {}) // make sure resolve function is assigned
    _Vue.prototype.$trackEvent = trackComponentBasedEvent // eslint-disable-line no-param-reassign
    _Vue.prototype.$trackMixpanelEvent = trackMixpanelEvent // eslint-disable-line no-param-reassign
    _Vue.prototype.$trackingReady = trackingReady // eslint-disable-line no-param-reassign
    _Vue.directive('track', trackingDirective)

    const unwatchMixpanelInitData = store.watch(
      (state, getters) => ({
        trackingConfig: getters['analytics/getTrackingConfig'],
        realmSettings: getters['realmSettings/realmSettings']
      } as IStoreWatcherReturnValue),
      async ({ trackingConfig, realmSettings }: IStoreWatcherReturnValue): Promise<void> => {
        if (trackingConfig) {
          trackingSalt = trackingConfig.trackingSalt
          if (trackingConfig.trackingEnabled && trackingConfig.mixpanelToken && !(window as any).Cypress) {
            // Sneaky chunk name, will load as "essentials.js" instead of something with Mixpanel
            mixpanel = (await import(/* webpackChunkName: "essentials" */'mixpanel-browser')).default
            mixpanel.init(trackingConfig.mixpanelToken, {
              api_host: window.MIXPANEL_API_HOST,
              cross_subdomain_cookie: false,
              ignore_dnt: true,
              debug: options.debugMode,
              persistence: 'localStorage'
            })
            mixpanel.identify(trackingConfig.superproperties.user_tracking_id)
            mixpanel.register(trackingConfig.superproperties)

            // read again the UTM params and merge the list to hopefully not miss any
            const allUtmParams = { ...utmParams, ..._mapValues(campaignParams()) }
            mixpanel.people.set({
              ...allUtmParams,
              ...pickUserProfileProperties(trackingConfig.superproperties)
            })
            mixpanel.register(allUtmParams)

            trackingReadyResolve()
            unwatchMixpanelInitData()
            trackSignInEvent(trackMixpanelEvent, realmSettings?.auth.cookie_same_site_none)

            store.subscribe(({ type }: MutationPayload, state: IRootState) => {
              if (type === 'analytics/setSuperproperties') {
                mixpanel!.identify(state.analytics.superproperties?.user_tracking_id)
                mixpanel!.register(state.analytics.superproperties ?? {})
              }
            })
          } else {
            trackingReadyResolve()
            unwatchMixpanelInitData()
          }
        }
      }
    )
  }
}
