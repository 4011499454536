import { AxiosPromise } from 'axios'
import createAxiosInstance, { createOptimizedAPI } from '..'

const api = createAxiosInstance('feedback', { web: true })

export interface IFeedbackApi {
  sendFeedback(message: string): AxiosPromise<{ id: number }>;
  publishFeedback(id: number): Promise<void>;
}

const feedbackApi: IFeedbackApi = {
  publishFeedback(id: number): Promise<void> {
    return api.put(`${id}/publish`)
  },
  sendFeedback(message: string): AxiosPromise<{ id: number }> {
    return api.post('', {
      description: message,
      feedback_type: 'product'
    })
  }
}

export default {
  ...feedbackApi,
  ...createOptimizedAPI(feedbackApi)
}
