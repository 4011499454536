





































import { defineComponent, inject } from '@vue/composition-api'
import { ISimilarQuestion } from '@src/types/question.types'
import { IUseTracking } from '../../composables/tracking'
import { SMAskInjectables } from '../../constants'

export default defineComponent({
  name: 'SMSimilarQuestionsDisplay',
  props: {
    similarQuestions: {
      type: Array as () => ISimilarQuestion[],
      default: (() => []) as any
    }
  },
  setup() {
    const { trackExpandSimilarQuestions, trackClickSimilarQuestion } = inject(SMAskInjectables.Tracking) as IUseTracking

    return {
      trackExpandSimilarQuestions,
      trackClickSimilarQuestion
    }
  }
})
