









import {
  defineComponent, onBeforeUpdate, onMounted, onUnmounted, onUpdated, ref
} from '@vue/composition-api'
import Vue from 'vue'
import SMLightboxDialog from './SMLightboxDialog.vue'

export default defineComponent({
  name: 'SMLightboxWrapper',
  components: {
    SMLightboxDialog
  },
  setup() {
    const el = ref<HTMLElement | null>(null)
    const imgSrc = ref('')
    const dialog = ref(false)

    function imageClickEventListener(event: MouseEvent) {
      imgSrc.value = (event.target as HTMLImageElement).src
      dialog.value = true
    }

    function addImageEventListeners() {
      el.value?.querySelectorAll('img').forEach((image) => {
        image.addEventListener('click', imageClickEventListener)
      })
    }

    function removeImageEventListeners() {
      el.value?.querySelectorAll('img').forEach((image) => {
        image.removeEventListener('click', imageClickEventListener)
      })
    }

    onMounted(async () => {
      await Vue.nextTick() // awaiting the next tick to make sure the children in the slot are rendered
      addImageEventListeners()
    })
    onUnmounted(removeImageEventListeners) // clean up on unmounted

    onBeforeUpdate(removeImageEventListeners) // when translating the content, the DOM updatees and we need to remove the event listeners before re-attaching them
    onUpdated(addImageEventListeners) // when translating the content, the DOM updates and we need to re-attach the event listeners

    return { el, imgSrc, dialog }
  }
})
