import { computed } from '@vue/composition-api'
import last from 'lodash/last'
import useRealmSettings from './realmSettings'

export const ATTACHMENT_FILE_MAX_SIZE = 52428800

export default function useAttachmentValidation() {
  const { realmSettings } = useRealmSettings()
  const allowedFileTypes = computed(() => realmSettings.value?.general.allowed_upload_file_types)
  const blacklist = computed(() => realmSettings.value?.general.upload_file_types_blacklist)

  function getFileExtension(fileName: string) {
    return last(fileName.split('.')) ?? ''
  }

  function isAllowedFiletype(fileName: string) {
    const fileExtension = getFileExtension(fileName).toLowerCase()
    return !!allowedFileTypes.value?.includes(fileExtension) && !blacklist.value?.includes(fileExtension)
  }

  function isWithinAllowedSize(fileSize: number) {
    return fileSize <= ATTACHMENT_FILE_MAX_SIZE
  }

  function isValidAttachment(file: File) {
    return isAllowedFiletype(file.name) && isWithinAllowedSize(file.size)
  }

  return {
    isAllowedFiletype, isWithinAllowedSize, isValidAttachment, getFileExtension
  }
}
