



















































































import Vue from 'vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import { ITermsTranslations } from '@src/types/terms.types'
import { logout } from '@src/utilities/auth'
import {
  TOGGLE_TERMS_CHECKBOX, CLICK_APPROVE_TERMS, CLICK_LOGOUT_TERMS, VIEW_TERMS
} from '@src/plugins/analytics/events/ui/SMActivationFlow.events'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import SMDialog from '@src/ui/SMDialog/index.vue'

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('activationFlow/terms')

export default Vue.extend({
  name: 'SMActivationFlowTermsDialog',
  components: { SMDialog },
  data() {
    return {
      checkboxChecked: false
    }
  },
  computed: {
    ...mapState([ 'isDialogVisible', 'isDialogReadonly' ]) as {
      isDialogVisible: () => boolean;
      isDialogReadonly: () => boolean;
    },
    ...mapGetters('activationFlow/terms', [ 'termsInUserLanguage' ]) as {
      termsInUserLanguage: () => ITermsTranslations | undefined;
    },
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings | undefined;
    },
    title(): string | undefined {
      return this.termsInUserLanguage?.title
    },
    text(): string {
      return sanitizeHtml(this.termsInUserLanguage?.text || '')
    },
    isCheckboxVisible(): boolean {
      return this.realmSettings?.terms_of_use.checkbox_enabled ?? false
    },
    acceptButtonDisabled(): boolean {
      return this.isCheckboxVisible && !this.checkboxChecked
    }
  },
  watch: {
    isDialogVisible(visible: boolean): void {
      if (visible) {
        this.trackView()
      }
    }
  },
  methods: {
    ...mapActions([ 'acceptTerms' ]) as {
      acceptTerms: () => Promise<void>;
    },
    ...mapMutations([ 'hideTermsDialog' ]) as {
      hideTermsDialog: () => void;
    },
    async logout(): Promise<void> {
      // Must await the Mixpanel call to complete before we can logout (which redirects the browser)
      await this.$trackEvent(CLICK_LOGOUT_TERMS, this.$options.name)
      await logout()
    },
    trackCheckboxChange(): void {
      this.$trackEvent(TOGGLE_TERMS_CHECKBOX, this.$options.name)
    },
    trackApprove(): void {
      this.$trackEvent(CLICK_APPROVE_TERMS, this.$options.name)
    },
    trackView(): void {
      this.$trackEvent(VIEW_TERMS, this.$options.name)
    },
    onCloseAttempt(): void {
      // Only possible when readonly
      this.hideTermsDialog()
    }
  }
})
