




















import Vue from 'vue'
import { Editor } from '@tiptap/vue-2'

export default Vue.extend({
  props: {
    editor: {
      type: Object as () => Editor,
      required: true
    }
  },
  data() {
    return {
      icons: [ '😄', '😉', '🤔', '😐', '😮', '🙁', '👍', '👎', '👏', '💪', '🎉', '🚀' ]
    }
  },
  methods: {
    onIconClick(icon: string): void {
      this.editor.chain().focus().insertContent(icon).run()
    }
  }
})
