

































































import { Editor } from '@tiptap/vue-2'
import { isUrl } from '@src/utilities/validations'
import { CLICK_ADD_HYPERLINK } from '@src/plugins/analytics/events/ui/SMTipTapToolbar.events'
import { defineComponent } from '@vue/composition-api'
import SMDialog from '@src/ui/SMDialog/index.vue'
import SMTipTapToolbarButton from './SMTipTapToolbarButton.vue'

export default defineComponent({
  name: 'SMTipTapToolbarButtonLink',
  components: {
    SMTipTapToolbarButton,
    SMDialog
  },
  props: {
    editor: {
      type: Object as () => Editor,
      required: true
    }
  },
  data() {
    return {
      link: '',
      label: '',
      isOpen: false,
      CLICK_ADD_HYPERLINK
    }
  },
  computed: {
    rules(): ((value: string) => string | true)[] {
      return [
        (value: string): true | string => (!value || (value && isUrl(value))) || this.$t('tiptap.toolbar.link.invalid-url')?.toString()
      ]
    },
    isValidUrl(): boolean {
      return !this.link || (!!this.link && isUrl(this.link))
    }
  },
  watch: {
    isOpen(): void {
      this.editor.chain().focus().extendMarkRange('link').run()
      const previousUrl = this.editor.getAttributes('link').href
      if (previousUrl) {
        this.link = previousUrl
      } else {
        (this.$refs.form as HTMLFormElement)?.reset()
      }
      this.label = this.editor.state.doc.textBetween(this.editor.view.state.selection.from, this.editor.view.state.selection.to, '')
    }
  },
  methods: {
    insert(): void {
      // empty
      if (!this.link) {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()
        this.isOpen = false
        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: this.link, target: '_blank' })
        .command(({ tr }) => {
          if (this.label) {
            tr.insertText(this.label)
          } else {
            tr.insertText(this.link)
          }
          return true
        })
        .run()
      this.isOpen = false
    }
  }
})
