import { computed } from '@vue/composition-api'
import _once from 'lodash/once'
import generateStore from '@src/store'
import { ICategory } from '@src/types/category.types'

const store = generateStore()
const loadCategoriesOnce = _once(() => store.dispatch('categories/loadCategories'))

export default function useCategories() {
  loadCategoriesOnce()

  const categories = computed<ICategory[]>(() => store.getters['categories/getCategories'])
  const categoriesTranslated = computed<ICategory[]>(() => store.getters['categories/getCategoriesTranslated'])

  return {
    categories,
    categoriesTranslated
  }
}
