import { IVSMMetric, VSMMetricId } from '@src/types/metric.types'

const metric: IVSMMetric = {
  id: VSMMetricId.ACTIVATED_USERS,
  name: 'admin.vsm.metrics.activated-users.name',
  description: 'admin.vsm.metrics.activated-users.description',
  targetValue: 100,
  currentValue: 0,
  percentageValue: 0,
  unit: 'percentage',
  color: 'sm-bronze',
  loading: true,
  status: 'default',
  actions: [
    {
      id: 'resend_invitation',
      label: 'admin.vsm.metrics.actions.resend-invitation',
      primary: true
    },
    {
      id: 'see_inactive_users',
      label: 'admin.vsm.metrics.actions.see-inactive-users',
      icon: 'ico-sm-inactiv-users',
      link: {
        url: 'admin/users/?filter=inactive'
      }
    },
    {
      id: 'learn_more',
      label: 'admin.vsm.metrics.actions.learn-more',
      icon: 'ico-sm-help-outline',
      link: {
        url: 'https://help.starmind.com/docs/user-activation-1',
        target: 'blank'
      }
    }
  ]
}

export default metric
