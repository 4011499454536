import { AxiosPromise } from 'axios'
import {
  IUserActivityOverview, IKpiRequest, IKpiResponse, EngagementKpis
} from '@src/types/statistics.types'
import _mean from 'lodash/mean'
import _zipObject from 'lodash/zipObject'
import _sum from 'lodash/sum'
import { IUser } from '@src/types/user.types'
import { IExpert } from '@src/types/experts.types'
import { IQuestion } from '@src/types/question.types'
import createAxiosInstance, { createOptimizedAPI } from '.'

export interface IExpertWithScore extends IExpert {
  score: number;
}

export interface IStatisticsApi {
  getUserActivity(userId: number): AxiosPromise<IUserActivityOverview>;
  getAvgDailyActiveUsersLast30Days(): Promise<number>;
  getTopContributors(): AxiosPromise<{ items: IUser[]; total: number }>;
  getTopActivityQuestions(): AxiosPromise<{items: IQuestion[]; total: number }>;
  getTopUpvotedQuestions(): AxiosPromise<{items: IQuestion[]; total: number }>;
  getEngagementKpisLast30Days(): Promise<EngagementKpis>;
  getViralityEventsKpisLast30Days(): Promise<EngagementKpis>;
  getTagExperts(tags: string[], limit: number): AxiosPromise<IExpertWithScore[]>;
}

const api = createAxiosInstance('statistics')

function getKpi(request: IKpiRequest): AxiosPromise<IKpiResponse> {
  return api.post('network/kpi', request)
}

const statisticsApi: IStatisticsApi = {
  getUserActivity(userId) {
    return api.get('network/content', {
      params: {
        user_id: userId
      }
    })
  },
  async getAvgDailyActiveUsersLast30Days() {
    const now = (new Date()).toISOString()
    const before30days = (new Date(Date.now() - (1000 * 60 * 60 * 24 * 30))).toISOString()
    const { data: { data } } = await getKpi({
      start: before30days,
      end: now,
      periodicity: 'daily',
      kpis: [ 'unique_active_users_period' ]
    })
    return _mean(data[0])
  },
  getTopContributors() {
    return api.get('network/contributors', {
      params: {
        limit: 3
      }
    })
  },
  getTopActivityQuestions() {
    return api.get('network/top-activity-questions', {
      params: {
        limit: 5
      }
    })
  },
  getTopUpvotedQuestions() {
    return api.get('network/top-upvoted-questions', {
      params: {
        limit: 5
      }
    })
  },
  async getEngagementKpisLast30Days() {
    const now = (new Date()).toISOString()
    const before30days = (new Date(Date.now() - (1000 * 60 * 60 * 24 * 30))).toISOString()
    const { data: { data, kpis } } = await getKpi({
      start: before30days,
      end: now,
      periodicity: 'monthly',
      kpis: [ 'questions_period', 'solutions_period', 'comments_period' ]
    })
    return _zipObject(kpis, data.map(_sum))
  },
  async getViralityEventsKpisLast30Days() {
    const now = (new Date()).toISOString()
    const before30days = (new Date(Date.now() - (1000 * 60 * 60 * 24 * 30))).toISOString()
    const { data: { data, kpis } } = await getKpi({
      start: before30days,
      end: now,
      periodicity: 'monthly',
      kpis: [ 'recommendations_period', 'solutions_period', 'user_mentions_period', 'user_invitations_period', 'user_follows_period' ]
    })
    return _zipObject(kpis, data.map(_sum))
  },
  getTagExperts(tags: string[], limit = 10): AxiosPromise<IExpertWithScore[]> {
    return api.get('network/experts', {
      params: {
        tags: tags?.join(','),
        limit
      }
    })
  }
}

export default {
  ...statisticsApi,
  ...createOptimizedAPI(statisticsApi)
}
