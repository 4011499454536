import { IVSMMetric, VSMMetricId } from '@src/types/metric.types'

const metric: IVSMMetric = {
  id: VSMMetricId.AVERAGE_ANSWER_TIME,
  name: 'admin.vsm.metrics.average-answer-time.name',
  description: '',
  targetValue: 2,
  currentValue: 0,
  percentageValue: 0,
  unit: 'hours',
  color: 'cyan',
  loading: true,
  status: 'default',
  tooltip: 'admin.vsm.metrics.average-answer-time.tooltip',
  actions: [
    {
      id: 'question_escalate',
      label: 'admin.vsm.metrics.actions.question-escalation',
      icon: 'ico-sm-calendar',
      primary: true,
      link: {
        url: 'admin/settings/question-escalation'
      }
    },
    {
      id: 'add_categories',
      label: 'admin.vsm.metrics.actions.add-categories',
      icon: 'ico-sm-category',
      link: {
        url: '/admin/content/categories/new'
      }
    },
    {
      id: 'manage_notifications',
      label: 'admin.vsm.metrics.actions.manage-notifications',
      icon: 'ico-sm-settings-line',
      link: {
        url: '/admin/settings/mail'
      }
    },
    {
      id: 'learn_more',
      label: 'admin.vsm.metrics.actions.learn-more',
      icon: 'ico-sm-help-outline',
      link: {
        url: 'https://help.starmind.com/docs/question-escalation',
        target: 'blank'
      }
    }
  ]
}

export default metric
