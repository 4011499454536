

































































import { IJobSkill, JobToSkillWizardSteps } from '@src/types/jobSkill'
import {
  PropType, computed, defineComponent, ref, onMounted, toRefs
} from '@vue/composition-api'
import { IInitialExpertise } from '@src/types/initialExpertise.types'
import useUser from '@src/composables/user'
import useTracking from '@src/composables/tracking/tracking'
import useUserExpertiseSelection from '@src/views/JobToSkillWizard/composables/useUserExpertiseSelection'
import JobTitleInput from './components/JobTitleInput.vue'
import TopicsInput from './components/TopicsInput.vue'

interface IJobToSkillWizardStep {
  title: string,
  body: string
}
const stepValuesMap: Record<JobToSkillWizardSteps, IJobToSkillWizardStep> = {
  [JobToSkillWizardSteps.Title]: {
    title: 'activation-flow.job-wizard.first-step.title',
    body: 'activation-flow.job-wizard.first-step.body'
  },
  [JobToSkillWizardSteps.Tags]: {
    title: 'activation-flow.job-wizard.second-step.title',
    body: 'activation-flow.job-wizard.second-step.body'
  }
}

export default defineComponent({
  name: 'JobToSkillWizard',
  components: {
    JobTitleInput,
    TopicsInput
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    skills: {
      type: Array as PropType<IJobSkill[]>,
      default: () => []
    },
    currentStep: {
      type: Number as PropType<JobToSkillWizardSteps>,
      required: true
    },
    jobTitle: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const { user } = useUser()
    const { currentStep } = toRefs(props)
    const { trackMixpanelEvent } = useTracking()
    const userId = computed(() => user.value.global_user_id)
    const selectedExpertise = ref<IInitialExpertise[]>([])
    const stepType = computed<IJobToSkillWizardStep>(() => stepValuesMap[currentStep.value])
    const disabled = computed<boolean>(() => {
      if (currentStep.value === JobToSkillWizardSteps.Title) {
        return props.jobTitle.length === 0
      }
      return selectedExpertise.value.length < 5
    })

    onMounted(() => {
      if (props.jobTitle) {
        emit('getSkills', props.jobTitle)
      }
    })

    function trackJobTitleInput(isSuggestion: boolean): void {
      trackMixpanelEvent(
        'inputJobTitleJobWizard',
        {
          component: 'job_wizard',
          element: 'input_field',
          type: 'click',
          isSuggestion,
          jobTitle: props.jobTitle
        }
      )
    }

    function trackExpertiseSelection(expertise: string, isSuggestion: boolean): void {
      trackMixpanelEvent(
        'selectExpertiseJobWizard',
        {
          component: 'job_wizard',
          element: 'button',
          type: 'click',
          expertise,
          isSuggestion
        }
      )
    }

    const { setExpertise } = useUserExpertiseSelection(selectedExpertise, currentStep)

    return {
      userId,
      JobToSkillWizardSteps,
      selectedExpertise,
      stepType,
      disabled,
      setExpertise,
      trackJobTitleInput,
      trackExpertiseSelection
    }
  }
})
