import { IRootState } from '@src/types/store.types'
import {
  MutationTree, ActionTree, Module
} from 'vuex'
import { ActivationFlowStepStatus, ActivationFlowStep } from '@src/types/activationFlow.types'
import { onboardingDialogApi } from '@src/api/apiModuleInstances'

export interface IActivationFlowEducationDialogState {
  isExpertsDialogVisible: boolean;
  isUserInteractionDialogVisible: boolean;
}

const state: IActivationFlowEducationDialogState = {
  isExpertsDialogVisible: false,
  isUserInteractionDialogVisible: false
}

const actions: ActionTree<IActivationFlowEducationDialogState, IRootState> = {
  possiblyShowExpertsDialog({ commit, dispatch, rootGetters }) {
    if (rootGetters['activationFlow/shouldShowOnboardingDialogs']) {
      commit('showExpertsDialog')
    } else {
      dispatch('next', { status: ActivationFlowStepStatus.ALREADY_DONE, step: ActivationFlowStep.EDUCATION_DIALOG_EXPERTS })
    }
  },
  possiblyShowUserInteractionDialog({ commit, dispatch, rootGetters }) {
    if (rootGetters['activationFlow/shouldShowOnboardingDialogs']) {
      commit('showUserInteractionDialog')
    } else {
      dispatch('next', { status: ActivationFlowStepStatus.ALREADY_DONE, step: ActivationFlowStep.EDUCATION_DIALOG_USER_INTERACTION })
    }
  },
  async completeDialog({ commit, dispatch }, step: ActivationFlowStep) {
    if (step === ActivationFlowStep.EDUCATION_DIALOG_EXPERTS) {
      commit('hideExpertsDialog')
    } else if (step === ActivationFlowStep.EDUCATION_DIALOG_USER_INTERACTION) {
      commit('hideUserInteractionDialog')
      await onboardingDialogApi.completeOnboardingDialog()
    }
    dispatch('next', { status: ActivationFlowStepStatus.STEPPED_THROUGH_COMPLETE, step })
  },
  next({ dispatch }, { status, step }: { status: ActivationFlowStepStatus, step: ActivationFlowStep }) {
    dispatch('activationFlow/next', { status, step }, { root: true })
  }
}

const mutations: MutationTree<IActivationFlowEducationDialogState> = {
  showExpertsDialog(state) {
    state.isExpertsDialogVisible = true
  },
  hideExpertsDialog(state) {
    state.isExpertsDialogVisible = false
  },
  showUserInteractionDialog(state) {
    state.isUserInteractionDialogVisible = true
  },
  hideUserInteractionDialog(state) {
    state.isUserInteractionDialogVisible = false
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
} as Module<IActivationFlowEducationDialogState, IRootState>
