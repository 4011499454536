import { IVSMMetric, VSMMetricId } from '@src/types/metric.types'

const metric: IVSMMetric = {
  id: VSMMetricId.AVERAGE_SOLUTION_RATING,
  name: 'admin.vsm.metrics.average-solution-rating.name',
  description: '',
  targetValue: 4,
  currentValue: 0,
  percentageValue: 0,
  unit: 'stars',
  color: 'amber',
  loading: true,
  status: 'default',
  actions: [
    {
      id: 'add_categories',
      label: 'admin.vsm.metrics.actions.add-categories',
      icon: 'ico-sm-category',
      primary: true,
      link: {
        url: '/admin/content/categories/new'
      }
    },
    {
      id: 'learn_more',
      label: 'admin.vsm.metrics.actions.learn-more',
      icon: 'ico-sm-help-outline',
      link: {
        url: 'https://help.starmind.com/docs/content-quality-1',
        target: 'blank'
      }
    }
  ]
}

export default metric
