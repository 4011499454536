import useStore from '@src/store'
import { ISnackbar } from '@src/types/snackbar.types'

export default function useSnackbar() {
  const store = useStore()

  function showSnackbar(snackbar: string | Partial<ISnackbar>) {
    return store.dispatch('snackbar/show', snackbar)
  }

  return {
    showSnackbar
  }
}
