





































import { defineComponent } from '@vue/composition-api'
import SMSnackbarRenderer from '@src/ui/SMSnackbarRenderer/index.vue'
import SMSidenavMobile from '@src/ui/SMSidenav/SMSidenavMobile/index.vue'
import SMBottomNavigation from '@src/ui/SMBottomNavigation/index.vue'
import SMEditQuestionButton from '@src/ui/SMAsk/components/editQuestion/SMEditQuestionButton.vue'
import SMAskDialogAnchor from '@src/ui/SMAsk/components/askQuestion/SMAskDialogAnchor.vue'
import SMFeedbackDialog from '@src/ui/SMFeedbackDialog/index.vue'
import SMWhatsNewDialog from '@src/ui/SMWhatsNew/components/SMWhatsNewDialog/index.vue'
import SMCompanyGuidelines from '@src/ui/SMCompanyGuidelines/index.vue'
import SMUserGuideSidenav from '@src/ui/SMUserGuideSidenav/index.vue'
import SMNotifications from '@src/ui/SMNotifications/index.vue'
import SMTrialBanner from '@src/ui/SMTrialBanner/index.vue'
import SMOnboardingTooltipOverlay from '@src/ui/SMOnboardingTooltip/components/SMOnboardingTooltipOverlay.vue'
import SMActivationFlow from '@src/ui/SMActivationFlow/index.vue'
import SMDeepLinkAnnouncementRenderer from '@src/ui/SMAnnouncements/SMDeepLinkAnnouncementRenderer.vue'
import SMOnboardingChecklist from '@src/ui/SMOnboardingChecklist/index.vue'
import SMSidenavDesktop from '@src/ui/SMSidenav/SMSidenavDesktop/index.vue'
import RootGridRow from '@src/ui/SMSidenav/RootGridRow.vue'
import SMInviteDialog from '@src/ui/SMInviteDialog/index.vue'
import Header from './Header.vue'

export default defineComponent({
  name: 'RootLayout',
  components: {
    RootGridRow,
    SMSnackbarRenderer,
    SMSidenavMobile,
    SMBottomNavigation,
    SMEditQuestionButton,
    SMAskDialogAnchor,
    SMInviteDialog,
    SMFeedbackDialog,
    SMWhatsNewDialog,
    SMCompanyGuidelines,
    SMUserGuideSidenav,
    SMNotifications,
    SMTrialBanner,
    SMOnboardingTooltipOverlay,
    SMActivationFlow,
    SMDeepLinkAnnouncementRenderer,
    SMOnboardingChecklist,
    Header,
    SMSidenavDesktop
  },
  props: {
    value: {
      /*
      The value prop provides a mechanism to add / remove all components to the DOM dynamically, EXCEPT for the default slot.
      This is required for the AngularJS iframe because the iframe itself must be present in the DOM all time, hence we may not control it via v-if.
      All other components need to be removed from the DOM when on a "normal", non-Angular route, in order to not be duplicated.
      */
      type: Boolean,
      default: true
    }
  }
})
