





























































































import { computed, defineComponent, ref } from '@vue/composition-api'
import SMAutoCompleteV2 from '@src/ui/SMAutoCompleteV2/index.vue'
import SMHeaderProfileMenu from '@src/ui/SMHeaderProfileMenu/index.vue'
import SMNotificationsButton from '@src/ui/SMNotificationsButton/index.vue'
import { createOnboardingTooltipDirective } from '@src/ui/SMOnboardingTooltip/directives/onboardingTooltip'
import { useOAuthClientId } from '@src/composables/useOAuthClientId'
import RootGridRow from '@src/ui/SMSidenav/RootGridRow.vue'
import SMWorkspaceSwitch from '@src/ui/SMWorkspaceSwitch/index.vue'
import SMSidenavMobileActivatorButton from '@src/ui/SMSidenav/SMSidenavMobile/SMSidenavMobileActivatorButton.vue'
import useTracking from '@src/composables/tracking/tracking'
import { useWindowScroll } from '@vueuse/core'

export default defineComponent({
  name: 'Header',
  components: {
    SMWorkspaceSwitch,
    RootGridRow,
    SMAutoCompleteV2,
    SMHeaderProfileMenu,
    SMNotificationsButton,
    SMSidenavMobileActivatorButton
  },
  directives: {
    onboardingTooltipDesktop: createOnboardingTooltipDirective(),
    onboardingTooltipMobile: createOnboardingTooltipDirective()
  },
  setup() {
    const { isMsTeamsPersonalApp } = useOAuthClientId()
    const { trackMixpanelEvent } = useTracking()
    const { y } = useWindowScroll()

    const hideAutocompleteMobile = ref(true)
    const autoFocusAutocomplete = ref(false)

    const isScrolling = computed(() => y.value > 0)
    const autocompleteCols = computed(() => (!isMsTeamsPersonalApp.value && !hideAutocompleteMobile.value ? 12 : !hideAutocompleteMobile.value && isMsTeamsPersonalApp.value ? 10 : 8))

    function toggleAutocomplete() {
      hideAutocompleteMobile.value = !hideAutocompleteMobile.value
      if (!hideAutocompleteMobile.value) {
        autoFocusAutocomplete.value = true
      }
    }
    function trackHomeClick() {
      trackMixpanelEvent('clickHome', {
        component: 'header',
        element: 'image',
        type: 'click'
      })
    }

    return {
      isMsTeamsPersonalApp,
      hideAutocompleteMobile,
      autocompleteCols,
      autoFocusAutocomplete,
      isScrolling,
      toggleAutocomplete,
      trackHomeClick
    }
  }
})
