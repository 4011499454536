import { CookieName, getCookie } from '@src/helpers/cookie'
import { IStarmindJwtPayload, OAuthClientId } from '@src/types/auth.types'
import { computed } from '@vue/composition-api'
import { jwtDecode } from 'jwt-decode'

export function useOAuthClientId() {
  const apiToken = getCookie(CookieName.API_TOKEN)

  const oAuthClientId = computed(() => {
    try {
      return jwtDecode<IStarmindJwtPayload>(apiToken!).azp
    } catch (error) {
      return undefined
    }
  })

  const isMsTeamsPersonalApp = computed(() => oAuthClientId.value === OAuthClientId.MSTEAMS_PERSONAL_APP)

  return { oAuthClientId, isMsTeamsPersonalApp }
}
