import { IVSMMetric, VSMMetricId } from '@src/types/metric.types'

const metric: IVSMMetric = {
  id: VSMMetricId.ANSWERED_QUESTIONS,
  name: 'admin.vsm.metrics.answered-questions.name',
  description: '',
  targetValue: 80,
  currentValue: 0,
  percentageValue: 0,
  unit: 'percentage',
  color: 'teal',
  loading: true,
  status: 'default',
  actions: [
    {
      id: 'see_unsolved_questions',
      label: 'admin.vsm.metrics.actions.see-unsolved-questions',
      icon: 'ico-sm-paragraph',
      primary: true,
      link: {
        url: '/stream?filter=unsolved'
      }
    },
    {
      id: 'assign_categories',
      label: 'admin.vsm.metrics.actions.assign-categories',
      icon: 'ico-sm-category',
      link: {
        url: '/admin/content/questions'
      }
    },
    {
      id: 'learn_more',
      label: 'admin.vsm.metrics.actions.learn-more',
      icon: 'ico-sm-help-outline',
      link: {
        url: 'https://help.starmind.com/docs/community-management-best-practices-2',
        target: 'blank'
      }
    }
  ]
}

export default metric
