import { Ref, watch } from '@vue/composition-api'
import announcementApi from '@src/api/web/announcements'
import useAnalytics from '@src/composables/tracking/tracking'
import { IAnnouncementComplete } from '@src/types/announcements.types'

export function useAnnouncementViewTracking(announcement: Ref<IAnnouncementComplete>, isOpen: Ref<boolean>) {
  const { trackMixpanelEvent } = useAnalytics()

  watch(isOpen, (value) => {
    if (value) {
      announcementApi.view(announcement.value.id)
      trackMixpanelEvent('viewAnnouncement', {
        element: 'announcement',
        announcement_id: announcement.value.id,
        clappingIsEnabled: announcement.value.reactions_enabled,
        commentIsEnabled: announcement.value.comments_enabled
      })
    }
  }, { immediate: true })
}
