import {
  computed, toRefs
} from '@vue/composition-api'
import useStore from '@src/store'
import useSnackbar from '@src/composables/snackbar'
import { useI18n } from '@src/plugins/i18n'
import useRealmSettings from '@src/composables/realmSettings'
import { UserChip } from '@src/ui/SMSocialDialog/types'
import { inviteUserCreationGraphQlApi } from '@src/api/apiModuleInstances'
import useSocialDialogTracking from '../SMSocialDialog/composables/useSocialDialogTracking'

export function useInviteDialog() {
  const store = useStore()
  const tracking = useSocialDialogTracking()
  const { showSnackbar } = useSnackbar()
  const { t } = useI18n()
  const { realmSettings } = useRealmSettings()

  const { isInviteDialogOpen } = toRefs(store.state.socialDialog)
  const toggleInviteDialog = (isOpen: boolean) => store.commit('socialDialog/toggleInviteDialog', isOpen)
  const allowedDomains = computed(() => realmSettings.value?.user_admin.invitation_email_allowed_domains ?? [])

  const inviteDialogOpenModel = computed({
    set: toggleInviteDialog,
    get: () => isInviteDialogOpen.value
  })

  async function invite(selectedUsers: UserChip[], message: string) {
    const recipients = (selectedUsers as string[]) ?? []
    if (recipients.length) {
      try {
        await inviteUserCreationGraphQlApi.inviteUserWithEmail(recipients, message ?? '')
        await tracking.submitInvite({ total_external_recipients: recipients.length })
        await showSnackbar({
          translationKey: `invite.snackbar.user.confirmation.${recipients.length > 1 ? 'multiple' : 'single'}`,
          translationParams: {
            email: recipients[0],
            count: recipients.length - 1
          }
        })
        inviteDialogOpenModel.value = false
      } catch {
        await showSnackbar(`${t('social.hint.suggestion.domain-restriction')} ${allowedDomains.value.join(', ')}`)
      }
    }
  }

  function cancel() {
    inviteDialogOpenModel.value = false
  }

  return {
    inviteDialogOpenModel,
    cancel,
    invite
  }
}
