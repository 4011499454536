



































































import {
  defineComponent,
  onMounted,
  toRefs,
  watch
} from '@vue/composition-api'

import useAttachments from '@src/composables/attachments'
import { IQuestionPayload } from '@src/types/question.types'
import useQuestionDrafts from '@src/ui/SMAsk/composables/questionDrafts'
import SMQuestionQualityMeter from '@src/ui/SMAsk/components/common/SMQuestionQualityMeter.vue'
import SMQuestionDialogActions from '@src/ui/SMAsk/components/common/SMQuestionDialogActions.vue'
import SMQuestionDialog from '@src/ui/SMAsk/components/common/SMQuestionDialog.vue'
import useSimilarQuestions from '../../composables/similarQuestions'
import useConceptualExpertSearch from '../../composables/conceptualExpertSearch'
import useQuestionContent from '../../composables/questionContent'
import useAutosave from '../../composables/autosave'
import useValidation from '../../composables/validation'
import useQuestionQuality from '../../composables/questionQuality'
import useVerySimilarQuestionDoublecheck from '../../composables/verySimilarQuestionDoubleCheck'
import useSave from '../../composables/save'
import useAskTracking from '../../composables/tracking'
import { useConceptsForQuestionEntity } from '../../composables/conceptsForQuestionEntity'

import SMQuestionForm from '../common/SMQuestionForm.vue'
import SMVerySimilarQuestionDoubleCheck from '../common/SMVerySimilarQuestionDoubleCheck.vue'

export default defineComponent({
  name: 'SMAskDialog',
  components: {
    SMQuestionDialog,
    SMQuestionDialogActions,
    SMQuestionQualityMeter,
    SMQuestionForm,
    SMVerySimilarQuestionDoubleCheck
  },
  props: {
    draftQuestionId: {
      type: Number,
      default: undefined
    },
    isFullscreen: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const { draftQuestionId } = toRefs(props)

    const {
      questionId,
      questionTitle,
      questionDescription,
      questionCategory,
      questionKnowledgeSpace,
      questionAttachments,
      initialKnowledgeSpace,
      generalFeedSelected,
      questionLanguage
    } = useQuestionContent(context, draftQuestionId)
    const {
      similarQuestions,
      hasSimilarQuestions,
      identicalQuestion,
      verySimilarQuestion,
      hasVerySimilarQuestion,
      totalSimilarQuestions
    } = useSimilarQuestions(questionTitle)
    useAutosave({
      title: questionTitle,
      description: questionDescription,
      category: questionCategory,
      knowledgeSpace: questionKnowledgeSpace,
      questionId
    })
    const { concepts } = useConceptsForQuestionEntity({
      questionId,
      questionTitle,
      questionDescription,
      autoSuggestionsBasedOnTitleAndDescription: true
    })
    const { hasDrafts } = useQuestionDrafts()

    const {
      titleError,
      descriptionError,
      conceptsError,
      categoryError,
      knowledgeSpaceMandatoryError,
      validate
    } = useValidation({
      title: questionTitle,
      description: questionDescription,
      concepts,
      category: questionCategory,
      knowledgeSpace: questionKnowledgeSpace,
      generalFeedSelected
    })
    const { questionQuality } = useQuestionQuality({
      title: questionTitle,
      description: questionDescription,
      concepts,
      category: questionCategory,
      hasSimilarQuestions
    })
    const {
      isShowingVerySimilarQuestionDoubleCheck,
      shouldShowVerySimilarQuestionDoublecheck
    } = useVerySimilarQuestionDoublecheck(hasVerySimilarQuestion)
    const {
      attachments,
      addFile,
      removeAttachment,
      attachmentError
    } = useAttachments(questionAttachments, questionId, 'questions')
    const { isLoading, publishNewQuestion } = useSave(context)

    const { conceptualExperts } = useConceptualExpertSearch(questionId, concepts)

    const {
      trackErrorView,
      trackViewAsk,
      trackViewIdenticalQuestionWarning,
      trackSubmit
    } = useAskTracking({
      title: questionTitle,
      description: questionDescription,
      questionId,
      isNewQuestion: true,
      similarQuestions,
      totalSimilarQuestions,
      isShowingVerySimilarQuestionDoubleCheck,
      concepts,
      questionQuality,
      hasVerySimilarQuestion,
      conceptualExperts,
      initialKnowledgeSpace
    })

    const submit = () => {
      const isValid = validate()
      if (isValid) {
        if (!shouldShowVerySimilarQuestionDoublecheck()) {
          const questionPayload: IQuestionPayload = {
            title: questionTitle.value,
            description: questionDescription.value,
            category: questionCategory.value,
            knowledge_space: questionKnowledgeSpace.value
          }
          publishNewQuestion(questionId.value, questionPayload)
          trackSubmit(questionPayload)
        }
      } else {
        if (categoryError.value) {
          trackErrorView({
            component: 'new_question_footer',
            element: 'dropdown_menu',
            form_field: 'category',
            error: 'required'
          })
        }
        if (conceptsError.value) {
          trackErrorView({
            component: 'new_question_footer',
            element: 'icon',
            form_field: 'concept',
            error: 'min_length'
          })
        }
        if (descriptionError.value) {
          trackErrorView({
            component: 'new_question_body',
            element: 'input_field',
            form_field: 'description',
            error: 'required'
          })
        }
        if (titleError.value) {
          trackErrorView({
            component: 'new_question_header',
            element: 'input_field',
            form_field: 'title',
            error: titleError.value.errorType
          })
        }
        if (knowledgeSpaceMandatoryError.value) {
          trackErrorView({
            component: 'new_question_header',
            element: 'input_field',
            form_field: 'knowledge_space_id',
            error: 'required'
          })
        }
      }
    }

    onMounted(trackViewAsk)
    watch(identicalQuestion, (identical) => {
      if (identical) {
        trackViewIdenticalQuestionWarning()
      }
    })

    return {
      addFile,
      attachments,
      attachmentError,
      categoryError,
      descriptionError,
      hasSimilarQuestions,
      identicalQuestion,
      isLoading,
      knowledgeSpaceMandatoryError,
      questionCategory,
      questionDescription,
      questionId,
      questionKnowledgeSpace,
      questionQuality,
      questionTitle,
      questionLanguage,
      generalFeedSelected,
      isShowingVerySimilarQuestionDoubleCheck,
      removeAttachment,
      similarQuestions,
      submit,
      concepts,
      conceptsError,
      conceptualExperts,
      titleError,
      verySimilarQuestion,
      hasDrafts
    }
  }
})
