export enum PostMessageType {
  ROUTER_NAVIGATION = 'ROUTER_NAVIGATION',
  TRANSLATION_LABELS_REQUEST = 'TRANSLATION_LABELS_REQUEST',
  AUTH_REDIRECT = 'AUTH_REDIRECT'
}
export interface IMessageWithType {
  type: PostMessageType;
}

export interface IRouterNavigationMessage extends IMessageWithType {
  newLocation?: string;
  notFoundInAngular?: string;
}

export interface ITranslationLabelsRequestMessage extends IMessageWithType {
  newLanguage: string;
}

export interface IAuthRedirectMessage extends IMessageWithType {
  url: string;
}
