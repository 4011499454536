




































import { PropType, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SMConceptDescriptionMenu',
  props: {
    label: {
      type: String,
      required: true
    },
    primaryLabel: {
      type: String as PropType<string | null | undefined>,
      default: undefined
    },
    description: {
      type: String as PropType<string | null | undefined>,
      default: undefined
    },
    showBelow: {
      type: Boolean,
      default: false
    }
  }
})
