


























































































import { defineComponent, onMounted, ref } from '@vue/composition-api'
import SMDialog from '@src/ui/SMDialog/index.vue'
import FeatureFeedbackDialog from '@src/ui/SMFeatureFeedbackDialog/FeatureFeedbackDialog.vue'
import { discussionParticipationUsersApi } from '@src/api/apiModuleInstances'
import useSnackbar from '@src/composables/snackbar'
import useTracking from '@src/composables/tracking/tracking'
import SMGifViewOnce from '@src/ui/SMGifViewOnce/index.vue'

export default defineComponent({
  name: 'SMDiscussButton',
  components: {
    SMDialog,
    FeatureFeedbackDialog,
    SMGifViewOnce
  },
  setup() {
    const { showSnackbar } = useSnackbar()
    const { trackMixpanelEvent } = useTracking()

    const isDialogOpen = ref(false)
    const userHasSignedUp = ref(false)

    async function notifyUser() {
      await discussionParticipationUsersApi.addDiscussionParticipationUser()
      trackMixpanelEvent('clickNotify', {
        state: 'discussion',
        component: 'discussion_dialog',
        element: 'button',
        type: 'click'
      })
      userHasSignedUp.value = true
      showSnackbar('discussion.dialog.notify.snackbar.title')
    }

    function trackDiscussClick() {
      trackMixpanelEvent('clickDiscussion', {
        component: 'sidenav',
        element: 'button',
        type: 'click'
      })
    }

    onMounted(async () => {
      const { data } = await discussionParticipationUsersApi.getDiscussionParticipationUser()
      userHasSignedUp.value = data
    })

    return {
      isDialogOpen,
      userHasSignedUp,
      notifyUser,
      trackDiscussClick
    }
  }
})
