import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'

export interface IOnboardingDialogStatus {
  completed_date: string;
}

export function researchParticipationUsersApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/research-participation-users'
  const methods = {
    getResearchParticipationUser() {
      return api.get<boolean>(baseUrl)
    },
    addResearchParticipationUser() {
      return api.post<void>(baseUrl)
    },
    deleteResearchParticipationUser() {
      return api.delete<void>(baseUrl)
    }
  }
  return createOptimizedAPI(methods)
}
