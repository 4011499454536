import { FeatureToggleNames } from '@src/plugins/featureToggles/featureToggle.names'
import { FeatureToggleVariants } from '@src/plugins/featureToggles/featureToggle.variants'
import { IFeatureToggle } from '@src/types/featureToggles.types'
import find from 'lodash/fp/find'

function findByName(list: IFeatureToggle[], name: FeatureToggleNames) {
  const toggle = find({ name }, list)

  return {
    /**
     * Returns the feature toggle
     * @returns IFeatureToggle | undefined
     */
    get() {
      return toggle
    },
    /**
     * Returns the variant of an experiment. The generic type is FeatureToggleVariants by default, but it can hold arbitrary string values as defined in Unleash
     */
    getExperimentVariant<T = FeatureToggleVariants>() {
      return toggle?.variant.payload?.value as T | undefined
    },
    /**
     * Returns if a release toggle is enabled
     * @returns boolean
     */
    isEnabled() {
      return !!toggle?.enabled
    }
  }
}

export function featureToggleList(list: IFeatureToggle[]) {
  return {
    findByName(name: FeatureToggleNames) {
      return findByName(list, name)
    }
  }
}
