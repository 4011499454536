import {
  ActionTree,
  GetterTree,
  Module, MutationTree
} from 'vuex'
import awaitGetterValue from '@src/utilities/storeUtils'
import unleashApi from '@src/api/unleash'
import { IUser } from '@src/types/user.types'
import { IRootState } from '@src/types/store.types'
import { IFeatureToggle } from '@src/types/featureToggles.types'
import { FeatureToggleNames } from '@src/plugins/featureToggles/featureToggle.names'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import impressions from './modules/impressions'

export interface IFeatureTogglesState {
  loading: boolean;
  error: boolean;
  featureToggles: IFeatureToggle[];
}

export const state: IFeatureTogglesState = {
  loading: true,
  error: false,
  featureToggles: []
}

const getters: GetterTree<IFeatureTogglesState, IRootState> = {
  getLoading(state): boolean { return state.loading },
  getToggles(state): IFeatureToggle[] {
    return state.featureToggles
  },
  getExperiments(state): IFeatureToggle[] {
    return state.featureToggles.filter(({ variant: { enabled } }) => enabled)
  },
  getExperimentsSuperProps(state, localGetters): {[key: string]: string | boolean | null} {
    const localExperiments: IFeatureToggle[] = localGetters.getExperiments
    const localImpressions: FeatureToggleNames[] = localGetters['impressions/getImpressions']
    return localExperiments.reduce((acc: {[key: string]: string | boolean | null }, experiment: IFeatureToggle) => {
      /* eslint-disable no-param-reassign */
      acc[`${experiment.name}_variant`] = experiment.variant.payload?.value ?? null
      acc[`${experiment.name}_impression`] = localImpressions.includes(experiment.name)
      /* eslint-enable no-param-reassign */
      return acc
    }, {})
  }
}

const actions: ActionTree<IFeatureTogglesState, IRootState> = {
  async loadFeatureToggles({ commit }): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { event_tracking_id, language } = await awaitGetterValue<IUser>('user/getUser')
      const { metadata: { realm_id } } = await awaitGetterValue<IUnauthenticatedRealmSettings>('realmSettings/realmSettings')
      const { data: { toggles } } = await unleashApi.get(event_tracking_id, realm_id, language)
      commit('setFeatureToggles', toggles)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations: MutationTree<IFeatureTogglesState> = {
  setFeatureToggles(state, toggles: IFeatureToggle[]): void {
    state.featureToggles = toggles
  },
  setLoading(state, loading): void {
    state.loading = loading
  },
  setError(state, error): void {
    state.error = error
  }
}

export default {
  namespaced: true,
  modules: {
    impressions
  },
  state: () => ({ ...state }),
  getters,
  actions,
  mutations
} as Module<IFeatureTogglesState, IRootState>
