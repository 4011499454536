import { Ref } from '@vue/composition-api'

export enum OnboardingTooltipId {
  AskQuestionButton = 'AskQuestionButton',
  FeedButton = 'FeedButton',
  UserProfileButton = 'UserProfileButton',
  HeaderSearchBar = 'HeaderSearchBar',

  AskQuestionButtonMobile = 'AskQuestionButtonMobile',
  FeedButtonMobile = 'FeedButtonMobile',
  UserProfileButtonMobile = 'UserProfileButtonMobile',
  HeaderSearchBarMobile = 'HeaderSearchBarMobile',

  ProfileEditCard = 'ProfileEditCard',
  ConversationView = 'ConversationView',
  ConversationViewMobile = 'ConversationViewMobile'
}

export enum OnboardingTooltipFlow {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  UserEditDesktopMobile = 'UserEditDesktopMobile',
  ConversationDesktop = 'ConversationDesktop',
  ConversationMobile = 'ConversationMobile'
}

export interface IUpdateOverlayCutoutPayload {
  tooltipId: OnboardingTooltipId;
  dimensions: DOMRect;
}

export interface ITooltipContextRefs {
  isHighlighting: Ref<boolean>;
  currentTooltipIndex: Ref<number>;
  totalTooltipsOfCurrentFlow: Ref<number>;
  currentTooltipFlow: Ref<OnboardingTooltipFlow>;
}
