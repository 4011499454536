import { ITrackingProps } from '@src/types/events.types'

export const ADD_TERMS = 'clickAddTerms'
export const ADD_TEXT = 'clickAdminTermsAddText'
export const BREADCRUMB = 'clickAdminTermsBreadcrumb'
export const CANCEL = 'clickCancelAdminTerms'
export const DELETE_DRAFT = 'clickDeleteAdminTermsDraft'
export const DELETE_TRANSLATION = 'clickDeleteAdminTermsLanguage'
export const MORE_INFO_LINK = 'clickMoreInformation'
export const NEW_VERSION = 'clickAdminTermsNewVersion'
export const CONFIRM_PUBLISH = 'clickOkAdminTermsPublishing'
export const PUBLISH = 'clickPublishAdminTermsDraft'
export const SAVE = 'clickSaveAdminTerms'
export const EDIT = 'editAdminTermsVersion'
export const TYPE_EDIT_TITLE = 'editAdminTermsDraftTitle'
export const TOGGLE_ENABLED = 'toggleAdminTerms'
export const TOGGLE_CHECKBOX = 'toggleCheckboxAdminTerms'

const commonProps = {
  state: 'admin.settings.terms'
}

export default {
  AdminTermsOverview: {
    [ADD_TERMS]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_overview'
    }),
    [MORE_INFO_LINK]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_overview'
    }),
    [NEW_VERSION]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_overview'
    }),
    [EDIT]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'list_item',
      component: 'admin_terms_overview'
    }),
    [TOGGLE_ENABLED]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'toggle',
      component: 'admin_terms_overview'
    }),
    [TOGGLE_CHECKBOX]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'toggle',
      component: 'admin_terms_overview'
    })
  },
  AdminTermsEdit: {
    [ADD_TEXT]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'list_item',
      component: 'admin_terms_edit'
    }),
    [BREADCRUMB]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_edit'
    }),
    [CANCEL]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_edit'
    }),
    [DELETE_DRAFT]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_edit'
    }),
    [PUBLISH]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_edit'
    }),
    [SAVE]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_edit'
    }),
    [TYPE_EDIT_TITLE]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'type',
      element: 'input_field',
      component: 'admin_terms_edit'
    })
  },
  AdminTermsTranslation: {
    [BREADCRUMB]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_translation'
    }),
    [CANCEL]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_translation'
    }),
    [SAVE]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_translation'
    }),
    [DELETE_TRANSLATION]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_translation'
    })
  },
  AdminTermsPublishDialog: {
    [CANCEL]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_publish_dialog'
    }),
    [CONFIRM_PUBLISH]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      type: 'click',
      element: 'button',
      component: 'admin_terms_publish_dialog'
    })
  }
}
