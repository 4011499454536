import { IRootState } from '@src/types/store.types'
import {
  MutationTree, ActionTree, Module, GetterTree
} from 'vuex'
import { IUpdateOverlayCutoutPayload, OnboardingTooltipId, OnboardingTooltipFlow } from '@src/types/onboardingTooltips.types'
import { getDOMRect } from '@src/utilities/DOMRect'
import { getWindowObject } from '@src/utilities/iframeDetection'

const onboardingFlowExecutionOrder: Record<OnboardingTooltipFlow, OnboardingTooltipId[]> = {
  [OnboardingTooltipFlow.Desktop]: [
    OnboardingTooltipId.AskQuestionButton,
    OnboardingTooltipId.FeedButton,
    OnboardingTooltipId.HeaderSearchBar,
    OnboardingTooltipId.UserProfileButton
  ],
  [OnboardingTooltipFlow.Mobile]: [
    OnboardingTooltipId.AskQuestionButtonMobile,
    OnboardingTooltipId.FeedButtonMobile,
    OnboardingTooltipId.HeaderSearchBarMobile,
    OnboardingTooltipId.UserProfileButtonMobile
  ],
  [OnboardingTooltipFlow.UserEditDesktopMobile]: [
    OnboardingTooltipId.ProfileEditCard
  ],
  [OnboardingTooltipFlow.ConversationDesktop]: [
    OnboardingTooltipId.ConversationView
  ],
  [OnboardingTooltipFlow.ConversationMobile]: [
    OnboardingTooltipId.ConversationViewMobile
  ]
}

interface IActivationFlowOnboardingTooltipsState {
  isShowingTooltips: boolean;
  currentTooltipIndex: number;
  currentTooltipFlow: OnboardingTooltipFlow;
  overlayCutoutDimensions: Record<OnboardingTooltipId, DOMRect>;
}

const state: IActivationFlowOnboardingTooltipsState = {
  isShowingTooltips: false,
  currentTooltipIndex: 0,
  currentTooltipFlow: OnboardingTooltipFlow.Desktop,
  overlayCutoutDimensions: {
    AskQuestionButton: getDOMRect(),
    FeedButton: getDOMRect(),
    HeaderSearchBar: getDOMRect(),
    UserProfileButton: getDOMRect(),
    AskQuestionButtonMobile: getDOMRect(),
    FeedButtonMobile: getDOMRect(),
    HeaderSearchBarMobile: getDOMRect(),
    UserProfileButtonMobile: getDOMRect(),
    ProfileEditCard: getDOMRect(),
    ConversationView: getDOMRect(),
    ConversationViewMobile: getDOMRect()
  }
}

const actions: ActionTree<IActivationFlowOnboardingTooltipsState, IRootState> = {
  startOnboardingTooltipsManually({ commit }) {
    // TODO: redirect to homepage if not already there yet?
    commit('setTooltipFlow', OnboardingTooltipFlow.Desktop)
    commit('start')
  },
  dismissOnboardingTooltips({ commit }) {
    commit('done')
  },
  nextOnboardingTooltip({ commit, state }) {
    if (state.currentTooltipIndex >= onboardingFlowExecutionOrder[state.currentTooltipFlow].length - 1) {
      commit('done')
    }
    commit('next') // show next tooltip
  },
  async startEducationalTooltipFlow({
    commit, state
  }, flow: OnboardingTooltipFlow) {
    if (!state.isShowingTooltips && !getWindowObject().Cypress) {
      commit('setTooltipFlow', flow)
      commit('start')
    }
  },
  dismissEducationalTooltips({ commit }) {
    // If needed: call an API to store the status here
    commit('done')
  },
  nextEducationalTooltip({ commit, state }) {
    if (state.currentTooltipIndex >= onboardingFlowExecutionOrder[state.currentTooltipFlow].length - 1) {
      // If needed: call an API to store the status here
      commit('done')
    }
    commit('next') // show next tooltip
  },
  // Aborting means hide the tooltip when it goes out of the viewport without persisting the state
  abortEducationalTooltipFlow({ commit, state }, flow: OnboardingTooltipFlow) {
    if (state.currentTooltipFlow === flow) {
      commit('done')
    }
  }
}

const mutations: MutationTree<IActivationFlowOnboardingTooltipsState> = {
  start(state) {
    state.isShowingTooltips = true
    state.currentTooltipIndex = 0
  },
  next(state) {
    state.currentTooltipIndex = (state.currentTooltipIndex + 1) % onboardingFlowExecutionOrder[state.currentTooltipFlow].length
  },
  done(state) {
    state.isShowingTooltips = false
  },
  updateOverlayCutout(state, { dimensions, tooltipId }: IUpdateOverlayCutoutPayload) {
    state.overlayCutoutDimensions[tooltipId] = dimensions
  },
  updateBreakpoint(state, innerWidth: number) {
    // only switch flows on resize if it's the standard flow
    if ([ OnboardingTooltipFlow.Desktop, OnboardingTooltipFlow.Mobile ].includes(state.currentTooltipFlow)) {
      state.currentTooltipFlow = innerWidth < 960 ? OnboardingTooltipFlow.Mobile : OnboardingTooltipFlow.Desktop
    }
  },
  setTooltipFlow(state, flow: OnboardingTooltipFlow) {
    state.currentTooltipFlow = flow
  }
}

const getters: GetterTree<IActivationFlowOnboardingTooltipsState, IRootState> = {
  currentTooltipId(state) {
    return state.isShowingTooltips ? onboardingFlowExecutionOrder[state.currentTooltipFlow][state.currentTooltipIndex] : undefined
  },
  currentTooltipIndex(state) {
    return state.currentTooltipIndex
  },
  overlayCutoutDimensionsOfCurrentTooltip(state, localGetters) {
    if (localGetters.currentTooltipId) {
      return state.overlayCutoutDimensions[localGetters.currentTooltipId as OnboardingTooltipId]
    }
    return new DOMRect()
  },
  totalTooltipsOfCurrentFlow(state) {
    return onboardingFlowExecutionOrder[state.currentTooltipFlow].length
  },
  currentTooltipFlow(state) {
    return state.currentTooltipFlow
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
} as Module<IActivationFlowOnboardingTooltipsState, IRootState>
