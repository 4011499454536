









































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'SMOnboardingFigmaPrototype',
  props: {
    isPrototypeOpen: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    const desktopSrc = 'https://www.figma.com/proto/gyVh4dAlCdQELCJkiut8aR/Live-Prototypes?embed_host=share&kind=&node-id=1-7993&page-id=1%3A2&scaling=scale-down&starting-point-node-id=1%3A7993&t=HMwqdHxlkdYw5CUH-1&type=design&viewport=121%2C96%2C0.06'
    const mobileSrc = 'https://www.figma.com/proto/gyVh4dAlCdQELCJkiut8aR/Live-Prototypes?embed_host=share&kind=&node-id=3-43049&page-id=3%3A42727&scaling=scale-down&starting-point-node-id=3%3A43049&t=PSBjhAyGWoDJ8wOa-1&type=design&viewport=136%2C99%2C0.07'
    const loading = ref(true)

    return {
      loading,
      desktopSrc,
      mobileSrc
    }
  }
})
