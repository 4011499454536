import {
  GetterTree, Module, MutationTree, ActionTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { IApprovalGroupOfUser } from '@src/types/solutionApproval.types'

import userApi from '@src/api/user'

export interface IApprovalGroupsState {
  groups: IApprovalGroupOfUser[];
  loading: boolean;
  error: boolean;
}

const state: IApprovalGroupsState = {
  groups: [],
  loading: true,
  error: false
}

const getters: GetterTree<IApprovalGroupsState, IRootState> = {
  getGroups(state) {
    return state.groups
  },
  hasGroups(state) {
    return !!state.groups.length
  },
  getLoading(state) {
    return state.loading
  },
  getFetchError(state) {
    return state.error
  }
}

const actions: ActionTree<IApprovalGroupsState, IRootState> = {
  async loadGroups({ commit }): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { data } = await userApi.cached.getSolutionAuthorities()
      commit('setGroups', data)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations: MutationTree<IApprovalGroupsState> = {
  setLoading: (state, loading: boolean): void => {
    state.loading = loading
  },
  setError: (state, error: boolean): void => {
    state.error = error
  },
  setGroups: (state, groups: IApprovalGroupOfUser[]): void => {
    state.groups = groups
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
} as Module<IApprovalGroupsState, IRootState>
