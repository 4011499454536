










































import {
  computed, defineComponent, PropType, ref, toRefs
} from '@vue/composition-api'
import { IAnnouncementComment } from '@src/types/announcements.types'
import SMUserAvatar from '@src/ui/SMUserAvatar/index.vue'
import SMTimeAgo from '@src/ui/SMTimeAgo/index.vue'
import SMAnnouncementCommentMenu from '@src/ui/SMAnnouncements/SMAnnouncementCommentMenu.vue'
import SMEditAnnouncementComment from '@src/ui/SMAnnouncements/SMEditAnnouncementComment.vue'
import { useUserInfo } from '@src/composables/useUserInfo'

export default defineComponent({
  name: 'SMAnnouncementCommentItem',
  components: {
    SMEditAnnouncementComment, SMTimeAgo, SMUserAvatar, SMAnnouncementCommentMenu
  },
  props: {
    comment: {
      type: Object as PropType<IAnnouncementComment>,
      required: true
    },
    announcementId: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const { comment } = toRefs(props)
    const commentAuthor = computed(() => comment.value.author)
    const { isCurrentUser, fullName } = useUserInfo(commentAuthor)
    const editMode = ref(false)

    async function editAnnouncementComment(val: string) {
      // Not calling directly from useAnnouncementComments to avoid remounting and triggering
      // unnecessary API calls
      emit('updateComment', comment.value.id, val)
      editMode.value = false
    }

    return {
      isCurrentUser,
      editMode,
      fullName,
      editAnnouncementComment
    }
  }
})
