import {
  Ref,
  ref, watch
} from '@vue/composition-api'
import debounce from 'lodash/fp/debounce'
import {
  createConceptFromOntologyInULF, createConceptFromTextInULF, removeAmbiguousAndDuplicateResults
} from '@src/utilities/conceptSearch'
import { IConceptSearchResultItem } from '@src/types/concept.types'
import { parseConcept } from '@src/utilities/concepts'
import { conceptsApi } from '@src/api/apiModuleInstances'

const CONCEPT_SEARCH_DEBOUNCE_TIME = 400

export function useConceptSearch(query: Ref<string>, language: Ref<string>) {
  const searchResults = ref<IConceptSearchResultItem[]>([])

  async function onSelectSearchResult(item: IConceptSearchResultItem | string) {
    if (typeof item === 'string') {
      // item is of type string when there was no item selected from the suggestions but a custom text was entered and selected by hitting enter
      const [ concept ] = await createConceptFromTextInULF(item, language.value)
      return parseConcept(concept).withLanguage(language.value).getOne()
    } if (item.ontology_id) {
      // create concept from search result that is an ontology but doesn't have a concept id yet
      const [ concept ] = await createConceptFromOntologyInULF(item)
      return parseConcept(concept).withLanguage(language.value).getOne()
    }
    // if there is a concept id, we can transform it directly without creating a concept in ULF beforehand
    return parseConcept(item).withLanguage(language.value).getOne()
  }

  const debouncedConceptSearch = debounce(CONCEPT_SEARCH_DEBOUNCE_TIME)(async (queryValue) => {
    if (queryValue) {
      const { data } = await conceptsApi.searchTopicConcepts(queryValue, language.value)
      searchResults.value = removeAmbiguousAndDuplicateResults(data)
    } else {
      searchResults.value = []
    }
  })

  watch(query, debouncedConceptSearch)

  return {
    searchResults,
    onSelectSearchResult
  }
}
