import {
  watch, Ref, ref
} from '@vue/composition-api'
import { IUser } from '@src/types/user.types'
import _debounce from 'lodash/debounce'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import useRealmSettings from '@src/composables/realmSettings'
import { getSuggestedUsers } from '@src/utilities/suggestedUsersUtils'
import { UserSuggestionType } from '@src/ui/SMSocialDialog/types'
import userApi from '@src/api/user'

export default function useUserSuggestions(userSuggestionType: Ref<UserSuggestionType>, knowledgeSpace: Ref<IKnowledgeSpace | null | undefined>) {
  const suggestionsForUser = ref<IUser[]>([])
  const suggestions = ref<IUser[]>([])
  const searchInput = ref<string>('')
  const { realmSettings } = useRealmSettings()

  watch(knowledgeSpace, async () => {
    const privateKnowledgeSpaceId = knowledgeSpace.value?.is_closed ? knowledgeSpace.value.id : undefined

    switch (userSuggestionType.value) {
      case UserSuggestionType.Recommend: {
        const { data } = await userApi.cached.getRecentRecommendations(privateKnowledgeSpaceId)
        suggestionsForUser.value = data
        break
      }
      case UserSuggestionType.Share: {
        const { data } = await userApi.cached.getRecentShares(privateKnowledgeSpaceId)
        suggestionsForUser.value = data
        break
      }
      case UserSuggestionType.NoSuggestions:
      default: {
        suggestionsForUser.value = []
        break
      }
    }
    suggestions.value = suggestionsForUser.value
  }, { immediate: true })

  const handleInputChange = _debounce(async (value: string) => {
    searchInput.value = value
    if (value && userSuggestionType.value !== UserSuggestionType.NoSuggestions) {
      suggestions.value = await getSuggestedUsers({
        query: value,
        params: { limit: 10 },
        allowInactiveInteraction: realmSettings.value?.user_admin.allow_inactive_user_interaction || false,
        knowledgeSpace: knowledgeSpace.value
      })
    } else {
      suggestions.value = suggestionsForUser.value
    }
  }, 300)

  return {
    suggestions,
    handleInputChange,
    searchInput
  }
}
