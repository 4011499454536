



























import { defineComponent, PropType } from '@vue/composition-api'
import SMQuestionQualityMeter from '@src/ui/SMAsk/components/common/SMQuestionQualityMeter.vue'
import SMQuestionSubmitButton from '@src/ui/SMAsk/components/common/SMQuestionSubmitButton.vue'

export default defineComponent({
  name: 'SMQuestionDialogActions',
  components: { SMQuestionSubmitButton, SMQuestionQualityMeter },
  props: {
    questionQuality: {
      type: Number,
      required: true
    },
    expertCount: {
      type: Number,
      default: 0
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    canSubmit: {
      type: Boolean,
      required: true
    },
    existingQuestionId: {
      type: Number as PropType<number | undefined>,
      default: undefined
    },
    showCancelButton: {
      type: Boolean,
      default: false
    }
  }
})
