













import useRealmSettings from '@src/composables/realmSettings'
import useWhatsNew from '@src/composables/whatsNew'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SMWhatsNewBadge',
  props: {
    inline: {
      type: Boolean,
      default: false
    },
    overlap: {
      type: Boolean,
      default: false
    },
    offsetX: {
      type: String,
      default: '20'
    }
  },
  setup() {
    const { isWhatsNewEnabled } = useRealmSettings()
    const { unreadCountString } = useWhatsNew()
    const isBadgeVisible = computed<boolean>(() => isWhatsNewEnabled.value && !!unreadCountString.value)

    return {
      isBadgeVisible,
      unreadCountString
    }
  }
})
