import { JwtPayload } from 'jwt-decode'

export enum OAuthClientId {
  QA_FRONTEND = 'qa-frontend',
  QA_FRONTEND_IFRAME = 'qa-frontend-iframe',
  MSTEAMS_PERSONAL_APP = 'msteams-personal-app'
}

export interface IStarmindJwtPayload extends Required<JwtPayload> {
  azp?: OAuthClientId | string
  nonce?: string
  session_state?: string
  realm_access?: {
    roles: string[]
  }
  resource_access?: {
    [resource: string]: {
      roles: string[]
    }
  }
  scope: string
  sid?: string
  email_verified: true
  name: string
  sm: {
    tracking_user_id: string
  }
  preferred_username: string
  given_name: string
  family_name: string
  locale: string
  email: string
}
