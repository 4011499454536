










































import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import { SELECT_LANGUAGE } from '@src/plugins/analytics/events/ui/SMLanguageSelection.events'
import { ALL_INTERFACE_LANGUAGES } from '@src/plugins/i18n/constants'

const { mapActions } = createNamespacedHelpers('language')

export default Vue.extend({
  name: 'LanguageSelection',
  props: {
    element: {
      type: String as () => 'select' | 'menu',
      default: 'menu'
    },
    outlined: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      languages: ALL_INTERFACE_LANGUAGES
    }
  },
  computed: {
    selectedLanguage(): string {
      return this.languages.find((language) => language === this.$i18n.locale) || 'en'
    },
    languageItems(): { value: string; text: string }[] {
      return this.languages.map((language) => ({ value: language, text: this.$t(`language.${language}`).toString() }))
    }
  },
  methods: {
    ...mapActions([ 'setLanguage' ]) as {
      setLanguage: (language: string) => Promise<string>;
    },
    trackSetLanguage(language: string): void {
      this.$trackEvent(SELECT_LANGUAGE, this.$options.name, {
        selected_language: language
      })
    }
  }
})
