import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'
import { IStandardizedConceptAllLanguages, IStandardizedConceptSingleLanguage } from '../../../types/concept.types'

interface ISubscriberCountResponse {
  subscribers_count: number
}

interface IConceptRecommendationsParams {
  limit?: number,
  past_days?: number,
  languages: string[],
}

type RecommendedConceptsResponse = {
  concept: IStandardizedConceptAllLanguages,
  subscribers_count: number,
  recommendation_source: string
}[]

export function personalisationApiFactory(api: AxiosInstance) {
  const baseUrl = '/api/v2/learning/personalisation'
  const methods = {
    getConceptRecommendations({
      limit = 15,
      past_days = 30,
      languages
    }: IConceptRecommendationsParams) {
      return api.get<RecommendedConceptsResponse>(`${baseUrl}/me/recommendations`, {
        params: {
          limit,
          past_days,
          languages: languages.join(',')
        }
      })
    },
    getConceptSubscriptions(globalUserId: string) {
      return api.get<IStandardizedConceptSingleLanguage[]>(`${baseUrl}/${globalUserId}/subscriptions`)
    },
    getConceptSubscriptionStatus(globalUserId: string, conceptId: string) {
      return api.get<{ is_subscribed: boolean }>(`${baseUrl}/${globalUserId}/concepts/${conceptId}/subscriptions`)
    },
    getSubscribersForConcept(conceptId: string) {
      return api.get<ISubscriberCountResponse>(`${baseUrl}/concepts/${conceptId}/stats/subscribers`)
    },
    subscribeToConcept({
      globalUserId,
      conceptId,
      label,
      language
    } : {
      globalUserId: string,
      conceptId: string,
      label: string,
      language?: string
    }) {
      return api.put<void>(`${baseUrl}/${globalUserId}/concepts/${conceptId}/subscriptions`, {
        label,
        language
      })
    },
    unsubscribeFromConcept(globalUserId: string, conceptId: string) {
      return api.delete<void>(`${baseUrl}/${globalUserId}/concepts/${conceptId}/subscriptions`)
    }
  }
  return createOptimizedAPI(methods)
}
