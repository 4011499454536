import { AxiosPromise } from 'axios'
import { ICompanyGuideline, ICompanyGuidelineResponse } from '@src/types/companyGuidelines.types'
import createAxiosInstance, { createOptimizedAPI } from '..'

interface ICompanyGuidelineApi {
  getGuideline(lang: string): AxiosPromise<ICompanyGuidelineResponse>;
  setGuideline(lang: string, guideline: ICompanyGuideline): AxiosPromise<ICompanyGuidelineResponse>;
  deleteGuideline(lang: string): AxiosPromise<void>;
}

const api = createAxiosInstance('company_guidelines', { web: true })

const companyGuidelinesApi: ICompanyGuidelineApi = {
  getGuideline(lang) {
    return api.get(lang)
  },
  setGuideline(lang, guideline) {
    return api.put(lang, guideline)
  },
  deleteGuideline(lang) {
    return api.delete(lang)
  }
}

export default {
  ...companyGuidelinesApi,
  ...createOptimizedAPI(companyGuidelinesApi)
}
