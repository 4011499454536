/* eslint-disable import/prefer-default-export */
import { EditorExtensionsType } from '@src/ui/SMEditor/TipTap/components/toolbar/toolbarExtensions'

export function getEntity(extensionType: EditorExtensionsType): string {
  switch (extensionType) {
    case 'QUESTION_EXTENSIONS':
      return 'question'
    case 'SOLUTION_EXTENSIONS':
      return 'solution'
    case 'COMMENT_EXTENSIONS':
      return 'comment'
    default:
      return ''
  }
}
// for some reason the old iframe videos that were using froala
// are not displayed because they are wrapped in a span/p instead of div
// we therefore replace all the other tags that are wrapping the iframe in div
export function wrapIframeInDiv(htmlStr: string): string {
  const wrapper = document.createElement('div')
  wrapper.innerHTML = htmlStr
  const iframes = wrapper.getElementsByTagName('iframe')
  if (!iframes || !iframes.length) {
    return htmlStr
  }
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < iframes.length; i++) {
    const { parentElement } = iframes[i]
    if (parentElement && parentElement.tagName !== 'DIV') {
      parentElement.innerHTML = `<div>${iframes[i].parentElement?.innerHTML}</div>`
    }
  }
  return wrapper.innerHTML
}
