import { IKnowledgeSpace, IMemberQueryParams } from '@src/types/knowledgeSpaces.types'
import knowledgeSpacesApi from '@src/api/knowledgeSpaces'
import userApi from '@src/api/user'
import { IGetUsersParams } from '@src/types/user.types'

export async function getSuggestedUsers(
  args : {
    query: string,
    params: (IMemberQueryParams & IGetUsersParams),
    allowInactiveInteraction: boolean,
    knowledgeSpace?: IKnowledgeSpace | null,
}
) {
  const {
    query,
    params,
    knowledgeSpace,
    allowInactiveInteraction
  } = args
  if (knowledgeSpace && knowledgeSpace.is_closed) {
    const { data: { items } } = await knowledgeSpacesApi.getMembers(
      knowledgeSpace.id,
      {
        query,
        filter: allowInactiveInteraction ? null : 'active',
        ...params
      }
    )
    return items
  }
  const { data: { items } } = await userApi.getUsers(
    {
      query,
      filter: allowInactiveInteraction ? null : 'active',
      ...params
    }
  )
  return items
}
