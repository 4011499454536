































































































import {
  defineComponent, ref, nextTick, computed, toRefs, onMounted
} from '@vue/composition-api'
import SMSpinner from '@src/ui/SMSpinner/index.vue'
import SMHighlightWords from '@src/ui/SMHighlightWords/index.vue'
import { useVuetifyAutocompleteMenuHelper, VuetifyAutoCompleteElement } from '@src/composables/useVuetifyAutocompleteMenuHelper'
import useAutoComplete from './composables/useAutoComplete'
import SMAutoCompleteLabel from './components/SMAutoCompleteLabel.vue'
import { useRecentAutocompleteSearch } from './composables/useRecentAutocompleteSearch'
import SMClearRecentSearchesDialog from './components/SMClearRecentSearchesDialog.vue'
import { useAlternativeQueriesSearch } from './composables/useAlternativeQueriesSearch'
import SMAlternativeQueries from './components/SMAlternativeQueries.vue'

export default defineComponent({
  name: 'SMAutoCompleteV2',
  components: {
    SMSpinner,
    SMHighlightWords,
    SMClearRecentSearchesDialog,
    SMAlternativeQueries,
    SMAutoCompleteLabel
  },
  props: {
    autoFocus: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { autoFocus } = toRefs(props)
    const hasSearchTerm = computed(() => (searchTerm.value !== null && searchTerm.value.length > 1))
    const hasSearchResults = computed(() => (searchResults?.value.length > 0))
    const autocompleteResults = computed(() => (hasSearchTerm.value ? searchResults?.value : recentSearches.value))
    const {
      searchTerm, searchResults, evaluating, selectedAutocompleteResult,
      onSelect, clearInput, trackFocus
    } = useAutoComplete()

    const { recentSearches, clearRecentSearches } = useRecentAutocompleteSearch(hasSearchTerm)
    const { alternativeQueries } = useAlternativeQueriesSearch(hasSearchResults, searchTerm)

    const headerAutoCompleteElement = ref<VuetifyAutoCompleteElement|null>(null)
    const { isMenuActive } = useVuetifyAutocompleteMenuHelper(headerAutoCompleteElement)
    function blurInput() {
      if (headerAutoCompleteElement.value) {
        nextTick(() => headerAutoCompleteElement?.value?.blur())
      }
    }
    function focusInput() {
      if (headerAutoCompleteElement?.value) {
        nextTick(() => headerAutoCompleteElement.value?.focus())
        headerAutoCompleteElement.value.isMenuActive = true
      }
    }

    onMounted(() => {
      if (autoFocus.value) {
        focusInput()
      }
    })

    return {
      evaluating,
      searchTerm,
      autocompleteResults,
      selectedAutocompleteResult,
      headerAutoCompleteElement,
      recentSearches,
      searchResults,
      alternativeQueries,
      hasSearchTerm,
      isMenuActive,
      onSelect,
      clearInput,
      trackFocus,
      blurInput,
      clearRecentSearches
    }
  }
})
