




















































































import { Editor } from '@tiptap/vue-2'
import { EntityType } from '@src/types/tiptap.types'
import { CLICK_ADD_IMAGE } from '@src/plugins/analytics/events/ui/SMTipTapToolbar.events'
import SMDialog from '@src/ui/SMDialog/index.vue'
import {
  computed, defineComponent, PropType, ref, toRefs
} from '@vue/composition-api'
import SMTipTapToolbarButton from '../SMTipTapToolbarButton.vue'
import SMImageUpload from './SMImageUpload.vue'
import SMImageInputUrl from './SMImageInputUrl.vue'

export default defineComponent({
  name: 'SMTipTapToolbarImageButton',
  components: {
    SMDialog,
    SMTipTapToolbarButton,
    SMImageUpload,
    SMImageInputUrl
  },
  props: {
    editor: {
      type: Object as () => Editor,
      required: true
    },
    entityType: {
      type: String as PropType<EntityType>,
      required: true
    },
    entityId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { entityId, entityType, editor } = toRefs(props)

    const imagePath = ref('')
    const isOpen = ref(false)
    const model = ref(entityId.value ? 'upload' : 'url')

    const canUpload = computed(() => !!entityId.value || entityType.value === 'adminGeneric')

    function setImagePath(imagePathValue: string, delay = 0): void {
      if (imagePathValue) {
        imagePath.value = imagePathValue
        editor.value.chain().focus().setImage({ src: imagePathValue }).run()
        setTimeout(() => {
          isOpen.value = false
          imagePath.value = ''
        }, delay)
      }
    }
    return {
      CLICK_ADD_IMAGE,
      imagePath,
      isOpen,
      model,
      canUpload,
      setImagePath
    }
  }
})
