import { computed, onMounted } from '@vue/composition-api'
import useStore from '@src/store'
import { ISettingsParams, IUser, IUserSettings } from '@src/types/user.types'
import { PermissionLevel, isMine } from '@src/utilities/userUtils'
import { INotificationsSettings } from '@src/types/notifications.types'
import userApi from '@src/api/user'
import { useRouter } from '@src/composables/router'
import useTracking from '@src/composables/tracking/tracking'

export default function useUser() {
  const store = useStore()
  const router = useRouter()
  const { trackMixpanelEvent } = useTracking()

  const roles = computed(() => store.state.user.roles)
  const user = computed<IUser>(() => store.getters['user/getUser'])
  const language = computed(() => user.value.language)
  const userSettings = computed<Partial<IUserSettings>>(() => store.getters['user/settings/getSettings'])
  const permissionLevel = computed<PermissionLevel>(() => store.getters['user/getPermissionLevel'])
  const getUserLoading = computed<boolean>(() => store.getters['user/getUserLoading'])
  const getEditUser = computed<IUser>(() => store.getters['user/getEditUser'])
  const getUserSettingsLoading = computed<boolean>(() => store.getters['user/settings/getLoading'])
  const getUserSettings = computed<Partial<IUserSettings>>(() => store.getters['user/settings/getSettings'])
  const isAdmin = computed(() => permissionLevel.value === PermissionLevel.Admin)
  const isSuperAdmin = computed<boolean>(() => store.getters['user/getIsSuperAdmin'])
  const isNewUserThirtyDays = computed<boolean>(() => store.getters['user/getIsNewUserThirtyDays'])
  const isNewUserSevenDays = computed<boolean>(() => store.getters['user/getIsNewUserSevenDays'])

  function loadUserSettings() {
    store.dispatch('user/settings/load')
  }

  function saveUser(): Promise<boolean> {
    return store.dispatch('user/saveUser')
  }

  function saveUserPartialUpdate(updatedUser: Partial<IUser>): Promise<boolean> {
    return store.dispatch('user/saveUserPartialUpdate', updatedUser)
  }

  function updateUserSettings(settings: Partial<ISettingsParams>): Promise<void> {
    return store.dispatch('user/settings/update', settings)
  }
  function resetUserSettings(): Promise<void> {
    return store.dispatch('user/settings/resetUserSettings')
  }

  function setEditUserLanguage(lang: string) {
    store.commit('user/setEditUserLanguage', lang)
  }

  function resetEditUser() {
    store.commit('user/resetEditUser')
  }

  function trackUserAvatarClick(userId: number) {
    trackMixpanelEvent('clickProfile', {
      is_own_profile: isMine(user.value, userId)
    })
  }

  async function goToUser(userId: number | string) {
    if (typeof userId === 'number') {
      router.push({ path: `/user/${userId}` })
      trackUserAvatarClick(userId)
    } else {
      const { data } = await userApi.cached.getUser(userId)
      router.push({ path: `/user/${data.id}` })
      trackUserAvatarClick(data.id)
    }
  }

  return {
    user,
    language,
    userSettings,
    roles,
    permissionLevel,
    isAdmin,
    isSuperAdmin,
    getUserLoading,
    getEditUser,
    getUserSettingsLoading,
    getUserSettings,
    isNewUserThirtyDays,
    isNewUserSevenDays,
    loadUserSettings,
    resetEditUser,
    resetUserSettings,
    saveUser,
    saveUserPartialUpdate,
    setEditUserLanguage,
    updateUserSettings,
    goToUser
  }
}

export function useUserNotificationSettings() {
  const store = useStore()
  const notificationSettings = computed<INotificationsSettings | undefined>(() => ({
    email: store.getters['user/communicationSettings/getEmailSettings'],
    webPush: store.getters['user/communicationSettings/getWebPushSettings']
  }))
  const loading = computed<boolean>(() => store.getters['user/communicationSettings/getCommunicationSettingsLoading'])
  const updating = computed<boolean>(() => store.getters['user/communicationSettings/getCommunicationSettingsUpdating'])

  onMounted(() => {
    loadNotificationSettings()
  })

  function loadNotificationSettings() {
    store.dispatch('user/communicationSettings/loadNotificationSettings')
  }

  function updateNotificationSettings(settings: INotificationsSettings) {
    store.dispatch('user/communicationSettings/updateEmailSettings', settings.email)
    store.dispatch('user/communicationSettings/updateWebPushSettings', settings.webPush)
  }

  return {
    loading,
    updating,
    notificationSettings,
    updateNotificationSettings
  }
}
