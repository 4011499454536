import { ITrackingProps } from '@src/types/events.types'

export const CREATE_GROUP = 'createNewApprovalGroup'
export const EDIT_GROUP = 'editApprovalGroup'
export const DELETE_GROUP = 'deleteApprovalGroup'
export const TOGGLE_FEATURE = 'enableDisableApprovalGroup'
export const ADD_USERS = 'addMembersApprovalGroup'
export const REMOVE_USERS = 'removeMembersApprovalGroup'
export const VISIT = 'visitApprovalGroup'

const commonProps = {
}

export default {
  SolutionApproval: {
    [VISIT]: (): ITrackingProps => ({
      ...commonProps,
      type: 'view',
      component: 'SolutionApproval'
    })
  },
  SolutionApprovalOverview: {
    [TOGGLE_FEATURE]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      element: 'toggle',
      type: 'click',
      component: 'SolutionApprovalOverview'
    })
  },
  SolutionApprovalDetail: {
    [CREATE_GROUP]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      element: 'button',
      type: 'click',
      component: 'SolutionApprovalDetail'
    }),
    [EDIT_GROUP]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      element: 'button',
      type: 'click',
      component: 'SolutionApprovalDetail'
    }),
    [DELETE_GROUP]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      element: 'button',
      type: 'click',
      component: 'SolutionApprovalDetail'
    }),
    [ADD_USERS]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      element: 'drop_down',
      type: 'click',
      component: 'SolutionApprovalDetail'
    }),
    [REMOVE_USERS]: (customProps: any): ITrackingProps => ({
      ...commonProps,
      ...customProps,
      element: 'icon',
      type: 'click',
      component: 'SolutionApprovalDetail'
    })
  }
}
