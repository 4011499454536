import { InterfaceLanguages } from '../../../types/language.types'
import {
  ClobbopusTier, Environment, IUnauthenticatedRealmSettings, PricingTier, RelevantUserActivityAutoKpi, ValidationPeriodId
} from '../../../types/realmSettings.types'

// add some fallback settings in case the Realm Settings Service is down to not break the application
const FALLBACK_SETTINGS: IUnauthenticatedRealmSettings = {
  auth: {
    password_auth_enabled: true,
    keycloak_url: 'https://auth.starmind.com/auth',
    cookie_same_site_none: false
  },
  context_message: {
    enabled: false
  },
  categories: {
    categories_enabled: false,
    categories_in_pricing_plan: true
  },
  content: {
    allowed_iframe_sources: [ 'www.youtube.com', 'player.vimeo.com' ],
    attachment_upload_enabled: true
  },
  expert_search: {
    show_expert_search: true,
    show_expert_users_enabled: true,
    people_search_enabled: true
  },
  email: {
    email_delivery_enabled: true,
    technical_support_email: 'support@starmind.com'
  },
  notification: {
    email_notifications_enabled: true,
    mobile_push_notifications_enabled: false,
    browser_push_notifications_enabled: false,
    one_signal_browser_app_id: '',
    one_signal_browser_safari_web_id: ''
  },
  general: {
    allowed_image_file_types: [ 'jpg', 'jpeg', 'png', 'gif' ],
    allowed_upload_file_types: [
      'xlsx',
      'jpg',
      'pdf',
      'pptx',
      'jpeg',
      'rtf',
      'gif',
      'avi',
      'docx',
      'mp3',
      '7z',
      'xls',
      'png',
      'mov',
      'zip',
      'csv',
      'mp4',
      'ppt',
      'doc',
      'txt',
      'odt',
      'rar',
      'pps'
    ],
    default_allowed_upload_file_types: [
      'xlsx',
      'jpg',
      'pdf',
      'pptx',
      'jpeg',
      'rtf',
      'gif',
      'avi',
      'docx',
      'mp3',
      '7z',
      'xls',
      'png',
      'mov',
      'zip',
      'csv',
      'mp4',
      'ppt',
      'doc',
      'txt',
      'odt',
      'rar',
      'pps'
    ],
    default_language: InterfaceLanguages.EN,
    languages_in_pricing_plan: [ 'en', 'de', 'fr' ],
    upload_file_types_blacklist: [],
    uses_formal_language: true,
    environment: Environment.PROD
  },
  knowledge_spaces: {
    knowledge_spaces_enabled: false,
    knowledge_spaces_in_pricing_plan: true,
    question_knowledge_space_mandatory: false
  },
  metadata: {
    company_name: 'Starmind',
    go_live_date: '2011-11-01T23:00:00Z',
    creation_date: '2011-11-01T23:00:00Z',
    realm_name: 'Starmind',
    is_trial: false,
    pricing_tier: PricingTier.STANDARD,
    realm_id: '1'
  },
  question: {
    delete_solved_questions: false,
    mandatory_question_description: false,
    question_title_max_length: 100
  },
  solution: {
    show_average_rating_to_user: true,
    mark_outdated_enabled: false
  },
  solution_authority: {
    solution_authority_enabled: false,
    solution_authority_in_pricing_plan: true
  },
  terms_of_use: {
    checkbox_enabled: false,
    enabled: false,
    in_pricing_plan: true
  },
  translation: {
    available_translation_languages: [],
    available_translation_providers: [ 'deepl', 'google' ],
    default_translation_provider: 'deepl',
    translation_provider: 'deepl',
    translations_rolled_out: false,
    automatic_translation_languages: [],
    automatic_translations_enabled: false,
    automatic_translations_in_pricing_plan: true,
    cross_lingual_search_enabled: false,
    cross_lingual_search_in_pricing_plan: true,
    manual_translation_languages_in_pricing_plan: []
  },
  user_admin: {
    allow_inactive_user_interaction: false,
    email_change_allowed: false,
    extended_user_properties_change_allowed: false,
    invitation_email_allowed_domains: [],
    invitation_enabled: true,
    research_participation_enabled: false
  },
  user_follow: {
    user_follow_enabled: true
  },
  user_profile: {
    anonymize_deleted_users: true,
    anonymize_users_email: false,
    appendix_user_info_clip: [ 'email' ],
    user_activity_visible_by_everyone: false,
    user_knowhow_map_visible_by_everyone: true,
    quantified_expertise_visibility: true
  },
  white_labelling: {},
  gamification: {
    badges_enabled: true,
    user_badges_visible_by_everyone: false,
    user_score_enabled: false,
    user_score_frontend_enabled: false,
    user_score_max_level: 100,
    user_score_start_date: null
  },
  integrations: {
    microsoft: {
      ms_teams_contact_button_enabled: true
    }
  },
  user_tracking: {
    enabled: false,
    mixpanel_analytics_token: '',
    tracking_salt: ''
  },
  canny: {
    enabled: true
  },
  solution_validation: {
    default_period: ValidationPeriodId.OPEN,
    enabled: true
  },
  nps: {
    enabled: false
  },
  solution_value: {
    enabled: true
  },
  company_guidelines: {
    enabled: true
  },
  homepage: {
    enabled: true
  },
  workspaces: {
    enabled: true,
    listed_workspaces: []
  },
  question_flagging: {
    enabled: true
  },
  question_escalation: {
    enabled: true
  },
  whats_new: {
    enabled: true,
    url: 'https://whatsnew-v2.starmind.io/'
  },
  kpi: {
    expert_statistics_enabled: true,
    relevant_user_activity_auto_kpis: RelevantUserActivityAutoKpi.UNIQUE_ACTIVE_USERS_PERIOD,
    clobbopus_tier: ClobbopusTier.STANDARD
  },
  vsm: {
    vsm_enabled: true
  }
}

export default FALLBACK_SETTINGS
