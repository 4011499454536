import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'
import { IConceptSearchResultItem, IStandardizedConceptAllLanguages } from '../../../types/concept.types'

interface IUserContributions {
  normalized_contributions: {
    comment: number[],
    question: number[],
    solution: number[]
  },
  weeks: string[],
  total_contributions: number,
  total_questions: number,
  unique_users: number
}

interface IConceptCreate {
  ontology_id?: string;
  languages: {
    aliases: string[];
    language_id: string;
    primary_label?: string | null;
  }[];
}

export function conceptsApiFactory(api: AxiosInstance) {
  const baseUrl = '/api/v2/learning/concepts'
  const methods = {
    getRelatedConcepts(conceptId: string) {
      return api.get<IStandardizedConceptAllLanguages[]>(`${baseUrl}/${conceptId}/related`)
    },
    searchTopicConcepts(query: string, language_id: string) {
      return api.get<IConceptSearchResultItem[]>(`${baseUrl}/search`, {
        params: {
          query,
          language_id
        }
      })
    },
    getConcept(id: string) {
      return api.get<IStandardizedConceptAllLanguages>(`${baseUrl}/${id}`)
    },
    getUserContributions(concept_id: string, isCurrentUser: boolean) {
      return api.get<IUserContributions>(`${baseUrl}/${concept_id}/contributions/statistics`, {
        params: {
          current_user: isCurrentUser
        }
      })
    },
    createConcept(obj: IConceptCreate) {
      return api.post<IStandardizedConceptAllLanguages[]>(baseUrl, obj)
    }
  }

  return createOptimizedAPI(methods)
}
