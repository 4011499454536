import { IRootState } from '@src/types/store.types'
import { ActionTree, Module } from 'vuex'
import { ActivationFlowStepStatus, ActivationFlowStep } from '@src/types/activationFlow.types'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IActivationFlowWhatsNewState {}

const actions: ActionTree<IActivationFlowWhatsNewState, IRootState> = {
  possiblyShowWhatsNew({ dispatch, rootGetters }) {
    const isSteppedThroughSomeSteps: boolean = rootGetters['activationFlow/isSteppedThroughSomeSteps']

    if (isSteppedThroughSomeSteps) {
      dispatch('activationFlow/next', { status: ActivationFlowStepStatus.INACTIVE, step: ActivationFlowStep.WHATS_NEW }, { root: true })
    } else {
      dispatch('whatsNew/openWhatsNewDialog', true, { root: true })
    }
  },

  whatsNewCompleted({ dispatch }, status: ActivationFlowStepStatus) {
    dispatch('activationFlow/next', { status, step: ActivationFlowStep.WHATS_NEW }, { root: true })
  }
}

export default {
  namespaced: true,
  actions
} as Module<IActivationFlowWhatsNewState, IRootState>
