import { AxiosPromise } from 'axios'
import {
  IAdminSolutionApprovalGroup,
  ISolutionAuthoritiesOfUserResponse
} from '@src/types/solutionApproval.types'
import createAxiosInstance from '.'

const api = createAxiosInstance('solution-authorities')

export default {
  getSolutionAuthorities(): AxiosPromise<ISolutionAuthoritiesOfUserResponse> {
    return api.get('')
  },
  createSolutionAuthority(name: string, userIds: number[]): AxiosPromise<IAdminSolutionApprovalGroup> {
    return api.post('', {
      name,
      user_ids: userIds
    })
  },
  updateSolutionAuthority(id: number, name: string, userIds: number[]): AxiosPromise<IAdminSolutionApprovalGroup> {
    return api.put(id.toString(), {
      id,
      name,
      user_ids: userIds
    })
  },
  getSolutionAuthority(id: number): AxiosPromise<IAdminSolutionApprovalGroup> {
    return api.get(id.toString())
  },
  deleteSolutionAuthority(id: number): AxiosPromise<void> {
    return api.delete(id.toString())
  }
}
