


















































import {
  defineComponent, inject
} from '@vue/composition-api'
import { IUseTracking } from '../../composables/tracking'
import { SMAskInjectables } from '../../constants'

export default defineComponent({
  name: 'SMQuestionFormHeader',
  props: {
    existingQuestionId: {
      type: Number,
      default: undefined
    },
    isFullscreen: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { trackClickCancel } = inject(SMAskInjectables.Tracking) as IUseTracking

    return { trackClickCancel }
  }
})
