import generateOrGetSharedStore from '@src/store'
import _isEmpty from 'lodash/isEmpty'

function defaultAcceptingCondition<T>(expectedValue: T): boolean {
  if (typeof expectedValue === 'object') {
    return !_isEmpty(expectedValue)
  }
  return !!expectedValue
}

export default function awaitGetterValue<T>(getterPath: string, acceptingCondition: (expectedValue: T) => boolean = defaultAcceptingCondition): Promise<T> {
  const store = generateOrGetSharedStore()
  return new Promise((resolve) => {
    if (acceptingCondition(store.getters[getterPath])) {
      // if it's already there, the watcher will not necessarily trigger
      resolve(store.getters[getterPath])
    } else {
      const unwatch = store.watch((state, getters) => getters[getterPath], (value: T) => {
        if (acceptingCondition(value)) {
          resolve(value)
          unwatch()
        }
      })
    }
  })
}
