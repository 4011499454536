import { IUser } from '@src/types/user.types'
import { IQuestionComplete } from '@src/types/question.types'
import { ISolutionComplete } from '@src/types/solution.types'
import { ICommentComplete } from '@src/types/comment.types'

export type UserChip = IUser | string
export type EntityType = 'question' | 'solution' | 'invite'
export type DialogEntity = IQuestionComplete | ISolutionComplete | ICommentComplete

export enum UserSuggestionType {
    Share = 'Share',
    Recommend = 'Recommend',
    NoSuggestions = 'NoSuggestions'
}
