import { AxiosPromise } from 'axios'
import { IJobSkillResponse, IJobSuggestionResponse } from '@src/types/jobSkill'
import createAxiosInstance, { createOptimizedAPI } from '.'

interface IJobSkillApi {
  getJobs(query: string, limit?: number): AxiosPromise<IJobSuggestionResponse>;
  getSkills(job: string, limit?: number): AxiosPromise<IJobSkillResponse>;
}

const api = createAxiosInstance('jobskill')

const jobSkillApi: IJobSkillApi = {
  getJobs(query, limit = 10) {
    return api.get('jobs', {
      params: {
        limit,
        query
      }
    })
  },
  getSkills(job, limit = 15) {
    return api.get('skills', {
      params: {
        limit,
        job
      }
    })
  }
}

export default {
  ...jobSkillApi,
  ...createOptimizedAPI(jobSkillApi)
}
