






















import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { CLICK_NOTIFICATION_ITEM } from '@src/plugins/analytics/events/ui/SMNotificationsButton.events'

export default Vue.extend({
  name: 'SMNotificationsButton',
  props: {
    isHeader: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters('notifications', [ 'getUnseenNotificationsCount', 'getNotificationsCountAsString' ]) as {
      getUnseenNotificationsCount: () => number;
      getNotificationsCountAsString: () => string;
    }
  },
  methods: {
    ...mapActions('notifications', [ 'showDrawer' ]) as {
      showDrawer: () => Promise<void>;
    },
    displayDrawer(): void {
      this.trackNotificationClick()
      this.showDrawer()
    },
    async trackNotificationClick(): Promise<void> {
      try {
        this.$trackEvent(CLICK_NOTIFICATION_ITEM, this.$options.name, {
          icon_status: this.getUnseenNotificationsCount > 0 ? 'unseen' : 'seen'
        })
      } catch {
        //
      }
    }
  }
})
