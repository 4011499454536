

























































import SMTimeAgo from '@src/ui/SMTimeAgo/index.vue'
import { INotificationItem, NotificationType } from '@src/types/notifications.types'
import _includes from 'lodash/includes'
import { mapActions, mapMutations } from 'vuex'
import { getAvatarUrl, getFullName } from '@src/utilities/userUtils'
import { placeholdersToLinks } from '@src/utilities/filters/mention'
import SMUserAvatar from '@src/ui/SMUserAvatar/index.vue'
import { CLICK_NOTIFICATION } from '@src/plugins/analytics/events/ui/NotificationsListItem.events'
import sanitizeHtml from 'sanitize-html'
import { defineComponent } from '@vue/composition-api'
import { INotificationV2User, IUser } from '@src/types/user.types'
import { getNotificationUserObject } from '@src/utilities/notifications'

const notificationsWithMessageBody = [ 'share_question', 'share_solution', 'new_kudo' ]
const notificationWithTitleOnly = [ 'new_question', 'question_forwarded', 'question_escalation' ]

export default defineComponent({
  name: 'NotificationsListItem',
  components: {
    SMTimeAgo,
    SMUserAvatar
  },
  filters: {
    placeholdersToLinks
  },
  props: {
    notification: {
      type: Object as () => INotificationItem<IUser | INotificationV2User>,
      required: true
    }
  },
  computed: {
    questionPoser(): boolean {
      const { user } = this.notification
      return user === null || Object.keys(user).length === 0
    },
    title(): string {
      const { knowledge_space_name, type } = this.notification
      const user = getNotificationUserObject(this.notification)
      const fullName = getFullName(user?.firstname, user?.lastname, !user)
      const notificationTitle = _includes(notificationsWithMessageBody, type)
        ? `notification.type.${type}-in-app` : `notification.type.${type}`
      const knowledgeSpaceTitlePrefix = knowledge_space_name ? `${knowledge_space_name} - ` : ''
      return this.$t(notificationTitle, { user: fullName, knowledgeSpaceTitlePrefix })?.toString()
    },
    subtitle(): string {
      return sanitizeHtml(this.notification.content, {
        allowedTags: [ 'a' ],
        allowedAttributes: {
          a: [ 'href' ]
        }
      })
    },
    isUserAvatar(): boolean {
      return !!this.notification.user
    },
    avatar(): string {
      const { icon } = this.notification
      const user = getNotificationUserObject(this.notification)
      const avatar = getAvatarUrl(user?.image as string, 32)
      return this.isUserAvatar ? avatar : icon
    },
    showAsPlainText(): boolean {
      return _includes(notificationWithTitleOnly, this.notification.type)
    },
    subtitleClass(): string {
      return this.notification.question_id ? '' : 'multi-line-text'
    },
    listItemClass(): string {
      return this.notification.is_read ? 'lighter' : ''
    }
  },
  methods: {
    ...mapMutations('notifications', [ 'hideDrawer' ]) as {
      hideDrawer: () => void;
    },
    ...mapActions('notifications', [ 'markNotificationAsRead', 'executeNotificationClickCallback' ]) as {
      markNotificationAsRead: (id: number) => Promise<void>;
      executeNotificationClickCallback: (notification: INotificationItem<IUser | INotificationV2User>) => Promise<void>;
    },
    async onNotificationItemClick(): Promise<void> {
      if (!this.notification.showAsPlainText && this.notification.url) {
        this.trackNotificationClick()
        this.hideDrawer()

        if (!this.notification.is_read) {
          this.markNotificationAsRead(this.notification.id)
        }
        await this.executeNotificationClickCallback(this.notification)
        this.$router.push({ path: this.notification.url, query: this.getQueryParamsFromNotificationUrl(this.notification.url) })
      }
    },
    trackNotificationClick(): void {
      this.$trackEvent(CLICK_NOTIFICATION, this.$options.name, {
        is_gamification_badge: this.notification.type === NotificationType.NewBadgeObtained,
        notification_status: this.notification.is_read ? 'read' : 'unread',
        notification_type: this.notification.type
      })
    },
    getQueryParamsFromNotificationUrl(notificationUrl: string): Record<string, string> {
      const targetUrl = new URL(window.location.origin + notificationUrl)
      // TODO: DEVOPS-654 once Jenkins is updated to Node.js > 12 we can return Object.fromEntries(targetUrl.searchParams)
      const queryParams: Record<string, string> = {}
      targetUrl.searchParams.forEach((value, key) => { queryParams[key] = value })
      return queryParams
    }
  }
})
