/* eslint-disable */
// This code is copied from https://help.mixpanel.com/hc/en-us/articles/360001337103
// and just a bit adapted to pass ESLint and TS checks

function getQueryParam(url: string, param: string) {
  // Expects a raw URL
  param = param.replace(/[[]/, '\[').replace(/[]]/, '\]')
  const regexS = `[\?&]${param}=([^&#]*)`
  const regex = new RegExp(regexS)
  const results = regex.exec(url)
  if (results === null || (results && typeof (results[1]) !== 'string' && (results[1] as any).length)) {
    return ''
  }
  return decodeURIComponent(results[1]).replace(/\W/gi, ' ')
}
export function campaignParams() {
  const campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
  let kw = ''
  const params: any = {}
  let index
  for (index = 0; index < campaign_keywords.length; ++index) {
    kw = getQueryParam(document.URL, campaign_keywords[index])
    params[`${campaign_keywords[index]} [last touch]`] = kw
  }
  
  return params
}
