











import { defineComponent } from '@vue/composition-api'
import SMCarouselDelimiter from '@src/ui/SMCarouselDelimiter/index.vue'

export default defineComponent({
  name: 'ActivationFlowStepper',
  components: { SMCarouselDelimiter },
  props: {
    totalSteps: {
      type: Number,
      required: true
    },
    currentStep: {
      type: Number,
      required: true
    }
  }
})
