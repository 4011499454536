import { createConceptFromTextInULF } from '@src/utilities/conceptSearch'
import { JobToSkillWizardSteps } from '@src/types/jobSkill'
import { IInitialExpertise } from '@src/types/initialExpertise.types'
import useUser from '@src/composables/user'
import { Ref, computed } from '@vue/composition-api'
import { ulfShieldExpertsApi } from '@src/api/apiModuleInstances'

export default function useUserExpertiseSelection(selectedExpertise: Ref<IInitialExpertise[]>, currentStep: Ref<JobToSkillWizardSteps>) {
  const { user } = useUser()
  const userId = computed(() => user.value.global_user_id)

  // For the labels that have neither an ontology_id nor a concept_id
  // we need to create new concept. This is the case of the skills that get passed from the skills API
  async function fetchMissingConcepts() {
    try {
      return Promise.all(
        selectedExpertise.value
          .filter((exp) => !exp.concept_id && !exp.ontology_id)
          .map(async (exp) => {
            const [ concept ] = await createConceptFromTextInULF(exp.label, exp.language_id)
            return {
              ...exp,
              concept_id: concept.concept_id
            }
          })
      )
    } catch {
      return []
    }
  }
  async function setExpertise() {
    if (currentStep.value === JobToSkillWizardSteps.Tags) {
      const newConcepts = await fetchMissingConcepts()
      await ulfShieldExpertsApi.setInitialUserExpertise(
        userId.value,
        [
          ...selectedExpertise.value.filter((exp) => exp.concept_id || exp.ontology_id),
          ...newConcepts
        ]
      )
    }
  }

  return {
    setExpertise,
    fetchMissingConcepts
  }
}
