import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import { IRootState } from '@src/types/store.types'
import _mergeWith from 'lodash/mergeWith'
import _merge from 'lodash/merge'
import _isFunction from 'lodash/isFunction'

import sentryContextHook from '@src/store/plugins/sentryContextHook'
import searchV2 from './modules/searchV2'
import ask from './modules/ask'
import categories from './modules/categories'
import analytics from './modules/analytics'
import user from './modules/user'
import otherUsers from './modules/otherUsers'
import language from './modules/language'
import vsm from './modules/vsm'
import solutionApproval from './modules/solutionApproval'
import notifications from './modules/notifications'
import terms from './modules/terms/index'
import activationFlow from './modules/activationFlow'
import snackbar from './modules/snackbar'
import userGuideSidenav from './modules/userGuideSidenav'
import knowledgeSpaces from './modules/knowledgeSpaces'
import userLanguageHook from './plugins/userLanguageHook'
import analyticsConfigHook from './plugins/analyticsConfigHook'
import featureToggleHooks from './plugins/featureTogglesHook'
import realmSettings from './modules/realmSettings'
import featureToggles from './modules/featureToggles'
import whatsNew from './modules/whatsNew'
import socialDialog from './modules/socialDialog'
import askNicelyHook from './plugins/askNicelyHook'
import companyGuidelines from './modules/companyGuidelines'
import feedbackDialog from './modules/feedbackDialog'

Vue.use(Vuex)
let sharedStore: Store<IRootState>

// Vuex 'state' property can be a factory function. When merging with an overwrite, the
// merge result should be a function that invokes the state functions and merges their result.
// Also see https://stackoverflow.com/questions/59052646/vuex-state-field-foo-was-overridden-by-a-module-with-the-same-name-at-foo
// We had that problem when using vuex modules in jest tests
function mergeState(first: any, second: any, propName: string): any {
  if (propName === 'state') {
    try {
      const isFirstFunction = _isFunction(first)
      const isSecondFunction = _isFunction(second)
      return isFirstFunction || isSecondFunction ? (): any => _merge(
        { ...(isFirstFunction ? first() : first) },
        { ...(isSecondFunction ? second() : second) }
      ) : _merge(first, second)
    } catch {
      throw new Error('Cannot merge vuex state overwrite!')
    }
  }

  return undefined
}

function createStore(overwrite = {}): Store<IRootState> {
  return new Vuex.Store<IRootState>(
    _mergeWith(
      {
        modules: {
          ask,
          categories,
          analytics,
          user,
          otherUsers,
          language,
          vsm,
          solutionApproval,
          terms,
          activationFlow,
          snackbar,
          knowledgeSpaces,
          notifications,
          userGuideSidenav,
          realmSettings,
          featureToggles,
          whatsNew,
          socialDialog,
          searchV2,
          companyGuidelines,
          feedbackDialog
        },
        plugins: [
          userLanguageHook,
          analyticsConfigHook,
          featureToggleHooks,
          askNicelyHook,
          sentryContextHook
        ]
      },
      overwrite,
      mergeState
    )
  )
}

export default (overwrite = {}, shared = true): Store<IRootState> => {
  if (!sharedStore) {
    sharedStore = createStore(overwrite)
  }

  return shared ? sharedStore : createStore(overwrite)
}
