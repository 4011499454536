import Vue from 'vue'
import { ComponentBasedTrackingFn, MixpanelTrackingFn } from '@src/types/events.types'

/*
* Analytics is a plugin and by providing it from the root instance, we can make it available for the composition API.
*/
export default function useAnalytics() {
  const trackEvent: ComponentBasedTrackingFn = Vue.prototype.$trackEvent
  const trackMixpanelEvent: MixpanelTrackingFn = Vue.prototype.$trackMixpanelEvent

  return {
    /**
    * @deprecated Please use trackMixpanelEvent instead.
    */
    trackEvent,
    trackMixpanelEvent
  }
}
