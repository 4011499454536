

















































import Vue from 'vue'

export default Vue.extend({
  name: 'SMAttributionsButton',
  data() {
    return {
      dialog: false
    }
  }
})
