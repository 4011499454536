/* eslint-disable default-param-last */
import { AxiosInstance } from 'axios'
import { IPerson, IUser } from '../../../types/user.types'
import { IExpert } from '../../../types/experts.types'
import { IPaginatedHasMoreResp } from '../../../types/api.types'
import { createOptimizedAPI } from '../utils'

export interface IQuestionsApiResp {
  items: string[];
  total: number;
}

export interface IUsersApiResp {
  items: IUser[];
  total: number;
}

export type ITagApiResp = {
  total: number;
  items: string[];
};

export interface IExpertsApiResp {
  tags: string[];
  experts: IExpert[];
}

export interface IRecentSearchesResp {
  items: {text: string}[];
  total: number;
}

interface IScoredUserId {
  user_id: string,
  score: number,
}

export interface IConceptsExpertsApiResp {
  experts: IScoredUserId[];
}

export function searchApiFactory(api: AxiosInstance) {
  const baseUrl = '/api/v1/search'
  const methods = {
    getQuestionAutocomplete(query: string, limit?: number, abortController?: AbortController) {
      return api.get<IQuestionsApiResp>(`${baseUrl}/questions-autocomplete`, {
        params: {
          query,
          limit
        },
        signal: abortController ? abortController.signal : undefined
      })
    },
    getUsersStream({
      query = '',
      limit,
      offset,
      abortController
    } : {
      query: string,
      limit?: number,
      offset?: number,
      abortController?: AbortController
    }) {
      return api.post<IUsersApiResp>(`${baseUrl}/users-stream`, {
        query,
        limit,
        offset,
        signal: abortController ? abortController.signal : undefined
      })
    },
    getExperts(query = '', limit?: number) {
      return api.post<IExpertsApiResp>(`${baseUrl}/experts`, {
        query,
        limit
      })
    },
    getConceptExperts(conceptsArray: string[], limit = 10) {
      const concepts = conceptsArray.join(',')
      return api.get<IConceptsExpertsApiResp>(`${baseUrl}/experts`, {
        params: {
          limit,
          concepts
        }
      })
    },
    getTags(query: string) {
      return api.get<ITagApiResp>(`${baseUrl}/tags`, {
        params: {
          query
        }
      })
    },
    logSimilarQuestionClick({
      id,
      title,
      description,
      rank
    } : { id: number, title: string, description: string, rank?: number }) {
      return api.post<void>(`${baseUrl}/log/${id}/similar-question-click`, {
        title,
        description,
        rank
      })
    },
    logQuestionFeedClick(id: number, searchQueryId: number, rank?: number) {
      return api.post<void>(`${baseUrl}/log/${id}/click`, {
        search_query_id: searchQueryId,
        rank
      })
    },
    // A merger of two endpoints:
    // - [User stream]({{ '/internal/api/search/users/' | prepend: site.baseurl }})
    // - [Expert Search]({{ '/api/v1/resources/search/experts/' | prepend: site.baseurl }})
    getPeople(query: string, limit = 50, abortController?: AbortController) {
      return api.post<IPaginatedHasMoreResp<IPerson[]>>(`${baseUrl}/people`, {
        query,
        limit,
        signal: abortController ? abortController.signal : undefined
      })
    },
    getRecentSearches(limit = 5) {
      return api.get<IRecentSearchesResp>(`${baseUrl}/recent`, {
        params: {
          limit
        }
      })
    },
    clearRecentSearches() {
      return api.delete<void>(`${baseUrl}/recent`)
    }
  }
  return createOptimizedAPI(methods)
}
