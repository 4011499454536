












































import {
  defineComponent, ref, toRefs
} from '@vue/composition-api'
import { useAnnouncementComments } from '@src/ui/SMAnnouncements/composables/announcementComments'
import SMAnnouncementCommentItem from '@src/ui/SMAnnouncements/SMAnnouncementCommentItem.vue'
import useUser from '@src/composables/user'
import SMEditAnnouncementComment from '@src/ui/SMAnnouncements/SMEditAnnouncementComment.vue'
import SMUserAvatar from '@src/ui/SMUserAvatar/index.vue'

export default defineComponent({
  name: 'SMAnnouncementComments',
  components: { SMUserAvatar, SMAnnouncementCommentItem, SMEditAnnouncementComment },
  props: {
    announcementId: {
      type: Number,
      required: true
    }
  },
  setup(props) {
    const { announcementId } = toRefs(props)

    const editMode = ref(false)

    const { user } = useUser()

    const {
      postAnnouncementComment,
      deleteAnnouncementComment,
      updateAnnouncementComment,
      comments,
      loading
    } = useAnnouncementComments(announcementId)

    return {
      editMode,
      user,
      comments,
      loading,
      updateAnnouncementComment,
      postAnnouncementComment,
      deleteAnnouncementComment
    }
  }
})
