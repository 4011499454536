



































import Vue from 'vue'
import { mapGetters } from 'vuex'
import defaultUserImage from '@src/assets/img/ico-sm-avatar-default.svg'

export default Vue.extend({
  name: 'SMAvatar',
  props: {
    size: {
      type: Number,
      default: 30
    },
    resolutionFactor: {
      type: Number,
      default: 2
    },
    anonymous: {
      type: Boolean,
      default: false
    },
    self: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: undefined
    },
    tooltipText: {
      type: String,
      default: ''
    },
    tile: {
      type: Boolean,
      default: false
    },
    roundedTile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('user', {
      fetchedUserImage: 'getUserImage',
      userFetchError: 'getUserFetchError',
      isUserDataLoading: 'getUserLoading'
    }),
    defaultImageUrl(): string {
      return defaultUserImage
    },
    selfImageUrl(): string | undefined {
      return !this.isUserDataLoading && !this.userFetchError
        ? this.fetchedUserImage || undefined
        : undefined
    },
    customImageUrl(): string | undefined {
      return this.url || undefined
    },
    finalImageUrl(): string | undefined {
      const unprocessedUrl = (this.self ? this.selfImageUrl : this.customImageUrl)
      if (unprocessedUrl) {
        const url = new URL(unprocessedUrl)
        const scaledSize = this.size * this.resolutionFactor
        url.searchParams.set('nocrop', 'false')
        url.searchParams.set('width', `${scaledSize}`)
        url.searchParams.set('height', `${scaledSize}`)
        return url.toString()
      }
      return this.defaultImageUrl
    },
    showImage(): boolean {
      return !this.anonymous && !!this.finalImageUrl
    }
  }
})
