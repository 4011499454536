





















































































































import { Editor } from '@tiptap/vue-2'
import urlVideoParser from 'js-video-url-parser'
import { CLICK_ADD_VIDEO } from '@src/plugins/analytics/events/ui/SMTipTapToolbar.events'
import { mapGetters } from 'vuex'
import _some from 'lodash/some'
import _includes from 'lodash/includes'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import { defineComponent } from '@vue/composition-api'
import SMDialog from '@src/ui/SMDialog/index.vue'
import SMTipTapToolbarButton from '../SMTipTapToolbarButton.vue'

export default defineComponent({
  name: 'SMTipTapToolbarVideoButton',
  components: {
    SMTipTapToolbarButton,
    SMDialog
  },
  props: {
    editor: {
      type: Object as () => Editor,
      required: true
    }
  },
  data() {
    return {
      videoPath: '',
      embeddedCode: '',
      isOpen: false,
      model: 'tab-1',
      CLICK_ADD_VIDEO
    }
  },
  computed: {
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings | undefined;
    },
    videoUrlRules(): ((value: string) => string | true)[] {
      return [
        (value: string): true | string => !!value || this.$t('tiptap.toolbar.required')?.toString(),
        (value: string): true | string => this.checkIfVideoUrlIsValid(value) || this.$t('tiptap.toolbar.video.invalid-url')?.toString()
      ]
    },
    embeddedCodeRules(): ((value: string) => string | true)[] {
      return [
        (value: string): true | string => !!value || this.$t('tiptap.toolbar.required')?.toString(),
        (): true | string => this.isValidEmbeddedCode || this.$t('tiptap.toolbar.video.invalid-embedded')?.toString()
      ]
    },
    isValidVideoUrl(): boolean {
      return !!this.videoPath && this.checkIfVideoUrlIsValid(this.videoPath)
    },
    isValidEmbeddedCode(): boolean {
      const iframe = this.getIframeFromString(this.embeddedCode)
      if (iframe) {
        const src = this.getSrcFromIframe(iframe)
        if (src) {
          return this.checkIfVideoUrlIsValid(src)
        }
      }
      return false
    }
  },
  watch: {
    isOpen(isOpen: boolean): void {
      if (!isOpen) {
        this.resetEmbededForm()
        this.resetUrlForm()
      }
    }
  },
  methods: {
    resetEmbededForm(): void {
      (this.$refs.embeddedForm as HTMLFormElement)?.reset()
    },
    resetUrlForm(): void {
      (this.$refs.urlForm as HTMLFormElement)?.reset()
    },
    insertVideoUrl(): void {
      if (this.isValidVideoUrl) {
        const parsedVideoUrl = urlVideoParser.parse(this.videoPath)
        if (parsedVideoUrl) {
          const embeddedPath = urlVideoParser.create({
            videoInfo: parsedVideoUrl,
            format: 'embed'
          })
          if (embeddedPath) {
            this.editor.chain().focus().setIframe({ src: embeddedPath }).run()
            this.isOpen = false
            this.videoPath = ''
          }
        }
      }
    },
    insertEmbeddedCode(): void {
      if (this.isValidEmbeddedCode) {
        const iframe = this.getIframeFromString(this.embeddedCode)
        if (iframe) {
          this.editor.chain().focus().insertContent(iframe).run()
          this.isOpen = false
          this.embeddedCode = ''
        }
      }
    },
    checkIfVideoUrlIsValid(str: string): boolean {
      const allowedIframeSources = this.realmSettings?.content.allowed_iframe_sources || []
      return !!str && (!!urlVideoParser.parse(str) || _some(allowedIframeSources, (el) => _includes(str, el)))
    },
    getIframeFromString(str: string): string {
      const regex = /<iframe(.+)<\/iframe>/
      const match = regex.exec(str)
      if (!match || !match.length) {
        return ''
      }
      return match[0]
    },
    getSrcFromIframe(str: string): string {
      const regex = /src\s*=\s*"(.+?)"/gm
      const match = regex.exec(str)
      if (!match || !match.length) {
        return ''
      }
      return match[0]
    }
  }
})
