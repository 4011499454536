export enum OnboardingChecklistItems {
  ADD_TOPICS = 'add_topics',
  TAKE_PRODUCT_TOUR = 'take_product_tour',
  ADD_PROFILE_PICTURE = 'add_profile_picture',
  UNDERSTAND_ANSWERS = 'understand_answers',
  ASK_STARGPT = 'ask_stargpt',
  CUSTOMISE_HOME = 'customise_home'
}
export const checkListTitles = {
  [OnboardingChecklistItems.ADD_TOPICS]: 'onboarding-checklist.add-topics.title',
  [OnboardingChecklistItems.TAKE_PRODUCT_TOUR]: 'onboarding-checklist.take-tour.title',
  [OnboardingChecklistItems.ADD_PROFILE_PICTURE]: 'onboarding-checklist.add-profile-pic.title',
  [OnboardingChecklistItems.UNDERSTAND_ANSWERS]: 'onboarding-checklist.understand-answers.title',
  [OnboardingChecklistItems.ASK_STARGPT]: 'onboarding-checklist.ask-stargpt.title',
  [OnboardingChecklistItems.CUSTOMISE_HOME]: 'onboarding-checklist.customise-home.title'
} as Record<OnboardingChecklistItems, string>
export const checkListBodies = {
  [OnboardingChecklistItems.ADD_TOPICS]: 'onboarding-checklist.add-topics.body',
  [OnboardingChecklistItems.TAKE_PRODUCT_TOUR]: 'onboarding-checklist.take-tour.body',
  [OnboardingChecklistItems.ADD_PROFILE_PICTURE]: 'onboarding-checklist.add-profile-pic.body',
  [OnboardingChecklistItems.UNDERSTAND_ANSWERS]: 'onboarding-checklist.understand-answers.body',
  [OnboardingChecklistItems.ASK_STARGPT]: 'onboarding-checklist.ask-stargpt.body',
  [OnboardingChecklistItems.CUSTOMISE_HOME]: 'onboarding-checklist.customise-home.body'
} as Record<OnboardingChecklistItems, string>
export const checkListButtonLabels = {
  [OnboardingChecklistItems.ADD_TOPICS]: 'onboarding-checklist.add-topics.btn',
  [OnboardingChecklistItems.TAKE_PRODUCT_TOUR]: 'onboarding-checklist.take-tour.btn',
  [OnboardingChecklistItems.ADD_PROFILE_PICTURE]: 'onboarding-checklist.add-profile-pic.btn',
  [OnboardingChecklistItems.UNDERSTAND_ANSWERS]: 'onboarding-checklist.understand-answers.btn',
  [OnboardingChecklistItems.ASK_STARGPT]: 'onboarding-checklist.ask-stargpt.btn',
  [OnboardingChecklistItems.CUSTOMISE_HOME]: 'onboarding-checklist.customise-home.btn'
} as Record<OnboardingChecklistItems, string>

export interface IOnboardingChecklistInfo {
  is_finished: boolean
  user_has_seen: boolean
}

export interface IOnboardingChecklistItem {
  checklist_item: OnboardingChecklistItems,
  completed: boolean
}

export interface IOnboardingChecklist {
  info: IOnboardingChecklistInfo
  checklist_items: IOnboardingChecklistItem[]
}
