

























import { IUser } from '@src/types/user.types'
import { defineComponent, PropType } from '@vue/composition-api'
import { getFullName } from '@src/utilities/userUtils'

export default defineComponent({
  name: 'SMTipTapMentionUserList',
  props: {
    items: {
      type: Array as () => IUser[],
      required: true
    },
    clientRect: {
      type: Function as PropType<() => DOMRect>,
      required: true
    },
    command: {
      type: Function as PropType<(props: any) => void>,
      required: true
    },
    selectedSuggestionIndex: {
      type: Number,
      required: true
    },
    trackUserMention: {
      type: Function as PropType<() => void>,
      required: true
    }
  },
  setup() {
    return {
      getFullName
    }
  },
  computed: {
    position(): { top: string; left: string } {
      const clientRect = this.clientRect()
      return {
        top: `${clientRect.y + 24}px`,
        left: `${clientRect.x}px`
      }
    }
  }
})
