import questions from '@src/store/modules/searchV2/modules/questions'
import experts from '@src/store/modules/searchV2/modules/experts'
import people from '@src/store/modules/searchV2/modules/people'
import { Module } from 'vuex'
import { IRootState } from '@src/types/store.types'

export default {
  namespaced: true,
  modules: {
    questions,
    experts,
    people
  }
} as Module<null, IRootState>
