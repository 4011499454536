import { useI18n } from '@src/plugins/i18n'
import { isUrl } from '@src/utilities/validations'
import { Ref } from '@vue/composition-api'
import { LocaleMessages } from 'vue-i18n'

const PASSWORD_MINLENGTH = 8
type ITranslation = string | true | LocaleMessages;

export function useFormRules() {
  const { t } = useI18n()

  function requiredRule(value: string) {
    return !!value || t('api.error.missing-form-field.msg')
  }

  return {
    requiredRule
  }
}

export function usePasswordValidationRules(password: Ref<string>) {
  const { t } = useI18n()

  function minlengthRule(value: string) {
    return value.length >= PASSWORD_MINLENGTH || t('set-password.error.minlength')
  }

  function charactersRule(value: string) {
    return (/[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value)) || t('set-password.error.characters')
  }

  function matchingPasswordsRule(value: string) {
    return password.value === value || t('api.error.validation.invalid-password-confirm.msg')
  }

  return {
    minlengthRule,
    charactersRule,
    matchingPasswordsRule
  }
}

export function useProfileFormRules() {
  const { t } = useI18n()
  function profileRequiredRule(v: string) {
    return !!v || t('api.error.missing-form-field.msg').toString()
  }

  function profileMaxLengthRule(v: string) {
    return !v || (v && v.length <= 255) || t('settings.personal-info.error.length').toString()
  }

  function isUrlRule(v: string | null) {
    return ((!!v && isUrl(v)) || !v || !v.length) || t('user.social-links.input.validation.link', { platform: 'website' })
  }

  const emailValidation = [
    (v: string): ITranslation => !!v || t('api.error.missing-form-field.msg'),
    (v: string): ITranslation => (!v || (v && v.length <= 255)) || t('settings.personal-info.error.length'),
    (v: string): ITranslation => /.+@.+\..+/.test(v) || t('login.error.email')
  ]

  return {
    emailValidation,
    isUrlRule,
    profileRequiredRule,
    profileMaxLengthRule
  }
}

export function useCareerFormRules() {
  const { t } = useI18n()

  function validYearRule(v?: string) {
    if (!v || !(/^-?\d+$/.test(v)) || parseInt(v, 10) < 1900) {
      return t('user-profile.career.invalid-year.msg').toString()
    }
    return true
  }

  function endDateGreaterThanStart({
    startMonth, endMonth, startYear, endYear
  } : {
    startMonth?: number,
    endMonth?: number,
    startYear?: string,
    endYear?: string
  }) {
    if (startMonth === undefined || endMonth === undefined || !startYear || !endYear) {
      return true
    }
    if (parseInt(startYear, 10) > parseInt(endYear, 10)) {
      return t('user-profile.career.greater-end-year.msg').toString()
    } if (startYear === endYear && startMonth > endMonth) {
      return t('user-profile.career.greater-end-year.msg').toString()
    }
    return true
  }

  return {
    validYearRule,
    endDateGreaterThanStart
  }
}
