









import {
  defineComponent
} from '@vue/composition-api'
import SMSidenavCore from '@src/ui/SMSidenav/SMSidenavCore/index.vue'
import SMAdminMenu from '@src/ui/SMAdminMenu/index.vue'
import { useAngularRouterState } from '../composables/useAngularRouterState'

export default defineComponent({
  name: 'SMSidenavDesktop',
  components: {
    SMSidenavCore,
    SMAdminMenu
  },
  setup() {
    const { isAdminStateActive } = useAngularRouterState()

    return { isAdminStateActive }
  }
})
