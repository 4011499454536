import axios from 'axios'
import { IFeatureUpdate } from '@src/types/featureUpdates'
import { Environment } from '@src/types/realmSettings.types'
import { createOptimizedAPI } from '.'
import { realmSettingsApi } from './apiModuleInstances'

interface IFeatureUpdatesApi {
  getFeatures(userTrackingId: string, offset?: number, languageId?: string): Promise<{data: IFeatureUpdate}>;
  markAsRead(userTrackingId: string, featureIds: string[]): Promise<void>;
}

interface IConfig { isEnabled: boolean; url: string; }

export const WHATS_NEW_LIMIT = 11
export const MAX_UNREAD_COUNT = 10

const config = async (): Promise<IConfig> => {
  const { data: { general: { environment }, whats_new: { enabled, url } } } = await realmSettingsApi.cached.get()
  return {
    isEnabled: enabled && environment !== Environment.ONPREM && !window.Cypress,
    url: `${url}/feature-updates`
  }
}

const featureUpdatesApi: IFeatureUpdatesApi = {
  async getFeatures(userTrackingId, offset = 0, languageId = 'en') {
    const params = {
      userId: userTrackingId,
      limit: WHATS_NEW_LIMIT,
      offset,
      languageId
    }
    const { isEnabled, url } = await config()
    return isEnabled
      ? axios.get(url, { params })
      : { data: { updates: [], lastSeenUser: (new Date()).toISOString() } }
  },
  async markAsRead(userTrackingId, featureIds) {
    const params = {
      userId: userTrackingId,
      featureIds: featureIds.join(',')
    }
    const { isEnabled, url } = await config()
    return isEnabled
      ? axios.post(`${url}/mark-as-read`, null, { params })
      : Promise.resolve()
  }
}

export default {
  ...featureUpdatesApi,
  ...createOptimizedAPI(featureUpdatesApi)
}
