import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'

type IInjectedSetting = { setting: string, value: string }

/*
  For the sake of not injecting whatever param gets to the realm setting base
 */
export function extractRealmSettingsFromURL(queryParams: string) {
  const search = new URLSearchParams(queryParams)
  const realmSettings = search.get('realmSettings')
  const splitSettings = realmSettings?.split(',') || []
  let filteredParams : IInjectedSetting[] = []
  if (splitSettings.length > 0) {
    splitSettings.forEach((param) => {
      const [ key, value ] = param.split('=')
      filteredParams = filteredParams.concat([ { setting: key, value } ])
    })
  }
  return filteredParams
}

export function injectRealmSettings(
  existingSettings: IUnauthenticatedRealmSettings,
  injectedSettings: IInjectedSetting[]
) : IUnauthenticatedRealmSettings {
  const modifiedObject = { ...existingSettings } as Record<string, any>
  injectedSettings.forEach((setting) => {
    const splitSetting = setting.setting.split('.')
    let currentObjectRef = modifiedObject
    for (let i = 0; i < splitSetting?.length; i += 1) {
      if (i === (splitSetting.length - 1)) {
        // Leaf field: modify the value if reference is not an object
        if (currentObjectRef[splitSetting[i]] !== undefined && !Object.keys(currentObjectRef[splitSetting[i]]).length) {
          currentObjectRef[splitSetting[i]] = convertedValue(setting.value)
        }
      } else if (currentObjectRef[splitSetting[i]]) {
        // Not a leaf field, access the reference and iterate
        currentObjectRef = currentObjectRef[splitSetting[i]]
      }
    }
  })
  return modifiedObject as IUnauthenticatedRealmSettings
}

function convertedValue(value: any) {
  if (value === 'true' || value === 'false') {
    return value === 'true'
  }
  if (!Number.isNaN(Number(value))) {
    return parseInt(value, 10)
  }
  return value
}
