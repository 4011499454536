














































import { PropType, computed, defineComponent } from '@vue/composition-api'
import useCategories from '@src/composables/categories'
import i18n from '@src/plugins/i18n'
import { IFormError } from '../SMAsk/composables/validation'

export default defineComponent({
  name: 'SMCategorySelector',
  props: {
    value: {
      type: Number,
      default: undefined
    },
    allowNoCategory: {
      type: Boolean,
      default: false
    },
    error: {
      type: Object as PropType<IFormError | undefined>,
      default: undefined
    }
  },
  setup(props) {
    const { categoriesTranslated } = useCategories()
    const availableCategories = computed(() => {
      if (props.allowNoCategory) {
        return [ {
          id: null,
          name: i18n.t('question.category.select.empty'),
          color: '#EEEEEE',
          translations: []
        }, ...categoriesTranslated.value ]
      }
      return categoriesTranslated.value
    })
    const errorMessages = computed(() => (props.error?.translatedError ? [ props.error?.translatedError ] : undefined))

    return {
      availableCategories,
      errorMessages
    }
  }
})
