/**
 * All the cookies we deal with in pegasus / legacy stack.
 */
export enum CookieName {

  /**
   * The user JWT access token as received by Keycloak Idp.
   */
  API_TOKEN = 'apiToken',
  /**
   * Represents the apiToken as MD5 hash. Sends a mixpanel event each time the apiToken has changed.
   * @see auth-run.js:12
   */
  AUTH_TRACKING = 'authTracking',
  /**
   * The user ID token as received by Keycloak Idp.
   */
  ID_TOKEN = 'idToken',
  /**
   * Helper cookie to detect if the client is in a redirect loop to act accordingly
   */
  INFINITE_LOOP_PROTECTION = 'infinite_loop_protection',
  /**
   * Preserves the requested target url in case the user has been logged out and redirects the user to this url after the login flow
   */
  SM_REDIRECT = 'sm_redirect',
  /**
   * Preserves the provided logout redirect url in case it was provided and running in iframe or webview authenticated context.
   */
  SM_LOGOUT_REDIRECT = 'sm_logout_redirect',
  /**
   * Preserves the provide client login url in case it was provided and running in iframe or webview authenticated context.
   */
  SM_CLIENT_LOGIN = 'sm_client_login',

  /**
   * Remembers the user's language preference, in order to speed up loading of the translations
   */
  LANGUAGE = 'sm_lang'
}
/**
 * Possible Cookie SameSite attributes.
 */
export type SameSiteAttribute = 'Strict' | 'Lax' | 'None'

/**
 * Evaluates the Cookie SameSite attribute based on the provided input.
 *
 * @param cookieSameSiteNone the value received from the realm settings
 * @return 'None' if `cookieSameSiteNone` is set to true otherwise 'Strict'
 */
export function resolveCookieSameSite(cookieSameSiteNone: boolean): SameSiteAttribute {
  return cookieSameSiteNone ? 'None' : 'Strict'
}

export function getCookie(name: CookieName): null|string {
  let c_value: null | string = ` ${document.cookie}`
  let c_start = c_value.indexOf(` ${name}=`)
  if (c_start === -1) {
    c_value = null
  } else {
    c_start = c_value.indexOf('=', c_start) + 1
    let c_end = c_value.indexOf(';', c_start)
    if (c_end === -1) {
      c_end = c_value.length
    }
    c_value = unescape(c_value.substring(c_start, c_end))
  }
  return c_value
}

export function removeCookie(name: CookieName, sameSite = 'Strict' as SameSiteAttribute): void {
  setCookie({
    name,
    value: '',
    expiration: new Date('Thu, 01 Jan 1970 00:00:00 GMT'),
    sameSite
  })

  // We also need to make sure that any potential unpartitioned cookie that might be in a user session is properly cleaned up
  // - For the migration to partitioned cookies
  // - For the case when the realm setting 'cookie_same_site_none' changes
  setCookie({
    name,
    value: '',
    expiration: new Date('Thu, 01 Jan 1970 00:00:00 GMT'),
    sameSite,
    unpartitioned: true
  })
}

export function getApiTokenAsPromise(): Promise<string> {
  return new Promise((resolve, reject) => {
    function getApiTokenCookie(counter: number): void {
      if (getCookie(CookieName.API_TOKEN) && getCookie(CookieName.API_TOKEN) !== 'path=/') {
        resolve(getCookie(CookieName.API_TOKEN) as string)
      } else if (counter < 7000) {
        setTimeout(() => getApiTokenCookie(counter + 200), 200)
      } else {
        reject()
      }
    }
    getApiTokenCookie(0)
  })
}

export function setCookie({
  name,
  value,
  expiration,
  sameSite = 'Strict' as SameSiteAttribute,
  unpartitioned = false
}: {
  name: CookieName,
  value: string,
  expiration?: Date,
  sameSite?: SameSiteAttribute,
  unpartitioned?: boolean
}): void {
  document.cookie = `${name}=${value}; path=/; Secure; SameSite=${sameSite};${(sameSite === 'None' && !unpartitioned) ? ' Partitioned;' : ''} ${expiration ? `expires=${expiration.toUTCString()}` : ''}`.trim()
}
