import { AxiosPromise } from 'axios'
import { IMetricsResponse } from '@src/types/metric.types'
import createAxiosInstance from '.'

const api = createAxiosInstance('vsm')

export default {
  getMetrics(): AxiosPromise<IMetricsResponse> {
    return api.get('metrics')
  }
}
