











import { defineComponent, toRefs } from '@vue/composition-api'
import useHighlightWords from './composables/useHighlightWords'

export default defineComponent({
  name: 'SMHighlightWords',
  props: {
    text: {
      type: String,
      required: true
    },
    query: {
      type: String,
      required: true
    },
    highlightNonMatches: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const { text, query, highlightNonMatches } = toRefs(props)
    const { chunks } = useHighlightWords(text, query)

    function shouldHighlight(chunkMatch: boolean) {
      return (!highlightNonMatches.value && chunkMatch) || (highlightNonMatches.value && !chunkMatch)
    }

    return {
      chunks,
      shouldHighlight
    }
  }
})
