import { ITrackingProps } from '@src/types/events.types'

const commonProps = {
  component: 'menu',
  element: 'menu_item',
  type: 'click'
} as ITrackingProps

export const CLICK_IN_HELP_MENU = 'clickInHelpMenu'

export default {
  SMUserGuideSidenavItem: {
    [CLICK_IN_HELP_MENU]: (
      customProps:
      {
        menu_item_id: number;
        label: string;
      }
    ): ITrackingProps => ({
      ...commonProps,
      ...customProps
    })
  }
}
