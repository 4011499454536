import {
  MutationTree, ActionTree, Module, GetterTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { ISnackbar } from '@src/types/snackbar.types'
import _first from 'lodash/first'

export interface ISnackbarState {
  snackbarQueue: ISnackbar[];
}

const SNACKBAR_MAX_VISIBILITY_DURATION = 5000
const SNACKBAR_MIN_VISIBILITY_DURATION = 1000
const timeoutRefs: ReturnType<typeof setTimeout>[] = [] // this is kept outside of serializable vuex state

const state: ISnackbarState = {
  snackbarQueue: []
}

const actions: ActionTree<ISnackbarState, IRootState> = {
  show({ commit }, snackbar: string | Partial<ISnackbar>) {
    const newSnackbar = typeof snackbar === 'string' ? {
      translationKey: snackbar
    } : snackbar
    commit('pushSnackbar', newSnackbar)

    const hideLatestSnackbar = (): void => {
      commit('hideLatestSnackbar')
      timeoutRefs.shift()
    }
    // If there is more than one snackbar, show each only for one second
    // Clear the timeout for each snackbar in the queue and set a new timeout
    // The first snackbar to 1000, the second to 2000, the third to 3000, etc.
    timeoutRefs.forEach((ref, index) => {
      clearTimeout(ref)
      timeoutRefs[index] = setTimeout(hideLatestSnackbar, SNACKBAR_MIN_VISIBILITY_DURATION * (index + 1))
    })
    // The timeout for the most recent snackbar is 5000 + 1000 for each of the previous snackbars
    timeoutRefs.push(setTimeout(hideLatestSnackbar, SNACKBAR_MIN_VISIBILITY_DURATION * timeoutRefs.length + SNACKBAR_MAX_VISIBILITY_DURATION))
  }
}

const mutations: MutationTree<ISnackbarState> = {
  pushSnackbar(state, snackbar: ISnackbar) {
    state.snackbarQueue = state.snackbarQueue.concat(snackbar)
  },
  hideLatestSnackbar(state) {
    state.snackbarQueue = state.snackbarQueue.slice(1)
  }
}

const getters: GetterTree<ISnackbarState, IRootState> = {
  isSnackbarVisible(state): boolean {
    return !!_first(state.snackbarQueue)
  },
  getSnackbar(state): ISnackbar | undefined {
    return _first(state.snackbarQueue)
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
} as Module<ISnackbarState, IRootState>
