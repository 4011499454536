import { Ref, computed } from '@vue/composition-api'
import { computedAsync } from '@vueuse/core'
import userApi from '@src/api/user'
import { getFullName, isMine } from '@src/utilities/userUtils'
import { IGlobalUser } from '@src/types/user.types'
import useUser from './user'

/**
 * Always pass the user object instead of the user id if it's available. That way we can avoid to do requests for a list in a loop
 */
export function useUserInfo(user: Ref<IGlobalUser | number | undefined>, cached = true) {
  const { user: currentUser } = useUser()

  const resolvedUser = computedAsync(async () => {
    if (typeof user.value === 'number') {
      const { data } = await (cached ? userApi.cached : userApi).getUser(user.value)
      return data
    }
    if (typeof user.value === 'object') {
      return user.value
    }
    return undefined
  }, undefined)

  const fullName = computed(() => getFullName(resolvedUser.value?.firstname, resolvedUser.value?.lastname))
  const isCurrentUser = computed(() => isMine(currentUser.value, (resolvedUser.value as IGlobalUser)?.globalUserId || resolvedUser.value?.global_user_id))

  return { user: resolvedUser, fullName, isCurrentUser }
}
