





























import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'QuestionActionBarButton',
  props: {
    tooltipText: {
      type: String,
      required: true
    },
    labelText: {
      type: String,
      required: true
    },
    buttonIcon: {
      type: String,
      required: true
    }
  }
})
