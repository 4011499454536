



































import Vue, { VueConstructor } from 'vue'
import {
  Editor, EditorContent, VueNodeViewRenderer, Mark, Extension, Node
} from '@tiptap/vue-2'
import { ITipTapPlugins } from '@src/types/tiptap.types'
import StarterKit from '@tiptap/starter-kit'
import Placeholder from '@tiptap/extension-placeholder'
import sanitizeHtml from 'sanitize-html'
import { tiptapHtmlToHtml, tiptapHtmlToPlaceholders, placeholdersToTiptapHtml } from '@src/utilities/filters/mention'
import { MENTION_USER } from '@src/plugins/analytics/events/views/mentionUser.events'
import { getEntity, wrapIframeInDiv } from '@src/utilities/tipTap'
import underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
import Highlight from '@tiptap/extension-highlight'
import Typography from '@tiptap/extension-typography'
import Link from '@tiptap/extension-link'
import { mapActions, mapGetters } from 'vuex'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
// eslint-disable-next-line import/no-extraneous-dependencies
import { EditorView } from 'prosemirror-view'
import attachmentsApi from '@src/api/attachments'
import { AxiosPromise } from 'axios'
import { IAttachment, IInlineAttachment } from '@src/types/attachments.types'
import { questionData } from '@src/views/Question/questionProviderKeys'
import { PropType } from '@vue/composition-api'
import { IKnowledgeSpace } from '@src/types/knowledgeSpaces.types'
import SMTipTapToolbar from './components/toolbar/SMTipTapToolbar.vue'
import MentionUserList from './components/mention/MentionUserList.vue'
import createConfiguredMentionExtension, { IMentionData } from './components/mention'
import { EditorExtensionsType } from './components/toolbar/toolbarExtensions'
import tipTapEditorMixin from './mixins/tipTapEditorMixin'
import sanitizeHtmlDefaultRules from './constants/sanitizeRules'

import lowlight from './components/CodeBlock/lowlight'
import CodeBlockComponent from './components/CodeBlock/index.vue'
import Iframe from './components/toolbar/components/SMTipTapToolbarVideoButton/tipTapVideoIframe'

type LocalData = {
  editor: Editor | null;
} & IMentionData

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof tipTapEditorMixin>
>).extend({
  name: 'SMTipTap',
  components: {
    EditorContent,
    SMTipTapToolbar,
    MentionUserList
  },
  mixins: [ tipTapEditorMixin ],
  inject: {
    question: { from: questionData as any, default: undefined }
  },
  props: {
    extensions: {
      type: Array as () => ITipTapPlugins[],
      default: (): ITipTapPlugins[] => [],
      required: true
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    knowledgeSpace: {
      type: Object as PropType<IKnowledgeSpace | null | undefined>,
      default: undefined
    }
  },
  data(): LocalData {
    return {
      editor: null,
      isMentioning: false,
      suggestionProps: {
        items: [],
        clientRect: null,
        command: (): void => {} // eslint-disable-line,
      },
      selectedSuggestionIndex: 0
    }
  },
  computed: {
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings;
    },
    isMentionEnabled(): boolean {
      return this.extensions.includes(ITipTapPlugins.mention)
    },
    isToolbarActive(): boolean {
      return !!this.editor && this.shouldShowToolbar && !this.isReadOnly
    },
    sanitizedValue(): string {
      return sanitizeHtml(this.value, sanitizeHtmlDefaultRules)
    },
    isImageUploadEnabled(): boolean {
      return !!this?.realmSettings?.content?.attachment_upload_enabled && this.extensions.includes(ITipTapPlugins.image)
    }
  },
  watch: {
    sanitizedValue(sanitizedValue): void {
      const htmlValue = placeholdersToTiptapHtml(sanitizedValue)
      const isSame = this.editor?.getHTML() === htmlValue
      if (isSame) {
        return
      }

      // Remember the cursor position before we're doing potential transformations to htmlValue
      const { state: { selection } } = this.editor!
      // eslint-disable-next-line no-unused-expressions
      this.editor?.commands.setContent(this.wrapIframeInDiv(htmlValue), false, { preserveWhitespace: true })

      // In case major transformations of the content have been applied (due to sanitizing, wrapping iframes, etc.), restore the selection
      this.editor?.commands.setTextSelection(selection)
    },
    isFocused(focused: boolean): void {
      if (focused && this.editor) {
        this.editor.commands.focus()
      }
    }
  },
  async mounted() {
    const extensions = await this.getExtensions()
    const placeholdersFN = this.isReadOnly ? tiptapHtmlToHtml : placeholdersToTiptapHtml
    this.editor = new Editor({
      content: placeholdersFN(this.wrapIframeInDiv(this.sanitizedValue)),
      extensions,
      editorProps: {
        attributes: {
          class: 'sm-tiptap',
          'data-cy': 'tiptap'
        },
        handleDOMEvents: {
          paste: this.isImageUploadEnabled ? this.pasteOrDropImages : undefined as any,
          drop: this.isImageUploadEnabled ? this.pasteOrDropImages : undefined as any
        }
      },
      editable: !this.isReadOnly,
      onUpdate: (): void => {
        const html = this.editor?.getHTML()
        this.$emit('input', tiptapHtmlToPlaceholders(html))
      },
      onFocus: (): void => {
        this.$emit('focus')
      },
      onBlur: (): void => {
        this.$emit('blur')
      }
    })
    this.$emit('mounted')
  },
  beforeDestroy() {
    // eslint-disable-next-line no-unused-expressions
    this.editor?.destroy()
  },
  methods: {
    ...mapActions('snackbar', { showSnackbar: 'show' }) as {
      showSnackbar(message: string): void;
    },
    wrapIframeInDiv,
    async pasteOrDropImages(view: EditorView, event: ClipboardEvent|DragEvent): Promise<boolean> {
      let hasFiles = false
      let files
      if (event.type === 'paste') {
        files = (event as ClipboardEvent)?.clipboardData?.files
      }
      if (event.type === 'drop') {
        files = (event as DragEvent)?.dataTransfer?.files
      }
      if (files && files.length) {
        const results: AxiosPromise<IInlineAttachment | IAttachment>[] = []
        Array.from(files).filter((item) => item.type.startsWith('image')).forEach((file) => {
          if (this.entityId) {
            results.push(attachmentsApi.uploadEntityAttachment(this.entityType === 'solution' ? 'solutions' : 'questions', this.entityId, file))
          } else if (this.entityType === 'adminGeneric') {
            results.push(attachmentsApi.uploadInlineAttachment(file))
          }
        })
        if (results.length) {
          event.preventDefault()
          hasFiles = true
          try {
            const uploadedImgs = await Promise.all(results)
            uploadedImgs.forEach((uploadedImg) => {
              if (uploadedImg.data.link) {
                const node = view.state.schema.nodes.image.create({ src: uploadedImg.data.link })
                const transaction = view.state.tr.replaceSelectionWith(node)
                view.dispatch(transaction)
              }
            })
          } catch {
            // do nothing
          }
        } else {
          this.showSnackbar('editor.image.upload.error')
        }
      }
      return hasFiles
    },
    trackUserMention(): void {
      this.$trackEvent(MENTION_USER, 'mentionUser', {
        type: this.extensionType === 'QUESTION_EXTENSIONS' ? 'dialog' : 'inline', // TODO use another property for that, not 'type'
        entity: getEntity(this.extensionType as EditorExtensionsType),
        state: this.extensionType === 'QUESTION_EXTENSIONS' ? 'ask' : 'question'
      })
    },
    async getExtensions(): Promise<(Mark<unknown> | Node<unknown> | Extension<unknown>)[]> {
      const vm = this
      const extensions = []
      extensions.push(...[
        StarterKit.configure({ // undefined means on by default
          bold: this.extensions.includes(ITipTapPlugins.bold) || this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          blockquote: this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          bulletList: this.extensions.includes(ITipTapPlugins.bulletList) || this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          code: this.extensions.includes(ITipTapPlugins.codeHighlight) ? undefined : false,
          codeBlock: this.extensions.includes(ITipTapPlugins.codeHighlight) ? undefined : false,
          document: undefined, // is needed by default as the top node of tiptap
          dropcursor: this.extensions.includes(ITipTapPlugins.image) || this.extensions.includes(ITipTapPlugins.video) ? undefined : false, // used to drag images/videos etc up and down should be on by default
          gapcursor: this.extensions.includes(ITipTapPlugins.image) || this.extensions.includes(ITipTapPlugins.video) ? undefined : false, // adds a gap betwen images/video or when you navigate with arrows
          hardBreak: this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          heading: this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          history: undefined, // undo and redo should always be possible using the keyboard shortcuts
          horizontalRule: this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          italic: this.extensions.includes(ITipTapPlugins.italic) || this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          listItem: this.extensions.includes(ITipTapPlugins.bulletList) || this.extensions.includes(ITipTapPlugins.orderedList) || this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false, // for bullet lists and ordered lists
          orderedList: this.extensions.includes(ITipTapPlugins.orderedList) || this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          paragraph: undefined, // needed to write paragraph of texts, should be on by default
          strike: this.extensions.includes(ITipTapPlugins.strike) || this.extensions.includes(ITipTapPlugins.markdown) ? undefined : false,
          text: undefined // in order to render any text, should be on by default
        }),
        Placeholder.configure({ // the textarea placeholder text
          placeholder: this.placeholder
        })
      ])
      if (this.extensions.includes(ITipTapPlugins.underline)) {
        extensions.push(underline)
      }

      if (this.isImageUploadEnabled) {
        extensions.push(
          Image.extend({
            addAttributes() {
              const attributes = {
                ...this.parent?.(),
                style: {
                  default: 'max-width: 500px;max-height: 500px;'
                }
              }
              return attributes
            }
          }).configure({ inline: true, allowBase64: true }),
        )
      }
      if (this.extensions.includes(ITipTapPlugins.codeHighlight)) {
        extensions.push(CodeBlockLowlight
          .extend({
            addNodeView() {
              return VueNodeViewRenderer(CodeBlockComponent)
            }
          })
          .configure({ lowlight }),)
      }
      if (this.extensions.includes(ITipTapPlugins.codeHighlight) || this.extensions.includes(ITipTapPlugins.markdown)) {
        extensions.push(Highlight)
      }

      if (this.extensions.includes(ITipTapPlugins.typography)) {
        extensions.push(Typography)
      }

      if (this.extensions.includes(ITipTapPlugins.link)) {
        extensions.push(Link.configure({
          HTMLAttributes: {
            target: '_blank',
            rel: ''
          }
        }))
      }
      if (this.extensions.includes(ITipTapPlugins.video)) {
        extensions.push(Iframe)
      }

      if (this.isMentionEnabled) {
        extensions.push(createConfiguredMentionExtension(vm))
      }

      return extensions
    }
  }
})
