import { InterfaceLanguage } from './language.types'
import { IGraphqlUserSchema, IUser } from './user.types'

export enum PricingTier {
  STANDARD = 'standard',
  PREMIUM = 'premium',
  ENTERPRISE = 'enterprise'
}

export enum Environment {
  PROD = 'prod',
  DEV = 'dev',
  ONPREM = 'onprem',
}

export enum RelevantUserActivityAutoKpi {
  UNIQUE_ACTIVE_USERS_PERIOD = 'unique_active_users_period',
  UNIQUE_LOGGED_IN_USERS_PERIOD = 'unique_logged_in_users_period'
}

export enum ClobbopusTier {
  STANDARD = 'standard',
  ADVANCED = 'advanced'
}

export interface IGeneralRealmSettings {
  default_language: InterfaceLanguage;
  uses_formal_language: boolean;
  upload_file_types_blacklist: string[];
  default_allowed_upload_file_types: string[];
  allowed_upload_file_types: string[];
  allowed_image_file_types: string[];
  languages_in_pricing_plan: string[];
  environment: Environment;
}

export interface IMetadataRealmSettings {
  company_name: string;
  realm_name: string;
  go_live_date: string | null;
  creation_date: string;
  is_trial: boolean;
  pricing_tier: PricingTier;
  realm_id: string;
}

interface IAuthRealmSettings {
  password_auth_enabled: boolean;
  keycloak_url: string;
  cookie_same_site_none: boolean;
}

export interface IPlainBooleanRealmSetting {
  enabled: boolean
}

export type IContextMessageRealmSettings = IPlainBooleanRealmSetting

export interface ITermsOfUseRealmSettings {
  enabled: boolean;
  checkbox_enabled: boolean;
  in_pricing_plan: boolean;
}

export interface IAdminEmailMailRealmSettings extends IEmailRealmSettings {
  mail_sender_name: string;
  feedback_email: string;
  client_transport: string;
}

export interface IEmailRealmSettings {
  email_delivery_enabled: boolean;
  technical_support_email: string;
}

export interface INotificationRealmSettings {
  email_notifications_enabled: boolean;
  mobile_push_notifications_enabled: boolean;
  browser_push_notifications_enabled: boolean;
  one_signal_browser_app_id: string;
  one_signal_browser_safari_web_id: string;
}

export interface IUserAdminRealmSettings {
  allow_inactive_user_interaction: boolean;
  email_change_allowed: boolean;
  extended_user_properties_change_allowed: boolean;
  invitation_email_allowed_domains: string[];
  invitation_enabled: boolean;
  research_participation_enabled: boolean;
}

export interface IUserFollowRealmSettings {
  user_follow_enabled: boolean;
}

export interface IUserProfileRealmSettings {
  anonymize_deleted_users: boolean;
  anonymize_users_email: boolean;
  appendix_user_info_clip: string[];
  user_activity_visible_by_everyone: boolean;
  user_knowhow_map_visible_by_everyone: boolean;
  quantified_expertise_visibility: boolean;
}

export interface IAdminTranslationRealmSettings {
  translation_provider: string;
  translations_rolled_out: boolean;
}

export interface ITranslationRealmSettings extends IAdminTranslationRealmSettings {
  automatic_translation_languages: string[]
  automatic_translations_enabled: boolean;
  automatic_translations_in_pricing_plan: boolean;
  available_translation_languages: InterfaceLanguage[];
  available_translation_providers: string[];
  cross_lingual_search_enabled: boolean;
  cross_lingual_search_in_pricing_plan: boolean;
  default_translation_provider: string;
  manual_translation_languages_in_pricing_plan: string[];
}

export interface IWhitelabellingRealmSettings {
  custom_js?: {
    body_url?: string;
    head_url?: string;
  } | null;
  offset?: {
    desktop?: string;
    mobile?: string;
  } | null;
  primary?: string;
  accent?: string;
}

export interface IUnauthenticatedRealmSettings {
  general: IGeneralRealmSettings;
  metadata: IMetadataRealmSettings;
  auth: IAuthRealmSettings;
  context_message: IContextMessageRealmSettings;
  terms_of_use: ITermsOfUseRealmSettings;
  user_admin: IUserAdminRealmSettings;
  user_follow: IUserFollowRealmSettings;
  user_profile: IUserProfileRealmSettings;
  translation: ITranslationRealmSettings;
  white_labelling: IWhitelabellingRealmSettings;
  email: IEmailRealmSettings;
  notification: INotificationRealmSettings;
  gamification: IGamificationSettings;
  content: IContentSettings;
  question: IQuestionSettings;
  expert_search: IExpertSearchSettings;
  solution: ISolutionSettings;
  categories: ICategoriesRealmSettings;
  solution_authority: ISolutionAuthorityRealmSettings;
  knowledge_spaces: IKnowledgeSpacesRealmSettings & IKnowledgeSpacesReadOnlyRealmSettings;
  integrations: IIntegrationsRealmSettings;
  user_tracking: IUserTrackingRealmSettings;
  canny: ICannyRealmSettings;
  solution_validation: ISolutionValidationRealmSettings;
  nps: INPSRealmSettings;
  solution_value: ISolutionValueRealmSettings;
  company_guidelines: ICompanyGuidelinesRealmSettings;
  homepage: IHomepageRealmSettings;
  workspaces: IWorkspacesRealmSettings;
  question_flagging: IFlaggingRealmSettings;
  question_escalation: IQuestionEscalationRealmSettings;
  whats_new: IWhatsNewRealmSettings;
  kpi: IKpiRealmSettings;
  vsm: IVsmSettings;
}

export interface IGamificationSettings {
  badges_enabled: boolean;
  user_badges_visible_by_everyone: boolean;
  user_score_enabled: boolean;
  user_score_frontend_enabled: boolean;
  user_score_max_level: number;
  user_score_start_date: null | string;
}

export interface IContentSettings {
  attachment_upload_enabled: boolean;
  allowed_iframe_sources: string[];
}

export interface IQuestionSettings {
  delete_solved_questions: boolean;
  mandatory_question_description: boolean;
  question_title_max_length: number;
}

export interface IExpertSearchSettings {
  show_expert_search: boolean;
  show_expert_users_enabled: boolean;
  people_search_enabled: boolean;
}

export interface IAdminExpertSearchSettings extends IExpertSearchSettings {
  expert_search_max_experts_per_question: number;
  expert_search_first_question_multiple: number;
}

export interface ISolutionSettings {
  show_average_rating_to_user: boolean;
  mark_outdated_enabled: boolean;
}

export interface ICategoriesRealmSettings {
  categories_enabled: boolean;
  categories_in_pricing_plan: boolean;
}

export interface ISolutionAuthorityRealmSettings {
  solution_authority_enabled: boolean;
  solution_authority_in_pricing_plan: boolean;
}

export interface IKnowledgeSpacesRealmSettings {
  knowledge_spaces_enabled: boolean;
}

export interface IKnowledgeSpacesReadOnlyRealmSettings {
  knowledge_spaces_in_pricing_plan: boolean;
  question_knowledge_space_mandatory: boolean;
}

export interface IIntegrationsRealmSettings {
  microsoft: IMicrosoftIntegrationRealmSettings;
}

export interface IMicrosoftIntegrationRealmSettings {
  ms_teams_contact_button_enabled: boolean;
}

export interface IUserTrackingRealmSettings {
  enabled: boolean;
  mixpanel_analytics_token: string;
  tracking_salt: string;
}

export type ICannyRealmSettings = IPlainBooleanRealmSetting
export type INPSRealmSettings = IPlainBooleanRealmSetting
export type ICompanyGuidelinesRealmSettings = IPlainBooleanRealmSetting
export type IHomepageRealmSettings = IPlainBooleanRealmSetting
export type IFlaggingRealmSettings = IPlainBooleanRealmSetting
export type IQuestionEscalationRealmSettings = IPlainBooleanRealmSetting

export enum ValidationPeriodId {
  DAYS_ONE = 'days_one',
  WEEKS_ONE = 'weeks_one',
  MONTHS_ONE = 'months_one',
  MONTHS_THREE = 'months_three',
  MONTHS_SIX = 'months_six',
  YEARS_ONE = 'years_one',
  YEARS_TWO = 'years_two',
  OPEN = 'open'
}
export interface ISolutionValidationRealmSettings {
  enabled: boolean;
  default_period: ValidationPeriodId
}

export interface IAdminNotificationRealmSettings {
  email_notifications_enabled: boolean;
  browser_push_notifications_enabled: boolean;
}

export interface IRssFeedRealmSettings {
  token: string;
}

export interface IWebhookRealmSettings {
  enabled: boolean;
  secret: string | null;
  url: string;
}

export interface ISolutionValueRealmSettings {
  enabled: boolean;
}

export interface IOpenSearchRealmSettings {
  contact: string | null;
  description: string;
  short_name: string;
  tags: string[];
  token: string
}

export interface IWorkspacesRealmSettings {
  enabled: boolean;
  listed_workspaces: {
    domain: string;
  }[];
}

export interface IAdminQuestionEscalationRealmSettings {
  enabled: boolean;
  first_only: boolean;
  ignore_comments: boolean;
  start_date: string | null;
  tolerance: number;
  user_ids: string[]; // list of global user id GUID
}

export interface IQuestionEscalationRealmSettingsForm extends Omit<IAdminQuestionEscalationRealmSettings, 'user_ids'> {
  users: IUser[] | Partial<IGraphqlUserSchema>[]
}

export interface IAdminFlaggingRealmSettings {
  enabled: boolean;
  user_ids: string[]; // list of global user id GUID
}

export interface IFlaggingRealmSettingsForm extends Omit<IAdminFlaggingRealmSettings, 'user_ids'> {
  users: IUser[] | Partial<IGraphqlUserSchema>[]
}

export interface IKpiRealmSettings {
  expert_statistics_enabled: boolean;
  relevant_user_activity_auto_kpis: RelevantUserActivityAutoKpi;
  clobbopus_tier: ClobbopusTier;
}

export interface IVsmSettings {
  vsm_enabled: boolean
}

export interface IWhatsNewRealmSettings {
  enabled: boolean;
  url: string;
}
export interface IVsmMetricsTargetsResponse {
  categories?: number;
  invited_users?: number;
}

export type RealmSettingsScope = keyof IUnauthenticatedRealmSettings
