import { INotificationItem, NotificationType } from '@src/types/notifications.types'
import { INotificationV2User, IUser } from '@src/types/user.types'

type INotificationUser = Pick<IUser, 'firstname' | 'lastname' | 'image' | 'global_user_id'>

export const notificationIcons: Partial<Record<NotificationType, string>> = {
  [NotificationType.NewQuestion]: 'ico-sm-avatar-question.png',
  [NotificationType.NewUserScoreLevelReached]: 'ico-sm-avatar-level-up.png',
  [NotificationType.SolutionRated]: 'ico-sm-avatar-rating.png',
  [NotificationType.SolutionFromComment]: 'ico-sm-avatar-solution.png'
}

function isV2Notification(notification: INotificationItem<IUser | INotificationV2User>) {
  const notificationV2Types = [ NotificationType.NewKudos ]
  return notificationV2Types.includes(notification.type)
}

export function isHomepageRelevantType(notification: INotificationItem<IUser | INotificationV2User>) {
  const relevantNotificationTypes = [
    NotificationType.NewSolutionToYourQuestion,
    NotificationType.SolutionRatedByQuestionPoser,
    NotificationType.SolutionRated,
    NotificationType.NewQuestion,
    NotificationType.NewQuestionNewUser,
    NotificationType.ShareQuestion,
    NotificationType.ShareSolution,
    NotificationType.MentionInComment,
    NotificationType.MentionInSolution,
    NotificationType.MentionInQuestion,
    NotificationType.QuestionForwarded,
    NotificationType.QuestionForwardedInactive,
    NotificationType.QuestionForwardedFollowup,
    NotificationType.NewCommentToYourSolution,
    NotificationType.FollowNewUserFollowsMe
  ]
  return relevantNotificationTypes.includes(notification.type)
}

export function isHomepageRelevantTypeFollow(notification: INotificationItem<IUser | INotificationV2User>) {
  const relevantNotificationTypes = [
    NotificationType.FollowNewSolutionFromSubject
  ]
  return relevantNotificationTypes.includes(notification.type)
}

export function getNotificationUserObject(notification: INotificationItem<IUser | INotificationV2User>): INotificationUser | null {
  if (isV2Notification(notification)) {
    const { user } = (notification as INotificationItem<INotificationV2User>)
    return user ? {
      firstname: user.first_name,
      lastname: user.last_name,
      image: user.avatar,
      global_user_id: user.global_user_id
    } : null
  }
  const { user } = (notification as INotificationItem<IUser>)
  return user ? {
    firstname: user.firstname,
    lastname: user.lastname,
    image: user.image,
    global_user_id: user.global_user_id
  } : null
}
