import {
  computed,
  ref, watch, Ref, readonly
} from '@vue/composition-api'
import debounce from 'lodash/fp/debounce'
import questionsApi from '@src/api/questions'
import useRealmSettings from '@src/composables/realmSettings'
import { ISimilarQuestion } from '@src/types/question.types'
import { SIMILAR_QUESTIONS_DEBOUNCE_TIME } from '../constants'

const VERY_SIMILAR_QUESTION_SCORE_THRESHOLD = 2

export default function useSimilarQuestions(query: Ref<string>) {
  const { realmSettings } = useRealmSettings()
  const similarQuestions = ref<ISimilarQuestion[]>([])
  const totalSimilarQuestions = ref(0)

  const getSimilarQuestions = debounce(SIMILAR_QUESTIONS_DEBOUNCE_TIME)(async (value: string) => {
    const { data: { items, total } } = await questionsApi.getQuestions({ query: value, limit: 5, include_archived: true })

    similarQuestions.value = items.map((question) => {
      return {
        ...question,
        title: (realmSettings.value?.translation.automatic_translations_enabled && question.translations?.length && question.translations[0]?.title) ? question.translations[0].title : question.title,
        isVerySimilar: (question.search_metadata?.score ?? 0) >= VERY_SIMILAR_QUESTION_SCORE_THRESHOLD,
        isIdentical: value.toLowerCase() === question.title.toLowerCase()
      } as ISimilarQuestion
    })
    totalSimilarQuestions.value = total
  })

  watch(query, getSimilarQuestions)

  const hasSimilarQuestions = computed(() => !!similarQuestions.value.length)
  const verySimilarQuestion = computed(() => similarQuestions.value.find((question) => question.isVerySimilar))
  const hasVerySimilarQuestion = computed(() => !!verySimilarQuestion.value)
  const identicalQuestion = computed(() => similarQuestions.value.find((question) => question.isIdentical))

  return {
    similarQuestions: readonly(similarQuestions),
    totalSimilarQuestions: readonly(totalSimilarQuestions),
    hasSimilarQuestions,
    identicalQuestion,
    verySimilarQuestion,
    hasVerySimilarQuestion
  }
}
