


































































import Vue from 'vue'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import imageUrl from '@src/assets/img/sm-online.svg'
import SMLanguageSelection from '@src/ui/SMLanguageSelection/index.vue'
import { COMPLETE_ONBOARDING_WIZARD } from '@src/plugins/analytics/events/ui/SMActivationFlow.events'
import SMDialog from '@src/ui/SMDialog/index.vue'
import { IUnauthenticatedRealmSettings } from '@src/types/realmSettings.types'
import { IUser } from '@src/types/user.types'

const { mapState, mapActions } = createNamespacedHelpers('activationFlow/languageSelection')

export default Vue.extend({
  name: 'SMActivationFlowLanguageDialog',
  components: {
    SMDialog,
    SMLanguageSelection
  },
  data() {
    return {
      imageUrl,
      emailsEnabled: false
    }
  },
  computed: {
    ...mapState([ 'isDialogVisible', 'isLoading' ]) as {
      isDialogVisible: () => boolean;
      isLoading: () => boolean;
    },
    ...mapGetters('user', [ 'getUser' ]) as {
      getUser: () => Partial<IUser>;
    },
    ...mapGetters('user', [ 'getUserFullName' ]) as {
      getUserFullName: () => string;
    },
    ...mapGetters('language', [ 'getCurrentLanguage' ]) as {
      getCurrentLanguage: () => string;
    },
    ...mapGetters('realmSettings', [ 'realmSettings' ]) as {
      realmSettings: () => IUnauthenticatedRealmSettings | undefined;
    },
    nameToDisplay(): string {
      return this.realmSettings?.general.uses_formal_language ? this.getUserFullName : (this.getUser.firstname ?? '')
    }
  },
  methods: {
    ...mapActions([ 'updateUser' ]) as {
      updateUser: (emailsEnabled: boolean) => Promise<void>;
    },
    done(): void {
      this.$emit('change', this.getCurrentLanguage) // switch the language in Phoenix
      this.updateUser(this.emailsEnabled)
      this.$trackEvent(COMPLETE_ONBOARDING_WIZARD, this.$options.name, {
        approved_emails: this.emailsEnabled
      })
    }
  }
})
