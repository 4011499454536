






















































import Vue from 'vue'
import { INotificationItem } from '@src/types/notifications.types'
import { VIEW_BADGE_NOTIFICATION, CLOSE_BADGE_NOTIFICATION } from '@src/plugins/analytics/events/ui/NotificationsDialog.events'

import { mapGetters, mapActions } from 'vuex'
import { getWindowObject } from '@src/utilities/iframeDetection'
import NotificationDialogItem from './NotificationDialogItem.vue'

export default Vue.extend({
  name: 'NotificationsDialog',
  components: {
    NotificationDialogItem
  },
  data() {
    return {
      carouselIndex: 0
    }
  },
  computed: {
    ...mapGetters('notifications', [ 'getUnreadBadgesNotifications' ]) as {
      getUnreadBadgesNotifications: () => INotificationItem[];
    },
    shouldHideControls(): boolean {
      return this.getUnreadBadgesNotifications?.length <= 1
    },
    dialogWidth(): string {
      return this.$vuetify.breakpoint.smAndUp ? '480px' : '100%'
    },
    dialog: {
      get(): boolean {
        const dialogVal = this.shouldShowDialog
        if (dialogVal) {
          this.trackBadgeNotificationView()
        }
        return dialogVal
      },
      set(value: boolean): void {
        if (!value) {
          this.markBadgesNotificationsAsRead()
        }
      }
    },
    shouldShowDialog(): boolean {
      return this.getUnreadBadgesNotifications?.length > 0 && !('Cypress' in getWindowObject())
    },
    notificationsCount(): number {
      return this.getUnreadBadgesNotifications.length
    },
    currentNotificationType(): string {
      if (this.getUnreadBadgesNotifications.length > 1) {
        return this.getUnreadBadgesNotifications[this.carouselIndex].target_type
      }
      return this.getUnreadBadgesNotifications[0].target_type
    }
  },
  methods: {
    ...mapActions('notifications', [ 'markBadgesNotificationsAsRead' ]) as {
      markBadgesNotificationsAsRead: () => void;
    },
    trackBadgeNotificationView(): void {
      this.$trackEvent(VIEW_BADGE_NOTIFICATION, this.$options.name, {
        badge_type: this.currentNotificationType,
        badge_count: this.notificationsCount
      })
    },
    trackCloseBadgeNotification(element: 'ok_button' |'close_button' |'backdrop'): void {
      this.$trackEvent(CLOSE_BADGE_NOTIFICATION, this.$options.name, {
        badge_type: this.currentNotificationType,
        badge_count: this.notificationsCount,
        element
      })
    }
  }
})
