import { IQuestionComplete } from '@src/types/question.types'
import {
  Ref, InjectionKey
} from '@vue/composition-api'

export const isWritingSolution = Symbol('isWritingSolution') as InjectionKey<Ref<boolean>>
export const setIsWritingSolution = Symbol('setIsWritingSolution') as InjectionKey<(val: boolean) => void>
export const questionData = Symbol('questionData') as InjectionKey<Ref<IQuestionComplete>>
export const lastValidationRequestData = Symbol('lastValidationRequestData') as InjectionKey<Ref<string | undefined>>
export const setLastValidationRequestFunction = Symbol('setLastValidationRequestFunction') as InjectionKey<(val: string) => void>

export const fetchQuestionFunction = Symbol('fetchQuestionFunction') as InjectionKey<() => void>
