export enum JobToSkillWizardSteps {
  Title = 0,
  Tags = 1
}

export interface IJobSuggestionResponse {
  values: string[];
}

export interface IJobSkill {
  concept_uri: string;
  label: string;
  relation_type: string;
  skill_type: string;
}

export interface IJobSkillResponse {
  values: IJobSkill[];
}
