/**
   * Replace all mention placeholders with actual links.
   *
   * @param text
   * @returns {string}
   */
export function placeholdersToLinks(text: string): string {
  if (text) {
    return text.replace(/\[~(.+?)\|id:([0-9]+)\]/g, (_, label, id) => {
      return `<a class="text-decoration-none primary--text" onclick="event.stopPropagation();" href="/user/${id}">${label}</a>`
    })
  }
  return text
}

/**
   * @param id
   * @param label name to be displayed
   * @returns {string} The mention placeholder with the pre-defined syntax
   */
function getUserPlaceholder(id: number, label: string): string {
  return (id && label) ? `[~${label}|id:${id}]` : ''
}

/**
   * Replace all actual links with the placeholders
   *
   * @param text
   * @returns {string}
   */
export function linksToPlaceholders(text: string): string | boolean {
  if (text) {
    /* eslint-disable-next-line no-unused-vars */
    return text.replace(/<a[^>]*href="\/user\/(\d+)\/?"[^>]*>([^<]*)<\/a>/g, (_, id, label) => {
      return getUserPlaceholder(id, label)
    })
  }
  return text
}

export function tiptapHtmlToPlaceholders(text?: string): string | undefined {
  if (text) {
    return text.replace(/<span[^>]*data-id="(\d+)"[^>]*>([^<]*)<\/span>/g, (match, id, label) => {
      return getUserPlaceholder(id, label.replace('@', ''))
    })
  }
  return text
}

export function tiptapHtmlToHtml(text?: string): string | undefined {
  if (text) {
    return placeholdersToLinks(tiptapHtmlToPlaceholders(text) as string)
  }
  return text
}

export function placeholdersToTiptapHtml(text? : string): string {
  if (text) {
    return text.replace(/\[~(.+?)\|id:([0-9]+)\]/g, (match, label, id) => {
      return `<span data-type="mention" class="mention" data-id="${id}" data-label="${label}">${label}</span>`
    })
  }
  return ''
}
