import { computed, Ref } from '@vue/composition-api'
import { getFullName } from '@src/utilities/userUtils'
import { IAutocompleteItemModel } from '@src/types/autocomplete.types'
import { IUser } from '@src/types/user.types'
import { useI18n } from '@src/plugins/i18n'

interface IComposable {
  questionSearchEvaluating: Ref<boolean>;
  userSearchEvaluating: Ref<boolean>;
  questionSearchResults: Ref<string[]>;
  userSearchResults: Ref<IUser[]>;
}

export function useAutocompleteSearchResultsAggregation({
  questionSearchEvaluating,
  userSearchEvaluating,
  questionSearchResults,
  userSearchResults
}: IComposable) {
  const { t } = useI18n()

  const evaluating = computed(() => questionSearchEvaluating.value || userSearchEvaluating.value)
  const searchResults = computed(() => {
    const allResults: IAutocompleteItemModel[] = [
      ...questionSearchResults.value.map((result) => ({ text: result, value: { image: null, text: result } })),
      { divider: true },
      { header: t('search.people.title').toString() },
      ...userSearchResults.value.map((result) => ({ text: getFullName(result.firstname, result.lastname), value: { image: result.image, text: getFullName(result.firstname, result.lastname) } }))
    ]

    return allResults
      .filter((result) => !result.header || userSearchResults.value.length) // remove the "People" header in case there's no user search results
      .filter((result) => !result.divider || userSearchResults.value.length) // remove the divider in case there's no user search results
  })

  return { evaluating, searchResults }
}
