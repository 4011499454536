





















import { IUpdate } from '@src/types/featureUpdates'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'SMWhatsNewItem',
  props: {
    update: {
      type: Object as () => IUpdate,
      required: true
    }
  },
  methods: {
    closeOnAnchorClick(event: Event) {
      if (event.target instanceof HTMLAnchorElement) {
        this.$emit('close')
      }
    }
  }
})
