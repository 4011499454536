/* eslint-disable import/prefer-default-export */
export enum FeedbackType {
  PRODUCT = 'product',
  ANALYTICS = 'analytics'
}

export interface IFeedback {
  description: string;
  score?: number;
  feedback_type: FeedbackType;
}

export interface IFeedbackResponse {
  description: string;
  id: number;
}
