import { AxiosInstance } from 'axios'

export interface IVersionResponse {
  name: string;
  version: string;
}

export function versionApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/version'
  const methods = {
    getVersion(currentClientVersion: string) {
      return api.get<IVersionResponse>(baseUrl, { params: { currentClientVersion } })
    }
  }
  return methods
}
