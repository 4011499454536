























import { PropType, defineComponent } from '@vue/composition-api'
import useUser from '@src/composables/user'
import { ActivationFlowStep } from '@src/types/activationFlow.types'
import useStore from '@src/store'
import { onboardingDialogApi } from '@src/api/apiModuleInstances'
import ActivationFlowStepper from './ActivationFlowStepper.vue'

export default defineComponent({
  name: 'ActivationFlowHeader',
  components: {
    ActivationFlowStepper
  },
  props: {
    totalSteps: {
      type: Number,
      required: true
    },
    steps: {
      type: Array as PropType<ActivationFlowStep[]>,
      required: true
    },
    currentStep: {
      type: String as PropType<ActivationFlowStep>,
      required: true
    }
  },
  setup() {
    const { isNewUserSevenDays } = useUser()
    const store = useStore()

    async function closeDialog() {
      store.dispatch('activationFlow/done')
      await onboardingDialogApi.completeOnboardingDialog()
    }

    return {
      isNewUserSevenDays,
      ActivationFlowStep,
      closeDialog
    }
  }
})
