import { AxiosInstance } from 'axios'
import { IOnboardingChecklist } from '../../../types/onboardingChecklist.types'
import { createOptimizedAPI } from '../utils'

export interface IOnboardingDialogStatus {
  completed_date: string;
}

export function onboardingChecklistApiFactory(api: AxiosInstance) {
  const baseUrl = '/web/api/v2/onboarding-checklist'
  const methods = {
    getOnboardingChecklist() {
      return api.get<IOnboardingChecklist>(baseUrl)
    },
    saveOnboardingChecklist(onboardingChecklist: IOnboardingChecklist) {
      return api.post<IOnboardingChecklist>(baseUrl, { onboardingChecklist })
    },
    resetOnboardingChecklist() {
      return api.delete<void>(baseUrl)
    }
  }
  return createOptimizedAPI(methods)
}
