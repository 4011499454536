import { IRootState } from '@src/types/store.types'
import {
  ActionTree, MutationTree, GetterTree, Module
} from 'vuex'
import { searchApi } from '@src/api/apiModuleInstances'
import { v4 as uuidv4 } from 'uuid'
import { IExpertsApiResp } from '@src/api/modules/phoenix/search'

export interface ISearchV2QuestionState {
  searchTerm: string,
  searchResults?: IExpertsApiResp
  searchLoading: boolean
  searchId?: string
}

const state: ISearchV2QuestionState = {
  searchTerm: '',
  searchResults: undefined,
  searchLoading: false,
  searchId: undefined
}

const getters: GetterTree<ISearchV2QuestionState, IRootState> = {
  getResults(state) {
    return state.searchResults?.experts || []
  },
  getResultsLoading(state) {
    return state.searchLoading
  },
  getTabCountString(state) {
    if (state.searchLoading) {
      return ''
    }
    return `(${state.searchResults?.experts?.length || 0})`
  },
  getSearchId(state) {
    return state.searchId
  }
}

const actions: ActionTree<ISearchV2QuestionState, IRootState> = {
  async fetchSearchResults({ commit, state }, query : string) {
    if (query === state.searchTerm) {
      return
    }
    commit('setQuestionSearchLoading', true)
    try {
      const { data } = await searchApi.getExperts(query)
      commit('setSearchTerm', query)
      commit('setResults', data)
      commit('setSearchId', uuidv4())
    } finally {
      commit('setQuestionSearchLoading', false)
    }
  }
}

const mutations: MutationTree<ISearchV2QuestionState> = {
  setResults(state, results: IExpertsApiResp): void { state.searchResults = results },
  setSearchTerm(state, term: string): void { state.searchTerm = term },
  setQuestionSearchLoading(state, loading: boolean): void { state.searchLoading = loading },
  setSearchId(state, searchId: string): void { state.searchId = searchId }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
} as Module<ISearchV2QuestionState, IRootState>
