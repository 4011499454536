import VueCompositionAPI, { computed } from '@vue/composition-api'
import { OnboardingTooltipId, IUpdateOverlayCutoutPayload, OnboardingTooltipFlow } from '@src/types/onboardingTooltips.types'
import useStore from '@src/store'
import Vue from 'vue'

Vue.use(VueCompositionAPI)

export function useOnboardingTooltips() {
  const store = useStore()

  const currentTooltipId = computed(() => store.getters['activationFlow/tooltips/currentTooltipId'] as OnboardingTooltipId | undefined)
  const currentTooltipIndex = computed(() => store.getters['activationFlow/tooltips/currentTooltipIndex'] as number)
  const overlayCutoutDimensionsOfCurrentTooltip = computed(() => store.getters['activationFlow/tooltips/overlayCutoutDimensionsOfCurrentTooltip'] as DOMRect)
  const totalTooltipsOfCurrentFlow = computed(() => store.getters['activationFlow/tooltips/totalTooltipsOfCurrentFlow'] as number)
  const currentTooltipFlow = computed(() => store.getters['activationFlow/tooltips/currentTooltipFlow'] as OnboardingTooltipFlow)
  const isHighlighting = computed(() => !!currentTooltipId.value)

  const updateOverlayCutout = (payload: IUpdateOverlayCutoutPayload): void => store.commit('activationFlow/tooltips/updateOverlayCutout', payload)
  const updateBreakpoint = (innerWidth: number): void => store.commit('activationFlow/tooltips/updateBreakpoint', innerWidth)
  const dismissOnboardingTooltips = (): Promise<void> => store.dispatch('activationFlow/tooltips/dismissOnboardingTooltips')
  const nextOnboardingTooltip = (): Promise<void> => store.dispatch('activationFlow/tooltips/nextOnboardingTooltip')
  const dismissEducationalTooltips = (): Promise<void> => store.dispatch('activationFlow/tooltips/dismissEducationalTooltips')
  const nextEducationalTooltip = (): Promise<void> => store.dispatch('activationFlow/tooltips/nextEducationalTooltip')

  const dynamicCutoutCss = computed(() => ({
    top: `${overlayCutoutDimensionsOfCurrentTooltip.value.top}px`,
    left: `${overlayCutoutDimensionsOfCurrentTooltip.value.left}px`,
    padding: `${overlayCutoutDimensionsOfCurrentTooltip.value.height / 2}px ${overlayCutoutDimensionsOfCurrentTooltip.value.width / 2}px`
  }))

  return {
    currentTooltipId,
    currentTooltipIndex,
    overlayCutoutDimensionsOfCurrentTooltip,
    isHighlighting,
    totalTooltipsOfCurrentFlow,
    currentTooltipFlow,
    dynamicCutoutCss,
    updateOverlayCutout,
    updateBreakpoint,
    dismissOnboardingTooltips,
    nextOnboardingTooltip,
    dismissEducationalTooltips,
    nextEducationalTooltip
  }
}
