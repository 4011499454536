import { AxiosInstance } from 'axios'
import _pick from 'lodash/pick'
import { ILatestContribution } from '../../../types/contribution.types'
import {
  IUser,
  IFollow,
  ISkills,
  IExpertiseTag,
  IOAuthClient,
  IGetUsersResponse,
  IGetUsersParams,
  IUserSettings,
  IUserActivity,
  IUserFollowing,
  ISettingsParams
} from '../../../types/user.types'
import { ISolutionAuthoritiesOfUserResponse } from '../../../types/solutionApproval.types'
import { ITagsApiResponse, ITagWithExpertise, IUpdateManualInterestsResponse } from '../../../types/tags.types'
import { IBadgeFamilyRaw } from '../../../types/badges.types'
import { IUsersKnowledgeSpacesResponse } from '../../../types/knowledgeSpaces.types'
import { INotificationChannelSettings } from '../../../types/notifications.types'
import { IPaginatedApiResp } from '../../../types/api.types'
import { IAnnouncementComplete } from '../../../types/announcements.types'
import { IExpiredAnswersResponse } from '../../../types/solution.types'
import { createOptimizedAPI } from '../utils'

export interface IExpertiseTagsResponse {
  tags: IExpertiseTag[];
  approximate_total: number;
}

export interface IUsersApiFactoryOptions {
  overrideFirstSeenForCurrentUser: boolean
}

export function usersApiFactory(api: AxiosInstance, options?: IUsersApiFactoryOptions) {
  const baseUrl = '/api/v1/users'
  const methods = {
    async getCurrentUser() {
      const response = await api.get<IUser>(`${baseUrl}/me`)
      if (options?.overrideFirstSeenForCurrentUser) {
        return {
          ...response,
          data: {
            ...response.data,
            first_seen: new Date().toISOString()
          }
        }
      }
      return response
    },
    getConceptExperience(globalConceptId: string) {
      return api.get<ILatestContribution[]>(`${baseUrl}/me/concept-experience/${globalConceptId}`)
    },
    getExpiredAnswers() {
      return api.get<IExpiredAnswersResponse>(`${baseUrl}/me/solutions/expired`)
    },
    getUser(userId: number|string) {
      return api.get<IUser>(`${baseUrl}/${userId.toString()}`)
    },
    getAnnouncements() {
      return api.get<IAnnouncementComplete[]>(`${baseUrl}/me/announcements`)
    },
    getUsers(params: IGetUsersParams = {}) {
      const requestParams: any = _pick(params, [ 'query', 'limit', 'offset' ])

      if (params.sort) {
        requestParams.sort = `${params.sort}.${params.sortDirection || 'asc'}`
      }

      if (params.deanonymizeUsersEmail) {
        requestParams.deanonymize_users_email = true
      }

      const include = [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...(params.deleted ? [ 'deleted' ] : []),
        ...(params.technical ? [ 'technical' ] : [])
      ]
      if (include.length) {
        requestParams.include = include.join(',')
      }

      if (params.filter) {
        requestParams.filter = params.filter
      } else if (params.filter !== null) {
        requestParams.filter = 'active' // if filter is not explicitly set to null, use 'active' as default
      }

      return api.get<IGetUsersResponse>(baseUrl, {
        params: requestParams
      })
    },
    isFollowingUser(userId: number) {
      return api.get<IFollow>(`${baseUrl}/${userId}/follow`)
    },
    unfollow(userId: number) {
      return api.delete<IFollow>(`${baseUrl}/${userId}/follow`)
    },
    follow(userId: number) {
      return api.put<IFollow>(`${baseUrl}/${userId}/follow`)
    },
    getSkills(userId: number) {
      return api.get<ISkills>(`${baseUrl}/${userId}/tag-labels/skills-map`)
    },
    searchTags({
      query,
      userId,
      limit = 5,
      offset = 0,
      query_type = 'prefix'
    } : {
      query: string,
      userId: number,
      limit?: number,
      offset?: number,
      query_type?: string
    }) {
      return api.get<ITagsApiResponse>(`${baseUrl}/${userId}/tag-labels/search`, {
        params: {
          query,
          limit,
          offset,
          query_type
        }
      })
    },
    updateExpertise(userId: number, tags: string[] = [], removeTags: string[] = []) {
      return api.put<ITagWithExpertise>(`${baseUrl}/${userId}/tag-labels/know-how`, { tags, remove_tags: removeTags })
    },
    updateManualInterests(userId: number, tags: string[]) {
      return api.put<IUpdateManualInterestsResponse>(`${baseUrl}/${userId}/tag-labels/manual-interests`, { tags })
    },
    getExpertiseTags(userId: number) {
      return api.get<IExpertiseTagsResponse>(`${baseUrl}/${userId}/tag-labels/expertise`)
    },
    getOAuthClients(userId: number) {
      return api.get<IOAuthClient[]>(`${baseUrl}/${userId}/oauth-clients`)
    },
    getRoles() {
      return api.get<string[]>(`${baseUrl}/me/roles/evaluated`)
    },
    removeSkill(userId: number, tag: string) {
      return api.delete<void>(`${baseUrl}/${userId}/tag-labels/know-how/${encodeURIComponent(tag)}`)
    },
    addSkill(userId: number, tag: string) {
      return api.post<void>(`${baseUrl}/${userId}/tag-labels/know-how/${encodeURIComponent(tag)}`)
    },
    getSolutionAuthorities() {
      return api.get<ISolutionAuthoritiesOfUserResponse>(`${baseUrl}/me/solution-authorities`)
    },
    setEmailSettings(settings: Partial<INotificationChannelSettings>) {
      return api.put<INotificationChannelSettings>(`${baseUrl}/me/settings/communication/email`, settings)
    },
    setWebPushSettings(settings: Partial<INotificationChannelSettings>) {
      return api.put<INotificationChannelSettings>(`${baseUrl}/me/settings/communication/push/web`, settings)
    },
    updateSettings(settings: Partial<ISettingsParams>) {
      return api.put<void>(`${baseUrl}/me/settings`, settings)
    },
    getEmailSettings() {
      return api.get<INotificationChannelSettings>(`${baseUrl}/me/settings/communication/email`)
    },
    getWebPushSettings() {
      return api.get<INotificationChannelSettings>(`${baseUrl}/me/settings/communication/push/web`)
    },
    getSettings() {
      return api.get<IUserSettings>(`${baseUrl}/me/settings`)
    },
    getBadges(userId: number) {
      return api.get<{ badges: IBadgeFamilyRaw[] }>(`${baseUrl}/${userId}/badges`)
    },
    getKnowledgeSpaces() {
      return api.get<IUsersKnowledgeSpacesResponse>(`${baseUrl}/me/knowledge-spaces`)
    },
    joinKnowledgeSpace(knowledgeSpaceId: number) {
      return api.put<void>(`${baseUrl}/me/knowledge-spaces/${knowledgeSpaceId}`)
    },
    leaveKnowledgeSpace(knowledgeSpaceId: number) {
      return api.delete<void>(`${baseUrl}/me/knowledge-spaces/${knowledgeSpaceId}`)
    },
    requestKnowledgeSpaceAccess(knowledgeSpaceId: number) {
      return api.put<void>(`${baseUrl}/me/knowledge-spaces/${knowledgeSpaceId}/membership-request`)
    },
    getAskNicelyEmailHash(userId: number) {
      return api.get<{ email_hash: string }>(`${baseUrl}/${userId}/asknicely`)
    },
    getUserActivity(userId: number, limit = 20, offset = 0) {
      return api.get<IPaginatedApiResp<IUserActivity[]>>(`${baseUrl}/${userId || 'me'}/activities?limit=${limit}&offset=${offset}`)
    },
    getFollowing(userId: number, offset = 0, limit = 30) {
      return api.get<IPaginatedApiResp<IUserFollowing[]>>(`${baseUrl}/${userId}/following`, {
        params: { offset, limit }
      })
    },
    getFollowingSuggestions(userId: number, limit = 2) {
      return api.get<IUser[]>(`${baseUrl}/${userId}/subjects-to-follow`, {
        params: { limit }
      })
    },
    getRecentRecommendations(knowledgeSpaceId?: number, limit = 3) {
      return api.get<IUser[]>(`${baseUrl}/me/users-i-recommended`, {
        params: { limit, knowledge_space: knowledgeSpaceId }
      })
    },
    getRecentMentions(knowledgeSpaceId?: number, limit = 3) {
      return api.get<IUser[]>(`${baseUrl}/me/users-i-mentioned`, {
        params: { limit, knowledge_space: knowledgeSpaceId }
      })
    },
    getRecentShares(knowledgeSpaceId?: number, limit = 3) {
      return api.get<IUser[]>(`${baseUrl}/me/users-i-shared-with`, {
        params: { limit, knowledge_space: knowledgeSpaceId }
      })
    }
  }
  return createOptimizedAPI(methods)
}
