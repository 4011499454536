import { AxiosInstance } from 'axios'
import { createOptimizedAPI } from '../utils'
import { IStandardizedConceptAllLanguages } from '../../../types/concept.types'

interface IConceptCreate {
  ontology_id?: string;
  languages: {
    aliases: string[]; // TODO: DEV-9175: why do we still use "aliases" and "languageId" in the payload here?
    language_id: string;
    primary_label?: string | null;
  }[];
}

export interface IEntityConceptResponse {
  id: string;
  last_update: string;
  entity_type: string;
  created: string;
  concepts: IStandardizedConceptAllLanguages[];
}

interface IEntity {
  entity_type: string;
  texts: {
    text: string;
    format: 'plain' | 'html_with_mention';
  }[];
}

export function entitiesApiFactory(api: AxiosInstance) {
  const baseUrl = '/api/v2/learning/entities'
  const methods = {
    getEntityConcepts(questionAlias: string) {
      return api.get<IStandardizedConceptAllLanguages[]>(`${baseUrl}/${questionAlias}/concepts`)
    },
    createEntityConcept(questionAlias: string, conceptId: string, obj: IConceptCreate) {
      return api.post<void>(`${baseUrl}/${questionAlias}/concepts/${conceptId}`, obj)
    },
    deleteEntityConcept({
      questionAlias,
      conceptId,
      languageId,
      label_match
    }: {
      questionAlias: string,
      conceptId: string,
      languageId: string | undefined,
      label_match: string
    }) {
      return api.delete<void>(`${baseUrl}/${questionAlias}/concepts/${conceptId}/languages/${languageId}/labels/${label_match}`)
    },
    createOrUpdateEntity(questionAlias: string, obj: IEntity) {
      return api.put<IEntityConceptResponse>(`${baseUrl}/${questionAlias}`, obj)
    }
  }
  return createOptimizedAPI(methods)
}
