import { Ref, computed, ref } from '@vue/composition-api'
import { computedAsync } from '@vueuse/core'
import { searchApi } from '@src/api/apiModuleInstances'
import { getFullName } from '@src/utilities/userUtils'
import { getChunks, chunksContainMatch } from '@src/ui/SMHighlightWords/composables/useHighlightWords'

export function useUserAutocompleteSearch(searchTerm: Ref<string | null>) {
  const evaluating = ref(false)
  const usersStreamResults = computedAsync(
    async (onCancel) => {
      const abortController = new AbortController()
      if (searchTerm.value && searchTerm.value.length > 1) {
        const { data: { items } } = await searchApi.getUsersStream({
          query: searchTerm.value,
          limit: 2,
          offset: undefined,
          abortController
        })
        return items
      }

      onCancel(() => abortController.abort())
      return []
    },
    [],
    evaluating
  )

  const searchResults = computed(() => usersStreamResults.value.filter((user) => {
    const fullName = getFullName(user.firstname, user.lastname)
    return searchTerm.value !== null ? chunksContainMatch(getChunks(fullName, searchTerm.value)) : false
  }))

  return { searchResults, evaluating }
}
