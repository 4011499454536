import { ITrackingProps } from '@src/types/events.types'

const commonProps = {
  type: 'icon_button' // TODO this is not a valid value for 'type'
} as ITrackingProps

export const CLICK_NOTIFICATION_ITEM = 'clickNotificationIcon'

export default {
  SMNotificationsButton: {
    [CLICK_NOTIFICATION_ITEM]: (
      customProps:
      {
        notification_status: 'unread' | 'read';
        icon_status: 'unseen' | 'seen';
      }
    ): ITrackingProps => ({
      ...commonProps,
      ...customProps
    })
  }
}
