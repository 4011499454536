




















































import {
  computed, defineComponent, inject, PropType, ref, toRefs
} from '@vue/composition-api'
import { useConceptSearch } from '@src/composables/useConceptSearch'
import { topicLabelsToString } from '@src/utilities/conceptSearch'
import SMConceptSearchResultItem from '@src/ui/SMTopicsInput/components/SMConceptSearchResultItem.vue'
import SMConceptDescriptionMenu from '@src/ui/SMConceptDescriptionMenu/index.vue'
import { IComboboxSearchResultItem, IConceptSearchResultItem, IStandardizedConceptSingleLanguage } from '@src/types/concept.types'
import SMTopicsChip from '../../../SMTopicsChip/index.vue'
import { SMAskInjectables } from '../../constants'
import { IUseTracking } from '../../composables/tracking'
import { IFormError } from '../../composables/validation'

export default defineComponent({
  name: 'SMConceptInput',
  components: {
    SMTopicsChip,
    SMConceptSearchResultItem,
    SMConceptDescriptionMenu
  },
  props: {
    value: {
      type: Array as PropType<IStandardizedConceptSingleLanguage[]>,
      default: () => []
    },
    error: {
      type: Object as PropType<IFormError | undefined>,
      default: undefined
    },
    language: {
      type: String,
      default: 'xx'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'question.concept.select.label'
    }
  },
  setup(props, { emit }) {
    const { trackAddTag, trackRemoveTag } = inject(SMAskInjectables.Tracking) as IUseTracking
    const { language } = toRefs(props)

    const query = ref('')

    const {
      searchResults: conceptSearchResults, onSelectSearchResult
    } = useConceptSearch(query, language)
    const errorMessages = computed(() => (props.error?.translatedError ? [ props.error?.translatedError ] : undefined))

    const conceptChips = computed<(IStandardizedConceptSingleLanguage & { displayText: string })[]>(() => {
      return props.value.map((topic) => ({
        ...topic,
        displayText: topicLabelsToString({
          concept_id: topic.concept_id,
          label_match: topic.label_match,
          primary_label: topic.primary_label,
          language: topic.language
        })
      }))
    })

    const searchResults = computed<string[] | IComboboxSearchResultItem[]>(() => {
      return conceptSearchResults.value.map((result) => ({
        text: topicLabelsToString(result),
        value: result
      }))
    })

    const addNewConcept = async (topicConcept: IConceptSearchResultItem | string) => {
      if (topicConcept) {
        const conceptualTopic = await onSelectSearchResult(topicConcept)
        emit('input', [ ...props.value, conceptualTopic ])
        trackAddTag(conceptualTopic)
        query.value = ''
      }
    }

    const add = (concepts: (IComboboxSearchResultItem | string | IStandardizedConceptSingleLanguage)[]) => {
      const newAddedConcept = concepts.find((concept) => typeof concept === 'string' || (typeof concept === 'object' && 'value' in concept))
      if (typeof newAddedConcept === 'string') {
        addNewConcept(newAddedConcept)
      } else if (typeof newAddedConcept === 'object' && 'value' in newAddedConcept) {
        addNewConcept(newAddedConcept.value)
      }
    }

    const remove = (removedTopic: IStandardizedConceptSingleLanguage & { displayText: string }) => {
      const updatedList = conceptChips.value.filter((topic) => {
        return topic.concept_id !== removedTopic.concept_id
      })
      emit('input', updatedList)
      trackRemoveTag(removedTopic)
    }

    function isDisabled(item: string | IComboboxSearchResultItem) {
      if (typeof item === 'string') {
        return conceptChips.value.some((topic) => topic.displayText === item)
      }
      return conceptChips.value.some((topic) => topic.displayText === item.text)
    }

    return {
      query,
      searchResults,
      conceptChips,
      errorMessages,
      topicLabelsToString,
      isDisabled,
      remove,
      add
    }
  }
})
