import { ITrackingProps } from '@src/types/events.types'

export const CLICK_ADD_EMOJI = 'clickAddEmoji'
export const CLICK_ADD_HYPERLINK = 'clickAddHyperlink'
export const CLICK_ADD_IMAGE = 'clickAddImage'
export const CLICK_ADD_VIDEO = 'clickAddVideo'

const commonProps = {
  component: 'tool_bar',
  element: 'icon',
  type: 'click'
} as ITrackingProps

export default {
  SMTipTapToolbar: {
    [CLICK_ADD_EMOJI]: (customProps: { state: string }): ITrackingProps => ({
      ...commonProps,
      ...customProps
    }),
    [CLICK_ADD_HYPERLINK]: (customProps: { state: string }): ITrackingProps => ({
      ...commonProps,
      ...customProps
    }),
    [CLICK_ADD_IMAGE]: (customProps: { state: string }): ITrackingProps => ({
      ...commonProps,
      ...customProps
    }),
    [CLICK_ADD_VIDEO]: (customProps: { state: string }): ITrackingProps => ({
      ...commonProps,
      ...customProps
    })
  }
}
