import {
  MutationTree, ActionTree, Module, GetterTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { ITermsVersion, ITermsTranslationContent, ITermsGetVersionsResponse } from '@src/types/terms.types'
import _find from 'lodash/find'
import _compact from 'lodash/compact'
import { InterfaceLanguage } from '@src/types/language.types'
import { ITermsOfUseRealmSettings } from '@src/types/realmSettings.types'
import { realmSettingsApi, termsGraphQlApi } from '@src/api/apiModuleInstances'

export interface ITermsState {
  isLoading: boolean;
  isSettingsLoading: boolean;
  enabled: boolean;
  checkboxEnabled: boolean;
  versions: ITermsVersion[];
  isPublishDialogVisible: boolean;
  shouldReload: boolean;
}

const state: ITermsState = {
  isLoading: false,
  isSettingsLoading: false,
  enabled: false,
  checkboxEnabled: false,
  versions: [],
  isPublishDialogVisible: false,
  shouldReload: true
}

const actions: ActionTree<ITermsState, IRootState> = {
  async loadSettings({ commit }) {
    try {
      commit('setSettingsLoading', true)
      const settings = await realmSettingsApi.getTermsOfUseSettings()
      commit('setSettings', settings.data)
    } finally {
      commit('setSettingsLoading', false)
    }
  },
  async setEnabled({ commit, dispatch, state }, value: boolean) {
    try {
      commit('setSettingsLoading', true)
      const settingsToUpdate = {
        enabled: value,
        checkbox_enabled: value ? state.checkboxEnabled : false // when turning off, also turn checkbox off
      } as ITermsOfUseRealmSettings
      const updatedSettings = await realmSettingsApi.setTermsOfUseSettings(settingsToUpdate)
      commit('setSettings', updatedSettings.data)
      dispatch('snackbar/show', 'admin.extensions.cds.snackbar.settings-saved', { root: true })
    } finally {
      commit('setSettingsLoading', false)
    }
  },
  async setCheckboxEnabled({ commit, dispatch }, value: boolean) {
    try {
      commit('setSettingsLoading', true)
      const updatedSettings = await realmSettingsApi.setTermsOfUseSettings({
        checkbox_enabled: value
      })
      commit('setSettings', updatedSettings.data)
      dispatch('snackbar/show', 'admin.extensions.cds.snackbar.settings-saved', { root: true })
    } finally {
      commit('setSettingsLoading', false)
    }
  },
  async loadVersions({ commit, dispatch, state }) {
    if (state.shouldReload) {
      try {
        commit('setLoading', true)
        const versions = await termsGraphQlApi.getVersions()
        commit('setVersions', versions)
        commit('setLoaded')
      } catch (error) {
        dispatch('snackbar/show', error, { root: true })
      } finally {
        commit('setLoading', false)
      }
    }
  },
  async create({ commit, dispatch }) {
    try {
      commit('setLoading', true)
      const newVersion = await termsGraphQlApi.createDraft()
      return newVersion.id
    } catch (error) {
      commit('shouldReload')
      dispatch('loadVersions')
      throw error // re-throw the error such that the component doesn't change the route to a non-existent ID
    } finally {
      commit('shouldReload')
      commit('setLoading', false)
    }
  },
  async update({ commit, dispatch }, version: ITermsVersion) {
    try {
      commit('setLoading', true)
      await termsGraphQlApi.updateVersion({ id: version.id, title: version.title })
      dispatch('snackbar/show', 'admin.extensions.cds.snackbar.draft-saved', { root: true })
    } catch (error) {
      dispatch('snackbar/show', error, { root: true })
    } finally {
      commit('shouldReload')
      commit('setLoading', false)
    }
  },
  async updateTranslations({ commit, dispatch }, { id, language, translations }: { id: number; language: InterfaceLanguage; translations: ITermsTranslationContent }) {
    try {
      commit('setLoading', true)
      await termsGraphQlApi.updateTranslation({
        termsId: id,
        languageId: language,
        title: translations.title,
        text: translations.text
      })
      dispatch('snackbar/show', 'admin.extensions.cds.snackbar.translations-saved', { root: true })
    } catch (error) {
      dispatch('snackbar/show', error, { root: true })
    } finally {
      commit('shouldReload')
      commit('setLoading', false)
    }
  },
  async deleteTranslations({ commit, dispatch }, { id, language }: { id: number; language: InterfaceLanguage }) {
    try {
      commit('setLoading', true)
      await termsGraphQlApi.deleteTranslation({
        termsId: id,
        languageId: language
      })
      dispatch('snackbar/show', 'admin.extensions.cds.snackbar.translations-deleted', { root: true })
    } catch (error) {
      dispatch('snackbar/show', error, { root: true })
    } finally {
      commit('shouldReload')
      commit('setLoading', false)
    }
  },
  async deleteVersion({ commit, dispatch }, id: number) {
    try {
      commit('setLoading', true)
      await termsGraphQlApi.deleteDraft(id)
      dispatch('snackbar/show', 'admin.extensions.cds.snackbar.draft-deleted', { root: true })
    } catch (error) {
      dispatch('snackbar/show', error, { root: true })
    } finally {
      commit('shouldReload')
      commit('setLoading', false)
    }
  },
  async publishVersion({ commit, dispatch }, id: number) {
    try {
      commit('setLoading', true)
      await termsGraphQlApi.publishVersion(id)
      dispatch('snackbar/show', 'admin.extensions.cds.snackbar.published', { root: true })
    } catch (error) {
      dispatch('snackbar/show', error, { root: true })
    } finally {
      commit('closePublishDialog')
      commit('shouldReload')
      commit('setLoading', false)
    }
  }
}

const mutations: MutationTree<ITermsState> = {
  setSettings(state, settings: ITermsOfUseRealmSettings) {
    state.enabled = settings.enabled
    state.checkboxEnabled = settings.checkbox_enabled
  },
  setVersions(state, { draftTerms, latestPublishedTerms }: ITermsGetVersionsResponse) {
    state.versions = _compact([ latestPublishedTerms, draftTerms ])
  },
  setLoading(state, isLoading: boolean) {
    state.isLoading = isLoading
  },
  setSettingsLoading(state, isLoading: boolean) {
    state.isSettingsLoading = isLoading
  },
  openPublishDialog(state) {
    state.isPublishDialogVisible = true
  },
  closePublishDialog(state) {
    state.isPublishDialogVisible = false
  },
  setLoaded(state) {
    state.shouldReload = false
  },
  shouldReload(state) {
    state.shouldReload = true
  }
}

const getters: GetterTree<ITermsState, IRootState> = {
  hasVersions(state): boolean {
    return !!state.versions.length
  },
  getVersionById(state) {
    return (id: number): ITermsVersion | undefined => _find(state.versions, { id })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
} as Module<ITermsState, IRootState>
