import { ITrackingProps } from '@src/types/events.types'

const commonProps = {
  type: 'notifications_menu' // TODO use another property for that, not type
} as ITrackingProps

export const CLICK_NOTIFICATION = 'clickNotification'

export default {
  NotificationsListItem: {
    [CLICK_NOTIFICATION]: (
      customProps:
      {
        is_gamification_badge: boolean;
        notification_status: 'unread' | 'read';
      }
    ): ITrackingProps => ({
      ...commonProps,
      ...customProps
    })
  }
}
