
















import useWhatsNew from '@src/composables/whatsNew'
import { defineComponent } from '@vue/composition-api'
import SMWhatsNewItem from './SMWhatsNewItem.vue'

export default defineComponent({
  name: 'SMWhatsNewList',
  components: {
    SMWhatsNewItem
  },
  setup() {
    const { updates, loadMore } = useWhatsNew()

    return {
      updates,
      loadMore
    }
  }
})
