import axios from 'axios'
import { ContextSetter } from '@apollo/client/link/context'
import { CookieName, getCookie } from '@src/helpers/cookie'
import { extractRealmSettingsFromURL, injectRealmSettings } from '@src/utilities/mockedRealmSettings'
import { addAxiosInterceptors } from '.'
import {
  expertSearchesApiFactory,
  homepageModulesApiFactory,
  onboardingChecklistApiFactory,
  onboardingDialogApiFactory,
  researchParticipationUsersApiFactory,
  discussionParticipationUsersApiFactory,
  savedFiltersApiFactory,
  searchApiFactory,
  translationApiFactory,
  versionApiFactory,
  userGraphqlApiFactory,
  termsGraphQlApiFactory,
  inviteUserCreationGraphQlApiFactory,
  usersApiFactory,
  questionsApiFactory,
  phoenixPersonalisationApiFactory,
  settingsApiFactory,
  settingsAdminApiFactory,
  dismissedExpertiseFeedbackApiFactory,
  sortingPreferenceApiFactory,
  ulfShieldExpertsApiFactory,
  personalisationApiFactory,
  entitiesApiFactory,
  conceptsApiFactory,
  notificationsApiFactory,
  careersApiFactory,
  connectorsApiFactory,
  userProjectsApiFactory
} from './modules'

const api = addAxiosInterceptors(axios.create())
const apiWithoutToken = axios.create()

const authContextSetter: ContextSetter = (_, { headers }) => {
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${getCookie(CookieName.API_TOKEN)}`
    }
  }
}

export const expertSearchesApi = expertSearchesApiFactory(api)
export const homepageModulesApi = homepageModulesApiFactory(api)
export const sortingPreferenceApi = sortingPreferenceApiFactory(api)
export const onboardingChecklistApi = onboardingChecklistApiFactory(api)
export const onboardingDialogApi = onboardingDialogApiFactory(api)
export const savedFiltersApi = savedFiltersApiFactory(api)
export const translationApi = translationApiFactory(apiWithoutToken)
export const versionApi = versionApiFactory(api)
export const researchParticipationUsersApi = researchParticipationUsersApiFactory(api)
export const discussionParticipationUsersApi = discussionParticipationUsersApiFactory(api)
export const searchApi = searchApiFactory(api)
export const userGraphQlApi = userGraphqlApiFactory(authContextSetter)
export const termsGraphQlApi = termsGraphQlApiFactory(authContextSetter)
export const inviteUserCreationGraphQlApi = inviteUserCreationGraphQlApiFactory(authContextSetter)
export const usersApi = usersApiFactory(api, { overrideFirstSeenForCurrentUser: new URLSearchParams(window.location.search).has('i-am-new') })
export const questionsApi = questionsApiFactory(api)
export const phoenixPersonalisationApi = phoenixPersonalisationApiFactory(api)
export const dismissedExpertiseFeedbackApi = dismissedExpertiseFeedbackApiFactory(api)
export const ulfShieldExpertsApi = ulfShieldExpertsApiFactory(api)
export const personalisationApi = personalisationApiFactory(api)
export const entitiesApi = entitiesApiFactory(api)
export const conceptsApi = conceptsApiFactory(api)
export const connectorsApi = connectorsApiFactory(api)
export const notificationsV2Api = notificationsApiFactory(api)
export const userProjectsApi = userProjectsApiFactory(api)
export const careersApi = careersApiFactory(api)

export const realmSettingsApi = {
  ...settingsApiFactory(api, {
    staticSettings: (window as any).REALM_SETTINGS,
    postProcessSettings(settings) {
      if (window.location.search) {
        return injectRealmSettings(settings, extractRealmSettingsFromURL(window.location.search))
      }
      return settings
    }
  }),
  ...settingsAdminApiFactory(api, {
    userGraphQlApi
  })
}
