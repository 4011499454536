import { IAnnouncementComplete } from '@src/types/announcements.types'
import { Ref, computed, ref } from '@vue/composition-api'

export function useAnnouncementTranslation(announcement: Ref<IAnnouncementComplete>) {
  const isOriginal = ref(!announcement.value.translation)
  const translatedTitle = computed(() => (isOriginal.value ? announcement.value.title : announcement.value.translation?.title))
  const translatedMessage = computed(() => (isOriginal.value ? announcement.value.message : announcement.value.translation?.message))

  function toggleTranslation() {
    isOriginal.value = !isOriginal.value
  }

  return {
    isOriginal,
    translatedTitle,
    translatedMessage,
    toggleTranslation
  }
}
