











































































































import {
  defineComponent, onMounted, ref
} from '@vue/composition-api'
import useUser from '@src/composables/user'
import useTracking from '@src/composables/tracking/tracking'
import { useOnboardingChecklist } from '@src/pinia/onboardingChecklist'
import { storeToRefs } from 'pinia'
import SMOnboardingChecklistItem from './components/SMOnboardingChecklistItem.vue'
import SMOnboardingChecklistFinished from './components/SMOnboardingChecklistFinished.vue'
import SMOnboardingFigmaPrototype from './components/SMOnboardingFigmaPrototype.vue'

export default defineComponent({
  name: 'SMOnboardingChecklist',
  components: {
    SMOnboardingChecklistItem,
    SMOnboardingChecklistFinished,
    SMOnboardingFigmaPrototype
  },
  setup() {
    const { isNewUserThirtyDays } = useUser()
    const { trackMixpanelEvent } = useTracking()
    const isPrototypeOpen = ref(false)

    function trackOnboardingChecklistItemClick(item: string) {
      trackMixpanelEvent('onboardingChecklistItemClick', {
        element: 'onboarding_checklist_item',
        type: 'click',
        item
      })
    }

    const store = useOnboardingChecklist()

    const {
      onboardingChecklistInfo,
      currentActiveItemIndex,
      menuModel,
      progress,
      readyToFinish,
      onboardingChecklistItems,
      loading
    } = storeToRefs(store)

    const { updateChecklist, loadOnboardingChecklist } = store

    onMounted(loadOnboardingChecklist)

    return {
      loading,
      menuModel,
      onboardingChecklistInfo,
      currentActiveItemIndex,
      isPrototypeOpen,
      isNewUserThirtyDays,
      progress,
      readyToFinish,
      onboardingChecklistItems,
      updateChecklist,
      trackOnboardingChecklistItemClick
    }
  }
})
