import {
  ActionTree,
  GetterTree,
  Module, MutationTree
} from 'vuex'
import { IRootState } from '@src/types/store.types'
import { ISettingsParams, IUserSettings } from '@src/types/user.types'
import userApi from '@src/api/user'

export interface IUserSettingsState {
  loading: boolean;
  error: boolean;
  settings: Partial<IUserSettings>;
}

export const state: IUserSettingsState = {
  loading: true,
  error: false,
  settings: {}
}

const getters: GetterTree<IUserSettingsState, IRootState> = {
  getLoading: (state): boolean => state.loading,
  getError: (state): boolean => state.error,
  getSettings: (state): Partial<IUserSettings> => state.settings
}

const actions: ActionTree<IUserSettingsState, IRootState> = {
  async load({ commit, state }, lazy = true): Promise<Partial<IUserSettings>> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { data: userSettings } = await (lazy
        ? userApi.cached.getSettings()
        : userApi.getSettings())

      commit('setUserSettings', userSettings)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
    return state.settings
  },
  async update({ commit }, settings: Partial<ISettingsParams>): Promise<void> {
    commit('setLoading', true)
    commit('setError', false)

    try {
      const { data: userSettings } = await userApi.updateSettings(settings)
      commit('setUserSettings', userSettings)
    } catch {
      commit('setError', true)
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations: MutationTree<IUserSettingsState> = {
  setUserSettings(state, userSettings: IUserSettings): void {
    state.settings = userSettings
  },
  setLoading(state, loading: boolean): void { state.loading = loading },
  setError(state, error: boolean): void { state.error = error }
}

export default {
  namespaced: true,
  state: () => ({ ...state }),
  getters,
  actions,
  mutations
} as Module<IUserSettingsState, IRootState>
